import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import debounce from "lodash/debounce";
import { useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import {
  EVENT_ID,
  PASSWORD_TYPE,
  ROLE,
  SHARE_KEY_PREFIX,
  Space,
} from "@highnote/server/src/core/entities";
import {
  getEntityShareKeys,
  getFullRoles,
  isSpacePrivateInboxEnabled,
} from "@highnote/server/src/core/shared-util";
import { CopyButton } from "App/common/CopyButton";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { List, ListItemButton } from "App/common/List";
import { TextInput } from "App/common/TextInput";
import { ReactComponent as ChatSVG } from "App/common/icons/chat.svg";
import { ReactComponent as DownloadSVG } from "App/common/icons/download.svg";
import { ReactComponent as LinkSVG } from "App/common/icons/link-hollow.svg";
import { ReactComponent as UploadSVG } from "App/common/icons/upload.svg";
import { ReactComponent as UserSVG } from "App/common/icons/user-hollow.svg";
import { useToast } from "App/common/useToast";
import { getAuthId } from "App/components/Auth";
import { EntityPasswordEditor } from "App/components/EntityPasswordEditor";
import { PermissionTooltip } from "App/components/PermissionTooltip";
import { BUTTON_THEME } from "App/core/Button";
import {
  CONTROLLED_SHARE_FEATURES,
  ControlledFeatureTooltipLabel,
} from "App/core/ControlledFeatureCallout";
import { Switch } from "App/core/Switch";
import { useSegmentContext } from "App/modules/useSegment";
import { useUrlContext } from "App/routes/Main/useUrlContext";
import { useShareDialogContext } from "../ShareDialogContext";

export const useEntityShareKeyInUrl = (entity: Space) => {
  const { toasted } = useToast();
  const history = useHistory();

  useEffect(() => {
    if (!entity) return;
    const query = new URLSearchParams(location.search);
    const shareKeyInUrl = query.get("shareKey");
    const shareKeyInEntity = getEntityShareKeys(entity)[0];
    if (!shareKeyInEntity) {
      const authId = getAuthId();
      const isEntityMember = (getFullRoles(entity)[authId] || []).length > 0;
      // alert anonymous user about invalid share key in URL
      if (shareKeyInUrl && !isEntityMember) {
        toasted({
          promise: Promise.reject(),
          errorMessage: `Invalid or expired share link. Please try a new share link or contact help@highnote.fm for further assistance.`,
        });
      }
      query.delete("shareKey");
    } else if (shareKeyInEntity !== shareKeyInUrl) {
      query.set("shareKey", shareKeyInEntity);
    }
    history.replace({
      pathname: location.pathname,
      search: query.toString(),
    });
  }, [entity]);
};

interface EntityShareSettings {
  isShared: boolean;
  shareRoles: ROLE[];
  sharingUpdatedAt: number;
}

export const InviteByLink = () => {
  const { trackEvent } = useSegmentContext();
  const {
    isEntityOwner,
    entity,
    entityHasChildren,
    updateEntityRoles,
    updateEntityPassword,
    defaultFeatureAlertMessage,
    canManageEntity,
    canManagePassword,
    canManageDownloadControl,
  } = useShareDialogContext();
  const { trackId } = useUrlContext();
  const entityRoles = entity?.rolesV2 || {};

  // Get all the valid share keys (must have roles attached).
  const [shareKey] = getEntityShareKeys(entity);
  const shareRoles = entityRoles[shareKey] || [];
  const isShared = shareRoles.includes(ROLE.VIEW);

  // Share link. Contains trackId if we're on a Track page.
  const rootUrl = `${window.location.origin}/space`;
  let shareLink = `${rootUrl}/${entity?.id}`;
  if (trackId) shareLink += `/${trackId}`;
  if (shareKey) {
    shareLink += `?shareKey=${shareKey}`;
  }

  const [entityShareSettings, setEntityShareSettings] =
    useState<EntityShareSettings>({
      isShared,
      shareRoles,
      sharingUpdatedAt: entity?.updatedAt || 0,
    });

  useEffect(() => {
    if (!entity) {
      return;
    }
    setEntityShareSettings((prev) => {
      // local updates will take precedence over server updates
      // unless the server updates are newer
      if (entity.updatedAt <= prev.sharingUpdatedAt) {
        return prev;
      }
      return {
        ...prev,
        isShared,
        shareRoles: isShared ? shareRoles : [],
        sharingUpdatedAt: entity.updatedAt,
      };
    });
  }, [entity?.updatedAt, isShared, shareRoles]);

  const downloadToggleable = shareRoles.includes(ROLE.DOWNLOAD)
    ? canManageDownloadControl
    : canManageEntity;

  const isPrivateInboxEnabled = isSpacePrivateInboxEnabled(entity);

  const updateShareRoles = useMemo(() => {
    return debounce(async (roles: ROLE[] | null, onError: () => void) => {
      // If we don't have a share key, generate a new one.
      const [shareKey, ...staleKeys] = getEntityShareKeys(entity);
      const latestShareKey = shareKey || `${SHARE_KEY_PREFIX}${uuidv4()}`;

      // Clean up any old share keys that may have lingered.
      const updateData = { [latestShareKey]: roles };
      staleKeys.forEach((key) => (updateData[key] = null));
      try {
        await updateEntityRoles(updateData, true);
      } catch (e) {
        onError();
      }
    }, 500);
  }, [entity]);

  const toggleRole = useCallback(
    async (role: ROLE) => {
      if (role === ROLE.VIEW) return; // Direct toggling of ROLE.VIEW and ROLE.DOWNLOAD when privateInboxEnabled is true is skipped Direct toggling of ROLE.VIEW is skipped

      const newRolesSet = new Set(entityShareSettings.shareRoles);

      if (newRolesSet.has(role)) {
        newRolesSet.delete(role);
      } else {
        newRolesSet.add(role);
        // If enabling any role and ROLE.VIEW is not already enabled, enable it
        if (!newRolesSet.has(ROLE.VIEW)) {
          newRolesSet.add(ROLE.VIEW);
        }
      }
      const updatedRoles = Array.from(newRolesSet);

      const previousSettings = entityShareSettings;
      setEntityShareSettings((prev) => ({
        ...prev,
        isShared: updatedRoles.length > 0,
        shareRoles: updatedRoles,
        sharingUpdatedAt: Date.now(),
      }));
      await updateShareRoles(updatedRoles, () => {
        // error handling is done inside `updateEntityRoles`
        // this is just to revert in case of failure
        setEntityShareSettings(previousSettings);
      });
    },
    [updateShareRoles, entityShareSettings, isPrivateInboxEnabled],
  );

  const handleEnableLinkToggle = useCallback(async () => {
    const previousSettings = entityShareSettings;
    setEntityShareSettings((prev) => ({
      ...prev,
      isShared: !prev.isShared,
      shareRoles: !prev.isShared
        ? [
            ROLE.VIEW,
            ROLE.COMMENT,
            ROLE.UPLOAD,
            ROLE.DOWNLOAD,
            ROLE.GUESTS_ALLOWED,
          ]
        : [],
      sharingUpdatedAt: Date.now(),
    }));
    await updateShareRoles(
      !entityShareSettings.isShared
        ? [
            ROLE.VIEW,
            ROLE.COMMENT,
            ROLE.UPLOAD,
            ROLE.DOWNLOAD,
            ROLE.GUESTS_ALLOWED,
          ]
        : null,
      () => {
        // error handling is done inside `updateEntityRoles`
        // this is just to revert in case of failure
        setEntityShareSettings(previousSettings);
      },
    );
    if (!entityShareSettings.isShared) {
      trackEvent(
        EVENT_ID.CREATED_INVITE_LINK,
        {
          entityName: entity.name,
          entityId: entity.id,
          entityUrl: shareLink,
        },
        entity.createdBy,
      );
    }
  }, [
    updateShareRoles,
    trackEvent,
    entity?.name,
    entity?.id,
    shareLink,
    entityShareSettings,
  ]);

  return (
    <ErrorBoundary name="InviteByLink">
      <div className="ShareDialogLink InviteByLink">
        <List>
          <ListItemButton
            disabled={!canManageEntity}
            className="ShareDialogLink-main-toggle"
            onClick={handleEnableLinkToggle}
          >
            <div className="info">
              <h4>Enable Link</h4>
              <p>
                Invite anyone to collaborate in your Space without an account
              </p>
            </div>

            <PermissionTooltip hasPermission={canManageEntity}>
              <div>
                <Switch checked={entityShareSettings.isShared} />
              </div>
            </PermissionTooltip>
          </ListItemButton>
          <div
            className={classNames("ShareDialogLink-share-link", {
              isNotShared: !entityShareSettings.isShared,
            })}
          >
            <TextInput value={shareLink} />
            <CopyButton
              disabled={!entityShareSettings.isShared || !shareKey}
              theme={BUTTON_THEME.CTA}
              value={shareLink}
              onClick={() => {
                trackEvent(
                  EVENT_ID.COPIED_INVITE_LINK,
                  {
                    entityName: entity.name,
                    entityId: entity.id,
                    entityUrl: shareLink,
                  },
                  entity.createdBy,
                );
              }}
            >
              <LinkSVG /> <span>Copy Link</span>
            </CopyButton>
          </div>

          <div className="link-options-header">
            <h4>Options</h4>
            <p>Control link permissions</p>
          </div>
          <ListItemButton
            disabled={!canManageEntity}
            className="ShareDialogLink-option"
            onClick={() => toggleRole(ROLE.COMMENT)}
          >
            <ChatSVG />
            <p>
              <span className="title">Allow Comments</span>
            </p>
            <PermissionTooltip hasPermission={canManageEntity}>
              <div>
                <Switch
                  checked={entityShareSettings.shareRoles.includes(
                    ROLE.COMMENT,
                  )}
                />
              </div>
            </PermissionTooltip>
          </ListItemButton>
          <ListItemButton
            disabled={!canManageEntity}
            className="ShareDialogLink-option"
            onClick={() => toggleRole(ROLE.UPLOAD)}
          >
            <UploadSVG />
            <p>
              <span className="title">Allow Uploads</span>
            </p>
            <PermissionTooltip hasPermission={canManageEntity}>
              <div>
                <Switch
                  checked={entityShareSettings.shareRoles.includes(ROLE.UPLOAD)}
                />
              </div>
            </PermissionTooltip>
          </ListItemButton>
          {!isPrivateInboxEnabled && (
            <ListItemButton
              disabled={isPrivateInboxEnabled || !downloadToggleable}
              className="ShareDialogLink-option"
              onClick={() => toggleRole(ROLE.DOWNLOAD)}
            >
              <DownloadSVG />
              <p>
                <span className="title">Allow Downloads</span>
              </p>
              <PermissionTooltip
                title={
                  isEntityOwner
                    ? ControlledFeatureTooltipLabel[
                        CONTROLLED_SHARE_FEATURES.DOWNLOAD
                      ]
                    : defaultFeatureAlertMessage
                }
                hasPermission={canManageEntity && downloadToggleable}
              >
                <div>
                  <Switch
                    checked={entityShareSettings.shareRoles.includes(
                      ROLE.DOWNLOAD,
                    )}
                  />
                </div>
              </PermissionTooltip>
            </ListItemButton>
          )}
          {entityShareSettings.isShared && canManageEntity && (
            <React.Fragment>
              <div className="link-options-header">
                <h4>Additional Security</h4>
                <p>Control link security</p>
              </div>
              <EntityPasswordEditor
                entity={entity as Entity}
                entityHasChildren={entityHasChildren}
                canManagePassword={canManagePassword}
                password={entity.sharePassword}
                passwordEnabled={Boolean(entity.sharePasswordEnabled)}
                updatePassword={(props) =>
                  updateEntityPassword({
                    passwordType: PASSWORD_TYPE.Share,
                    ...props,
                  })
                }
                className="ShareDialogLink-option"
              />
              {!isPrivateInboxEnabled && (
                <ListItemButton
                  disabled={isPrivateInboxEnabled}
                  className="ShareDialogLink-option"
                  onClick={() => toggleRole(ROLE.GUESTS_ALLOWED)}
                >
                  <UserSVG />
                  <p>
                    <span className="title">Require Account</span>
                  </p>
                  <div>
                    <Switch
                      checked={
                        !entityShareSettings.shareRoles.includes(
                          ROLE.GUESTS_ALLOWED,
                        )
                      }
                    />
                  </div>
                </ListItemButton>
              )}
            </React.Fragment>
          )}
        </List>
      </div>
    </ErrorBoundary>
  );
};
