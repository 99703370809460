import "./SpaceTrack.scss";
import React, { useEffect, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import { CarouselSyncProvider } from "App/routes/Main/Space/Carousel/useCarouselSync";
import Carousel from "./Carousel/Carousel";
import { useCommentCards } from "App/common/useCommentCards";
import { useSpaceContext } from "App/common/useSpace";
import { NewCommentButtons } from "./NewCommentButton";
import { THEME, ThemeProvider } from "App/common/ThemeProvider";
import { SpaceCommentsContextProvider } from "App/common/useSpaceComments";
import { TrackWaveform } from "./TrackWaveform";
import {
  getDefaultVersionId,
  getNextInArray,
  getPrevInArray,
} from "@highnote/server/src/core/shared-util";
import { CommentFilters } from "App/components/SpaceTools";
import { useHistory, useLocation } from "react-router";
import { EntityNotFound } from "../EntityNotFound";
import { useTrack } from "App/components/useEntities/useTrack";
import { useUrlContext } from "../useUrlContext";
import { useGlobalAudioPlayer } from "App/components/GlobalAudioPlayer";
import { useTrackEditor } from "App/components/useTrackEditor";
import {
  GlobalAudioPlayer,
  GlobalAudioTrackPreview,
} from "App/components/GlobalAudioPlayer/GlobalAudioPlayer";
import { useViewport } from "App/common/useViewport";
import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";
import { daw, useDAWTrack } from "@highnote/daw/src";
import { SpaceTrackNavigation } from "./SpaceNavigation";
import { FocusedCardContextProvider } from "App/common/useFocusedCard";
import { TrackVersionTabs } from "App/routes/Main/Space/TrackVersionTabs";
import { EditableArtwork } from "App/components/EditableArtwork";
import { useNotificationsContext } from "App/common/useNotifications";
import {
  COLLECTION_ID,
  KNOCK_WORKFLOW,
} from "@highnote/server/src/core/entities";
import { useEntityShareKeyInUrl } from "App/components/ShareDialog/Members/InviteByLink";
import { useChildEntityWatchers } from "App/store/helpers/useChildEntityWatchers";
import { useGlobalTracks } from "App/store/tracks/useGlobalTracks";
import { DownloadRequestsContextProvider } from "App/components/useEntities/useDownloadRequests";
import {
  getSortedChildRows,
  useFormatTrackToBaseEntityRow,
} from "App/components/EntityTable/ExpandableSpace/ExpandableSpaceRow";
import { SORT_TYPE } from "../Library/config";
import { useIntercomContext } from "App/services/intercom/IntercomContext";
import { useMoveToSpace } from "App/components/useEntities/useLibraryTracks/useMoveToSpace";
import { useMobileAppParams } from "../useMobileAppParams";

export const useDAWTrackVersionId = () => {
  const { currentTrackVersion, track } = useTrack();
  const defaultVersionId = getDefaultVersionId(track);
  return currentTrackVersion?.id === defaultVersionId
    ? track?.id
    : currentTrackVersion?.id;
};

export const useTrackVersionInDAW = () => {
  const dawTrackId = useDAWTrackVersionId();
  const dawTrack = useDAWTrack(dawTrackId);
  return dawTrack;
};

const COMMENT_TOOL_BREAKPOINT = 1024;

const getSpaceTrackToolsEl = () =>
  document.getElementById("TEMP_SPACE_TRACK_TOOLS");

const SpaceTrackUI = () => {
  const { versionIdPath } = useUrlContext();
  const { pathname } = useLocation();
  const { openTrackEditor } = useTrackEditor();
  const { vw } = useViewport();
  const { pause, setQueue, queueTrackVersion, preloadTrackVersions } =
    useGlobalAudioPlayer();
  const {
    track,
    currentTrackVersion,
    pinnedTrackVersions,
    trackFilesLoading,
    trackArtworkUrl,
  } = useTrack();
  const { notifications, markAsRead } = useNotificationsContext();
  const { spaceArtworkUrl } = useSpaceContext();
  const { orderedCards } = useCommentCards();
  const waveformRef = useRef<HTMLDivElement>();
  const hasComments = orderedCards.length > 0;
  const unmountedRef = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  // Reset the minimum duration prop when you leave Space Track
  useEffect(() => {
    if (!track) return;

    // Special case: if the track specified on this page was ALREADY active
    // before you arrived here, retain the time and play state.
    // Otherwise PAUSE audio and start at 0.
    const dawTrack = daw.getTrack(track.id);
    const trackTime = daw.getTrackTime(track.id);
    const alreadyPlaying = trackTime > 0 && trackTime < dawTrack?.duration;
    if (!alreadyPlaying) {
      pause();
      daw.seekTo(dawTrack?.startTime || 0);
    }

    return () => {
      daw.__minDuration = 0;
    };
  }, [!!track]);

  useEffect(() => {
    if (!track || trackFilesLoading) return;
    setQueue({
      type: "UPDATE_CURRENT",
      _tracks: [track],
      keepPreloads: true,
      currentPageId: pathname,
    });
    preloadTrackVersions(track.id, pinnedTrackVersions);
    queueTrackVersion(track.id, currentTrackVersion?.id);
    daw.__minDuration = currentTrackVersion?.metadata?.duration || 0;
  }, [track, currentTrackVersion, trackFilesLoading, pinnedTrackVersions]);

  useEffect(() => {
    if (!track) return;
    const unreads = notifications.filter((n) => {
      // Track ID must match
      const isTrackAddedNotif =
        n.source.key === KNOCK_WORKFLOW.TRACK_ADDED &&
        n.data.trackId === track.id;

      // Version ID must match & be pinned
      const isTrackVersionAdded =
        n.source.key === KNOCK_WORKFLOW.TRACK_VERSION_ADDED &&
        pinnedTrackVersions.includes(n.data.versionId);

      return (isTrackAddedNotif || isTrackVersionAdded) && !n.read_at;
    });
    markAsRead(unreads);
  }, [track, pinnedTrackVersions, notifications]);

  if (trackFilesLoading) {
    return <LoadingScreen />;
  }

  return (
    <ThemeProvider theme={THEME.DARK}>
      {getSpaceTrackToolsEl() &&
        vw <= COMMENT_TOOL_BREAKPOINT &&
        ReactDOM.createPortal(
          <div className="highnote-comment-tools">
            <CommentFilters />
          </div>,
          getSpaceTrackToolsEl(),
        )}

      <div className="ThreadUI">
        <section className="thread-comments" data-has-comments={hasComments}>
          <SpaceTrackNavigation />

          <div className="space-comments">
            <div className="track-art-wrapper">
              <div className="track-art">
                <EditableArtwork
                  disabled
                  artworkUrl={trackArtworkUrl}
                  fallbackArtworkUrl={
                    spaceArtworkUrl || "/public/default-track-artwork.png"
                  }
                />
              </div>

              <div
                className="track-art-icon"
                onClick={() => openTrackEditor({ track })}
              >
                <EditableArtwork
                  disabled
                  artworkUrl={trackArtworkUrl}
                  fallbackArtworkUrl={
                    spaceArtworkUrl || "/public/default-track-artwork.png"
                  }
                />
              </div>
            </div>
            <Carousel />
          </div>

          <div className="space-track-tools">
            <section className="comment-toolbar">
              <div className="inner">
                <div className="left-col"></div>
                <div className="middle-col">
                  <NewCommentButtons targetEl={waveformRef.current} />
                </div>
                <div className="right-col">
                  <CommentFilters />
                </div>
              </div>
            </section>
            <div ref={waveformRef}>
              <TrackWaveform />
            </div>
          </div>
          {!versionIdPath && <TrackVersionTabs />}
        </section>
      </div>
    </ThemeProvider>
  );
};

const SpaceTrackPlayer = () => {
  const history = useHistory();
  const { spaceId, space, itemSortType } = useSpaceContext();
  const { formatTracksListToBaseEntityRows } = useFormatTrackToBaseEntityRow();
  const { getChildTracks } = useChildEntityWatchers();
  const { globalTracks } = useGlobalTracks();
  const spaceTracks = useMemo(() => {
    const formattedChildTracks = formatTracksListToBaseEntityRows({
      tracks: getChildTracks(spaceId),
    });

    // Sort the child tracks by the space's itemSortType
    return getSortedChildRows({
      childItemRows: formattedChildTracks,
      sortType: itemSortType || SORT_TYPE.CUSTOM,
      itemsOrder: space?.itemsOrder || [],
    });
  }, [
    spaceId,
    itemSortType,
    space?.itemsOrder,
    globalTracks.childTracks.get(spaceId),
  ]);

  const { track } = useTrack();
  const { openTrackEditor } = useTrackEditor();

  const spaceTrack = spaceTracks.find((s) => s.id === track?.id);
  const nextSpaceTrack = getNextInArray(spaceTrack, spaceTracks);
  const prevSpaceTrack = getPrevInArray(spaceTrack, spaceTracks);

  return (
    <GlobalAudioPlayer
      seekable={false}
      preview={
        <>
          <GlobalAudioTrackPreview
            track={track}
            onClick={() => {
              openTrackEditor({ track });
            }}
          ></GlobalAudioTrackPreview>
        </>
      }
      onNext={
        nextSpaceTrack
          ? () => {
              history.push(`/space/${spaceId}/${nextSpaceTrack.id}`);
            }
          : undefined
      }
      onPrev={
        prevSpaceTrack
          ? () => {
              history.push(`/space/${spaceId}/${prevSpaceTrack.id}`);
            }
          : undefined
      }
    />
  );
};

export const SpaceTrack = () => {
  const { spaceId, space } = useSpaceContext();
  const { openMoveToSpace } = useMoveToSpace();
  const { trackId } = useUrlContext();
  const { showMoveToSpaceDialog } = useMobileAppParams();
  const { setShowLauncher } = useIntercomContext();

  const { globalTracks, globalTracksWatchers } = useGlobalTracks();
  const isChildTrack = globalTracks.childTracks.get(spaceId)?.includes(trackId);
  const track = globalTracks.tracks.get(trackId);

  useEntityShareKeyInUrl(space);

  useEffect(() => {
    setShowLauncher(false);
  }, []);

  useEffect(() => {
    if (showMoveToSpaceDialog && track) {
      openMoveToSpace({
        entity: track,
        entityType: COLLECTION_ID.TRACK,
      });
    }
  }, [showMoveToSpaceDialog, track]);

  if (globalTracksWatchers.get(spaceId)?.loading) {
    return (
      <>
        <SpaceTrackNavigation />
        <LoadingScreen />
      </>
    );
  }

  if (!track || !isChildTrack) {
    return (
      <>
        <SpaceTrackNavigation />
        <EntityNotFound entityType="track" />
      </>
    );
  }

  return (
    <SpaceCommentsContextProvider spaceId={spaceId} trackId={trackId}>
      <CarouselSyncProvider>
        <FocusedCardContextProvider>
          <DownloadRequestsContextProvider isProcessing>
            <SpaceTrackUI />
          </DownloadRequestsContextProvider>
          <SpaceTrackPlayer />
        </FocusedCardContextProvider>
      </CarouselSyncProvider>
    </SpaceCommentsContextProvider>
  );
};
