import { FileEntity, Track } from "@highnote/server/src/core/entities";
import { TrackProviderRaw, useTrackRaw } from "App/components/useEntities";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { UPLOAD_GROUP, useFiles } from "../useFiles";
import { highnote } from "@highnote/server/src/sdk";
import { getDefaultVersionId } from "@highnote/server/src/core/shared-util";
import { useUrlContext } from "App/routes/Main/useUrlContext";

type TrackContextValue = {
  track: Track;
  trackLoading: boolean;
  trackVersions: FileEntity[];
  trackFilesLoading: boolean;
  currentTrackVersion: FileEntity;
  setTrackVersions: (versions: FileEntity[]) => void;
  setCurrentTrackVersion: (version: FileEntity) => void;
  trackArtworkUrl: string;
  refetchTrackFiles: () => void;
  setTrackArtworkUrl: (url: string) => void;
  pinnedTrackVersions: string[];
  setPinnedTrackVersions: (pinnedVersions: string[]) => void;
};

const TrackContext = createContext<TrackContextValue>({
  track: undefined,
  trackVersions: [],
  currentTrackVersion: undefined,
  trackFilesLoading: true,
  setTrackVersions: () => undefined,
  setCurrentTrackVersion: () => undefined,
  trackArtworkUrl: undefined,
  refetchTrackFiles: () => undefined,
  setTrackArtworkUrl: () => undefined,
  trackLoading: true,
  pinnedTrackVersions: [],
  setPinnedTrackVersions: () => undefined,
});

export const getTrackFiles = async (track: Track) => {
  const res = await highnote.getTrack({ id: track.id });
  const files = res.files || [];

  const artworkFile = files.find((f) => f.id === track.artworkFile);

  const versionFiles = (track.versionFilesV2 || [])
    .map((fileId) => files.find((f) => f.id === fileId))
    .filter((f) => !!f);

  const attachmentFiles = (track.files || [])
    .map((fileId) => files.find((f) => f.id === fileId))
    .filter((f) => !!f);

  return { artworkFile, versionFiles, attachmentFiles };
};

/**
 * useTrackFiles can be used outside of the TrackContextProvider to fetch files for a track.
 */
export const useTrackFiles = (track: Track) => {
  const { versionIdPath } = useUrlContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [artwork, setArtwork] = useState<FileEntity>();
  const [versions, setVersions] = useState<FileEntity[]>([]);
  const [attachments, setAttachments] = useState<FileEntity[]>([]);

  const resetState = () => {
    setArtwork(undefined);
    setVersions([]);
    setAttachments([]);
  };

  const setFiles = useCallback(() => {
    setLoading(true);
    getTrackFiles(track)
      .then(({ artworkFile, versionFiles, attachmentFiles }) => {
        setArtwork(artworkFile);
        setVersions(versionFiles);
        setAttachments(attachmentFiles);
      })
      .finally(() => setLoading(false));
  }, [track]);

  useEffect(() => {
    if (!track) {
      resetState();
      setLoading(false);
      return;
    }

    setFiles();
  }, [track]);

  const currentTrackPageVersion = useMemo(() => {
    if (!versionIdPath || versions.length === 0) return undefined;
    return versions.find((v) => v.id === versionIdPath);
  }, [versionIdPath, versions, track?.id]);

  return {
    refetchTrackFiles: setFiles,
    artwork,
    versions,
    attachments,
    loading,
    currentTrackPageVersion,
  };
};

const _TrackContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { getDownloadUrl, removeUpload } = useFiles();
  const { entity: track, loading: trackLoading } = useTrackRaw();
  const {
    versions,
    artwork,
    refetchTrackFiles,
    loading: trackFilesLoading,
    currentTrackPageVersion,
  } = useTrackFiles(track);
  const [pinnedTrackVersions, setPinnedTrackVersions] = useState<string[]>([]);
  const defaultVersionId = getDefaultVersionId(track);
  const [trackVersions, setTrackVersions] = useState<FileEntity[]>(versions);
  const [currentTrackVersion, setCurrentTrackVersion] = useState<FileEntity>();
  const [trackArtworkUrl, setTrackArtworkUrl] = useState<string>();
  const unmountedRef = useRef<boolean>(false);
  const { getUploadCache, getUploads } = useFiles();
  const uploadCachePinned = getUploadCache(
    UPLOAD_GROUP.TRACK_VERSIONS_PINNED,
    track?.id,
  );
  const uploadCacheUnpinned = getUploadCache(
    UPLOAD_GROUP.TRACK_VERSIONS_UNPINNED,
    track?.id,
  );

  useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    [],
  );

  useEffect(() => {
    if (!track || trackFilesLoading) {
      setTrackVersions([]);
      setCurrentTrackVersion(undefined);
      setPinnedTrackVersions([]);
      return;
    }

    const versionFileIds = track?.versionFilesV2 || [];
    const _trackVersions = versionFileIds.map((fileId) => {
      const downloaded = versions.find((v) => v.id === fileId);
      if (downloaded) return downloaded;

      const uploadsPinned = getUploads({ cache: uploadCachePinned });
      const uploadsUnpinned = getUploads({ cache: uploadCacheUnpinned });
      const upload = [...uploadsPinned, ...uploadsUnpinned].find(
        (u) => u.file.id === fileId,
      );

      return {
        createdAt: 0,
        createdBy: "",
        id: fileId,
        name: "[File Not Found]",
        ...(upload?.file || {}),
      };
    });
    const defaultVersion = _trackVersions.find(
      (v) => v.id === defaultVersionId,
    );

    let _pinnedTrackVersions = (track.pinnedVersionFiles || []).filter((id) =>
      versionFileIds.includes(id),
    );
    if (_pinnedTrackVersions.length < 1 && defaultVersionId) {
      _pinnedTrackVersions = [defaultVersionId];
    }

    setTrackVersions(_trackVersions);
    setPinnedTrackVersions(_pinnedTrackVersions);
    setCurrentTrackVersion(currentTrackPageVersion ?? defaultVersion);
    _trackVersions.forEach((v) => removeUpload(v.id));
  }, [
    track,
    trackFilesLoading,
    versions,
    defaultVersionId,
    currentTrackPageVersion,
  ]);

  useEffect(() => {
    if (!artwork) {
      setTrackArtworkUrl(undefined);
      return;
    }

    getDownloadUrl(artwork).then((url) => {
      if (unmountedRef.current) return;
      setTrackArtworkUrl(url);
    });
  }, [artwork]);

  const value = useMemo(
    () => ({
      track,
      trackLoading,
      trackArtworkUrl,
      setTrackArtworkUrl,
      trackVersions,
      trackFilesLoading,
      currentTrackVersion,
      refetchTrackFiles,
      setCurrentTrackVersion,
      setTrackVersions,
      pinnedTrackVersions,
      setPinnedTrackVersions,
    }),
    [
      track,
      trackLoading,
      trackArtworkUrl,
      setTrackArtworkUrl,
      trackVersions,
      trackFilesLoading,
      currentTrackVersion,
      refetchTrackFiles,
      setCurrentTrackVersion,
      setTrackVersions,
      pinnedTrackVersions,
    ],
  );

  return (
    <TrackContext.Provider value={value}>{children}</TrackContext.Provider>
  );
};

export const TrackContextProvider = ({
  id,
  children,
}: {
  id: Id;
  children: React.ReactNode;
}) => {
  if (!id) return <>{children}</>;

  return (
    <TrackProviderRaw id={id}>
      <_TrackContextProvider>{children}</_TrackContextProvider>
    </TrackProviderRaw>
  );
};

export const useTrack = () => useContext(TrackContext);
