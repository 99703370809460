import { Space } from "@highnote/server/src/core/entities";

export enum JOIN_ENTITY_TRIGGER {
  BUTTON = "joinButton",
  UPLOAD = "upload",
  DOWNLOAD = "download",
  COMMENT = "comment",
}

export type JoinEntityConfig = {
  entity: Space;
  entityType: "Space";
  trigger: JOIN_ENTITY_TRIGGER;
};

export enum JOIN_BUTTON_TYPES {
  NOTIFY = "notifyMe",
  GUEST = "continueAsGuest",
  JOIN = "joinSpace",
}
