import React, { useCallback, useState } from "react";

import { ROLE, Space } from "@highnote/server/src/core/entities";
import { Button, BUTTON_THEME } from "App/core/Button";
import { DialogButtons, Dialog, DialogSection } from "App/common/Dialog";
import {
  ENTITY_TYPE,
  DEFAULT_COLUMNS,
  EntityRowConfig,
} from "App/components/EntityTable/config";
import {
  ScrollableContainer,
  SCROLLABLE_CONTAINER_VARIANT,
} from "App/common/ScrollableContainer";
import { sortRowByCreatedAt } from "App/components/util";
import { ClickThruSpaceRowComponent } from "App/components/ClickThruSpaceRow";
import { hasRole } from "@highnote/server/src/core/shared-util";
import { useAuth } from "App/components/Auth";

export const DropboxSpacePicker = ({
  onSubmit,
  onCancel,
}: {
  onSubmit: (spaceId: string) => Promise<void>;
  onCancel: () => void;
}) => {
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [currentRoot, setCurrentRoot] = useState<Space>();

  const filter = useCallback(
    (row: EntityRowConfig) => {
      return (
        row?.type === ENTITY_TYPE.SPACE &&
        hasRole(user.id, ROLE.UPLOAD, row.entity as Space)
      );
    },
    [user?.id],
  );

  const handleOnSelect = async (row: EntityRowConfig) => {
    try {
      setIsLoading(true);
      await onSubmit(row.id);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open onClose={onCancel} title="Import to...">
      <DialogSection>
        <ScrollableContainer
          maxHeight={400}
          variant={SCROLLABLE_CONTAINER_VARIANT.FRAMED}
        >
          <ClickThruSpaceRowComponent
            parentSpace={currentRoot}
            tableBaseProps={{
              columns: [DEFAULT_COLUMNS.ICON, DEFAULT_COLUMNS.PREVIEW],
              filter,
              tableRowVariant: "nested-click-thru",
              sort: sortRowByCreatedAt,
              rootEntity: currentRoot,
              onRootEntityChange: setCurrentRoot,
              onSelect: handleOnSelect,
            }}
          />
        </ScrollableContainer>
      </DialogSection>
      <DialogButtons>
        <Button
          disabled={isLoading}
          theme={BUTTON_THEME.SECONDARY}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </DialogButtons>
    </Dialog>
  );
};
