import "./ChannelPicker.scss";
import React, { useRef, useState } from "react";
import { ReactComponent as BellMutedSVG } from "App/common/icons/bell-muted.svg";
import { ReactComponent as EllipsisHorizontalSVG } from "App/common/icons/ellipsis-horizontal.svg";
import { CHANNEL } from "@highnote/server/src/core/entities";
import { Switch } from "App/core/Switch";
import { Menu, MenuItem } from "App/common/Menu";
import { Button, BUTTON_THEME } from "App/core/Button";

const channelOptions: Partial<
  Record<CHANNEL, { label: string; value: CHANNEL }>
> = {
  [CHANNEL.FEED]: {
    label: "Feed",
    value: CHANNEL.FEED,
  },
  [CHANNEL.EMAIL]: {
    label: "Email",
    value: CHANNEL.EMAIL,
  },
  [CHANNEL.SMS]: {
    label: "SMS",
    value: CHANNEL.SMS,
  },
};

export const ChannelPicker = ({
  value,
  options: consumerOptions = [],
  DynamicContent,
  onChange,
  isDisabled,
}: {
  value: CHANNEL[];
  options: CHANNEL[];
  DynamicContent?: React.FC<{ value: CHANNEL[] }>;
  onChange?: (value: CHANNEL[]) => void;
  isDisabled?: boolean;
}) => {
  const triggerRef = useRef();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const options = consumerOptions.map((option) => ({
    ...channelOptions[option],
    id: option,
  }));

  return (
    <div className="ChannelPicker">
      <Button
        disabled={!!isDisabled}
        className="RolePicker-edit"
        ref={triggerRef}
        data-is-active={isMenuOpen}
        theme={BUTTON_THEME.ICON}
        onClick={() => {
          if (isDisabled) return;
          setMenuOpen(true);
        }}
      >
        <EllipsisHorizontalSVG />
      </Button>
      <Menu
        className="RolePicker-menu"
        open={isMenuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={triggerRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem
              className="ChannelPicker-option"
              key={`item-${option.id}`}
              value={option.value}
              onClick={(e) => {
                e.stopPropagation();
                const selected: Set<CHANNEL> = new Set(value);
                const alreadyOn = selected.has(option.value as CHANNEL);
                if (alreadyOn) selected.delete(option.value as CHANNEL);
                else selected.add(option.value);
                const newValue = Array.from(selected);
                onChange && onChange(newValue);
              }}
            >
              <span className="title">{option.label}</span>
              <Switch checked={(value || []).includes(option.id as CHANNEL)} />
            </MenuItem>
          );
        })}

        {!!DynamicContent && <DynamicContent value={value} />}

        {value.length > 0 && (
          <>
            <div className="divider"></div>

            <MenuItem
              className="ChannelPicker-option"
              key={`item-mute`}
              onClick={(e) => {
                e.stopPropagation();
                onChange && onChange([]);
              }}
            >
              <BellMutedSVG />
              <p>
                <span className="title">Mute</span>
                Don&apos;t notify me about this
              </p>
            </MenuItem>
          </>
        )}
      </Menu>
    </div>
  );
};
