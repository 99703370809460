import React from "react";
import { useTrack } from "App/components/useEntities/useTrack";
import { FileEntity } from "@highnote/server/src/core/entities";
import { DEFAULT_VERSION_COLOR_ID } from "App/components/TrackEditor/TrackVersions/index";
import styles from "./TrackVersionIcon.module.scss";

export const TrackVersionIcon = ({ version }: { version: FileEntity }) => {
  const { trackVersions } = useTrack();

  const versionId = version.id;
  const versionNumber = trackVersions.indexOf(
    trackVersions.find((v) => v.id === versionId),
  );

  return (
    <div
      className={styles["track-version-thumbnail"]}
      data-color-id={DEFAULT_VERSION_COLOR_ID}
    >
      <div className={styles["version-number"]}>V{versionNumber + 1}</div>
    </div>
  );
};
