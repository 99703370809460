import { TextInput } from "App/common/TextInput";
import { Button, BUTTON_THEME } from "App/core/Button";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { highnote } from "@highnote/server/src/sdk";
import { useToast } from "App/common/useToast";
import { WithBackButton } from "./BackButton";
import { getIsInvitedUserLogin, loginWithGoogle } from ".";
import { EXISTING_AUTH_WITH_GOOGLE_ERR } from "@highnote/server/src/core/shared-util";
import { ReactComponent as AuthGoogleSVG } from "App/common/icons/auth-google.svg";
import { AUTH_TYPE } from "./AuthForm";
import { PublicSpaceData } from "./useInviteSpaceData";

enum STAGE {
  EMAIL = "email",
  PASSWORD = "password",
  INVITE = "invite",
  GOOGLE = "google",
}

export const AuthByEmail = ({
  identifier,
  setIdentifier,
  password,
  setPassword,
  isSignUp,
  setSignUp,
  name,
  setName,
  onBack,
  onSubmit,
  isSubmitting: isFormSubmitting,
  signUpCta,
  inviteSpaceData,
}: {
  identifier: string;
  setIdentifier: (val: string) => void;
  password: string;
  setPassword: (val: string) => void;
  isSignUp: boolean;
  setSignUp: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  setName: (val: string) => void;
  onBack?: () => void;
  onSubmit?: () => void;
  isSubmitting?: boolean;
  signUpCta?: string;
  inviteSpaceData?: PublicSpaceData;
}) => {
  const { toasted, addErrorMessage } = useToast();
  const [stage, setStage] = useState<STAGE>(STAGE.EMAIL);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const emailInputRef = useRef<HTMLInputElement>();
  const nameInputRef = useRef<HTMLInputElement>();
  const passwordInputRef = useRef<HTMLInputElement>();
  const isInvitedUserLogin = useMemo(() => getIsInvitedUserLogin(), []);

  useEffect(() => {
    if (stage === STAGE.EMAIL) emailInputRef.current?.focus();
    if (stage === STAGE.PASSWORD) {
      if (isSignUp) nameInputRef.current?.focus();
      else passwordInputRef.current?.focus();
    }
  }, [stage, isSignUp]);

  const onSubmitEmail = (
    e?: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    e?.preventDefault();
    const email = identifier;

    if (!email) {
      onSubmit();
      return;
    }

    setSubmitting(true);
    highnote
      .searchUsers({ email })
      .then((users = []) => {
        const sameEmailUsers = users.filter((u) => u.email === email);
        const emailPwdUser = sameEmailUsers.find((u) => !u.googleId);
        const googleEmailUser = sameEmailUsers.find((u) => Boolean(u.googleId));
        // if google acount exists with same email, but no other email/pwd account
        // exists with the same email, DO NOT let the user to sign up or sign in.
        if (googleEmailUser && !emailPwdUser) {
          addErrorMessage(EXISTING_AUTH_WITH_GOOGLE_ERR, {
            title: "Account mismatch",
          });
          if (isInvitedUserLogin) {
            setStage(STAGE.GOOGLE);
          }
          return;
        }
        // sign up only when there are NO accounts with same email address
        setSignUp(sameEmailUsers.length < 1);

        setStage(
          isInvitedUserLogin && sameEmailUsers.length < 1
            ? STAGE.INVITE
            : STAGE.PASSWORD,
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const loginDescription = useMemo(() => {
    if (isInvitedUserLogin) {
      const query = new URLSearchParams(location.search);
      const returnTo = (query.get("returnTo") || "").replace(" ", "+");
      const returnToQuery = new URLSearchParams(returnTo);
      // Used as a backup in case the senderName is not provided in the query or
      // the api call fails
      const senderName = (
        returnToQuery.get("senderName") ||
        query.get("senderName") ||
        ""
      ).replace(" ", "+");

      const entityName = inviteSpaceData?.space?.name || "Space";
      const spaceOwnerName =
        inviteSpaceData?.spaceOwner?.name || senderName || "Someone";

      if (stage === STAGE.INVITE) {
        return `Finish setting up your free account to join ${spaceOwnerName}'s ${entityName}.`;
      }

      if (stage === STAGE.GOOGLE) {
        return `Continue to join ${spaceOwnerName}'s ${entityName}.`;
      }

      if (stage === STAGE.PASSWORD) {
        if (isSignUp) {
          return `Create a display name and password to join ${spaceOwnerName}'s ${entityName}.`;
        }
        return `Enter your password to join ${spaceOwnerName}'s ${entityName}.`;
      }

      return `${spaceOwnerName} would like to share ${entityName} with you.`;
    }

    // Non-invited user flow
    if (stage === STAGE.PASSWORD) {
      return isSignUp
        ? "Create a display name and password."
        : "Enter your password.";
    }

    return "Enter your email to continue.";
  }, [isInvitedUserLogin, stage, isSignUp, inviteSpaceData]);

  return (
    <>
      <>
        {stage === STAGE.EMAIL && (
          <>
            <p className={isInvitedUserLogin ? "invite-header" : "direction"}>
              {loginDescription}
            </p>

            <TextInput
              placeholder="Email"
              type="email"
              isSingleLine
              value={identifier}
              onChange={setIdentifier}
              onSubmit={onSubmitEmail}
              inputRef={emailInputRef}
              isReadOnly={isInvitedUserLogin}
              required
            />
            <div className="spacer" />
            {isInvitedUserLogin ? (
              <Button
                loading={isSubmitting || isFormSubmitting}
                disabled={!identifier}
                type="button"
                theme={BUTTON_THEME.CTA}
                onClick={() => onSubmitEmail()}
                autoFocus
              >
                Continue
              </Button>
            ) : (
              <WithBackButton onBack={onBack}>
                <Button
                  loading={isSubmitting || isFormSubmitting}
                  disabled={!identifier}
                  type="button"
                  theme={BUTTON_THEME.CTA}
                  onClick={() => onSubmitEmail()}
                >
                  Continue
                </Button>
              </WithBackButton>
            )}
          </>
        )}

        {stage === STAGE.INVITE && (
          <>
            <p className="invite-header">{loginDescription}</p>
            <TextInput
              placeholder="Email"
              type="email"
              isSingleLine
              value={identifier}
              inputRef={emailInputRef}
              isReadOnly
              required
            />
            <div className="spacer" />
            <TextInput
              inputRef={nameInputRef}
              isSingleLine
              maxLength={70}
              onChange={setName}
              onSubmit={() => passwordInputRef.current?.focus()}
              placeholder="Display Name"
              type="displayname"
              value={name}
              required
              autoFocus
            />
            <div className="spacer" />
            <TextInput
              type="password"
              placeholder="Password"
              isSingleLine
              value={password}
              onChange={setPassword}
              inputRef={passwordInputRef}
              onSubmit={onSubmit}
              required
            />
            <div className="spacer" />
            <Button
              data-cypress-id="submit-auth-by-email"
              loading={isSubmitting || isFormSubmitting}
              disabled={!password}
              type="button"
              theme={BUTTON_THEME.CTA}
              onClick={onSubmit}
            >
              Sign Up
            </Button>
          </>
        )}

        {stage === STAGE.GOOGLE && (
          <>
            <p className="auth-direction">{loginDescription}</p>
            <p className="auth-headline">
              Your account is associated with Google
            </p>

            <TextInput
              placeholder="Email"
              type="email"
              isSingleLine
              value={identifier}
              inputRef={emailInputRef}
              isReadOnly
              required
            />
            <div className="spacer" />
            <Button
              autoFocus
              type="button"
              disabled={isSubmitting}
              className="select-auth-method"
              theme={BUTTON_THEME.CTA}
              onClick={() => {
                setSubmitting(true);
                loginWithGoogle({ type: AUTH_TYPE.LOG_IN });
              }}
            >
              <AuthGoogleSVG /> Continue with Google Account
            </Button>
          </>
        )}

        {stage === STAGE.PASSWORD && (
          <>
            <p className={isInvitedUserLogin ? "invite-header" : "direction"}>
              {loginDescription}
            </p>

            {isInvitedUserLogin && (
              <>
                <TextInput
                  placeholder="Email"
                  type="email"
                  isSingleLine
                  value={identifier}
                  inputRef={emailInputRef}
                  isReadOnly
                  required
                />
                <div className="spacer" />
              </>
            )}

            {isSignUp && (
              <>
                <TextInput
                  inputRef={nameInputRef}
                  isSingleLine
                  maxLength={70}
                  onChange={setName}
                  onSubmit={() => passwordInputRef.current?.focus()}
                  placeholder="Display Name"
                  type="displayname"
                  value={name}
                  required
                />
                <div className="spacer" />
              </>
            )}
            <TextInput
              type="password"
              placeholder="Password"
              isSingleLine
              value={password}
              onChange={setPassword}
              inputRef={passwordInputRef}
              onSubmit={onSubmit}
              required
            />
            <div className="spacer" />
            <WithBackButton
              onBack={() => {
                setStage(STAGE.EMAIL);
              }}
            >
              <Button
                data-cypress-id="submit-auth-by-email"
                loading={isSubmitting || isFormSubmitting}
                disabled={!password}
                type="button"
                theme={BUTTON_THEME.CTA}
                onClick={onSubmit}
              >
                {isSignUp ? signUpCta || "Sign Up" : "Log In"}
              </Button>
            </WithBackButton>
            <div className="spacer" />
            <p className="hint">
              Forgot Password?
              <Button
                className="forgot-password"
                theme={BUTTON_THEME.LINK}
                type="button"
                onClick={() => {
                  const requestReset = async () => {
                    if (!identifier) {
                      throw new Error("No email provided.");
                    }

                    return highnote.resetAuth0Password({
                      email: identifier,
                    });
                  };

                  toasted({
                    promise: requestReset(),
                    createMessage: "Sending...",
                    successMessage: `We've emailed you a link to reset your password at ${identifier}.`,
                    errorMessage:
                      "Could not send reset password email. Please try again.",
                  });
                }}
              >
                Reset
              </Button>
            </p>
          </>
        )}
      </>
    </>
  );
};
