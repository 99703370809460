import React, { useEffect, useState } from "react";
import { COMMENT_BLOCK_TYPE, Track } from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import { EntityRowConfig, ENTITY_TYPE, Version } from "./config";
import { ReactComponent as CommentHollowSvg } from "App/common/icons/comment-hollow.svg";
import { useHistory } from "react-router";
import { Button, BUTTON_THEME } from "App/core/Button";

interface TrackCommentCountProps {
  entity: Track | Version;
  entityType: ENTITY_TYPE;
  fetchCommentCount?: () => Promise<number>;
  generateUrl: (entity: Track | Version) => string;
}

const TrackCommentCount = ({
  entity,
  fetchCommentCount,
  generateUrl,
}: TrackCommentCountProps) => {
  const [commentCount, setCommentCount] = useState<number>(
    (entity as Version).commentCount ?? 0,
  );
  const history = useHistory();

  useEffect(() => {
    let unmounted = false;

    if (!("spaceId" in entity) || !fetchCommentCount) {
      return;
    }

    fetchCommentCount().then((count) => {
      if (unmounted) return;
      setCommentCount(count);
    });

    return () => {
      unmounted = true;
    };
  }, [entity]);

  useEffect(() => {
    if ("commentCount" in entity && entity.commentCount !== undefined) {
      setCommentCount(entity.commentCount);
    }
  }, [(entity as Version).commentCount]);

  const commentCountStr = commentCount >= 99 ? "99+" : `${commentCount}`;

  return (
    <Button
      className="highnote-entity-comments"
      theme={BUTTON_THEME.ICON}
      type="button"
      onClick={(e) => {
        // Prevent the click event from propagating to the parent element
        e.preventDefault();
        e.stopPropagation();
        if ((entity as Track).spaceId || (entity as Version).track.spaceId) {
          history.push(generateUrl(entity));
        }
      }}
    >
      <CommentHollowSvg />
      <span className="comment-count">{commentCountStr}</span>
    </Button>
  );
};

export const EntityComments = ({ row }: { row: EntityRowConfig }) => {
  if (row.type === ENTITY_TYPE.TRACK) {
    const track = row.entity as Track;
    return (
      <TrackCommentCount
        entity={track}
        entityType={ENTITY_TYPE.TRACK}
        fetchCommentCount={() =>
          highnote.getCommentsCount({
            spaceId: track.spaceId,
            trackId: track.id,
            excludeCommentTypes: [COMMENT_BLOCK_TYPE.POLL_REPLY],
          })
        }
        generateUrl={(entity: Track) => `/space/${entity.spaceId}/${entity.id}`}
      />
    );
  }

  if (row.type === ENTITY_TYPE.TRACK_VERSION) {
    const trackVersion = row.entity as Version;
    return (
      <TrackCommentCount
        entity={trackVersion}
        entityType={ENTITY_TYPE.TRACK_VERSION}
        generateUrl={(entity: Version) =>
          `/space/${entity.track.spaceId}/${entity.track.id}/${entity.id}`
        }
      />
    );
  }

  return null;
};
