import React, { useEffect, useState } from "react";
import { COMMENT_BLOCK_TYPE, Track } from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import { EntityRowConfig, ENTITY_TYPE } from "./config";
import { ReactComponent as CommentHollowSvg } from "App/common/icons/comment-hollow.svg";
import { useHistory } from "react-router";
import { Button, BUTTON_THEME } from "App/core/Button";

const TrackComments = ({ track }: { track: Track }) => {
  const [commentCount, setCommentCount] = useState<number>(0);
  const history = useHistory();

  useEffect(() => {
    let unmounted = false;

    if (!track.spaceId) {
      setCommentCount(0);
      return;
    }

    highnote
      .getCommentsCount({
        spaceId: track.spaceId,
        trackId: track.id,
        excludeCommentTypes: [COMMENT_BLOCK_TYPE.POLL_REPLY],
      })
      .then((count) => {
        if (unmounted) return;
        setCommentCount(count);
      });

    return () => {
      unmounted = true;
    };
  }, [track]);

  const commentCountStr = commentCount >= 99 ? "99+" : `${commentCount}`;

  return (
    <Button
      className="highnote-entity-comments"
      theme={BUTTON_THEME.ICON}
      type="button"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (track.spaceId) {
          history.push(`/space/${track.spaceId}/${track.id}`);
        }
      }}
    >
      <CommentHollowSvg />
      <span className="comment-count">{commentCountStr}</span>
    </Button>
  );
};

export const EntityComments = ({ row }: { row: EntityRowConfig }) => {
  if (row.type === ENTITY_TYPE.TRACK) {
    const track = row.entity as Track;
    return <TrackComments track={track} />;
  }

  return null;
};
