import {
  COLLECTION_ID,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import { highnote } from "@highnote/server/src/sdk";
import { collection, where, query, limit, getDocs } from "firebase/firestore";

const getChildTracks = async (spaceId: string, queryLimit: number = 50) => {
  const childTracksQuery = query(
    collection(highnote.__firebaseFirestore, COLLECTION_ID.TRACK),
    where("spaceId", "==", spaceId),
    limit(queryLimit),
  );

  const snapshot = await getDocs(childTracksQuery);
  return snapshot.docs.map((s) => s.data()) as Track[];
};

const getChildSpaces = async (spaceId: string, queryLimit: number = 50) => {
  const childSpacesQuery = query(
    collection(highnote.__firebaseFirestore, COLLECTION_ID.SPACE),
    where("spaceId", "==", spaceId),
    limit(queryLimit),
  );

  const snapshot = await getDocs(childSpacesQuery);
  return snapshot.docs.map((s) => s.data()) as Space[];
};

// TODO: Should we fetch attachments/files as well here?
export const getChildEntities = async (spaceId: string) => {
  const [childTracks, childSpaces] = await Promise.all([
    getChildTracks(spaceId),
    getChildSpaces(spaceId),
  ]);
  return { childTracks, childSpaces };
};
