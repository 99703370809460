import "./TrackWaveform.scss";
import React, { useEffect, useMemo, useState } from "react";
import { daw, useDAW } from "@highnote/daw";
import "moment-duration-format";
import { useCommentCards } from "App/common/useCommentCards";
import { SeekableWaveform } from "App/common/AudioPlayer";
import { ThemeProvider, THEME } from "App/common/ThemeProvider";
import { useCarouselSync } from "App/routes/Main/Space/Carousel/useCarouselSync";
import { ReactComponent as CommentSVG } from "App/common/icons/comment-filled.svg";
import { useTrack } from "App/components/useEntities/useTrack";
import { isFilePlayable } from "@highnote/server/src/core/shared-util";
import { useDAWTrackVersionId, useTrackVersionInDAW } from "./SpaceTrack";
import { useFocusedCard, useFocusOnCard } from "App/common/useFocusedCard";
import {
  useGlobalAudioPlayer,
  useHTMLAudioPlayer,
} from "App/components/GlobalAudioPlayer";
import { DEFAULT_VERSION_COLOR_ID } from "App/components/TrackEditor/TrackVersions";
import { useSpaceContext } from "App/common/useSpace";
import { useGlobalTracks } from "App/store/tracks/useGlobalTracks";

const CommentMarkers = () => {
  const { currentTrackVersion } = useTrack();
  const { newCard } = useFocusedCard();
  const sync = useCarouselSync();
  const { orderedCards } = useCommentCards();
  const { focusedCardId } = useFocusedCard();
  const { focusOnCard } = useFocusOnCard();
  const dawTrackId = useDAWTrackVersionId();
  const dawTrack = daw.getTrack(dawTrackId);
  const duration =
    dawTrack?.duration || currentTrackVersion?.metadata?.duration || 0;
  const centeredCardId = sync.getCenteredCardId();

  const cardsToMark = useMemo(() => {
    const cards = orderedCards.filter((card) => !!card.comment);
    if (newCard) {
      cards.push(newCard);
    }
    return cards;
  }, [orderedCards, newCard]);

  return (
    <div className="highnote-comment-markers">
      {cardsToMark.map((card, i) => {
        return (
          <div
            key={i}
            className="marker"
            onClick={() => {
              if (daw.state.isPlaying) {
                focusOnCard(card.id);
              } else {
                sync.onCardIdClick(card.id);
              }
            }}
            data-is-active={
              focusedCardId
                ? card.id === focusedCardId
                : card.id === centeredCardId
            }
            data-is-draft={card.id === newCard?.id}
            style={{ left: `${(card.timestamp / duration) * 100}%` }}
          >
            <CommentSVG />
          </div>
        );
      })}
    </div>
  );
};

export const TrackWaveform = () => {
  useDAW(["currentTime"]);
  const sync = useCarouselSync();
  const { currentTrackVersion } = useTrack();
  const { seek, nowPlaying } = useGlobalAudioPlayer();
  const { currentTime, useHTMLPlayer, onSeek } = useHTMLAudioPlayer();

  const { spaceId } = useSpaceContext();
  const { globalTracks } = useGlobalTracks();

  const _dawTrack = useTrackVersionInDAW();
  const [time, setTime] = useState<number>(
    useHTMLPlayer ? currentTime : daw.getTrackTime(_dawTrack?.id),
  );

  useEffect(() => {
    if (!_dawTrack || useHTMLPlayer) return;
    const trackTime = daw.getTrackTime(_dawTrack?.id);
    setTime(trackTime);
    sync.onAudioSeek(trackTime);
  }, [_dawTrack, daw.state.currentTime, useHTMLPlayer, sync]);

  useEffect(() => {
    if (!useHTMLPlayer) return;
    setTime(currentTime);
    sync.onAudioSeek(currentTime);
  }, [currentTime, useHTMLPlayer, sync]);

  const nowPlayingFile = nowPlaying?.file;

  return (
    <ThemeProvider theme={THEME.DARK}>
      <div
        className="highnote-track-waveform"
        data-cypress-id="space-player"
        data-has-track={!!currentTrackVersion}
        data-has-playlist={globalTracks.childTracks.get(spaceId).length > 1}
        data-version-color-id={DEFAULT_VERSION_COLOR_ID}
      >
        {isFilePlayable(nowPlayingFile) ? (
          <SeekableWaveform
            onSeek={(time) => {
              const dawTrack = daw.getTrack(_dawTrack?.id);
              const newTime = time + (dawTrack?.startTime || 0);
              (useHTMLPlayer ? onSeek : seek)(newTime);
            }}
            currentTime={time}
            waveformData={nowPlayingFile.metadata.waveform}
            duration={nowPlayingFile.metadata.duration}
          />
        ) : (
          <SeekableWaveform currentTime={0} duration={0} />
        )}
        <CommentMarkers />
      </div>
    </ThemeProvider>
  );
};
