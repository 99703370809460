import React from "react";
import { Card } from "App/common/useCommentCards";
import { SeekRef } from "App/routes/Main/Space/Carousel/useSeekRef";
import binarySearch from "./binarySearch";

type SyncCard = {
  timestamp: number;
  index: number;
  position: number;
  id: string;
};

class CarouselSync {
  public cards: SyncCard[];
  public cardTimestamps: number[];
  public cardPositionMidpoints: number[];

  public cardGap: number = 10;
  public cardWidth: number = 400;

  public scrollableWidth: number;

  public carouselScrollerRef: React.RefObject<HTMLDivElement> =
    React.createRef();

  public centeredCardIndex: number = 0;

  private carouselTouchStartX: number | null = null;
  private autoScrollingToCardIndex: number | null = null;
  private afterScrollTimeoutId: number = -1;

  constructor(
    // React will not automatically re-render when CarouselSync's internal state changes,
    // so when the UI needs to be updated, methods in CarouselSync must call this.updateReact
    // to re-render all components that use the `useCarouselSync` hook.
    private updateReact: () => void,
    private seekRef: SeekRef,
    cardWidth: number,
    commentCards: Card[],
  ) {
    this.cards = [];
    this.cardTimestamps = [];
    this.cardPositionMidpoints = [];
    this.scrollableWidth = 0;

    this.updateCards(cardWidth, commentCards);
  }

  // This function is called when the viewport was resized
  // or the comment cards changed (e.g. a comment was added/deleted).
  // It assigns an absolute position to each card
  // and pre-computes the midpoints between cards to enable fast lookup later.
  public updateCards = (cardWidth: number, commentCards: Card[]) => {
    const scroller = this.carouselScrollerRef.current;
    if (!scroller) return;
    const scrollerMidpointX = scroller.clientWidth / 2;

    window.clearTimeout(this.afterScrollTimeoutId);

    this.cardWidth = cardWidth;

    this.cards = commentCards.map((card, i) => {
      return {
        // Safari sometimes truncates timestamps in onScroll events, so
        // we truncate here to ensure the correct card is chosen when scrolling.
        timestamp: this.truncateDecimal(card.timestamp, 7),
        index: i,
        // This position refers to the *center* of the card along the x-axis.
        // Thus the first card (i == 0) is centered at scrollerMidpointX.
        position: scrollerMidpointX + i * (this.cardWidth + this.cardGap),
        id: card.id,
      };
    });
    this.cardTimestamps = this.cards.map((card) => card.timestamp);
    this.cardPositionMidpoints = this.cards.reduce<number[]>(
      (midpoints, card, cardIndex, cards) => {
        const nextCard = cards[cardIndex + 1];
        if (nextCard === undefined) return midpoints;
        return midpoints.concat((card.position + nextCard.position) / 2);
      },
      [],
    );

    // Since we use absolute positioning, we need to set an explicit width for the scroller.
    // Carousel.tsx will read this value on each render and use it as the width of a div.
    this.scrollableWidth =
      scrollerMidpointX * 2 +
      (this.cards.length - 1) * (this.cardWidth + this.cardGap);

    // In case cards were added or deleted, we need to update the current centered card index
    const { getCurrentTime } = this.seekRef.current;
    const newCenteredCardIndex =
      this.getCardIndexFromTimestamp(getCurrentTime());

    this.setCenteredCardIndex(newCenteredCardIndex, true, false);
  };

  // When a card (or marker) is clicked, scroll to it and seek the audio
  public onCardClick = (cardIndex: number): void => {
    this.setCenteredCardIndex(cardIndex, true, true);
  };

  // Convenience method for external components to call `onCardClick`
  // using the card's ID instead of its index
  public onCardIdClick = (cardId: string): void => {
    const cardIndex = this.cards.findIndex((card) => card.id === cardId);
    if (cardIndex === -1) return;
    this.onCardClick(cardIndex);
  };

  // This gets called on every audio seek event, so it needs to be fast!
  // That's why this.getCardIndexFromTimestamp uses binary search
  // over the pre-computed array `this.timestamps`.
  public onAudioSeek = (timestamp: number): void => {
    // When the audio seeks (either because the user clicked on the waveform
    // or because of playback), find the new timestamp's corresponding card index
    const i = this.getCardIndexFromTimestamp(timestamp);

    // If the card index has changed, update it and scroll to the new card
    // (but don't seek the audio!)
    if (i !== this.centeredCardIndex) {
      this.setCenteredCardIndex(i, true, false);
    }
  };

  public getCenteredCardId = (): string => {
    return this.cards[this.centeredCardIndex]?.id ?? "";
  };

  public get scrollPosition(): number {
    const scroller = this.carouselScrollerRef.current;
    if (!scroller) return 0;
    const scrollerMidpointX = scroller.clientWidth / 2;

    // Return the position of the *center* of the scroller along the x-axis.
    // Note that the `.position` value for each card gives the position of its *center*.
    return scroller.scrollLeft + scrollerMidpointX;
  }

  // See README.md in this directory for more context on this function
  private setCenteredCardIndex = (
    centeredCardIndex: number,
    mayScroll: boolean,
    mustSeek: boolean,
  ): void => {
    this.centeredCardIndex = centeredCardIndex;

    window.clearTimeout(this.afterScrollTimeoutId);

    const { seek, getCurrentTime } = this.seekRef.current;

    const currentTimestamp = getCurrentTime();

    // We only want to seek the audio if the calling function explicitly asked for it
    // (e.g. this.onCardClick, or this.onCarouselTouchEnd's timeout)
    // or if the audio timestamp would be out of bounds for the card index we're setting.
    if (
      (mustSeek ||
        !this.doesTimestampMatchCardIndex(
          currentTimestamp,
          centeredCardIndex,
        )) &&
      this.cards[centeredCardIndex] // But if no cards exist (e.g. after a delete), we never want to seek the audio
    ) {
      seek(this.cards[centeredCardIndex].timestamp);
    }

    // We typically want to scroll the carousel to the centered card,
    // but not if the user's finger is currently touching the carousel.
    // The calling function can also request that we don't scroll
    // (e.g. this.onScroll).
    if (mayScroll && this.carouselTouchStartX === null) {
      this.scrollToCard(centeredCardIndex);
    }

    // See comment in constructor about this.updateReact
    this.updateReact();
  };

  private scrollToCard = (cardIndex: number): void => {
    const scroller = this.carouselScrollerRef.current;
    if (!scroller) return;
    const scrollerMidpointX = scroller.clientWidth / 2;

    const targetPosition = this.cards[cardIndex]?.position ?? 0;

    if (this.scrollPosition !== targetPosition) {
      // Keep track of the card we're auto-scrolling to, so that
      // the this.onCarouselScroll listener doesn't interrupt our auto-scroll
      this.autoScrollingToCardIndex = cardIndex;

      // We use .scrollTo('smooth') because it's the only API that will interrupt momentum scrolling.
      // .scrollTo('auto') and scroller.scrollLeft don't interrupt momentum scrolling,
      // so the user would experience jitter if we used those.
      scroller.scrollTo({
        left: targetPosition - scrollerMidpointX,
        behavior: "smooth",
      });
    }
  };

  // This gets called on every scroll event, so it needs to be fast!
  // That's why `getCardIndexFromScrollPosition` uses binary search
  // over the pre-computed array `this.cardPositionMidpoints`.
  public onCarouselScroll = (): void => {
    // Compute the card index corresponding to the current scroll position
    const centeredCardIndex = this.getCardIndexFromScrollPosition(
      this.scrollPosition,
    );

    // If we've been auto-scrolling and have arrived at our destination card,
    // we can stop auto-scrolling.
    if (centeredCardIndex === this.autoScrollingToCardIndex) {
      this.autoScrollingToCardIndex = null;
    }

    // If the centered card index has changed, update it,
    // but don't auto-scroll or seek the audio.
    // This is just to update the highlighted card and marker in the UI.
    if (
      this.autoScrollingToCardIndex === null &&
      centeredCardIndex !== this.centeredCardIndex
    ) {
      this.setCenteredCardIndex(centeredCardIndex, false, false);
    }

    window.clearTimeout(this.afterScrollTimeoutId);
    this.afterScrollTimeoutId = window.setTimeout(() => {
      if (this.autoScrollingToCardIndex === null) {
        // Once the scroll has stopped for 200ms, we can auto-scroll to the centered card.
        // In this case, we don't want to seek the audio.
        this.setCenteredCardIndex(centeredCardIndex, true, false);
      } else {
        // In this edge case, we were auto-scrolling but (for some reason) the scroll stopped
        // before the destination card was reached -- so just restart the auto-scroll.
        this.scrollToCard(this.autoScrollingToCardIndex);
      }
    }, 200); // 100ms is not enough to guarantee that no more momentum scroll events will fire
  };

  public onCarouselTouchStart = (): void => {
    // Record the scroll position at the start of the touch event,
    // so that in this.onCarouselTouchEnd we can detect whether it was a tap.
    this.carouselTouchStartX = this.scrollPosition;

    // Immediately cancel any auto-scroll, now that the user's in control.
    this.autoScrollingToCardIndex = null;
  };

  public onCarouselTouchEnd = (evt: React.TouchEvent<HTMLDivElement>): void => {
    const scrollPosition = this.scrollPosition;

    // By default, scroll to the most nearly centered card
    let cardIndex = this.getCardIndexFromScrollPosition(scrollPosition);

    // But if this touch was a tap, scroll to the tapped card
    if (this.carouselTouchStartX === scrollPosition) {
      const cardIndexFromTouchEvent = this.getCardIndexFromTouchEvent(evt);
      if (cardIndexFromTouchEvent !== null) {
        cardIndex = cardIndexFromTouchEvent;
      }

      // If it's a tap on the currently centered card, do nothing
      if (cardIndex === this.centeredCardIndex) {
        this.carouselTouchStartX = null;
        return;
      }
    }

    // Either way, perform the desired scroll (and audio seek) after 100ms
    // unless another scroll or touchend event happens in the meantime
    window.clearTimeout(this.afterScrollTimeoutId);
    this.afterScrollTimeoutId = window.setTimeout(() => {
      this.setCenteredCardIndex(cardIndex, true, true);
    }, 100);

    // Unset this.carouselTouchStartX, because this.setCenteredCardIndex
    // checks it to determine whether the user is touching the carousel
    this.carouselTouchStartX = null;
  };

  private getCardIndexFromTouchEvent = (
    evt: React.TouchEvent<HTMLDivElement>,
  ): number | null => {
    const card = (evt.target as HTMLDivElement).closest(
      "[data-card-index]",
    ) as HTMLDivElement | null;
    const cardIndexAttribute = card?.getAttribute("data-card-index");
    if (cardIndexAttribute === null || cardIndexAttribute === undefined) {
      return null;
    }
    return parseInt(cardIndexAttribute);
  };

  // `position` represents the center of the scroller.
  // This function returns the index of the card whose center is closest to `position`.
  private getCardIndexFromScrollPosition = (position: number): number => {
    const midpointIndex = binarySearch(this.cardPositionMidpoints, position);
    const cardIndex = midpointIndex + 1;
    return cardIndex;
  };

  // This function returns the index of the rightmost card whose timestamp
  // is less than or equal to `timestamp`.
  // If there are no cards or the timestamp is before the first card, it returns 0.
  private getCardIndexFromTimestamp = (timestamp: number): number => {
    let cardIndex = binarySearch(this.cardTimestamps, timestamp);
    if (cardIndex === -1) {
      cardIndex = 0;
    }
    return cardIndex;
  };

  // This function determines whether the current audio timestamp matches the "range" of a given card.
  // If the answer is no, we will seek the audio to the card's timestamp.
  //
  // The matching range is defined as:
  // For the first card: anywhere before the *second* card (including before the first card)
  // For the last card: anywhere after the last card.
  // For any other card: anywhere between this card and the one after it.
  // (If the timestamp is exactly equal to the card's timestamp, it's always considered a match.)
  //
  // Note that this.getCardIndexFromTimestamp uses the same logic.
  private doesTimestampMatchCardIndex = (
    timestamp: number,
    cardIndex: number,
  ): boolean => {
    if (!this.cards[cardIndex]) return false;

    if (cardIndex === 0 && timestamp < this.cards[0].timestamp) {
      return true;
    }

    return (
      this.cards[cardIndex].timestamp <= timestamp &&
      (cardIndex >= this.cards.length - 1 ||
        timestamp < this.cards[cardIndex + 1].timestamp)
    );
  };

  // See this.updateCards above: this is a workaround for a Safari quirk.
  // We truncate instead of rounding because it preserves the math behind
  // this.doesTimestampMatchCardIndex and this.getCardIndexFromTimestamp.
  private truncateDecimal(num: number, places: number): number {
    const factor = Math.pow(10, places);
    return Math.floor(num * factor) / factor;
  }
}

export default CarouselSync;
