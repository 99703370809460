import React, { useEffect, useMemo, useState } from "react";
import {
  KNOCK_WORKFLOW,
  PUBLIC_ID,
  ROLE,
  Roles,
} from "@highnote/server/src/core/entities";
import { DialogSection } from "App/common/Dialog";
import { ReactComponent as EyeSVG } from "App/common/icons/eye.svg";
import { ReactComponent as EyeSlashedSVG } from "App/common/icons/eye-slashed.svg";
import { ReactComponent as EnterInheritedCircleSVG } from "App/common/icons/enter-inherited-circle.svg";
import { ReactComponent as TrashSVG } from "App/common/icons/trash.svg";
import { InviteByEmail } from "./InviteByEmail";
import {
  getFullRoles,
  getEntityShareKeys,
  getInviteEmail,
  isInviteId,
  isShareId,
} from "@highnote/server/src/core/shared-util";
import { UserInfo } from "App/common/UserAvatar/UserAvatar";
import { RolePicker } from "../RolePicker";
import { PermissionTooltip } from "../../PermissionTooltip";
import { getRoleSummary } from "../util";
import { useShareDialogContext } from "../ShareDialogContext";
import { MenuItem } from "App/common/Menu";
import { InheritanceIndicator } from "../InheritanceIndicator";
import { Tooltip } from "App/core/Tooltip";
import { useAuth } from "App/components/Auth";
import { Button, BUTTON_THEME } from "App/core/Button";
import { useNotificationsContext } from "App/common/useNotifications";
import { usePlanLimitsContext } from "App/common/PlanLimits/usePlanLimits";
import {
  CONTROLLED_SHARE_FEATURES,
  ControlledFeatureTooltipLabel,
  ControlledFeatureCallout,
} from "App/core/ControlledFeatureCallout";
import { debounce } from "lodash";

export const ShareDialogMembers = () => {
  const { user } = useAuth();
  const { notifications, markAsRead } = useNotificationsContext();
  const {
    isEntityOwner,
    entity,
    updateEntityRoles,
    defaultFeatureAlertMessage,
    canManageEntity,
    canManageDownloadControl,
    canManagePassword,
  } = useShareDialogContext();
  const { showPlanPickerDialog } = usePlanLimitsContext();

  const [roleSettings, setRoleSettings] = useState<{
    roles: Roles;
    updatedAt: number;
  }>({
    roles: getFullRoles(entity),
    updatedAt: entity?.updatedAt || 0,
  });

  useEffect(() => {
    if (!entity) {
      return;
    }
    setRoleSettings((prev) => {
      if (entity?.updatedAt <= prev.updatedAt) {
        return prev;
      }
      return {
        roles: getFullRoles(entity),
        updatedAt: entity.updatedAt,
      };
    });
  }, [entity]);

  useEffect(() => {
    const key = KNOCK_WORKFLOW.SPACE_MEMBER_JOINED;
    const unreads = notifications.filter(
      (n) => n.source.key === key && !n.read_at,
    );

    markAsRead(unreads);
  }, [notifications]);

  const updateRemoteEntityRoles = useMemo(() => {
    return debounce(async (newRoles: Roles, onError: () => void) => {
      try {
        await updateEntityRoles(newRoles, true);
      } catch (e) {
        onError();
      }
    }, 500);
  }, []);

  const updateRoleSettings = async (newRoles: Roles) => {
    const prevRoles = roleSettings;
    setRoleSettings((prev) => {
      const updatedRoles = {
        ...prev.roles,
        ...newRoles,
      };
      return {
        roles: updatedRoles,
        updatedAt: Date.now(),
      };
    });
    await updateRemoteEntityRoles(newRoles, () => {
      setRoleSettings(prevRoles);
    });
  };

  const userRoleOptions = useMemo(
    () => [ROLE.MANAGE, ROLE.COMMENT, ROLE.UPLOAD, ROLE.DOWNLOAD],
    [],
  );

  const [shareKey] = getEntityShareKeys(entity);
  const shareRoles = (entity?.rolesV2 || {})[shareKey] || [];
  const filteredRoles = Object.entries(roleSettings.roles).filter(
    ([userId]) => userId !== PUBLIC_ID && !isShareId(userId),
  );

  const adminRoles = filteredRoles
    /* eslint-disable @typescript-eslint/no-unused-vars */
    .filter(([_, userRoles]) => userRoles?.includes(ROLE.ADMIN))
    .sort(([userIdA, _userRolesA], [userIdB, _userRolesB]) =>
      userIdA.localeCompare(userIdB),
    );
  const nonAdminRoles = filteredRoles
    .filter(([_, userRoles]) => !userRoles?.includes(ROLE.ADMIN))
    .sort(([userIdA, _userRolesA], [userIdB, _userRolesB]) =>
      userIdA.localeCompare(userIdB),
    );
  const shareMembers = [...adminRoles, ...nonAdminRoles];
  const downloadEnabledForMembers = shareMembers.some(([_, userRoles]) => {
    return (userRoles || []).includes(ROLE.DOWNLOAD);
  });
  /* eslint-enable @typescript-eslint/no-unused-vars */

  const isShared = shareRoles.includes(ROLE.VIEW);

  const downloadToggleable =
    isShared &&
    (shareRoles.includes(ROLE.DOWNLOAD) || downloadEnabledForMembers)
      ? canManageDownloadControl
      : true;

  const passwordToggleable = canManagePassword;

  return (
    <div className="ShareDialogMembers">
      {isEntityOwner && (
        <ControlledFeatureCallout
          featuresStatus={{
            [CONTROLLED_SHARE_FEATURES.DOWNLOAD]: {
              enabled: downloadToggleable,
            },
            [CONTROLLED_SHARE_FEATURES.PASSWORD]: {
              enabled: passwordToggleable,
              ...(!downloadToggleable && {
                label: "passwords",
              }),
            },
          }}
          upgradeSuffix={
            !downloadToggleable && !passwordToggleable
              ? "for advanced sharing options inc."
              : "to enable"
          }
          onUpgrade={showPlanPickerDialog}
        />
      )}

      {canManageEntity && (
        <DialogSection>
          <InviteByEmail
            roles={roleSettings.roles}
            onChange={updateEntityRoles}
          />
        </DialogSection>
      )}

      <DialogSection>
        {shareMembers.map(([userId, userRoles]) => {
          const isOwner = (userRoles || []).includes(ROLE.ADMIN);
          const inheritedRoles = entity?.inheritedRolesV3 || {};
          const entityRoles = entity?.rolesV2 || {};
          const hasEntityRoles = !!entityRoles[userId];
          const isInheriting = !!inheritedRoles[userId];
          const isBlocked = (userRoles || []).length === 0;
          const canManageParent =
            inheritedRoles[userId]?.includes(ROLE.ADMIN) ||
            inheritedRoles[userId]?.includes(ROLE.MANAGE);
          const inviteEmail = getInviteEmail(userId);
          const userDisabledRoles =
            (userRoles || []).includes(ROLE.DOWNLOAD) &&
            !canManageDownloadControl
              ? [
                  {
                    role: ROLE.DOWNLOAD,
                    message: isEntityOwner
                      ? ControlledFeatureTooltipLabel[
                          CONTROLLED_SHARE_FEATURES.DOWNLOAD
                        ]
                      : defaultFeatureAlertMessage,
                  },
                ]
              : [];

          return (
            <div className="ShareDialog-member" key={`${userId}-share-dialog`}>
              <UserInfo userId={userId} alias={inviteEmail}>
                {isOwner ? "" : getRoleSummary(userRoles)}
              </UserInfo>

              {isInviteId(userId) && (
                <span className="ShareDialog-invite-tag">Invite Sent</span>
              )}

              {isOwner ? (
                <span className="ShareDialog-owner-tag">Owner</span>
              ) : (
                <>
                  <InheritanceIndicator entity={entity} userId={userId} />

                  {!isInheriting && user?.id === userId ? (
                    <Button
                      className="ShareDialog-remove-myself"
                      theme={BUTTON_THEME.TEXT}
                      onClick={async (e) => {
                        e.stopPropagation();
                        await updateRoleSettings({
                          [userId]: null,
                        });
                      }}
                    >
                      Remove Myself
                    </Button>
                  ) : (
                    <PermissionTooltip hasPermission={canManageEntity}>
                      <div>
                        <RolePicker
                          isDisabled={!canManageEntity}
                          value={userRoles || []}
                          disabledRoles={userDisabledRoles}
                          options={isBlocked ? [] : userRoleOptions}
                          additionalOptions={[
                            <Tooltip
                              key="block-access-tooltip"
                              isDisabled={!canManageParent}
                              title="User cannot be blocked since they have manage access to the parent Collection."
                            >
                              <div>
                                <MenuItem
                                  disabled={canManageParent}
                                  className="SpaceDialogMembers-option"
                                  key={`item-block`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    updateRoleSettings({
                                      [userId]: isBlocked ? [ROLE.VIEW] : [],
                                    });
                                  }}
                                >
                                  {isBlocked ? <EyeSVG /> : <EyeSlashedSVG />}
                                  <p>
                                    <span className="title">
                                      {isBlocked
                                        ? "Unblock User"
                                        : "Block User"}
                                    </span>

                                    {isBlocked
                                      ? "Restore access in this space"
                                      : "Block User in this space"}
                                  </p>
                                </MenuItem>
                              </div>
                            </Tooltip>,
                            ...(hasEntityRoles
                              ? [
                                  <MenuItem
                                    className="SpaceDialogMembers-option"
                                    key={`item-reset`}
                                    onClick={async (e) => {
                                      e.stopPropagation();
                                      await updateRoleSettings({
                                        [userId]: null,
                                      });

                                      // If you remove the user, close the menu.
                                      const backdropEl = document.querySelector(
                                        ".RolePicker-menu .MuiBackdrop-root",
                                      ) as HTMLDivElement;
                                      if (backdropEl) backdropEl.click();
                                    }}
                                  >
                                    {isInheriting ? (
                                      <EnterInheritedCircleSVG />
                                    ) : (
                                      <TrashSVG />
                                    )}
                                    <p>
                                      <span className="title">
                                        {isInheriting
                                          ? "Undo Overrides"
                                          : "Remove User"}
                                      </span>
                                      {isInheriting
                                        ? "Restore inherited permissions"
                                        : "Remove user from this Space"}
                                    </p>
                                  </MenuItem>,
                                ]
                              : []),
                          ]}
                          onChange={(userRoles) => {
                            updateRoleSettings({ [userId]: userRoles });
                          }}
                        />
                      </div>
                    </PermissionTooltip>
                  )}
                </>
              )}
            </div>
          );
        })}
      </DialogSection>
    </div>
  );
};
