export enum APP_FEATURES {
  PRIVATE_INBOX = "privateInbox",
  TRACK_VERSIONS_USAGE = "trackVersionsUsage",
}

export const AppFeaturesStatus: Record<APP_FEATURES, boolean> = {
  [APP_FEATURES.PRIVATE_INBOX]: false,
  [APP_FEATURES.TRACK_VERSIONS_USAGE]: false,
};

export const checkFeaturePermissions = (allowedChecks: {
  [key in APP_FEATURES]?: boolean;
}): boolean => {
  return Object.keys(allowedChecks).every((key) => {
    const featureKey = key as unknown as APP_FEATURES;
    if (AppFeaturesStatus[featureKey]) {
      return allowedChecks[featureKey];
    }
    return true;
  });
};
