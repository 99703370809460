import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
  ReactElement,
} from "react";
import { useCommentCards } from "App/common/useCommentCards";
import { useViewport } from "App/common/useViewport";
import { useSeekRef } from "App/routes/Main/Space/Carousel/useSeekRef";

import CarouselSync from "./CarouselSync";

const dummyCarouselSync = new CarouselSync(() => {}, { current: null }, 0, []);

const CarouselSyncContext = createContext<{ sync: CarouselSync }>({
  sync: dummyCarouselSync,
});

const SMALL_VIEWPORT_WIDTH = 450;

export const CarouselSyncProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const seekRef = useSeekRef();

  const { vw } = useViewport();
  const cardWidth = vw <= SMALL_VIEWPORT_WIDTH ? vw - 40 : 400;
  const { orderedCards } = useCommentCards();

  // React will not automatically re-render when CarouselSync's internal state changes,
  // so when the UI needs to be updated, methods in CarouselSync must call `updateReact`
  // to re-render all components that use this `useCarouselSync` hook
  const [, setTimestamp] = useState(0);
  const updateReact = useCallback(() => {
    setTimestamp(Date.now());
  }, []);

  const [sync] = useState<CarouselSync>(
    () => new CarouselSync(updateReact, seekRef, cardWidth, orderedCards),
  );

  useEffect(() => {
    sync.updateCards(cardWidth, orderedCards);

    const resizeObserver = new ResizeObserver(() => {
      sync.updateCards(cardWidth, orderedCards);
    });

    if (!sync.carouselScrollerRef.current) return;
    resizeObserver.observe(sync.carouselScrollerRef.current);

    return () => resizeObserver.disconnect();
  }, [cardWidth, orderedCards, sync]);

  // We *want* to re-render consumers of this context
  // whenever `updateReact` is called, so we wrap `sync` in an object here
  return (
    <CarouselSyncContext.Provider value={{ sync }}>
      {children}
    </CarouselSyncContext.Provider>
  );
};

export const useCarouselSync = (): CarouselSync => {
  return useContext(CarouselSyncContext).sync;
};
