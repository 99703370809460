import "./Actions.scss";
import React, { useRef, useState } from "react";
import { Menu, MenuItem } from "../Menu";
import { ReactComponent as EllipsisVerticalSVG } from "App/common/icons/ellipsis-vertical.svg";
import { Tooltip } from "App/core/Tooltip";
import { Button, BUTTON_THEME } from "App/core/Button";
import { MenuProps } from "@mui/material";

export type Action = {
  name?: string;
  icon?: React.ReactNode;
  onClick?: React.MouseEventHandler;
  Component?: React.FC<{ action: Action }>;
  disabled?: boolean;
  disabledReason?: string;
  warn?: boolean;
};

export const ActionDivider: Action = {
  Component: () => <div className="divider"></div>,
};

export const Actions = ({
  actions = [],
  onClick,
  menuProps,
}: {
  actions?: Action[];
  onClick?: () => void;
  menuProps?: Partial<MenuProps>;
}) => {
  const triggerRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  if (!onClick && (!actions || actions.length < 1)) {
    return null;
  }

  return (
    <>
      <Button
        className="highnote-actions"
        data-cypress-id="highnote-actions"
        ref={triggerRef}
        data-is-active={isMenuOpen}
        theme={BUTTON_THEME.ICON}
        type="button"
        onClick={(e) => {
          onClick && onClick();

          e.preventDefault();
          e.stopPropagation();
          setIsMenuOpen(true);
        }}
      >
        <EllipsisVerticalSVG />
      </Button>
      <Menu
        className="highnote-actions-menu"
        data-cypress-id="highnote-actions-menu"
        anchorEl={triggerRef.current}
        onClick={(e) => {
          e.stopPropagation();
          setIsMenuOpen(false);
        }}
        onClose={() => {
          setIsMenuOpen(false);
        }}
        open={isMenuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        {...(menuProps || {})}
      >
        {actions.map((action, i) => {
          if (!action) return null;

          if (action.name === "SEPARATOR") {
            return <div key={i} className="separator" />;
          }

          if (action.Component) {
            return <action.Component key={i} action={action} />;
          }

          if (!action.disabled) {
            return <ActionItem key={i} action={action} warn={action.warn} />;
          }

          return (
            <Tooltip
              isDisabled={!action.disabledReason}
              title={action.disabledReason}
              key={i}
            >
              <div>
                <ActionItem action={action} />
              </div>
            </Tooltip>
          );
        })}
      </Menu>
    </>
  );
};

export const ActionItem = ({
  action,
  disabled,
  warn,
}: {
  action: Action;
  disabled?: boolean;
  warn?: boolean;
}) => {
  return (
    <MenuItem
      className="highnote-action-item"
      onClick={disabled || action.disabled ? undefined : action.onClick}
      disabled={!!disabled || action.disabled}
      data-warn={!!warn}
    >
      {action.icon || null}
      <span>{action.name}</span>
    </MenuItem>
  );
};

export const ActionInfo = ({ children }: { children: React.ReactNode }) => {
  return (
    <MenuItem className="highnote-action-info" disabled={true}>
      {children}
    </MenuItem>
  );
};
