import React from "react";
import "./ProgressBar.scss";
import { useTheme } from "./ThemeProvider";

export const ProgressBar = ({ progress = 0 }: { progress: number }) => {
  const { theme } = useTheme();

  return (
    <div className="progress-bar" data-theme={theme}>
      <div className="outer">
        <div className="inner" style={{ width: `${progress * 100}%` }}></div>
      </div>
    </div>
  );
};
