/* WARNING!!!
This file is imported both on the server and the client!
Make sure all dependencies are context-agnostic. */
import { Response as NodeFetchResponse } from "node-fetch";
import { Dropbox, files } from "dropbox";
import {
  AUDIO_QUALITY,
  audioQualityOptions,
  Comment,
  Entity,
  Space,
  Track,
  SUBSCRIPTION_TIER,
  FileBlockV2,
  FileEntity,
  STRIPE_INTERVAL_FORMATTED,
  SUBSCRIPTION_INTERVAL,
  SUBSCRIPTION_PRICING,
  SUBSCRIPTION_TIER_FORMATTED,
  SUBSCRIPTION_TIER_HIERARCHY,
  UserSubscriptions,
  ROLE,
  COMPATIBLE_ROLES,
  PUBLIC_ID,
  COLLECTION_ID,
  UserEntity,
  SHARE_KEY_REGEX,
  AUDIO_ACCEPT_TYPES,
  SHARE_KEY_PREFIX,
  DropboxSyncJob,
} from "./entities";
import { Stripe } from "stripe";
import { APP_FEATURES, AppFeaturesStatus } from "./features";
import { JOB_STATUS } from "./entities";

// No import support for Node
// eslint-disable-next-line @typescript-eslint/no-var-requires
const moment = require("moment");
// No TS support

export const ADMIN_UID = "auth0|62cf2b879d94d0fae1cfada1";

export const MAX_PINNED_VERSIONS = 3;

export const MAX_TITLE_LENGTH = 50;
export const MAX_DESCRIPTION_LENGTH = 300;

export const DEMO_ENTITY_PREFIX = "debut-";

export const TYPEFORM_WELCOME_SURVEY_ID = "zdFFbNYv";

export const AUDIO_UPLOAD_SIZE_LIMIT_IN_BYTES = 2 * 1024 * 1024 * 1024; // 2GB

const ENTITY_PROPS = [
  "id",
  "createdBy",
  "lastUpdatedBy",
  "createdAt",
  "updatedAt",
  "rolesV2",
  "inheritedRolesV3",
  "readableByV2",
];

export interface AuthToken {
  entityPasswords?: Record<
    string,
    {
      share?: string;
      public?: string;
    }
  >;
  shareKey?: string;
}

export const getFullRoles = (entity: Entity) => {
  if (!entity) return {};

  const inheritedRoles = entity.inheritedRolesV3 || {};
  const entityRoles = entity.rolesV2 || {};

  const allRoles: Entity["rolesV2"] = {
    ...inheritedRoles,
  };

  // Anyone who owns the parent collection is downgraded to Manage
  Object.entries(inheritedRoles).forEach(([userId, permissions]) => {
    if (permissions.includes(ROLE.ADMIN)) {
      allRoles[userId] = [
        ROLE.MANAGE,
        ROLE.COMMENT,
        ROLE.DOWNLOAD,
        ROLE.UPLOAD,
        ROLE.VIEW,
      ];
    }
  });

  Object.entries(entityRoles).forEach(([userId, userRoles]) => {
    // Anyone who has management access to the parent should at least have view
    // access, so they can remove it if they want.
    const canManageParent =
      allRoles[userId] &&
      (allRoles[userId].includes(ROLE.ADMIN) ||
        allRoles[userId].includes(ROLE.MANAGE));

    if (userRoles) {
      allRoles[userId] = userRoles;
      if (canManageParent && !userRoles.includes(ROLE.VIEW)) {
        userRoles.push(ROLE.VIEW);
      }
    }
  });

  if (entity.createdBy) {
    allRoles[entity.createdBy] = [ROLE.ADMIN];
  }

  return allRoles;
};

export enum PERMISSION {
  TO_DELETE_SPACE,
  TO_ARCHIVE_SPACE,
  TO_MANAGE_SPACE,
  TO_ADD_TO_SPACE,
  TO_ADD_SPACE_TO_SPACE,
  TO_ADD_ATTACHMENT_TO_SPACE,
  TO_VIEW_SPACE,
  TO_REMOVE_SELF_FROM_SPACE,

  TO_TOGGLE_SPACE_CHAT_ENABLED_FIELD,
  TO_TOGGLE_SPACE_SPACE_SETUP_FIELD,

  TO_REMOVE_FILE_FROM_TRACK_IN_SPACE,
  TO_DOWNLOAD_TRACK,
  TO_DOWNLOAD_TRACK_IN_SPACE,
  TO_ADD_TO_TRACK,
  TO_ADD_TO_TRACK_IN_SPACE,
  TO_EDIT_TRACK_IN_SPACE,
  TO_VIEW_TRACK_IN_SPACE,

  TO_RESOLVE_COMMENT_IN_SPACE,
  TO_DELETE_COMMENT_IN_SPACE,
  TO_EDIT_COMMENT_IN_SPACE,
  TO_COMMENT_IN_SPACE,
  TO_REPLY_TO_COMMENT_IN_SPACE,

  TO_DISABLE_DOWNLOAD,
  TO_DISABLE_GUEST_MODE,
  TO_DOWNLOAD_SPACE,
  TO_DOWNLOAD_FILE_IN_SPACE,
  TO_DOWNLOAD_FILE_IN_COMMENT,
  TO_CREATE_FILE_IN_SPACE,
  TO_EDIT_FILE,

  TO_LOCK_AUDIO_QUALITY,
}

export const hasRole = (identifier: string, role: ROLE, entity: Entity) => {
  const userId = sanitizeShareKey(identifier);
  const roles = getFullRoles(entity);
  const userRoles = [...(roles[userId] || []), ...(roles[PUBLIC_ID] || [])];
  const rolesToMatch = COMPATIBLE_ROLES[role] || [];
  return userRoles.some((r) => rolesToMatch.includes(r));
};

export const isAllowed: (
  key: PERMISSION,
  identifier: Id,
  entities: {
    space?: Space;
    comment?: Comment;
    track?: Track;
    file?: FileEntity;
  },
) => boolean = (key, identifier, entities) => {
  const userId = sanitizeShareKey(identifier);
  const can = (role: ROLE, entity: Entity) => {
    if (userId === "auth0|62cf2b879d94d0fae1cfada1") return true;
    if (!entity) return false;
    return hasRole(userId, role, entity);
  };

  if (key === PERMISSION.TO_TOGGLE_SPACE_CHAT_ENABLED_FIELD) {
    return !isShareId(userId) && can(ROLE.MANAGE, entities.space);
  }

  if (key === PERMISSION.TO_TOGGLE_SPACE_SPACE_SETUP_FIELD) {
    return !isShareId(userId) && can(ROLE.ADMIN, entities.space);
  }

  if (key === PERMISSION.TO_DISABLE_GUEST_MODE) {
    const baseAllowed = can(ROLE.MANAGE, entities.space);
    const isPrivateInbox = isSpacePrivateInboxEnabled(entities.space);
    return !isPrivateInbox && baseAllowed;
  }

  if (key === PERMISSION.TO_DISABLE_DOWNLOAD && entities.space) {
    const entity = entities.space;
    const baseAllowed = can(ROLE.MANAGE, entity);
    const entityAllowed =
      entity.subscriptionTier &&
      entity.subscriptionTier !== SUBSCRIPTION_TIER.FREE;
    return baseAllowed && entityAllowed;
  }

  if (key === PERMISSION.TO_LOCK_AUDIO_QUALITY) {
    const entity = entities.space;
    const entityAllowed =
      entity.subscriptionTier &&
      entity.subscriptionTier === SUBSCRIPTION_TIER.STUDIO;
    return entityAllowed && can(ROLE.MANAGE, entity);
  }

  /* SPACE */
  if (key === PERMISSION.TO_DELETE_SPACE) {
    return can(ROLE.ADMIN, entities.space);
  }

  if (key === PERMISSION.TO_ARCHIVE_SPACE) {
    return can(ROLE.ADMIN, entities.space);
  }

  if (key === PERMISSION.TO_MANAGE_SPACE) {
    return !entities.space?.isArchived && can(ROLE.MANAGE, entities.space);
  }

  if (key === PERMISSION.TO_ADD_TO_SPACE) {
    return !entities.space?.isArchived && can(ROLE.UPLOAD, entities.space);
  }

  if (
    key === PERMISSION.TO_ADD_SPACE_TO_SPACE ||
    key === PERMISSION.TO_ADD_ATTACHMENT_TO_SPACE
  ) {
    // if checking for TO_ADD_SPACE_TO_SPACE and the space is password protected,
    // return false
    if (
      key === PERMISSION.TO_ADD_SPACE_TO_SPACE &&
      entities.space?.sharePasswordEnabled
    ) {
      return false;
    }
    const isPrivateInboxEnabled = isSpacePrivateInboxEnabled(entities.space);
    // if a private inbox is ON, you have to be logged-in to be able to add a space to a space
    if (isPrivateInboxEnabled && isShareId(userId)) {
      return false;
    }
    return isAllowed(PERMISSION.TO_ADD_TO_SPACE, identifier, entities);
  }

  if (key === PERMISSION.TO_VIEW_SPACE) {
    return can(ROLE.VIEW, entities.space);
  }

  if (key === PERMISSION.TO_REMOVE_SELF_FROM_SPACE) {
    const isOwner = entities.space?.createdBy === userId;
    const isTopLevelReadable =
      entities.space?.topLevelReadableBy?.includes(userId);
    return !isOwner && isTopLevelReadable;
  }

  /* TRACK IN SPACE */
  if (key === PERMISSION.TO_REMOVE_FILE_FROM_TRACK_IN_SPACE) {
    return (
      can(ROLE.ADMIN, entities.file) ||
      can(ROLE.ADMIN, entities.track) ||
      can(ROLE.MANAGE, entities.space)
    );
  }

  if (key === PERMISSION.TO_DOWNLOAD_TRACK) {
    return can(ROLE.ADMIN, entities.track);
  }

  if (key === PERMISSION.TO_DOWNLOAD_TRACK_IN_SPACE) {
    return (
      can(ROLE.DOWNLOAD, entities.space) || can(ROLE.ADMIN, entities.track)
    );
  }

  // this is for tracks that do not belong to any space
  if (key === PERMISSION.TO_ADD_TO_TRACK) {
    return (
      entities.track &&
      !entities.track?.spaceId &&
      can(ROLE.ADMIN, entities.track)
    );
  }

  if (key === PERMISSION.TO_ADD_TO_TRACK_IN_SPACE) {
    return can(ROLE.MANAGE, entities.space) || can(ROLE.UPLOAD, entities.space);
  }

  if (key === PERMISSION.TO_EDIT_TRACK_IN_SPACE) {
    return can(ROLE.ADMIN, entities.track) || can(ROLE.MANAGE, entities.space);
  }

  if (key === PERMISSION.TO_VIEW_TRACK_IN_SPACE) {
    return can(ROLE.ADMIN, entities.track) || can(ROLE.VIEW, entities.space);
  }

  /* COMMENT IN SPACE */
  if (key === PERMISSION.TO_RESOLVE_COMMENT_IN_SPACE) {
    return can(ROLE.ADMIN, entities.space) || can(ROLE.MANAGE, entities.space);
  }

  if (key === PERMISSION.TO_DELETE_COMMENT_IN_SPACE) {
    return (
      can(ROLE.MANAGE, entities.space) ||
      (can(ROLE.ADMIN, entities.comment) && can(ROLE.COMMENT, entities.space))
    );
  }

  if (key === PERMISSION.TO_EDIT_COMMENT_IN_SPACE) {
    return (
      !entities.comment?.isResolved &&
      can(ROLE.ADMIN, entities.comment) &&
      can(ROLE.COMMENT, entities.space)
    );
  }

  if (key === PERMISSION.TO_COMMENT_IN_SPACE) {
    return !entities.space?.isArchived && can(ROLE.COMMENT, entities.space);
  }

  if (key === PERMISSION.TO_REPLY_TO_COMMENT_IN_SPACE) {
    return (
      !entities.space?.isArchived &&
      !entities.comment?.isResolved &&
      can(ROLE.COMMENT, entities.space)
    );
  }

  if (key === PERMISSION.TO_DOWNLOAD_SPACE) {
    return can(ROLE.DOWNLOAD, entities.space);
  }

  /* FILE IN SPACE */
  if (key === PERMISSION.TO_DOWNLOAD_FILE_IN_SPACE) {
    return (
      can(ROLE.DOWNLOAD, entities.space) ||
      !entities.file ||
      can(ROLE.ADMIN, entities.file)
    );
  }

  if (key === PERMISSION.TO_DOWNLOAD_FILE_IN_COMMENT) {
    return can(ROLE.ADMIN, entities.comment);
  }

  if (key === PERMISSION.TO_CREATE_FILE_IN_SPACE) {
    return can(ROLE.UPLOAD, entities.space);
  }

  /* FILE */
  if (key === PERMISSION.TO_EDIT_FILE) {
    return can(ROLE.ADMIN, entities.file);
  }

  return false;
};

export const isInviteId = (userId = "") => {
  return userId.indexOf("invite:") === 0;
};

// shareKey was once in a format that started with "share|", which caused issues
// due to how "|" is not safe when used in URL. So, we changed the format to start
// with "share_".
// sanitizeShareKey converts shareKey in the old format to the new format.
export const sanitizeShareKey = (shareKey: string) => {
  return shareKey && shareKey.replace(SHARE_KEY_REGEX, SHARE_KEY_PREFIX);
};

export const isShareId = (userId: string) => {
  return userId && SHARE_KEY_REGEX.test(userId);
};

export const getInviteEmail = (userId = "") => {
  const parts = userId.split("invite:");
  return parts[1]?.replace("\\", ".");
};

export const getCommentType = (comment: Comment) => {
  const block = comment?.blocks && comment.blocks[0];
  let commentType: string = block?.type;
  if (block?.type === "custom") commentType = "sticker";
  if (block?.type === "file-v2") commentType = "voice";
  return commentType;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const sanitizeEntityData = (data: any) => {
  const sanitized: any = {};
  /* eslint-enable @typescript-eslint/no-explicit-any */

  Object.keys(data).forEach((key) => {
    const parts = key.split(",");
    if (parts[0] === "rolesV2" && !!parts[1]) {
      sanitized[`rolesV2,${parts[1].toLowerCase()}` as string as keyof Entity] =
        data[key];
      return;
    }

    if (!ENTITY_PROPS.includes(key)) {
      return;
    }

    sanitized[key as keyof Entity] = data[key];
  });

  return sanitized as Entity;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const sanitizeCommentData = <T>(data: any) => {
  const sanitized: any = { ...sanitizeEntityData(data) };
  /* eslint-enable @typescript-eslint/no-explicit-any */

  Object.keys(data).forEach((key) => {
    if (
      ![
        "spaceId",
        "trackId",
        "timestamp",
        "duration",
        "replies",
        "blocks",
        "parentComment",
        "createdByAlias",
        "trackVersionIds",
        "isResolved",
      ].includes(key)
    ) {
      return;
    }

    sanitized[key as keyof T] = data[key];
  });

  if (sanitized.blocks?.[0]?.type) {
    sanitized.commentType = sanitized.blocks[0].type;
  }

  return sanitized as T;
};

export const stripExtension = (path: string) => path.replace(/\.[^/.]+$/, "");

export const isAIFPath = (path: string) => path?.match(/\.(aif|aiff)$/);
export const isUniversalAudioPath = (path: string) =>
  path?.match(/\.(wav|mp3|mp4)$/);

export const formatDuration = (time: number, roundUp?: boolean) => {
  const _min = Math.floor(time / 60);
  let _sec = Math.floor(time % 60);

  if (roundUp && _min === 0 && _sec === 0) {
    // EDGE CASE!
    // If the duration is teeny tiny (less than 1 sec)
    // Round up to 1 second.
    if (time > 0) _sec = 1;
  }

  const min = isNaN(_min) ? 0 : _min;
  const sec = isNaN(_sec) ? 0 : _sec;
  const secString = sec < 10 ? `0${sec}` : String(sec);
  return `${min}:${secString}`;
};

export const formatDatetime = (unix: number) => {
  return moment.unix(unix).format("MMMM Do [at] h:mm a");
};

export const defaultUploadFileName = "original-file";

export const getFileStoragePath = ({
  userId,
  fileId,
  fileName,
}: {
  userId: string;
  fileId: string;
  fileName: string;
}) => {
  const ext = getFileExtension(fileName);
  return `${userId}/${fileId}/${defaultUploadFileName}.${ext}`;
};

export const getUserIdFromStoragePath = (path: string) => path.split("/")[0];

export const parsePriceId = (priceId: string | null) => {
  let interval: SUBSCRIPTION_INTERVAL;
  let tier: SUBSCRIPTION_TIER = SUBSCRIPTION_TIER.FREE;

  if (!priceId) return { tier, interval };

  [
    SUBSCRIPTION_TIER.INDIE,
    SUBSCRIPTION_TIER.PRO,
    SUBSCRIPTION_TIER.STUDIO,
  ].forEach((tierKey) => {
    Object.entries(SUBSCRIPTION_PRICING[tierKey]).forEach(
      ([intervalKey, id]) => {
        if (id === priceId) {
          interval = intervalKey as SUBSCRIPTION_INTERVAL;
          tier = tierKey;
        }
      },
    );
  });

  return { tier, interval };
};

type ParsedSubscription = {
  tier: SUBSCRIPTION_TIER;
  priceId: string | null;
  interval: SUBSCRIPTION_INTERVAL;
};

export const parseSubscription = (
  subscription?: Stripe.Subscription,
): ParsedSubscription => {
  const subItems = subscription?.items?.data || [];
  const item = subItems[0];
  const priceId = item?.price?.id || null;
  const { tier, interval } = parsePriceId(priceId);
  return {
    tier,
    priceId,
    interval,
  };
};

export const parseScheduledSubscription = (
  scheduledSubscription?: Stripe.SubscriptionSchedule,
): ParsedSubscription => {
  const subItems = scheduledSubscription?.phases[0]?.items || [];
  const item = subItems[0];
  const priceId = item?.price as string;
  const { tier, interval } = parsePriceId(priceId);
  return {
    tier,
    priceId,
    interval,
  };
};

export const getFileBlocksFromComment = (comment: Comment) => {
  const commentBlocks = comment.blocks || [];
  const fileBlocks: FileBlockV2[] = [];
  commentBlocks.forEach((b) => {
    if (b.type === "file-v2") fileBlocks.push(b as FileBlockV2);
    if (b.type === "poll-v2") {
      b.options.forEach((option) => {
        if (option.type === "file-v2") fileBlocks.push(option as FileBlockV2);
      });
    }
  });

  return fileBlocks;
};

export const processWaveformData = (_waveformData: number[], numBars = 50) => {
  let waveformData = _waveformData;
  if (!waveformData || waveformData.length === 0) {
    waveformData = [0];
  }

  const groups = new Array(numBars).fill("").map(() => []);

  let data: number[] = waveformData;

  if (waveformData.length < numBars) {
    data = [...groups].map((_, i) => {
      const ratio = i / groups.length;
      const waveformIndex = Math.floor(ratio * waveformData.length);
      return waveformData[waveformIndex];
    }) as number[];
  }

  const scale = data.length / groups.length;
  data.forEach((datum, i) => {
    const groupIndex = Math.floor(i / scale);
    groups[groupIndex].push(datum);
  });

  const averages = new Array(numBars).fill(0);
  groups.forEach((group, i) => {
    const sum = group.reduce((s: number, num: number) => s + num, 0);
    const avg = sum / group.length;
    averages[i] = avg;
  });

  const max = averages.reduce((n, a) => (a > n ? a : n), -100);
  const min = averages.reduce((n, a) => (a < n ? a : n), 0);
  const range = max - min;

  const ratio = (n: number) => {
    const val = Math.min(Math.max((n - min) / range, 0.01), 1);
    return isNaN(val) ? 0 : val;
  };

  return averages.map(ratio);
};

export const getSubscriptionTier = (subscriptions: UserSubscriptions) => {
  const { active } = subscriptions;
  if (!active) return SUBSCRIPTION_TIER.FREE;

  const subscriptionItem = active.items.data[0];
  const priceId = subscriptionItem.price.id;
  const { tier } = parsePriceId(priceId);
  return tier;
};

// WARNING!!!!
// If you edit these values, you must edit the matching values in
// @highnote/server/storage.rules
export const getStorageLimitByTier = (
  tier: SUBSCRIPTION_TIER = SUBSCRIPTION_TIER.FREE,
) => {
  const isProd = process.env.DEPLOY_ENV === "production";

  if (tier === SUBSCRIPTION_TIER.STUDIO) {
    return 1024 * 1024 * 1024 * 1024 * 5; // 5 TB - basically unlimited
  }

  if (tier === SUBSCRIPTION_TIER.PRO) {
    if (isProd) return 1024 * 1024 * 1024 * 1024; // 1 TB
    return 1024 * 1024 * 20; // 20 MB
  }

  if (tier === SUBSCRIPTION_TIER.INDIE) {
    if (isProd) return 1024 * 1024 * 1024 * 200; // 200 GB
    return 1024 * 1024 * 15; // 15 MB
  }

  if (isProd) return 1024 * 1024 * 1024 * 50; // 50 GB
  return 1024 * 1024 * 10; // 10 MB
};

export const getActiveSpacesLimitByTier = (tier: SUBSCRIPTION_TIER) => {
  if (tier === SUBSCRIPTION_TIER.STUDIO || tier === SUBSCRIPTION_TIER.PRO) {
    return Infinity;
  }

  if (tier === SUBSCRIPTION_TIER.INDIE) {
    return 3;
  }

  return 1;
};

export const getTrackVersionsLimitByTier = (
  tier: SUBSCRIPTION_TIER = SUBSCRIPTION_TIER.FREE,
) => {
  if (
    tier === SUBSCRIPTION_TIER.STUDIO ||
    !AppFeaturesStatus[APP_FEATURES.TRACK_VERSIONS_USAGE]
  ) {
    return Infinity;
  }

  if (tier === SUBSCRIPTION_TIER.PRO) {
    return 1500;
  }

  if (tier === SUBSCRIPTION_TIER.INDIE) {
    return 500;
  }

  return 15;
};

export const parseFileSize = (file: FileEntity) => {
  const size = Number(file.size);
  return !isNaN(size) ? size : 0;
};

export const getDefaultVersionId = (track: Track) => {
  if (!track) return undefined;
  const trackVersions = track.versionFilesV2 || [];
  const pinnedVersions = track.pinnedVersionFiles || [];
  const lastOrderedPinnedVersion = pinnedVersions[pinnedVersions.length - 1];
  const latestVersion = trackVersions[trackVersions.length - 1];
  return lastOrderedPinnedVersion || latestVersion;
};

export const formatStripeDate = (date: number) =>
  moment(date * 1000).format("MMMM Do, YYYY");

export const getPlanStatus = (subscriptions: UserSubscriptions) => {
  if (!subscriptions?.active) {
    return "You are currently on the Free Plan.";
  }

  const active = parseSubscription(subscriptions.active);
  const scheduled = parseScheduledSubscription(subscriptions.scheduled);
  const cancelAt = subscriptions.active.cancel_at;

  const currentStatus = `You are currently on the ${
    STRIPE_INTERVAL_FORMATTED[active.interval]
  } ${SUBSCRIPTION_TIER_FORMATTED[active.tier]} Plan.`;

  if (!cancelAt) {
    return currentStatus;
  }

  const subscriptionDirection = getSubscriptionDirection(
    active.priceId,
    scheduled.priceId,
  );
  let verb = "switched";
  if (subscriptionDirection === SUBSCRIPTION_DIRECTION.UPGRADE) {
    verb = "upgraded";
  }
  if (subscriptionDirection === SUBSCRIPTION_DIRECTION.DOWNGRADE) {
    verb = "downgraded";
  }
  const upcomingPlan =
    scheduled.tier === SUBSCRIPTION_TIER.FREE
      ? "Free"
      : `${STRIPE_INTERVAL_FORMATTED[scheduled.interval]} ${
          SUBSCRIPTION_TIER_FORMATTED[scheduled.tier]
        }`;

  return `${currentStatus} On ${formatStripeDate(
    cancelAt,
  )}, you will automatically be ${verb} to the ${upcomingPlan} Plan.`;
};

export enum SUBSCRIPTION_DIRECTION {
  UPGRADE = 1,
  DOWNGRADE = -1,
  SWITCH = 0,
}

export const getSubscriptionDirection = (
  fromPriceId: string,
  toPriceId: string,
) => {
  const { tier: fromTier } = parsePriceId(fromPriceId);
  const { tier: toTier } = parsePriceId(toPriceId);
  const fromIndex = SUBSCRIPTION_TIER_HIERARCHY.indexOf(fromTier);
  const toIndex = SUBSCRIPTION_TIER_HIERARCHY.indexOf(toTier);
  if (fromIndex < toIndex) return SUBSCRIPTION_DIRECTION.UPGRADE;
  if (fromIndex > toIndex) return SUBSCRIPTION_DIRECTION.DOWNGRADE;
  return SUBSCRIPTION_DIRECTION.SWITCH;
};

export const getPrevInArray = <T>(item: T, array: T[]) => {
  if (!item) return;
  const currentIndex = array.indexOf(item);
  if (currentIndex < 0) return;
  return array[currentIndex - 1];
};

export const getNextInArray = <T>(item: T, array: T[]) => {
  if (!item) return;
  const currentIndex = array.indexOf(item);
  if (currentIndex < 0) return;
  return array[currentIndex + 1];
};

export const getFileExtension = (fileName: string) => {
  const fileParts = fileName.toLowerCase().split(".");
  return fileParts[fileParts.length - 1];
};

export const isLossless = (fileName: string) => {
  const ext = getFileExtension(fileName);
  return ["flac", "wav", "aif", "aiff"].includes(ext);
};

export const getQualityOptionsForFile = (fileName: string) => {
  return audioQualityOptions.filter((option) => {
    if (option.quality !== AUDIO_QUALITY.ORIGINAL) return true;
    if (isLossless(fileName) && option.format === "flac") return true;
    if (!isLossless(fileName) && option.format === "mp3") return true;
    return false;
  });
};

export const isFilePlayable = (file: FileEntity) => {
  if (!file) return false;
  if (file.processingErrorV3) return false;
  if (!file.isProcessedV3) return false;
  if (!file.metadata?.duration) return false;
  return true;
};

export const FILE_NOT_FOUND_ERROR =
  "File not found. It may have been deleted or moved.";

export const getApiRoot = () => {
  return process.env.DEPLOY_ENV === "local"
    ? `http://127.0.0.1:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1`
    : `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`;
};

export const getEntityTypeName = (id: COLLECTION_ID) => {
  if (id === COLLECTION_ID.COMMENT) return "Comment";
  if (id === COLLECTION_ID.TRACK) return "Track";
  if (id === COLLECTION_ID.SPACE) return "Space";
  if (id === COLLECTION_ID.FILE) return "File";
  if (id === COLLECTION_ID.USER) return "User";
  return "Item";
};

export const getEntitySubscribers = (entity: Space, role = ROLE.VIEW) => {
  const roles = getFullRoles(entity);
  const subscribers = Object.keys(roles).filter(
    (id) => id !== PUBLIC_ID && hasRole(id, role, entity),
  );
  return subscribers;
};

export const isEntityPasswordProtected = (entity: Space): boolean => {
  return (
    (entity.subscriptionTier === SUBSCRIPTION_TIER.PRO ||
      entity.subscriptionTier === SUBSCRIPTION_TIER.STUDIO) &&
    // we only look at if share password is enabled for the space because
    // we temporarily disabled public share link completely.
    entity.sharePasswordEnabled
  );
};

export const getEntityShareKeys = (entity: Space) => {
  const entityRoles = entity?.rolesV2 || {};

  // Get all the valid share keys (must have roles attached).
  const shareKeys = Object.keys(entityRoles).filter(
    (key) => isShareId(key) && !!entityRoles[key],
  );

  return shareKeys;
};

export const getUserContactMethods = (user: Partial<UserEntity>) => {
  const emails: string[] = [];
  const phones: string[] = [];

  if (user) {
    const linkedAccountsV2 = user.linkedAccountsV2 || [];

    if (user.connection === "sms") phones.push(user.phone);
    else emails.push(user.email);

    linkedAccountsV2.forEach((acct) => {
      if (acct.connection === "sms") phones.push(acct.identifier);
      else emails.push(acct.identifier);
    });
  }

  return {
    email: emails[0],
    phone: phones[0],
  };
};

export const isActiveEntity = (entity: Space) => {
  if (!entity) return false;
  if ((entity as Space).isArchived) return false;

  const roles = getFullRoles(entity);
  let isActive = false;

  Object.entries(roles).forEach(([id, permissions]) => {
    // If this rule is just to block access, it doesn't count
    if (permissions.length < 1) return;

    // If the user is the owner, it doesn't count
    if (id === entity.createdBy) return;

    isActive = true;
  });

  return isActive;
};

export const isSpacePrivateInboxEnabled = (space: Space) => {
  return isSpacePrivateInboxToggleable(space) && space?.privateInboxEnabled;
};

// a dropbox job is considered a stale job if it's in progress
// but hasn't been updated in 30 seconds
export const isDropboxJobStale = (
  job?: DropboxSyncJob,
  states = [JOB_STATUS.IN_PROGRESS],
) => {
  if (!job || !states.includes(job.status)) {
    return false;
  }
  const elapsed = Date.now() - job.updatedAt;
  return elapsed > 30000;
};

export const isSpacePrivateInboxToggleable = (space: Space) => {
  return (
    AppFeaturesStatus[APP_FEATURES.PRIVATE_INBOX] &&
    space?.experimentalPrivateInboxEnabled
  );
};

export class OperationTimeoutError extends Error {
  constructor(message: string) {
    const errMessage = `OperationTimeoutErr: ${message}`;
    super(errMessage);

    // `Error` class needs a proper prototype chain setup to work correctly with `instanceof`.
    Object.setPrototypeOf(this, OperationTimeoutError.prototype);
  }
}

export const timer = (timeoutMs: number, errMessage: string) => {
  return new Promise((_, reject) => {
    if (timeoutMs < 0) {
      reject(new Error("Invalid timeout value"));
    }
    const signal = AbortSignal.timeout(timeoutMs);
    signal.addEventListener("abort", () => {
      reject(new OperationTimeoutError(errMessage));
    });
  });
};

export const checkIsUserAnonymous = (userId: string) => {
  // user is anonymous if the ID has no | in it
  // user is authenticated if the ID is in {login_method}|random_string format
  return !userId?.includes("|");
};

export const EXISTING_AUTH_WITH_GOOGLE_ERR =
  'You signed in differently before. Try "Continue with Google Account."';

export const MAX_ACTIVE_SPACES_ERROR =
  "User has the maximum number of Shared Spaces included in their account.";

export const MAX_TRACKS_ERROR =
  "User has the maximum number of tracks included in their account.";

export const sortItemsByItemsOrder = <T extends { id: Id; entity: Entity }>(
  itemsOrder: string[],
  items: T[],
) => {
  const itemsOrderMap = itemsOrder.reduce<Record<string, boolean>>(
    (acc, itemId) => {
      acc[itemId] = true;
      return acc;
    },
    {},
  );
  const itemsMap = items.reduce<Record<string, T>>((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
  const orderedItems = itemsOrder.reduce((acc, itemId) => {
    if (itemsMap[itemId]) {
      acc.push(itemsMap[itemId]);
    }
    return acc;
  }, []);
  const unOrderedItems = items.filter((item) => {
    return !itemsOrderMap[item.id];
  });
  return {
    orderedItems,
    unOrderedItems,
  };
};

export const sanitizeUserDataForSegment = (
  traits: object & {
    createdAt?: number;
    storageUsed?: number;
  },
) => {
  if (!traits) {
    return traits;
  }
  return {
    ...traits,
    ...(typeof traits?.createdAt === "number" && {
      createdAt_formatted: new Date(traits.createdAt).toISOString(),
    }),
    ...(typeof traits?.storageUsed === "number" && {
      // convert bytes to megabytes
      storageUsed_formatted:
        traits.storageUsed > 0
          ? parseFloat((traits.storageUsed / (1024 * 1024)).toFixed(19))
          : traits.storageUsed,
    }),
  };
};

export const GUEST_MODE_DISABLED_ERR_MESSAGE =
  "Guest mode is disabled for this resource.";

export const INBOX_PERMISSION_DENIED_ERR_MESSAGE =
  "You don't have permission to access this inbox.";

export const AUDIO_UPLOAD_LIMIT_ERR_MESSAGE =
  "This file size exceeds our audio upload limit of 2GB. Select a smaller audio file to continue.";

export class DropboxManagerBase {
  static fileSizeLimitExeedsErrorMsg = AUDIO_UPLOAD_LIMIT_ERR_MESSAGE;
  static storageLimitExceedsErrorMsg = "You have exceeded the storage limit.";

  clientId = process.env.REACT_APP_DROPBOX_CLIENT_ID;
  fetcher: (url: string) => Promise<NodeFetchResponse | Response>;

  static getTooManyFilesErrorMsg(
    subscriptionTier: SUBSCRIPTION_TIER = SUBSCRIPTION_TIER.FREE,
  ) {
    return `You can only import up to ${DropboxManagerBase.getNumberOfFilesLimit(subscriptionTier)} files at a time.`;
  }

  static getNumberOfFilesLimit(
    subscriptionTier: SUBSCRIPTION_TIER = SUBSCRIPTION_TIER.FREE,
  ) {
    return [
      SUBSCRIPTION_TIER.INDIE,
      SUBSCRIPTION_TIER.PRO,
      SUBSCRIPTION_TIER.STUDIO,
    ].includes(subscriptionTier)
      ? 25
      : 15;
  }

  private static validateFileMetadata(
    metadata: files.FileMetadataReference,
    contentType: string | null,
  ) {
    // make sure the file is less than 2GB
    if (metadata.size > AUDIO_UPLOAD_SIZE_LIMIT_IN_BYTES) {
      throw new Error(this.fileSizeLimitExeedsErrorMsg);
    }
    // make sure that the file type is what we support
    if (!AUDIO_ACCEPT_TYPES.includes(contentType)) {
      throw new Error(`${contentType} is not supported.`);
    }
  }

  constructor({
    fetcher,
  }: {
    fetcher: (url: string) => Promise<NodeFetchResponse | Response>;
  }) {
    this.fetcher = fetcher;
  }

  private getDropbox(accessToken: string) {
    return new Dropbox({
      clientId: this.clientId,
      fetch: this.fetcher,
      accessToken,
    });
  }

  // instance that extends this class should implement this method
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected reportAndThrowError(err: Error, messagePrefix?: string) {
    throw err;
  }

  async getFileMetadataForDownload(
    accessToken: string,
    fileId: string,
  ): Promise<{
    metadata: files.FileMetadataReference;
    fileFetchResponse: NodeFetchResponse | Response;
  }> {
    const metadata = await this.getFileMetadata(accessToken, fileId);
    const tempLink = await this.getFileTemporaryLink(accessToken, fileId);
    const resp = await this.fetcher(tempLink);
    const contentType = resp.headers.get("content-type");
    DropboxManagerBase.validateFileMetadata(metadata, contentType);
    return {
      metadata,
      fileFetchResponse: resp,
    };
  }

  async listFolder(
    accessToken: string,
    args: files.ListFolderArg,
  ): Promise<files.ListFolderResult> {
    try {
      const db = this.getDropbox(accessToken);
      const response = await db.filesListFolder(args);
      if (!response.result) {
        throw new Error("No result from Dropbox API.");
      }
      return response.result;
    } catch (err) {
      this.reportAndThrowError(
        err,
        "Error getting folder contents from Dropbox",
      );
    }
  }

  async getFileTemporaryLink(accessToken: string, fileId: string) {
    try {
      const db = this.getDropbox(accessToken);
      const response = await db.filesGetTemporaryLink({ path: fileId });
      if (!response?.result?.link) {
        throw new Error("No result from Dropbox API.");
      }
      return response.result.link;
    } catch (err) {
      this.reportAndThrowError(
        err,
        "Error getting file temporary link from Dropbox",
      );
    }
  }

  async getFileMetadata(accessToken: string, fileId: string) {
    try {
      const db = this.getDropbox(accessToken);
      const metadata = await db.filesGetMetadata({ path: fileId });
      // in our workflow, we should only be calling this API for an individual file
      // if response indicates that it is not a file, we should consider it an error.
      if (!metadata.result || metadata.result[".tag"] !== "file") {
        throw new Error("No result from Dropbox API.");
      }
      return metadata.result as files.FileMetadataReference;
    } catch (err) {
      this.reportAndThrowError(
        err,
        "Error getting files metadata from Dropbox",
      );
    }
  }
}

/**
 * This regex validates URLs, including those with localhost, ports, and query/hash parameters.
 * It is used to validate the redirect URL field in the Space Settings.
 */
export const isValidUrl = (url: string) =>
  /^(https?:\/\/)+([\da-z.-]+)(\.(localhost|\.[a-z.]{2,6}))?(:\d{1,5})?(\/[\w .-]*)*\/?(\?[^#]*)?(#.*)?$/.test(
    url,
  );
