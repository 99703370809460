import "./EntitySortMenu.scss";
import React from "react";
import { SORT_TYPE } from "App/routes/Main/Library/config";
import { Select } from "App/common/Select";
import { MenuItem } from "App/common/Menu";
import { ReactComponent as SortSVG } from "App/common/icons/sort.svg";

export const EntitySortMenu = ({
  sortType = SORT_TYPE.CUSTOM,
  onChange,
}: {
  sortType: SORT_TYPE;
  onChange: (sortType: SORT_TYPE) => void;
}) => {
  return (
    <span className="highnote-entity-sort-select">
      <Select
        className="sort-select"
        MenuProps={{
          className: "highnote-sort-menu",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
        startAdornment={<SortSVG />}
        value={sortType}
        size="small"
        onChange={(e) => onChange(e.target.value as SORT_TYPE)}
      >
        {[SORT_TYPE.ALPHABETICAL, SORT_TYPE.CREATED_AT, SORT_TYPE.CUSTOM].map(
          (type) => {
            return (
              <MenuItem key={`sort-${type}`} value={type}>
                <span className="title">{type}</span>
              </MenuItem>
            );
          },
        )}
      </Select>
    </span>
  );
};
