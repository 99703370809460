import React from "react";
import { v4 as uuidv4 } from "uuid";
import { AudioFileSelect } from "App/common/FileSelect";
import {
  AudioUploadFileSizeErr,
  UPLOAD_GROUP,
  useFiles,
} from "App/components/useFiles";
import { highnote } from "@highnote/server/src/sdk";
import { stripExtension } from "@highnote/server/src/core/shared-util";
import { useToast } from "App/common/useToast";

export const UploadTrackButton = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { addErrorMessage } = useToast();
  const { uploadFile, getUploadCache, removeUpload } = useFiles();

  const onFileSelect = async (files: File[]) => {
    if (!files) {
      return;
    }

    files.forEach(async (file) => {
      try {
        const trackId = uuidv4();
        const fileEntity = await uploadFile({
          file,
          isTrackUpload: true,
          cache: getUploadCache(UPLOAD_GROUP.TRACKS_BY_SPACE, null),
        });
        await highnote.createTrack({
          id: trackId,
          data: {
            title: stripExtension(fileEntity.fileName),
            versionFilesV2: [fileEntity.id],
          },
        });
        removeUpload(fileEntity.id);
      } catch (e) {
        if (e instanceof AudioUploadFileSizeErr) {
          addErrorMessage(e.message, { title: e.title });
          return;
        }
        throw e;
      }
    });
  };

  return (
    <AudioFileSelect onSelect={onFileSelect} theme="dark" allowMultiple>
      {children}
    </AudioFileSelect>
  );
};
