import React, { createContext, useContext, useMemo } from "react";
import { orderBy, where } from "firebase/firestore";

import { DropboxSyncJob, JOB_STATUS } from "@highnote/server/src/core/entities";
import {
  DropboxJobsProvider,
  useDropboxJobs,
} from "App/components/useEntities";
import { useAuth } from "App/components/Auth";
import {
  APP_FEATURES,
  AppFeaturesStatus,
} from "@highnote/server/src/core/features";

type DropboxJobsContextValue = {
  dropboxJobs: DropboxSyncJob[];
  dropboxJobsLoading: boolean;
  totalLimitDropboxJobs: number;
  loadMoreDropboxJobs: () => void;
};

const DropboxJobsContext = createContext<DropboxJobsContextValue>({
  dropboxJobs: [],
  dropboxJobsLoading: true,
  totalLimitDropboxJobs: 0,
  loadMoreDropboxJobs: () => undefined,
});

const _DropboxJobsProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    entities: dropboxJobs,
    loading: dropboxJobsLoading,
    loadMore: loadMoreDropboxJobs,
    totalLimit: totalLimitDropboxJobs,
  } = useDropboxJobs();

  const value = useMemo(() => {
    return {
      dropboxJobs,
      dropboxJobsLoading,
      loadMoreDropboxJobs,
      totalLimitDropboxJobs,
    };
  }, [
    dropboxJobs,
    dropboxJobsLoading,
    loadMoreDropboxJobs,
    totalLimitDropboxJobs,
  ]);

  return (
    <DropboxJobsContext.Provider value={value}>
      {children}
    </DropboxJobsContext.Provider>
  );
};

export const DropboxJobsContextProvider = ({
  children,
  spaceId,
  statuses = [],
}: {
  children: React.ReactNode;
  spaceId: string;
  statuses: JOB_STATUS[];
}) => {
  const { user } = useAuth();
  const constraints = useMemo(() => {
    if (!user?.id || !spaceId) return [];
    return [
      where("spaceId", "==", spaceId),
      where("status", "in", statuses),
      where("createdBy", "==", user.id),
      orderBy("createdAt", "asc"),
    ];
  }, [user?.id, spaceId, statuses]);

  if (
    !AppFeaturesStatus[APP_FEATURES.DROPBOX_EXTENSION] ||
    !user?.id ||
    !spaceId
  ) {
    return <>{children}</>;
  }

  return (
    <DropboxJobsProvider constraints={constraints} limit={100}>
      <_DropboxJobsProvider>{children}</_DropboxJobsProvider>
    </DropboxJobsProvider>
  );
};

export const useDropboxJobsContext = () => useContext(DropboxJobsContext);
