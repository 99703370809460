import { createContext, useContext } from "react";
import {
  PASSWORD_TYPE,
  Roles,
  Space,
} from "@highnote/server/src/core/entities";

type ShareDialogContextProps = {
  isEntityOwner: boolean;
  entityHasChildren: boolean;
  entity: Space;
  updateEntityRoles: (props: Roles, shouldThrowErr?: boolean) => Promise<void>;
  updateEntityPassword: (
    passwordType: PASSWORD_TYPE,
    password?: string,
  ) => Promise<void>;
  defaultFeatureAlertMessage: string;
  canManageEntity: boolean;
  canManageDownloadControl: boolean;
  canManagePassword: boolean;
};

export const ShareDialogContext = createContext<ShareDialogContextProps>({
  isEntityOwner: false,
  entityHasChildren: false,
  entity: undefined,
  updateEntityRoles: () => Promise.resolve(),
  updateEntityPassword: () => Promise.resolve(),
  defaultFeatureAlertMessage: "",
  canManageEntity: false,
  canManageDownloadControl: false,
  canManagePassword: false,
});

export const useShareDialogContext = () => useContext(ShareDialogContext);
