import "./GlobalHelp.scss";
import React, { useState, useRef } from "react";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { Menu, MenuItemExternalLink } from "App/common/Menu";

export const GlobalHelpMenu = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const triggerRef = useRef<HTMLButtonElement>();

  return (
    <>
      <Menu
        className="highnote-global-help-menu"
        anchorEl={triggerRef.current}
        open={isOpen}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItemExternalLink href="https://help.highnote.fm/">
          Help Center
        </MenuItemExternalLink>
        <MenuItemExternalLink href="https://www.youtube.com/@highnotefm">
          YouTube Videos
        </MenuItemExternalLink>
        <MenuItemExternalLink href="https://www.highnote.fm/updates">
          What&apos;s New?
        </MenuItemExternalLink>

        <div className="divider" />
        <MenuItemExternalLink href="https://help.highnote.fm/hc/en-us/requests/new?ticket_form_id=360003290692">
          Contact Support
        </MenuItemExternalLink>
        <MenuItemExternalLink href="https://help.highnote.fm/hc/en-us/requests/new?ticket_form_id=9650575523085">
          Submit Feedback
        </MenuItemExternalLink>
        <MenuItemExternalLink href="https://help.highnote.fm/hc/en-us/requests/new?ticket_form_id=9650575523085">
          Report Bug
        </MenuItemExternalLink>

        <div className="divider" />
        <MenuItemExternalLink href="https://highnotefm.notion.site/Highnote-Changelog-b0ca115ef57243fc83007bc5d87bb333">
          Release Notes
        </MenuItemExternalLink>
        <MenuItemExternalLink href="https://highnotefm.notion.site/Highnote-Status-2c32474310324d68a16af0d73ef1325d">
          Status
        </MenuItemExternalLink>
        <MenuItemExternalLink href="https://www.highnote.fm/terms">
          Terms of Service
        </MenuItemExternalLink>
        <MenuItemExternalLink href="https://www.highnote.fm/privacy">
          Privacy Policy
        </MenuItemExternalLink>
      </Menu>
      <Button
        className="highnote-global-help"
        size={BUTTON_SIZE.MEDIUM}
        theme={BUTTON_THEME.ICON}
        ref={triggerRef}
        onClick={() => {
          setOpen(true);
        }}
      >
        ?
      </Button>
    </>
  );
};
