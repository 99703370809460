import "./Settings.scss";
import React, { useEffect } from "react";
import { AuthRedirectRequired } from "App/modules/Auth/AuthRedirectRequired";
import { THEME, ThemeProvider } from "App/common/ThemeProvider";

import { NotificationSettings } from "./NotificationSettings";
import { Profile } from "./Profile";
import { TabbedView } from "App/core/TabbedView";
import { Plans } from "App/components/Plans/Plans";
import { useHistory } from "react-router";
import { useUrlContext } from "../Main/useUrlContext";
import { UsageDetails } from "./UsageDetails";
import { useSegmentContext } from "App/modules/useSegment";
import { EVENT_ID } from "@highnote/server/src/core/entities";
import { Navigation } from "App/components/Navigation";

export enum TAB_ID {
  PROFILE = "profile",
  PLANS = "plans",
  USAGE = "usage",
  NOTIFICATIONS = "notifications",
}

const SettingsUI = () => {
  const history = useHistory();
  const { settingsTabId } = useUrlContext();
  const { trackEvent } = useSegmentContext();

  useEffect(() => {
    trackEvent(EVENT_ID.ENTERED_ACCOUNT_SETTINGS);

    return () => {
      trackEvent(EVENT_ID.EXITED_ACCOUNT_SETTINGS);
    };
  }, []);

  return (
    <div className="highnote-settings">
      <Navigation showIndent hideBackButton>
        <h1>Account</h1>
      </Navigation>

      <div className="inner">
        <TabbedView
          activeTabId={settingsTabId || TAB_ID.PROFILE}
          tabs={[
            {
              id: TAB_ID.PROFILE,
              name: "Details",
              onClick: () => history.push(`/settings/${TAB_ID.PROFILE}`),
              view: <Profile />,
            },
            {
              id: TAB_ID.PLANS,
              name: "Plan",
              onClick: () => history.push(`/settings/${TAB_ID.PLANS}`),
              view: <Plans />,
            },
            {
              id: TAB_ID.USAGE,
              name: "Usage",
              onClick: () => history.push(`/settings/${TAB_ID.USAGE}`),
              view: <UsageDetails />,
            },
            {
              id: TAB_ID.NOTIFICATIONS,
              name: "Notifications",
              onClick: () => history.push(`/settings/${TAB_ID.NOTIFICATIONS}`),
              view: <NotificationSettings />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export const Settings = () => {
  return (
    <AuthRedirectRequired>
      <ThemeProvider theme={THEME.DARK}>
        <SettingsUI />
      </ThemeProvider>
    </AuthRedirectRequired>
  );
};
