import React from "react";

import "./UnreadDot.scss";

export const UnreadDot = ({
  inline,
  children,
}: {
  inline?: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <div
      className="highnote-unread-dot"
      data-inline={inline}
      data-has-children={!!children}
    >
      <div className="inner">{children}</div>
    </div>
  );
};
