import "./Button.scss";
import React, { forwardRef } from "react";
import { useTheme } from "App/common/ThemeProvider";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";

export enum BUTTON_THEME {
  PLAIN = "plain",
  TEXT = "text",
  LINK = "link",
  ICON = "icon",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  CTA = "cta",
  WARN = "warn",
}

export enum BUTTON_SIZE {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
  XSMALL = "xsmall",
  AUTO = "auto",
}

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { theme?: BUTTON_THEME; size?: BUTTON_SIZE; loading?: boolean };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    /* eslint-disable react/prop-types */
    const { theme: buttonTheme, size, loading, ...rest } = props;
    const { theme } = useTheme();

    return (
      <button
        {...rest}
        ref={ref}
        className={`highnote-button ${props.className}`}
        style={{ pointerEvents: "auto", ...(props.style || {}) }}
        data-theme={theme}
        data-button-theme={buttonTheme || BUTTON_THEME.PRIMARY}
        data-size={size || BUTTON_SIZE.MEDIUM}
        disabled={false}
        data-disabled={props.disabled || loading}
        onClick={(e) => {
          if (props.disabled) {
            e.preventDefault();
            return;
          }

          props.onClick && props.onClick(e);
        }}
      >
        {props.children}
        {/* eslint-enable react/prop-types  */}

        {!!loading && <LoadingSpinner />}
      </button>
    );
  },
);
Button.displayName = "Button";
