import "./EntityPreview.scss";
import React, { useEffect, useState } from "react";
import {
  collection,
  where,
  query,
  getCountFromServer,
} from "firebase/firestore";
import classNames from "classnames";
import { ReactComponent as CloseSVG } from "App/common/icons/close.svg";
import { PreviewText } from "@highnote/preview-text/src";
import {
  COLLECTION_ID,
  FileEntity,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import { FileUpload, useFiles } from "App/components/useFiles";
import { highnote } from "@highnote/server/src/sdk";
import { formatCount, formatFileSize, getFaviconUrl } from "App/modules/utils";
import { ENTITY_TYPE, EntityData, Version } from "./config";
import { ProgressBar } from "App/common/ProgressBar";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { useSpaceContext } from "App/common/useSpace";
import { ThumbnailPreview } from "App/common/ThumbnailPreview";
import { formatDuration } from "@highnote/server/src/core/shared-util";
import { TrackVersionIcon } from "App/components/TrackEditor/TrackVersions/TrackVersionIcon";
import { TrackVersionStar } from "../TrackEditor/TrackVersions/TrackVersionStar";
import { useSpaces } from "../useEntities";
import { GlobalAudioCurrentFormattedTime } from "../GlobalAudioPlayer/GlobalAudioPlayer";
import { useGlobalTracks } from "App/store/tracks/useGlobalTracks";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { useConfirmation } from "App/common/useConfirmation";
import { useTrackFiles } from "../useEntities/useTrack";

const downloadUrlCache: Record<string, string> = {};

const getFallback = (row: EntityData) => {
  if (row.type === ENTITY_TYPE.TRACK) {
    return "/public/default-track-artwork.png";
  }

  if (row.type === ENTITY_TYPE.SPACE) {
    return "/public/default-space-artwork.png";
  }

  if (row.type === ENTITY_TYPE.TRACK_VERSION) {
    const file = row.entity as Version;

    return <TrackVersionIcon version={file} />;
  }

  if (row.type === ENTITY_TYPE.FILE) {
    const file = row.entity as FileEntity;

    if ((file as Version).isVersion) {
      return <TrackVersionIcon version={file as Version} />;
    }

    if (file.url) {
      return "/public/icons/default-file-url.svg";
    }

    if (String(file.fileType).includes("image/")) {
      return "/public/icons/default-file-img.png";
    }

    const specialExtensions = ["mp3", "ogg", "aif", "mp4", "zip", "wav"];
    const ext = specialExtensions.find((e) => file.fileName?.endsWith(e));

    if (ext) {
      return `/public/icons/default-file-${ext}.svg`;
    }
  }

  return "/public/icons/default-file.svg";
};

const getCachedArtwork = (artworkFile?: string, url?: string) => {
  if (artworkFile) {
    return downloadUrlCache[artworkFile];
  }

  if (url) {
    return getFaviconUrl(url) || "/public/icons/default-file-url.svg";
  }
};

const useArtworkUrl = ({ row }: { row: EntityData }) => {
  const { getDownloadUrl } = useFiles();
  const entity = row.entity as Track | Space;
  const file = row.entity as FileEntity;
  const { entities: spaces } = useSpaces();
  const { space } = useSpaceContext();
  let parentArtworkFile: string;
  if (row.type === ENTITY_TYPE.TRACK) {
    const spaceId = (entity as Track).spaceId;
    if (spaceId) {
      parentArtworkFile = spaces.find((s) => s.id === spaceId)?.artworkFile;
    }
    if (!parentArtworkFile) {
      parentArtworkFile = space?.artworkFile;
    }
  }
  const artworkFile = entity.artworkFile || parentArtworkFile;
  const [source, setSource] = useState<string>(
    getCachedArtwork(artworkFile, file.url),
  );

  useEffect(() => {
    let unmounted: boolean;

    const cachedUrl = getCachedArtwork(artworkFile, file.url);
    if (cachedUrl) {
      setSource(cachedUrl);
      return;
    }

    if (!artworkFile) {
      setSource(undefined);
      return;
    }

    highnote.getFiles({ ids: [artworkFile] }).then(async (files) => {
      const artworkFileEntity = files.find((f) => f.id === artworkFile);
      const url = await getDownloadUrl(artworkFileEntity);
      if (unmounted) return;
      downloadUrlCache[artworkFile] = url;
      setSource(url);
    });

    return () => {
      unmounted = true;
    };
  }, [row.type, artworkFile, file.url]);

  return {
    source: source,
    fallback: getFallback(row),
  };
};

export const TrackTitle = ({
  track,
  onClick,
}: {
  track: Track;
  onClick: () => void;
}) => {
  return (
    <span className="TrackTitle" onClick={() => onClick()}>
      <PreviewText>{track.title}</PreviewText>
    </span>
  );
};

export const TrackDescription = ({
  track,
  onClick,
  showTrackTime,
}: {
  track: Track;
  onClick?: () => void;
  showTrackTime?: boolean;
}) => {
  const { currentTrackPageVersion } = useTrackFiles(track);
  const [spaceStr, setSpaceStr] = useState<string>();
  const { spaceId } = useSpaceContext();
  const versionsStr =
    currentTrackPageVersion?.name ??
    formatCount((track.versionFilesV2 || []).length, "Version");
  useEffect(() => {
    let unmounted: boolean;
    if (!track?.spaceId || track.spaceId === spaceId) {
      setSpaceStr(undefined);
      return;
    }

    highnote
      .getSpace({ id: track.spaceId, fromCache: true })
      .then((space) => {
        if (unmounted) return;
        setSpaceStr(` • ${space.name}`);
      })
      .catch((e) => {
        // Track you uploaded can belong to a space you don't have access to.
        // ie. User 1 has a space, and makes User 2 admin. User 2 creates their own space
        // that User 1 is not apart of yet, and moves a track/entity from User 1 Space -> User 2 Space.
        // User 1 will get an error for fetching space data of that new track's location.
        console.error("Error getting parent space", e);
      });

    return () => {
      unmounted = true;
    };
  }, [track?.spaceId, spaceId]);

  return (
    <span
      className={classNames("track-description", { showTrackTime })}
      data-clickable={!!onClick}
      onClick={() => onClick && onClick()}
    >
      {showTrackTime && (
        <span className="track-description-time">
          <GlobalAudioCurrentFormattedTime />
        </span>
      )}
      <span className="track-description-main">
        {versionsStr}
        {spaceStr}
      </span>
    </span>
  );
};

const getNumTracksInSpace = async (spaceId: string) => {
  const coll = collection(highnote.__firebaseFirestore, COLLECTION_ID.TRACK);
  const q = query(coll, where("spaceId", "==", spaceId));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

const getNumSpacesInSpace = async (spaceId: string) => {
  const coll = collection(highnote.__firebaseFirestore, COLLECTION_ID.SPACE);
  const q = query(coll, where("spaceId", "==", spaceId));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

const SpaceDescription = ({ space }: { space: Space }) => {
  const { globalTracks, globalTracksWatchers } = useGlobalTracks();
  const { globalSpaces, globalSpacesWatchers, hasGlobalSpace } =
    useGlobalSpaces();
  const [description, setDescription] = useState<string>();

  useEffect(() => {
    let unmounted: boolean;

    if (!space) {
      setDescription("");
      return;
    }

    if (globalTracksWatchers.has(space.id) || hasGlobalSpace(space.id)) {
      const childTracks = globalTracks.childTracks.get(space.id);
      const childSpaces = globalSpaces.childSpaces.get(space.id);
      const totalChildTracksCount = childTracks ? childTracks.length : 0;
      const totalChildSpacesCount = childSpaces ? childSpaces.length : 0;
      const totalChildItemsCount =
        totalChildTracksCount + totalChildSpacesCount;
      setDescription(`${formatCount(totalChildItemsCount, "Item")}`);
    } else {
      const itemsPromise = Promise.all([
        getNumTracksInSpace(space.id),
        getNumSpacesInSpace(space.id),
      ]).then((counts) => {
        const totalCount =
          counts.reduce((acc, count) => acc + count, 0) +
          (space.files || []).length;
        return `${formatCount(totalCount || 0, "Item")}`;
      });

      itemsPromise.then((summary) => {
        if (unmounted) return;
        setDescription(summary);
      });
    }

    return () => {
      unmounted = true;
    };
  }, [
    space?.id,
    globalTracks.childTracks.get(space.id),
    globalSpaces.childSpaces.get(space.id),
    globalTracksWatchers.get(space.id),
    globalSpacesWatchers.get(space.id),
  ]);

  return <>{description}</>;
};

export const EntityPreview = ({ row }: { row: EntityData }) => {
  const { source, fallback } = useArtworkUrl({ row });
  const { confirm, renderConfirmation } = useConfirmation();

  let title: string | React.ReactNode;
  let data: React.ReactNode;

  const space = row.entity as Space;
  const track = row.entity as Track;
  const file = row.entity as FileEntity;
  const version = (file as Version).isVersion && (file as Version);
  const upload = row.entity as FileUpload;

  if (row.type === ENTITY_TYPE.SPACE) {
    title = space.name;
    data = (
      <>
        SPACE • <SpaceDescription space={space} />
      </>
    );
  }

  if (row.type === ENTITY_TYPE.TRACK) {
    title = track.title;
    data = (
      <>
        TRACK • <TrackDescription track={track} />
      </>
    );
  }

  if (row.type === ENTITY_TYPE.TRACK_VERSION) {
    title = version.name || "";
    data = <>TRACK VERSION{version.isDefaultVersion && ` • DEFAULT`}</>;
  }

  if (row.type === ENTITY_TYPE.FILE) {
    title = file.name || file.fileName;

    if (version) {
      title = (
        <span className="version-title">
          <PreviewText>{file.name || file.fileName}</PreviewText>
          <TrackVersionStar version={version} />
        </span>
      );

      data = `VERSION ${
        file.metadata?.duration
          ? // eslint-disable-next-line no-irregular-whitespace
            `• ${formatDuration(file.metadata?.duration, true)}`
          : ""
      }`;
    } else if (file.url) {
      data = `BOOKMARK • ${file.url}`;
    } else {
      // eslint-disable-next-line no-irregular-whitespace
      data = `FILE ${file.size ? `• ${formatFileSize(file.size)}` : ""}`;
    }
  }

  if (row.type === ENTITY_TYPE.UPLOAD) {
    title =
      upload.progress === 1 ? (
        upload.file.fileName
      ) : (
        <>
          <PreviewText>{upload.file.fileName}</PreviewText>

          {row.type === ENTITY_TYPE.UPLOAD &&
            upload.progress !== 1 &&
            upload.cancelUpload && (
              <Button
                type="button"
                className="cancel-upload"
                theme={BUTTON_THEME.ICON}
                size={BUTTON_SIZE.XSMALL}
                onClick={upload.cancelUpload}
              >
                <CloseSVG />
              </Button>
            )}
        </>
      );
    data = <ProgressBar progress={upload.progress} />;
  }
  const handleOnClick = () => {
    if (row.confirmOnClick) {
      row
        .confirmOnClick(confirm)
        .then(() => {
          row.onConfirmCancel?.();
        })
        .catch(() => {
          row?.onClick?.();
        });
    } else {
      row?.onClick?.();
    }
  };

  return (
    <div
      className="highnote-entity-preview"
      {...(version
        ? {
            "data-is-default-version": !!version.isDefaultVersion,
            "data-version-color-id": version.colorId,
          }
        : {})}
    >
      <ThumbnailPreview
        onClick={handleOnClick}
        iconSource={source}
        iconFallback={fallback}
        title={title}
        description={data}
      />
      {renderConfirmation}
    </div>
  );
};
