import React, {
  useState,
  createContext,
  useMemo,
  useContext,
  useCallback,
  useRef,
} from "react";
import {
  COLLECTION_ID,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import { ReactComponent as WarningSVG } from "App/common/icons/warning-hollow.svg";
import { Button, BUTTON_THEME } from "App/core/Button";
import { DialogButtons, Dialog, DialogSection } from "App/common/Dialog";
import { useHighnote } from "App/common/useHighnote";
import {
  ENTITY_TYPE,
  DEFAULT_COLUMNS,
} from "App/components/EntityTable/config";
import {
  ScrollableContainer,
  SCROLLABLE_CONTAINER_VARIANT,
} from "App/common/ScrollableContainer";
import { getMoveToSpaceWarning, sortRowByCreatedAt } from "App/components/util";
import { Callout, CALLOUT_LAYOUT } from "App/core/Callout";
import { EmptyTable } from "App/components/EntityTable/EmptyTable";
import { useAuth } from "App/components/Auth";
import { isDescendantRecursiveForSpaces } from "App/components/useEntities/useLibrarySpaces/util";
import { ClickThruSpaceRowComponent } from "App/components/ClickThruSpaceRow";

const AddToSpaceContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openAddToSpace: (space: Space) => {},
});

export const AddToSpaceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useAuth();
  const [isOpen, setOpen] = useState(false);
  const [spaceToAddTo, setSpaceToAddTo] = useState<Space>();
  const { moveToSpace } = useHighnote();
  const [confirmation, setConfirmation] = useState<React.ReactNode>();
  const [confirmationLoading, setConfirmationLoading] =
    useState<boolean>(false);
  const [isMoving, setIsMoving] = useState<boolean>(false);
  const [currentRoot, setCurrentRoot] = useState<Space>();
  const selectedEntity = useRef<Track | Space>();

  const LIBRARY_COLUMNS = useMemo(
    () => [DEFAULT_COLUMNS.ICON, DEFAULT_COLUMNS.PREVIEW],
    [],
  );

  const close = useCallback(() => {
    setOpen(false);
    setCurrentRoot(undefined);
  }, []);

  const requestAddToSpace = async (
    entity: Track | Space,
    entityType: COLLECTION_ID.TRACK | COLLECTION_ID.SPACE,
  ) => {
    setConfirmationLoading(true);
    const warning = await getMoveToSpaceWarning({
      entity: entity,
      entityType: entityType,
      spaceDestination: spaceToAddTo,
      userId: user.id,
    });

    if (!warning) {
      await moveToSpace({
        entityId: entity.id,
        entityType,
        newSpaceId: spaceToAddTo.id,
      });
      close();
      return;
    }

    selectedEntity.current = entity;

    setConfirmation(
      <Callout
        layout={CALLOUT_LAYOUT.ROW}
        icon={<WarningSVG />}
        title={warning.title}
        body={warning.description}
      />,
    );
  };

  const value = useMemo(
    () => ({
      openAddToSpace: (space: Space) => {
        setConfirmationLoading(false);
        setConfirmation(null);
        selectedEntity.current = undefined;
        setSpaceToAddTo(space);
        setOpen(true);
      },
    }),
    [],
  );

  const filter = useCallback(
    (row) => {
      if (!spaceToAddTo) return false;
      if (spaceToAddTo.sharePasswordEnabled && row.type === ENTITY_TYPE.SPACE) {
        return false;
      }

      if (row.type == ENTITY_TYPE.TRACK || row.type === ENTITY_TYPE.SPACE) {
        const rowTrack = row.entity as Space | Track;
        if (rowTrack.spaceId === spaceToAddTo.id) return false;
        if (row.id === spaceToAddTo.id) return false;
        return true;
      }

      return false;
    },
    [spaceToAddTo],
  );

  const dialogTitle = spaceToAddTo ? `Add to ${spaceToAddTo.name}...` : "";

  return (
    <AddToSpaceContext.Provider value={value}>
      <Dialog
        className="highnote-add-to-space"
        open={isOpen}
        onClose={close}
        title={dialogTitle}
      >
        <DialogSection />

        <DialogSection data-blurred={!!confirmation || !!confirmationLoading}>
          <ScrollableContainer
            maxHeight={400}
            variant={SCROLLABLE_CONTAINER_VARIANT.FRAMED}
          >
            <ClickThruSpaceRowComponent
              parentSpace={currentRoot}
              tableBaseProps={{
                columns: LIBRARY_COLUMNS,
                filter,
                tableRowVariant: "nested-click-thru",
                sort: sortRowByCreatedAt,
                onSelect: (row) => {
                  const entity = row.entity as Space | Track;
                  let entityType = COLLECTION_ID.TRACK;
                  if (row.type === ENTITY_TYPE.SPACE) {
                    entityType = COLLECTION_ID.SPACE;
                  }
                  requestAddToSpace(entity, entityType);
                },
                EmptyComponent: () => (
                  <EmptyTable action="Create or add content" />
                ),
                rootEntity: currentRoot,
                onRootEntityChange: setCurrentRoot,
                processRows: (rows) => {
                  return Promise.all(
                    rows.map(async (row) => {
                      const { onClick, ...rowData } = row;
                      if (!spaceToAddTo) {
                        return rowData;
                      }
                      if (row.type === ENTITY_TYPE.SPACE) {
                        if (spaceToAddTo.sharePasswordEnabled) {
                          return rowData;
                        }
                        const isRecursive =
                          await isDescendantRecursiveForSpaces({
                            id: spaceToAddTo.id,
                            ancestorId: row.id,
                          });
                        return isRecursive ? rowData : { onClick, ...rowData };
                      }
                      return {
                        ...rowData,
                        onClick,
                      };
                    }),
                  );
                },
              }}
            />
          </ScrollableContainer>
        </DialogSection>

        {confirmation && <DialogSection>{confirmation}</DialogSection>}

        <DialogButtons>
          <Button theme={BUTTON_THEME.SECONDARY} onClick={close}>
            Cancel
          </Button>

          {!!confirmation && (
            <Button
              theme={BUTTON_THEME.SECONDARY}
              disabled={isMoving}
              onClick={async () => {
                setIsMoving(true);
                let entityType = COLLECTION_ID.TRACK;
                if (
                  selectedEntity.current &&
                  // "name" is only a key in Spaces, not Tracks.
                  "name" in selectedEntity.current
                ) {
                  entityType = COLLECTION_ID.SPACE;
                }
                try {
                  await moveToSpace({
                    entityId: selectedEntity.current.id,
                    entityType,
                    newSpaceId: spaceToAddTo.id,
                  });
                } finally {
                  setIsMoving(false);
                  setConfirmation(null);
                  setConfirmationLoading(false);
                }
                close();
              }}
            >
              Move
            </Button>
          )}
        </DialogButtons>
      </Dialog>

      {children}
    </AddToSpaceContext.Provider>
  );
};

export const useAddToSpace = () => useContext(AddToSpaceContext);
