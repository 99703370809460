import React, { useEffect } from "react";
import { THEME, ThemeProvider } from "App/common/ThemeProvider";
import { RefreshAppBanner } from "App/common/banners/RefreshAppBanner/RefreshAppBanner";
import { useAuth } from "App/components/Auth";
import { useGlobalAudioPlayer, useHTMLAudioPlayer } from "../GlobalAudioPlayer";
import { SIDEBAR_ID, SIDEBAR_SIDE, SIDEBAR_TYPE } from "../Sidebar";
import { SidebarContextProvider, WithSidebar } from "../Sidebar/Sidebar";
import { MobileNavigation } from "./MobileNavigation";
import "./Layout.scss";
import { useIntercomContext } from "App/services/intercom/IntercomContext";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const { isActive } = useGlobalAudioPlayer();
  const { useHTMLPlayer, isActive: isHTMLPlayerActive } = useHTMLAudioPlayer();
  const { user } = useAuth();
  const isLoggedIn = !!user;
  const { setIsGlobalAudioPlayerActive } = useIntercomContext();

  const isGlobalAudioPlayerActive = useHTMLPlayer
    ? isHTMLPlayerActive
    : isActive;

  useEffect(() => {
    setIsGlobalAudioPlayerActive(isGlobalAudioPlayerActive);
  }, [isGlobalAudioPlayerActive]);

  return (
    <ThemeProvider theme={THEME.DARK}>
      <div
        className="highnote-layout-with-navigation"
        data-is-anon={!isLoggedIn}
      >
        <SidebarContextProvider ids={[SIDEBAR_ID.GLOBAL_NAV]} ignoreUrl>
          <WithSidebar
            side={SIDEBAR_SIDE.LEFT}
            type={SIDEBAR_TYPE.PUSH}
            resizable
          >
            <div className="highnote-layout">
              <SidebarContextProvider ids={[SIDEBAR_ID.SPACE_CHAT]}>
                <WithSidebar side={SIDEBAR_SIDE.RIGHT} type={SIDEBAR_TYPE.PUSH}>
                  <RefreshAppBanner />
                  {children}
                </WithSidebar>
              </SidebarContextProvider>
            </div>
            <MobileNavigation />
          </WithSidebar>
        </SidebarContextProvider>
      </div>
    </ThemeProvider>
  );
};
