import "./UsageEditor.scss";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Dialog } from "App/common/Dialog";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { ManageStorage } from "./Editors/StorageUsageEditor";
import { ManageSpaces } from "./Editors/SpacesUsageEditor";
import { StorageUsage } from "./StorageUsage";
import { SpaceUsage } from "./SpaceUsage";
import { useHistory, useLocation } from "react-router";
import { LibrarySpacesContextProvider } from "../useEntities/useSpaces";
import { LibraryTracksContextProvider } from "../useEntities/useLibraryTracks";

export const USAGE_EDITOR_TAB_KEY = "usage-editor";

export enum USAGE_EDITOR_TAB {
  STORAGE = "storage",
  SPACES = "spaces",
}

const UsageEditor = ({
  activeEditorId,
  setActiveEditorId,
}: {
  activeEditorId?: USAGE_EDITOR_TAB;
  setActiveEditorId: (activeEditorId: USAGE_EDITOR_TAB | null) => void;
}) => {
  const usageEditorTabs = [
    {
      id: USAGE_EDITOR_TAB.STORAGE,
      Header: StorageUsage,
      Component: ManageStorage,
      title: "Storage",
    },
    {
      id: USAGE_EDITOR_TAB.SPACES,
      Header: SpaceUsage,
      Component: ManageSpaces,
      title: "Shared Spaces",
    },
  ];
  const activeTab = activeEditorId
    ? usageEditorTabs.find((editorTab) => {
        return editorTab.id === activeEditorId;
      })
    : undefined;

  return activeTab ? (
    <ErrorBoundary name="UsageEditor">
      <Dialog
        className="usage-editor-dialog"
        title={activeTab.title}
        open
        onClose={() => {
          setActiveEditorId(null);
        }}
      >
        <section className="usage-editor-dialog-usage">
          <activeTab.Header />
        </section>
        <section className="usage-editor-dialog-table">
          {activeEditorId === USAGE_EDITOR_TAB.SPACES ? (
            // TODO(GlobalSpaces): Consider removing dependence on useLibrarySpaces + useLibraryTracks
            // and use the global spaces/tracks context instead.
            <LibrarySpacesContextProvider>
              <LibraryTracksContextProvider>
                <activeTab.Component />
              </LibraryTracksContextProvider>
            </LibrarySpacesContextProvider>
          ) : (
            <activeTab.Component />
          )}
        </section>
      </Dialog>
    </ErrorBoundary>
  ) : null;
};

const UsageEditorContext = createContext({
  openStorageUsageEditor: () => {},
  openSpacesUsageEditor: () => {},
});

export const UsageEditorProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { push } = useHistory();
  const location = useLocation();
  const locationSearch = new URLSearchParams(location.search);

  const [activeEditorId, setActiveEditorId] = useState<USAGE_EDITOR_TAB | null>(
    null,
  );

  useEffect(() => {
    const currentQuery = locationSearch.get(
      USAGE_EDITOR_TAB_KEY,
    ) as USAGE_EDITOR_TAB;
    setActiveEditorId(
      Object.values(USAGE_EDITOR_TAB).includes(currentQuery)
        ? currentQuery
        : null,
    );
  }, [locationSearch]);

  const openUsageEditor = (newEditorId?: USAGE_EDITOR_TAB) => {
    if (newEditorId) {
      locationSearch.set(USAGE_EDITOR_TAB_KEY, newEditorId);
      push({
        pathname: location.pathname,
        search: locationSearch.toString(),
      });
    } else {
      push({
        pathname: location.pathname,
      });
    }
  };

  const openStorageUsageEditor = () => {
    openUsageEditor(USAGE_EDITOR_TAB.STORAGE);
  };
  const openSpacesUsageEditor = () => {
    openUsageEditor(USAGE_EDITOR_TAB.SPACES);
  };

  return (
    <UsageEditorContext.Provider
      value={{
        openStorageUsageEditor,
        openSpacesUsageEditor,
      }}
    >
      <UsageEditor
        activeEditorId={activeEditorId}
        setActiveEditorId={openUsageEditor}
      />
      {children}
    </UsageEditorContext.Provider>
  );
};

export const useUsageEditor = () => useContext(UsageEditorContext);
