import { daw, useDAW } from "@highnote/daw/src";
import { v4 as uuidv4 } from "uuid";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { loadFileIntoDAW } from "./util";
import { routePaths } from "App/modules/urls";
import { matchAppPath, spaceTrackToMediaMetadata } from "App/modules/utils";
import {
  AUDIO_QUALITY,
  FileEntity,
  Track,
} from "@highnote/server/src/core/entities";
import { watchAudioFiles } from "../watchAudioFiles";
import {
  FILE_NOT_FOUND_ERROR,
  getDefaultVersionId,
  getNextInArray,
  getPrevInArray,
  isFilePlayable,
} from "@highnote/server/src/core/shared-util";
import { DAWTrack } from "@highnote/daw/src/types";
import { ToggleQualityDialog } from "./ToggleQualityDialog";
import { SpaceProviderRaw } from "../useEntities";
import { getSourceUrl } from "App/common/AudioPlayer";
import { useSpaceContext } from "App/common/useSpace";
import { useTrackArtworkUrls } from "App/common/ThumbnailPreview";
import { useViewport } from "App/common/useViewport";
import { useUrlContext } from "App/routes/Main/useUrlContext";

type QueueItem = {
  id: string;
  track: Track;
  file: FileEntity;
};

type QueuedTrack = {
  track: Track;
  unwatch: () => void;
  versionId: string;
  preloadVersions?: string[];
  loading?: boolean;
};

export enum SKIP_AUDIO_MODE {
  FULL_SKIP = "full-skip",
  NUDGE_5S = "5s-nudge",
  NUDGE_10S = "10s-nudge",
}

type QueueOrder = {
  queue: string[];
  pageId?: string;
};

const DEFAULT_QUEUE_ORDER: QueueOrder = { queue: [], pageId: "" };

type GlobalAudioPlayerContextProps = {
  nowPlaying: QueueItem;
  prevTrackId?: string;
  nextTrackId?: string;
  getFileItem: (id: string) => FileEntity | undefined;
  getQueueItem: (id: string, fileId?: string) => QueueItem;
  setQueue: ({
    _tracks,
    type,
    currentPageId,
    keepPreloads,
  }: {
    _tracks: Track[];
    type: "SET" | "UPDATE_CURRENT" | "UPDATE_CURRENT_FROM_NEXT";
    currentPageId: string;
    keepPreloads?: boolean;
  }) => void;
  currentQueueOrder: QueueOrder;
  nextQueueOrder: QueueOrder;
  queueTrackVersion: (
    trackId: string,
    versionId?: string,
    preloadVersions?: string[],
  ) => void;
  preloadTrackVersions: (trackId: string, versions: string[]) => void;
  fetchAudioFiles: (track: Track) => void;
  goToTrack: (trackId: string, time?: number) => void;
  play: (trackId?: string, time?: number) => void;
  isActive: boolean;
  pause: () => void;
  seek: (time: number) => void;
  quality: AUDIO_QUALITY;
  setAudioQuality: (
    val: AUDIO_QUALITY,
    skipSavingToLocalStorage?: boolean,
  ) => void;
  skipAudioMode: SKIP_AUDIO_MODE;
  setSkipAudioMode: (
    skipMode: SKIP_AUDIO_MODE,
    skipSavingToLocalStorage?: boolean,
  ) => void;
  seekTrack: (direction: "forward" | "back") => void;
};

const audioPlayerDefaultContext: GlobalAudioPlayerContextProps = {
  nowPlaying: undefined,
  prevTrackId: undefined,
  nextTrackId: undefined,
  getFileItem: () => undefined,
  getQueueItem: () => undefined,
  currentQueueOrder: DEFAULT_QUEUE_ORDER,
  nextQueueOrder: DEFAULT_QUEUE_ORDER,
  setQueue: () => {},
  queueTrackVersion: () => {},
  preloadTrackVersions: () => {},
  fetchAudioFiles: () => {},
  goToTrack: () => {},
  isActive: false,
  play: () => {},
  pause: () => {},
  seek: () => {},
  quality: AUDIO_QUALITY.ORIGINAL,
  setAudioQuality: () => {},
  skipAudioMode: SKIP_AUDIO_MODE.FULL_SKIP,
  setSkipAudioMode: () => {},
  seekTrack: () => {},
};

const GlobalAudioPlayerContext = createContext<GlobalAudioPlayerContextProps>({
  ...audioPlayerDefaultContext,
});

// If you are referencing the default track version, use the TRACK ID as the
// identifier instead of the version id. This is so that we can cross-reference
// this DAW track in other contexts where we only have access to the TRACK ID.
export const getDAWTrackId = (versionId: string, track: Track) => {
  if (!track) return undefined;
  const defaultVersionId = getDefaultVersionId(track);
  if (versionId === defaultVersionId) return track.id;
  return versionId;
};

const LS_AUDIO_QUALITY_ID = "hignote-audio-quality";
const getPersistedAudioQuality = () => {
  const value = localStorage.getItem(LS_AUDIO_QUALITY_ID);
  if (value === AUDIO_QUALITY.LOW) return AUDIO_QUALITY.LOW;
  if (value === AUDIO_QUALITY.HIGH) return AUDIO_QUALITY.HIGH;
  return AUDIO_QUALITY.ORIGINAL;
};

const LS_SKIP_AUDIO_MODE_ID = "hignote-skip-mode";
const getPersistedSkipMode = () => {
  const value = localStorage.getItem(LS_SKIP_AUDIO_MODE_ID);
  if (value === SKIP_AUDIO_MODE.NUDGE_10S) return SKIP_AUDIO_MODE.NUDGE_10S;
  if (value === SKIP_AUDIO_MODE.NUDGE_5S) return SKIP_AUDIO_MODE.NUDGE_5S;
  return SKIP_AUDIO_MODE.FULL_SKIP;
};

const _GlobalAudioPlayerContextProvider = ({
  children,
  useHTMLAudioPlayer,
}: {
  children: React.ReactNode;
  useHTMLAudioPlayer?: boolean;
}) => {
  useDAW();
  const queuedTracks = useRef<Record<string, QueuedTrack>>({});
  const filesRef = useRef<Record<string, FileEntity>>({});
  const [currentQueueOrder, setCurrentQueueOrder] =
    useState<QueueOrder>(DEFAULT_QUEUE_ORDER);
  const [nextQueueOrder, setNextQueueOrder] =
    useState<QueueOrder>(DEFAULT_QUEUE_ORDER);

  const queueOrderRef = useRef<string[]>(currentQueueOrder.queue);
  const [activeTrackId, setActiveTrackId] = useState<string>();
  const activeTrackIdRef = useRef<string>(activeTrackId);
  const [isActive, setActive] = useState<boolean>(false);
  const [renderTrigger, setRenderTrigger] = useState<string>();
  const autoplayRef = useRef<boolean>(false);
  const savedTrackTimeRef = useRef<number>();
  const dawTrackRef = useRef<DAWTrack>();
  const loadingRef = useRef<Record<string, boolean>>({});
  const [quality, setQuality] = useState<AUDIO_QUALITY>(
    getPersistedAudioQuality(),
  );
  const qualityRef = useRef<AUDIO_QUALITY>(quality);
  const [skipAudioMode, _setSkipAudioMode] = useState<SKIP_AUDIO_MODE>(
    getPersistedSkipMode(),
  );

  useEffect(() => {
    if (currentQueueOrder.queue.length < 1) setActive(false);
    queueOrderRef.current = currentQueueOrder.queue;
  }, [currentQueueOrder.queue]);

  useEffect(() => {
    activeTrackIdRef.current = activeTrackId;
  }, [activeTrackId]);

  useEffect(() => {
    qualityRef.current = quality;
  }, [quality]);

  const seek = useCallback((time: number) => {
    if (savedTrackTimeRef.current !== undefined) {
      savedTrackTimeRef.current = time;
    }
    daw.seekTo(time);
  }, []);

  // This is used for specific audio player controls, skipped by specific amounts
  const seekTrack = useCallback(
    (direction: "forward" | "back") => {
      const offset = skipAudioMode === SKIP_AUDIO_MODE.NUDGE_5S ? 5 : 10;
      const currentTime = daw.state.currentTime;
      const newTime =
        direction === "forward" ? currentTime + offset : currentTime - offset;
      seek(newTime);
    },
    [skipAudioMode],
  );

  useEffect(() => {
    if (useHTMLAudioPlayer) return;

    const tryLoadingIntoDAW = async (trackId: string, time?: number) => {
      if (!trackId) return;
      const queuedTrack = queuedTracks.current[trackId];
      if (!queuedTrack) return;
      const activeVersionId = queuedTrack.versionId;

      await Promise.all(
        [activeVersionId, ...(queuedTrack.preloadVersions || [])].map(
          async (versionId) => {
            if (!versionId) return;

            const file = filesRef.current[versionId];
            const dawTrackId = getDAWTrackId(versionId, queuedTrack?.track);
            const muted = activeVersionId !== versionId;

            // If the file is ready and it's not already being loaded... load it up!
            if (file && !loadingRef.current[dawTrackId]) {
              loadingRef.current[dawTrackId] = true;
              try {
                await loadFileIntoDAW(
                  dawTrackId,
                  file,
                  qualityRef.current,
                  time,
                  muted,
                );
              } catch (e) {
                // empty
              }
              loadingRef.current[dawTrackId] = false;
            }
          },
        ),
      );
    };

    const interval = setInterval(async () => {
      const trackId = activeTrackIdRef.current;
      const nextTrackId = getNextInArray(trackId, queueOrderRef.current);

      const queuedTrack = queuedTracks.current[trackId];
      const versionId = queuedTrack?.versionId;
      const activeDAWTrackId = getDAWTrackId(versionId, queuedTrack?.track);

      // Remove all track EXCEPT the ones you need in this moment
      daw.removeAllTracks(
        [
          // The default version for the active track
          trackId,
          // The active version for the active track
          versionId,
          // The preloaded versions for the active track
          ...(queuedTrack?.preloadVersions || []),
          // The default version for the next track
          nextTrackId,
          // DO NOT KEEP THE ONE WITH THE DEFAULT VERSION ID!
          // The default version should always use the *Track ID*
          // so if it's using its *File ID* that means it's stale and
          // should be removed ASAP.
        ].filter((v) => v !== getDefaultVersionId(queuedTrack?.track)),
      );

      await tryLoadingIntoDAW(trackId);

      dawTrackRef.current = daw.getTrack(activeDAWTrackId);
      const dawTrack = dawTrackRef.current;

      if (!dawTrack) return;

      if (!daw.isLoading()) {
        if (savedTrackTimeRef.current !== undefined) {
          daw.seekTo(dawTrack.startTime + savedTrackTimeRef.current);
          savedTrackTimeRef.current = undefined;
        }

        if (autoplayRef.current) {
          autoplayRef.current = false;
          daw.play();
        }
      }

      if (!nextTrackId) return;

      const trackEnd = dawTrack.startTime + dawTrack.duration;

      //  If this track is already over, gaplessly skip to the next item.
      if (daw.state.currentTime >= trackEnd) {
        autoplayRef.current = daw.state.isPlaying;
        setActiveTrackId(nextTrackId);
        return;
      }

      // Otherwise (if there's fewer than 20 seconds left of the current track)
      // pre-load the next item.
      if (trackEnd - daw.state.currentTime < 20) {
        await tryLoadingIntoDAW(nextTrackId, trackEnd);
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [useHTMLAudioPlayer]);

  const goToTrack = useCallback(
    (_trackId?: string, time?: number) => {
      const trackId = _trackId || activeTrackId;
      const dawTrack = dawTrackRef.current;
      const isPlaying = daw.state.isPlaying;

      if (trackId !== activeTrackId) {
        if (isPlaying) daw.pause();
        daw.removeAllTracks();
        seek(dawTrack?.startTime || 0);
        if (isPlaying) autoplayRef.current = true;
        setActiveTrackId(trackId);
        return;
      }

      if (dawTrack) {
        const trackEnd = dawTrack.startTime + dawTrack.duration;
        if (daw.state.currentTime >= trackEnd) {
          seek(dawTrack.startTime);
          return;
        }

        if (daw.state.currentTime < dawTrack.startTime) {
          seek(dawTrack.startTime);
          return;
        }
      }

      if (time !== undefined) {
        seek(dawTrack.startTime + (time || 0));
      }
    },
    [activeTrackId, seek],
  );

  const play = useCallback(
    (trackId?: string, time?: number) => {
      setActive(true);
      autoplayRef.current = true;
      goToTrack(trackId, time);
      daw.play();
    },
    [activeTrackId],
  );

  const pause = useCallback(() => {
    autoplayRef.current = false;
    daw.pause();
  }, []);

  const queueTrackVersion = useCallback(
    (trackId: string, versionId: string) => {
      if (!trackId) return;
      const queueItem = queuedTracks.current[trackId];
      if (!queueItem) {
        throw new Error("Requested track is not in the queue.");
      }

      queueItem.versionId = versionId;
      autoplayRef.current = daw.__isPlaying;

      const dawTrack = dawTrackRef.current;
      const queuedDAWTrackId = getDAWTrackId(versionId, queueItem.track);
      const queuedDawTrack = daw.getTrack(queuedDAWTrackId);

      if (dawTrack && !queuedDawTrack?.audioBuffer) {
        savedTrackTimeRef.current = daw.getTrackTime(dawTrack.id);
      }

      if (!(queueItem.preloadVersions || []).includes(versionId)) {
        queueItem.preloadVersions.push(versionId);
      }

      setRenderTrigger(uuidv4());
    },
    [],
  );

  const setAudioQuality = useCallback(
    (quality: AUDIO_QUALITY, skipSavingToLocalStorage = false) => {
      const dawTrack = dawTrackRef.current;

      // This is a special case to ensure we keep the same playback time
      // even when switching between quality levels.
      if (dawTrack && savedTrackTimeRef.current === undefined) {
        savedTrackTimeRef.current = daw.getTrackTime(dawTrack.id);
      }

      // If we're already playing, continue playing as soon as the new
      // file at the new quality level has completed loading
      if (daw.state.isPlaying) {
        daw.pause();
        autoplayRef.current = true;
      }

      if (!skipSavingToLocalStorage) {
        localStorage.setItem(LS_AUDIO_QUALITY_ID, quality);
      }
      setQuality(quality);
    },
    [],
  );

  const setSkipAudioMode = useCallback(
    (skipMode: SKIP_AUDIO_MODE, skipSavingToLocalStorage: boolean = false) => {
      if (!skipSavingToLocalStorage) {
        localStorage.setItem(LS_SKIP_AUDIO_MODE_ID, skipMode);
      }
      _setSkipAudioMode(skipMode);
    },
    [],
  );

  const preloadTrackVersions = useCallback(
    (trackId: string, versions: string[]) => {
      if (!trackId) return;
      const queueItem = queuedTracks.current[trackId];
      if (!queueItem) {
        throw new Error("Requested track is not in the queue.");
      }

      queueItem.preloadVersions = queueItem.preloadVersions || [];
      versions.forEach((version) => {
        if (queueItem.preloadVersions.includes(version)) return;
        queueItem.preloadVersions.push(version);
      });
    },
    [],
  );

  const fetchAudioFiles = useCallback((track: Track) => {
    const queuedTrack = queuedTracks.current[track.id];
    // Check if the track is already in the queue and stop watching its files if necessary
    if (queuedTrack && queuedTrack.unwatch) queuedTrack.unwatch();

    if (track.versionFilesV2.every((fileId) => filesRef.current[fileId])) {
      return; // All files are already fetched, no need to fetch again
    }

    // Watch the audio files for the track and update the files reference when they change
    const unwatch = watchAudioFiles(track.versionFilesV2, (files) => {
      (track.versionFilesV2 || []).forEach((fileId) => {
        const file = files.find((f) => f.id === fileId);
        filesRef.current[fileId] = file || {
          id: fileId,
          createdBy: track.createdBy,
          createdAt: track.createdAt,
          name: `${track.title} - Version Not Found`,
          processingErrorV3: FILE_NOT_FOUND_ERROR,
        };
      });
      setRenderTrigger(uuidv4());
    });

    // Update or add the track to the queue with the new unwatch function
    if (queuedTrack) {
      queuedTrack.unwatch = unwatch;
    } else {
      const defaultVersionId = getDefaultVersionId(track);
      queuedTracks.current[track.id] = {
        track,
        unwatch,
        versionId: defaultVersionId,
      };
    }
  }, []);

  const setQueue = useCallback(
    ({
      _tracks,
      type = "SET",
      currentPageId,
      keepPreloads,
    }: {
      _tracks: Track[];
      type: "SET" | "UPDATE_CURRENT" | "UPDATE_CURRENT_FROM_NEXT";
      currentPageId: string;
      keepPreloads?: boolean;
    }) => {
      const isNotCurrentQueueOrderPageId =
        currentPageId !== currentQueueOrder.pageId;

      if (type === "UPDATE_CURRENT_FROM_NEXT" && !isNotCurrentQueueOrderPageId)
        return;

      // Filter out undefined or null tracks
      const tracks = _tracks.filter((t) => !!t);
      const newQueueOrder = tracks.map((t) => t.id);
      const newQueueOrderString = newQueueOrder.join("-");

      if (type === "SET") {
        // type === "SET" sets entire lists of queue.
        // This is typically used to set the newQueueOrder when someone navigates to a new page
        // but can also be used to update the queue when changes are done on the same page, like ordering.

        if (isNotCurrentQueueOrderPageId) {
          if (newQueueOrderString !== nextQueueOrder.queue.join("-")) {
            setNextQueueOrder({
              pageId: currentPageId,
              queue: newQueueOrder,
            });
          }
        } else {
          if (newQueueOrderString !== currentQueueOrder.queue.join("-")) {
            setCurrentQueueOrder({
              pageId: currentPageId,
              queue: newQueueOrder,
            });
            setRenderTrigger(uuidv4());
          }
        }
      } else if (type === "UPDATE_CURRENT") {
        // type === "UPDATE_CURRENT" is used to update the currentQueueOrder right away.
        // An example of this, is on the SpaceTrack page that requires the currentQueueOrder changed right away

        if (isNotCurrentQueueOrderPageId) {
          setCurrentQueueOrder({
            pageId: currentPageId,
            queue: newQueueOrder,
          });
          setNextQueueOrder(DEFAULT_QUEUE_ORDER);
          setActive(true);
        }
      } else if (type === "UPDATE_CURRENT_FROM_NEXT") {
        // type === "UPDATE_CURRENT_FROM_NEXT" is used to update currentQueueOrder directly from nextQueueOrder
        // Since we persist queues now, when a user clicks "play" in a different Space, since the nextQueueOrder has already been
        // preloaded and set, we can just update the currentQueueOrder with it.

        if (isNotCurrentQueueOrderPageId) {
          setCurrentQueueOrder({
            pageId: nextQueueOrder.pageId,
            queue: nextQueueOrder.queue,
          });
          setNextQueueOrder(DEFAULT_QUEUE_ORDER);
          return; // No further action needed for this case
        }
      }

      // Process each track in the new queue
      tracks.forEach((track) => {
        const existingQueueItem = queuedTracks.current[track.id];
        if (existingQueueItem) {
          if (!keepPreloads) existingQueueItem.preloadVersions = [];
          const newVersionId = getDefaultVersionId(track);
          if (existingQueueItem.versionId !== newVersionId) {
            existingQueueItem.versionId = newVersionId;
            setRenderTrigger(uuidv4());
          }

          const existingTrackVersions =
            existingQueueItem.track.versionFilesV2 || [];
          const newVersions = (track.versionFilesV2 || []).filter(
            (fileId) => !existingTrackVersions.includes(fileId),
          );

          // Keep the cached track object up to date
          existingQueueItem.track = track;

          // If the track now has more versions, we gotta go get 'em!
          if (newVersions.length > 0) {
            existingQueueItem.unwatch();
          } else {
            return;
          }
        }

        if (type === "SET" || type === "UPDATE_CURRENT") {
          fetchAudioFiles(track);
        }
      });

      // Set the active track if not already set or not in the current queue
      if (!activeTrackIdRef.current || type === "UPDATE_CURRENT") {
        setActiveTrackId(newQueueOrder[0]);
      }
    },
    [
      nextQueueOrder.queue,
      currentQueueOrder.queue,
      currentQueueOrder.pageId,
      fetchAudioFiles,
      setCurrentQueueOrder,
      setNextQueueOrder,
      setRenderTrigger,
      setActiveTrackId,
    ],
  );

  const getFileItem = useCallback((id: string) => {
    return filesRef.current[id];
  }, []);

  const getQueueItem = useCallback((id: string, fileId?: string) => {
    const queuedTrack = queuedTracks.current[id];
    const file = filesRef.current[fileId || queuedTrack?.versionId];

    return {
      id,
      track: queuedTrack?.track,
      file,
    };
  }, []);

  const activeItem = queuedTracks.current[activeTrackId];
  const activeFile = filesRef.current[activeItem?.versionId];

  const value = useMemo(() => {
    return {
      nowPlaying: {
        id: activeTrackId,
        track: activeItem?.track,
        file: activeFile,
      },
      prevTrackId: getPrevInArray(activeTrackId, currentQueueOrder.queue),
      nextTrackId: getNextInArray(activeTrackId, currentQueueOrder.queue),
      currentQueueOrder,
      nextQueueOrder,
      getFileItem,
      getQueueItem,
      play,
      isActive,
      setQueue,
      queueTrackVersion,
      preloadTrackVersions,
      fetchAudioFiles,
      goToTrack,
      pause,
      seek,
      quality,
      setAudioQuality,
      skipAudioMode,
      setSkipAudioMode,
      seekTrack,
    };
  }, [
    activeItem,
    activeFile,
    activeTrackId,
    setQueue,
    queueTrackVersion,
    preloadTrackVersions,
    fetchAudioFiles,
    goToTrack,
    play,
    isActive,
    currentQueueOrder,
    nextQueueOrder,
    renderTrigger,
    quality,
    skipAudioMode,
  ]);

  return (
    <GlobalAudioPlayerContext.Provider value={value}>
      <SpaceProviderRaw id={activeItem?.track.spaceId || ""}>
        <ToggleQualityDialog useHTMLAudioPlayer={useHTMLAudioPlayer} />
        {children}
      </SpaceProviderRaw>
    </GlobalAudioPlayerContext.Provider>
  );
};

type GlobalHTMLAudioPlayerContextProps = {
  useHTMLPlayer: boolean;
  isActive: boolean;
  isLoading: boolean;
  isPlaying: boolean;
  currentDuration: number;
  currentTime: number;
  getCurrentTime: () => number;
  getTrack: (
    trackId?: string,
    fileId?: string,
  ) => HTMLPlayerFileQueueData | undefined;
  playTrack: (trackId: string) => void;
  onTogglePlay: () => void;
  onSeek: (timestamp: number) => void;
  seekTrack: (direction: "forward" | "back") => void;
};

const GlobalHTMLAudioPlayerContext =
  createContext<GlobalHTMLAudioPlayerContextProps>({
    useHTMLPlayer: false,
    isActive: false,
    isLoading: true,
    isPlaying: false,
    currentDuration: 0,
    currentTime: 0,
    getCurrentTime: () => 0,
    getTrack: () => undefined,
    playTrack: () => undefined,
    onTogglePlay: () => {},
    onSeek: () => {},
    seekTrack: () => {},
  });

interface HTMLPlayerQueueOrderStatus {
  prev?: string;
  curr?: string;
  next?: string;
}

interface HTMLPlayerFileQueueData {
  isLoading: boolean;
  error?: string;
  metadata?: MediaMetadata;
  sourceURL?: string;
  refresh?: () => Promise<void>;
}

type HTMLPlayerQueueData = Record<string, HTMLPlayerFileQueueData>;

const GlobalHTMLAudioPlayerContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  useDAW();
  const { trackId } = useUrlContext();
  const {
    quality,
    currentQueueOrder,
    seek,
    nowPlaying,
    prevTrackId,
    nextTrackId,
    getQueueItem,
    goToTrack,
    skipAudioMode,
  } = useGlobalAudioPlayer();
  const { space } = useSpaceContext();

  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [renderTrigger, setRenderTrigger] = useState<string>(uuidv4());

  const audioElRef = useRef<HTMLAudioElement>(new Audio());
  const queueStatus = useRef<HTMLPlayerQueueOrderStatus>({});
  const trackIdDataURLMap = useRef<Record<string, HTMLPlayerQueueData>>({});

  const queueItems = useMemo(() => {
    return currentQueueOrder.queue.map((order) => getQueueItem(order));
  }, [currentQueueOrder.queue]);
  const queueTracks = useMemo(() => {
    return queueItems.every((queueItem) => Boolean(queueItem.track))
      ? queueItems.map((queueItem) => queueItem.track)
      : [];
  }, [queueItems]);
  const { sources, fallback } = useTrackArtworkUrls({
    tracks: queueTracks,
  });

  const dawVolume = daw.getTrackVolume(nowPlaying?.id);
  useEffect(() => {
    if (audioElRef.current) {
      audioElRef.current.volume = dawVolume;
    }
  }, [dawVolume]);

  useEffect(() => {
    // close daw, which will also suspend any existing audio context
    daw.close();
    return () => {
      if (audioElRef.current) {
        audioElRef.current.pause();
        audioElRef.current.remove();
      }
      daw.resume();
    };
  }, []);

  const eventHandlers: Record<string, EventListenerOrEventListenerObject> =
    useMemo(() => {
      return {
        seeking: () => {
          setCurrentTime(audioElRef.current.currentTime);
          setIsLoading(true);
        },
        seeked: () => {
          setIsLoading(false);
        },
        loadstart: () => {
          setIsLoading(true);
        },
        canplay: () => {
          setIsLoading(false);
        },
        pause: () => {
          setIsPlaying(false);
          if (navigator.mediaSession) {
            navigator.mediaSession.playbackState = "paused";
          }
        },
        play: () => {
          setIsPlaying(true);
          if (navigator.mediaSession) {
            navigator.mediaSession.playbackState = "playing";
          }
        },
        ended: () => {
          if (!matchAppPath(routePaths.spaceTrack)) {
            playTrack({ trackId: queueStatus.current.next, forcePlay: true });
          }
          if (!queueStatus.current.next) {
            setIsPlaying(false);
          }
        },
        timeupdate: () => {
          if (!audioElRef.current.seeking) {
            setCurrentTime(audioElRef.current.currentTime);
          }
        },
      };
    }, []);

  useEffect(() => {
    const currentAudioEl = audioElRef.current;

    Object.keys(eventHandlers).forEach((eventName) => {
      currentAudioEl.addEventListener(eventName, eventHandlers[eventName]);
    });

    return () => {
      Object.keys(eventHandlers).forEach((eventName) => {
        currentAudioEl.removeEventListener(eventName, eventHandlers[eventName]);
      });
      currentAudioEl.pause();
      currentAudioEl.remove();
    };
  }, [eventHandlers]);

  useEffect(() => {
    if (navigator.mediaSession) {
      const isTrackPage = Boolean(matchAppPath(routePaths.spaceTrack));
      navigator.mediaSession.setActionHandler("pause", () => {
        audioElRef.current.pause();
      });
      navigator.mediaSession.setActionHandler("play", () => {
        audioElRef.current.play();
      });
      navigator.mediaSession.setActionHandler("stop", () => {
        playTrack({ trackId: queueStatus.current.next, forcePlay: true });
      });
      if (!isTrackPage) {
        navigator.mediaSession.setActionHandler("previoustrack", () => {
          playTrack({ trackId: queueStatus.current.prev, forcePlay: true });
          if (!queueStatus.current.prev) {
            audioElRef.current.currentTime = 0;
          }
        });
        navigator.mediaSession.setActionHandler("nexttrack", () => {
          playTrack({ trackId: queueStatus.current.next, forcePlay: true });
        });
      }
    }
  }, []);

  const waitForTrackProcessing = (trackId: string, fileId: string) => {
    return new Promise<void>((resolve) => {
      // we wait til we are done fetching the audio file
      const interval = setInterval(() => {
        const cache = trackIdDataURLMap.current[trackId]?.[fileId];
        if (!cache || !cache.isLoading) {
          resolve();
          clearInterval(interval);
        }
      }, 500);
    });
  };

  const processTrack = async ({
    trackId,
    fileId,
    refresh,
  }: {
    trackId: string;
    fileId?: string;
    refresh?: boolean;
  }) => {
    const queueItem = getQueueItem(trackId, fileId);
    const queueItemValid = queueItem?.file && queueItem?.track;
    if (!queueItemValid) {
      return;
    }
    if (!refresh) {
      const cache = trackIdDataURLMap.current[trackId]?.[queueItem.file.id];
      if (cache?.sourceURL) {
        return;
      }
      if (cache?.isLoading) {
        return waitForTrackProcessing(trackId, queueItem.file.id);
      }
    }
    trackIdDataURLMap.current[trackId] = {
      ...(trackIdDataURLMap.current[trackId] || {}),
      [queueItem.file.id]: {
        isLoading: true,
      },
    };
    const { track, file } = queueItem;
    let error = !isFilePlayable(file) ? "Invalid audio file." : "";
    let sourceURL = "";
    try {
      sourceURL = await getSourceUrl({ id: file.id }, quality);
    } catch (err) {
      error = `Could not load audio. ${err}`;
    }
    trackIdDataURLMap.current[trackId] = {
      ...(trackIdDataURLMap.current[trackId] || {}),
      [queueItem.file.id]: {
        isLoading: false,
        error,
        sourceURL: !error ? sourceURL : "",
        metadata: spaceTrackToMediaMetadata(
          track,
          space,
          sources[track.id] || fallback,
        ),
        refresh: () => processTrack({ trackId, fileId, refresh: true }),
      },
    };
    setRenderTrigger(uuidv4());
  };

  useEffect(() => {
    // remove any tracks that are no longer in the queue
    trackIdDataURLMap.current = Object.keys(trackIdDataURLMap.current).reduce(
      (acc: Record<string, HTMLPlayerQueueData>, key: string) => {
        if (currentQueueOrder.queue.includes(key)) {
          acc[key] = trackIdDataURLMap.current[key];
        }
        return acc;
      },
      {},
    );
  }, [currentQueueOrder.queue]);

  const isNowTrackPage = Boolean(trackId);
  useEffect(() => {
    if (isNowTrackPage) {
      Promise.all(
        (nowPlaying?.track?.pinnedVersionFiles || [])
          .filter(Boolean)
          .map((fileId) => {
            return processTrack({ trackId: nowPlaying.track.id, fileId });
          }),
      ).then(() => {
        if (nowPlaying?.file?.id) {
          playTrack({
            trackId: nowPlaying.id,
            fileId: nowPlaying.file.id,
          }).then(() => {
            queueStatus.current = {
              curr: nowPlaying.id,
            };
          });
        }
      });
      return;
    }
    // load current track and play if previously playing
    playTrack({
      trackId: nowPlaying.id,
    }).then(() => {
      queueStatus.current = {
        curr: nowPlaying.id,
      };
    });
  }, [isNowTrackPage, nowPlaying]);

  const prevTrackItem = getQueueItem(prevTrackId);
  const nextTrackItem = getQueueItem(nextTrackId);
  useEffect(() => {
    const isTrackPage = Boolean(matchAppPath(routePaths.spaceTrack));
    if (isTrackPage) return;
    // preload prev and next tracks
    Promise.all(
      [prevTrackItem.id, nextTrackItem.id]
        .filter(Boolean)
        .map((trackId) => processTrack({ trackId })),
    ).then(async () => {
      queueStatus.current = {
        ...queueStatus.current,
        prev: prevTrackId,
        next: nextTrackId,
      };
    });
  }, [prevTrackItem, nextTrackItem]);

  // refresh audio source URLs when quality changes
  useEffect(() => {
    Object.values(trackIdDataURLMap.current).map((cache) => {
      Object.values(cache).map((fileCache) => {
        if (fileCache.refresh) {
          fileCache.refresh();
        }
      });
    });
  }, [quality]);

  const playAudioElement = (trackId: string, fileId?: string) => {
    audioElRef.current.play().then(() => {
      // MediaMetadata must be set when there is an HTMLAudioElement that is
      // currently being played.
      if (navigator.mediaSession) {
        const defaultFileId =
          fileId || Object.keys(trackIdDataURLMap.current[trackId] || {})[0];
        const newMetadata =
          trackIdDataURLMap.current[trackId]?.[defaultFileId]?.metadata;
        if (!newMetadata) {
          navigator.mediaSession.metadata = null;
          return;
        }
        // if metadata was previously set, it seems that you must update the
        // existing object in place instead of overwriting it with a new object
        // otherwise, stale metadata shows up until you pause & resume
        if (navigator.mediaSession.metadata) {
          navigator.mediaSession.metadata.title = newMetadata.title;
          navigator.mediaSession.metadata.artist = newMetadata.artist;
          navigator.mediaSession.metadata.album = newMetadata.album;
          navigator.mediaSession.metadata.artwork = newMetadata.artwork;
          return;
        }
        navigator.mediaSession.metadata = newMetadata;
      }
    });
  };

  const refreshAudioElement = () => {
    if (audioElRef.current) {
      audioElRef.current.pause();
      audioElRef.current.currentTime = 0;
      setIsPlaying(false);
      setCurrentTime(0);
    }
    // why create a new HTMLAudioElement instead of using an existing one?
    // it seems that auto-playing a track after a previous track finishes
    // does not work when a user is in lock screen unless you use the newly
    // created HTMLAudioElement.
    audioElRef.current = new Audio();
    audioElRef.current.volume = dawVolume;
    audioElRef.current.preload = "auto";

    Object.keys(eventHandlers).forEach((eventName) => {
      audioElRef.current.removeEventListener(
        eventName,
        eventHandlers[eventName],
      );
      audioElRef.current.addEventListener(eventName, eventHandlers[eventName]);
    });
  };

  const playTrack = async ({
    trackId,
    fileId,
    forcePlay,
    shouldSetActive,
  }: {
    trackId: string;
    fileId?: string;
    forcePlay?: boolean;
    shouldSetActive?: boolean;
  }) => {
    const isTrackPage = Boolean(matchAppPath(routePaths.spaceTrack));
    if (!isActive && shouldSetActive) {
      setIsActive(true);
    }

    await processTrack({ trackId, fileId });
    const trackFileId =
      fileId || Object.keys(trackIdDataURLMap.current[trackId] || {})[0];
    if (
      trackId &&
      trackIdDataURLMap.current[trackId]?.[trackFileId]?.sourceURL
    ) {
      if (!isTrackPage) {
        goToTrack(trackId);
      }
      const oldSrc = audioElRef.current.src;
      const newSrc = trackIdDataURLMap.current[trackId][trackFileId].sourceURL;
      if (oldSrc === newSrc) {
        return;
      }
      const paused = audioElRef.current.paused;
      const shouldPlay = isTrackPage
        ? !paused && queueStatus.current.curr === trackId
        : queueStatus.current.curr !== nowPlaying.id;
      const prevTimestamp = audioElRef.current.currentTime || 0;
      refreshAudioElement();
      audioElRef.current.src =
        trackIdDataURLMap.current[trackId][trackFileId].sourceURL;
      audioElRef.current.currentTime =
        isTrackPage && queueStatus.current.curr === trackId ? prevTimestamp : 0;
      audioElRef.current.load();
      if (shouldPlay || forcePlay) {
        playAudioElement(trackId);
      }
    }
  };

  const onTogglePlay = () => {
    if (!isActive) {
      setIsActive(true);
    }
    if (audioElRef.current.paused) {
      playAudioElement(queueStatus.current.curr);
      return;
    }
    audioElRef.current.pause();
  };

  const onSeek = (timestamp: number) => {
    seek(timestamp);
    audioElRef.current.currentTime = timestamp;
  };

  const seekTrack = (direction: "forward" | "back") => {
    const offset = skipAudioMode === SKIP_AUDIO_MODE.NUDGE_5S ? 5 : 10;
    const currentTime = daw.state.currentTime;
    const newTime =
      direction === "forward" ? currentTime + offset : currentTime - offset;
    onSeek(newTime);
  };

  const value = useMemo(() => {
    return {
      useHTMLPlayer: true,
      isActive,
      isLoading,
      isPlaying,
      currentDuration: !isNaN(audioElRef.current.duration)
        ? audioElRef.current.duration
        : 0,
      currentTime,
      getCurrentTime: () => audioElRef.current.currentTime,
      getTrack: (trackId?: string, fileId?: string) => {
        if (
          !trackIdDataURLMap.current[trackId] ||
          Object.values(trackIdDataURLMap.current[trackId]).length === 0
        ) {
          return undefined;
        }
        const trackFileId =
          fileId || Object.keys(trackIdDataURLMap.current[trackId])[0];
        return trackIdDataURLMap.current[trackId][trackFileId];
      },
      playTrack: (trackId: string) =>
        playTrack({ trackId, forcePlay: true, shouldSetActive: true }),
      onTogglePlay,
      onSeek,
      seekTrack,
    };
  }, [
    isActive,
    isLoading,
    isPlaying,
    audioElRef.current.duration,
    nowPlaying,
    currentTime,
    playTrack,
    onTogglePlay,
    onSeek,
    seekTrack,
    renderTrigger,
  ]);

  return (
    <GlobalHTMLAudioPlayerContext.Provider value={value}>
      {children}
    </GlobalHTMLAudioPlayerContext.Provider>
  );
};

export const useHTMLAudioPlayer = () =>
  useContext(GlobalHTMLAudioPlayerContext);

export const GlobalAudioPlayerContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const matchSpaceHome = !!matchAppPath(routePaths.spaceHome);
  const matchSpaceTrack = !!matchAppPath(routePaths.spaceTrack);
  const matchLibrary = !!matchAppPath(routePaths.library);

  const { vw } = useViewport();

  const isMobile = vw <= 768;
  const useHTMLPlayer = isMobile && (matchSpaceHome || matchSpaceTrack);

  // Stop playing audio when you unmount this context.
  useEffect(() => {
    return () => {
      daw.pause();
    };
  }, []);

  useEffect(() => {
    if (!matchSpaceHome && !matchSpaceTrack && !matchLibrary) {
      daw.pause();
      return;
    }
    if (useHTMLPlayer) {
      daw.pause();
    }
  }, [useHTMLPlayer, matchSpaceHome, matchSpaceTrack, matchLibrary]);

  return (
    <_GlobalAudioPlayerContextProvider useHTMLAudioPlayer={useHTMLPlayer}>
      {useHTMLPlayer ? (
        <GlobalHTMLAudioPlayerContextProvider>
          {children}
        </GlobalHTMLAudioPlayerContextProvider>
      ) : (
        children
      )}
    </_GlobalAudioPlayerContextProvider>
  );
};

export const useGlobalAudioPlayer = () => {
  const context = useContext(GlobalAudioPlayerContext);

  if (!context) {
    throw new Error(
      "useGlobalAudioPlayer must be used within a _GlobalAudioPlayerContextProvider",
    );
  }

  return context;
};
