import "./Select.scss";
import "./Menu/styles.scss";
import React, { useMemo } from "react";
import { Select as MuiSelect, SelectProps } from "@mui/material";
import { ReactComponent as CaretDownSVG } from "App/common/icons/caret-down.svg";
import { useTheme } from "./ThemeProvider";

export const Select = (props: SelectProps) => {
  const { theme } = useTheme();

  const MenuProps = useMemo(
    () => ({
      ...(props.MenuProps || {}),
      className: `highnote-menu ${props.MenuProps?.className || ""}`,
      "data-theme": theme,
    }),
    [props.MenuProps, theme],
  );

  return (
    <MuiSelect
      {...props}
      className={`highnote-select ${props.className}`}
      MenuProps={MenuProps}
      endAdornment={<CaretDownSVG />}
      data-theme={theme}
      data-disabled={!!props.disabled}
    />
  );
};
