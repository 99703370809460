import React from "react";
import classNames from "classnames";

import { Dialog, DialogButtons, DialogSection } from "App/common/Dialog";
import { BUTTON_SIZE, BUTTON_THEME, Button } from "App/core/Button";
import "./HelperDialog.scss";

type Props = {
  actions?: React.ReactNode;
  heading?: string;
  description?: string | React.ReactNode;
  confirmCta?: {
    label: string;
    onConfirm?: () => void;
  };
};

export const HelperDialog = ({
  actions,
  confirmCta,
  description,
  heading,
}: Props) => {
  return (
    <Dialog open className={classNames("HelperDialog-helper")}>
      <DialogSection className="HelperDialog-helper-content">
        {heading && (
          <h3 className="HelperDialog-helper-content-heading">{heading}</h3>
        )}
        {description && (
          <div className="HelperDialog-helper-content-description">
            {description}
          </div>
        )}
      </DialogSection>
      <DialogButtons className="HelperDialog-helper-ctas">
        {actions && actions}
        {confirmCta && (
          <Button
            className="HelperDialog-helper-ctas-confirm"
            size={BUTTON_SIZE.XSMALL}
            theme={BUTTON_THEME.CTA}
            onClick={confirmCta.onConfirm}
          >
            {confirmCta.label || "Okay"}
          </Button>
        )}
      </DialogButtons>
    </Dialog>
  );
};
