import "./EditableText.scss";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as EditSVG } from "App/common/icons/edit.svg";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";

export const EditableText = ({
  disabled,
  className,
  placeholder,
  value: initialValue,
  maxLength,
  required,
  onChange,
  ...rest
}: {
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  value: string;
  maxLength?: number;
  required?: boolean;
  onChange?: (value: string) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "onChange">) => {
  const isTouch = !!("ontouchstart" in window);
  const [value, setValue] = useState(initialValue || "");
  const [isEditing, setEditing] = useState(false);
  const unmountedRef = useRef<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement>();

  useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    [],
  );

  const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    const enterKeyDown = e.key === "Enter";
    const shiftKeyDown = e.shiftKey;

    if (!enterKeyDown) return;
    if (shiftKeyDown) return;

    e.preventDefault();
    textareaRef.current.blur();
  };

  return (
    <div
      className="EditableText"
      data-disabled={!!disabled}
      data-is-editing={isEditing}
      data-is-touch={!!isTouch}
      data-empty={!value}
      onClick={(e) => {
        if (isTouch) return;
        e.stopPropagation();
        e.preventDefault();
        if (disabled) return;
        setEditing(true);
        setTimeout(() => {
          textareaRef.current.focus();
          textareaRef.current.value = value || "";
        });
      }}
      {...rest}
    >
      <div className={`EditableText-display ${className}`}>
        <span>{value || placeholder || ""}</span>
      </div>

      <textarea
        ref={textareaRef}
        placeholder={isTouch ? placeholder : ""}
        className={`EditableText-input ${className}`}
        value={value}
        onChange={(e) => {
          const original = e.target.value || "";
          const trimmed = original.slice(0, maxLength || undefined);
          setValue(trimmed);
        }}
        onFocus={() => {
          setEditing(true);
        }}
        onBlur={() => {
          setEditing(false);
          const trimmed = value.trim();

          if (!trimmed && required) {
            setValue(initialValue || "");
            if (isTouch) textareaRef.current.value = initialValue || "";
            return;
          }

          onChange(trimmed);
        }}
        onKeyDown={!isTouch ? onKeyDown : undefined}
      >
        {isTouch ? value : ""}
      </textarea>

      {isEditing && !!maxLength && maxLength - value.length < 5 && (
        <div className="char-count">
          {value.length} / {maxLength}
        </div>
      )}

      <div className="button-spacer">
        <Button
          className="EditableText-edit"
          theme={BUTTON_THEME.ICON}
          size={BUTTON_SIZE.SMALL}
        >
          <EditSVG />
        </Button>
      </div>
    </div>
  );
};
