import "./AddToSpace.scss";
import { v4 as uuidv4 } from "uuid";
import React, { useState, useRef } from "react";
import { FileEntity } from "@highnote/server/src/core/entities";
import { stripExtension } from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import { useAuth } from "App/components/Auth";

import { useSpaceContext } from "App/common/useSpace";
import { RecordDialog } from "App/components/RecordDialog";
import { routePaths } from "App/modules/urls";
import { matchAppPath } from "App/modules/utils";
import { useHistory } from "react-router";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { ReactComponent as LinkHollowSVG } from "App/common/icons/link-hollow.svg";
import { ReactComponent as FolderHollowSVG } from "App/common/icons/folder-hollow.svg";
import { ReactComponent as PlusCircleHollowSVG } from "App/common/icons/plus-circle-hollow.svg";
import { ReactComponent as MicrophoneSVG } from "App/common/icons/microphone.svg";
import { ReactComponent as CaretDownSVG } from "App/common/icons/caret-down.svg";
import { Menu, MenuItem } from "App/common/Menu";
import { useAttachmentsContext } from "App/common/useAttachments";
import { useFileEditor } from "App/components/useFileEditor";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";
import { useHighnote } from "App/common/useHighnote";
import { useToast } from "App/common/useToast";

const AddToSpaceMenuUI = ({
  isMenuOpen,
  onClose,
}: {
  isMenuOpen: boolean;
  onClose: () => void;
}) => {
  const history = useHistory();
  const { addErrorMessage } = useToast();
  const { createSpace } = useHighnote();
  const { space, spaceId } = useSpaceContext();
  const [isRecorderOpen, setRecorderOpen] = useState<boolean>(false);
  const { user, joinEntity } = useAuth();
  const menuRef = useRef<HTMLDivElement>();
  const matchSpaceHome = matchAppPath(routePaths.spaceHome);
  const { attachFileToContext } = useAttachmentsContext();
  const { openFileEditor } = useFileEditor();

  const handleCreateSpace = async () => {
    onClose();
    try {
      await createSpace({
        data: {
          spaceId,
        },
      });
    } catch (e) {
      addErrorMessage(e.message || "Failed to create a new space.");
    }
  };

  const onSaveRecording = async (file: FileEntity) => {
    const trackId = uuidv4();
    await highnote.createTrack({
      id: trackId,
      data: {
        artistName: user.name,
        title: `${space.name} - ${stripExtension(file.name)}`,
        versionFilesV2: [file.id],
        spaceId,
      },
    });

    joinEntity({
      entity: space,
      entityType: "Space",
      trigger: JOIN_ENTITY_TRIGGER.UPLOAD,
    });

    if (matchSpaceHome) return;
    history.push(`/space/${spaceId}/${trackId}?track-details`);
  };

  return (
    <>
      <span ref={menuRef}></span>
      <Menu
        className="add-to-space-menu"
        anchorEl={menuRef.current}
        onClose={onClose}
        open={isMenuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={handleCreateSpace}
          data-cypress-id="highnote-add-to-space-new-space-menu-item"
        >
          <FolderHollowSVG />
          New Space
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClose();
            setRecorderOpen(true);
          }}
        >
          <MicrophoneSVG />
          Audio Recording
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClose();
            openFileEditor({
              onSave: (fileEntity) => attachFileToContext(fileEntity.id),
            });
          }}
        >
          <LinkHollowSVG />
          Attachment
        </MenuItem>
      </Menu>

      {isRecorderOpen && (
        <RecordDialog
          onClose={() => setRecorderOpen(false)}
          onSave={onSaveRecording}
        />
      )}
    </>
  );
};

export const AddToSpaceMenu = ({ disabled }: { disabled?: boolean }) => {
  const menuRef = useRef<HTMLDivElement>();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <div
      className="new-entity-menu-container"
      data-cypress-id="highnote-add-to-space"
    >
      <div ref={menuRef}>
        <Button
          size={BUTTON_SIZE.SMALL}
          theme={BUTTON_THEME.SECONDARY}
          disabled={!!disabled}
          onClick={() => setMenuOpen(true)}
        >
          <PlusCircleHollowSVG />
          <span>Create</span>
          <CaretDownSVG />
        </Button>
      </div>
      <AddToSpaceMenuUI
        isMenuOpen={isMenuOpen}
        onClose={() => setMenuOpen(false)}
      />
    </div>
  );
};
