import "./Sidebar.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as CaretRightSVG } from "App/common/icons/caret-right.svg";
import { THEME, ThemeProvider } from "App/common/ThemeProvider";
import { isSidebarUrl, unsetSidebarUrl } from "./util";
import {
  SidebarConfig,
  SIDEBAR_CONFIG,
  SIDEBAR_ID,
  SIDEBAR_SIDE,
  SIDEBAR_TYPE,
} from "./types";
import { useHistory, useLocation } from "react-router";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { PreviewText } from "@highnote/preview-text/src";
import { useSegmentContext } from "App/modules/useSegment";
import { useViewport } from "App/common/useViewport";
import { ToggleGlobalSidebar } from "./ToggleGlobalSidebar";
import { Navigation } from "../Navigation";
import { Link } from "react-router-dom";
import { Button, BUTTON_THEME } from "App/core/Button";
import { useAuth } from "App/components/Auth";

type SidebarContextProps = {
  forceClose?: boolean;
  isSidebarOpen: boolean;
  openSidebar: (onlyWideScreen?: boolean) => void;
  closeSidebar: (force?: boolean) => void;
  sidebarConfig: SidebarConfig;
};

// Keep in sync with $force-sidebar-overlay-width in Sidebar.scss
const FORCE_SIDEBAR_OVERLAY_WIDTH = 1350;

const SidebarContext = React.createContext<SidebarContextProps>({
  isSidebarOpen: false,
  openSidebar: () => {},
  closeSidebar: () => {},
  sidebarConfig: undefined,
});

export const SidebarContextProvider = ({
  ids,
  ignoreUrl,
  children,
  forceClose,
}: {
  ids: SIDEBAR_ID[];
  ignoreUrl?: boolean;
  children: React.ReactNode;
  forceClose?: boolean;
}) => {
  const history = useHistory();
  const location = useLocation();
  const { trackEvent } = useSegmentContext();
  const { vw } = useViewport();
  const [openSidebarId, setOpenSidebarId] = useState<SIDEBAR_ID>();
  const lastSidebarIdRef = useRef<SIDEBAR_ID>(ignoreUrl ? ids[0] : undefined);
  const sidebarConfig = SIDEBAR_CONFIG[lastSidebarIdRef.current];
  const isSidebarOpen = !!openSidebarId;
  const isWideScreen = vw > FORCE_SIDEBAR_OVERLAY_WIDTH;

  useEffect(() => {
    if (!sidebarConfig) return;
    if (isSidebarOpen && sidebarConfig.segmentEnterId) {
      trackEvent(sidebarConfig.segmentEnterId);
      return;
    }

    if (!isSidebarOpen && sidebarConfig.segmentExitId) {
      trackEvent(sidebarConfig.segmentExitId);
    }
  }, [isSidebarOpen, sidebarConfig]);

  useEffect(() => {
    if (ignoreUrl) return;

    const requestedSidebarId = ids.find((id) => {
      return isSidebarUrl(id);
    });

    if (requestedSidebarId) {
      lastSidebarIdRef.current = requestedSidebarId;
      setOpenSidebarId(requestedSidebarId);
    } else {
      setOpenSidebarId(undefined);
    }
  }, [location, ids, ignoreUrl]);

  useEffect(() => {
    if (sidebarConfig?.type !== SIDEBAR_TYPE.PUSH) return;
    window.dispatchEvent(new Event("resize"));
  }, [isSidebarOpen, sidebarConfig?.type]);

  const value = useMemo(
    () => ({
      forceClose,
      isSidebarOpen: forceClose ? false : isSidebarOpen,
      openSidebar: (onlyWideScreen?: boolean) => {
        if (onlyWideScreen && !isWideScreen) return;
        setOpenSidebarId(lastSidebarIdRef.current);
      },
      closeSidebar: (force?: boolean) => {
        const config = SIDEBAR_CONFIG[lastSidebarIdRef.current];
        if (!force && config?.keepOpenOnWideScreens && isWideScreen) return;
        setOpenSidebarId(undefined);
        if (ignoreUrl) return;
        unsetSidebarUrl(history);
      },
      sidebarConfig,
    }),
    [forceClose, isSidebarOpen, sidebarConfig, isWideScreen, ignoreUrl],
  );

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};

export const useSidebarContext = () => React.useContext(SidebarContext);

const SidebarWidthMap = {
  [SIDEBAR_SIDE.LEFT]: 320,
  [SIDEBAR_SIDE.RIGHT]: 400,
};

export const WithSidebar = ({
  side = SIDEBAR_SIDE.LEFT,
  type = SIDEBAR_TYPE.OVERLAY,
  resizable,
  children,
}: {
  side?: SIDEBAR_SIDE;
  type?: SIDEBAR_TYPE;
  resizable?: boolean;
  children: React.ReactNode;
}) => {
  const { user } = useAuth();
  const { sidebarConfig, closeSidebar, isSidebarOpen, forceClose } =
    useSidebarContext();

  // NOTE: This will break if we added more left-hand sidebars.
  // I'm adding this with the expectation that we won't. - cha
  const showToggleTab = !!user && side === SIDEBAR_SIDE.LEFT;

  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const [isResizing, setIsResizing] = useState(false);
  const SidebarMinWidth = SidebarWidthMap[side];
  const [sidebarWidth, setSidebarWidth] = useState(SidebarMinWidth);

  const startResizing = () => {
    setIsResizing(true);
  };

  const stopResizing = () => {
    setIsResizing(false);
  };

  const resize = (event: MouseEvent) => {
    if (isResizing && sidebarRef?.current) {
      setSidebarWidth(
        Math.max(
          SidebarMinWidth,
          event.clientX - sidebarRef.current.getBoundingClientRect().left,
        ),
      );
    }
  };

  useEffect(() => {
    if (resizable) {
      window.addEventListener("mousemove", resize);
      window.addEventListener("mouseup", stopResizing);
      return () => {
        window.removeEventListener("mousemove", resize);
        window.removeEventListener("mouseup", stopResizing);
      };
    }
  }, [resize, stopResizing]);

  const shouldSidebarBeSetFixed =
    resizable &&
    isSidebarOpen &&
    sidebarWidth > SidebarMinWidth &&
    window.innerWidth > 1350;

  return (
    <ErrorBoundary name="WithSidebar">
      <ThemeProvider theme={sidebarConfig?.theme}>
        <div
          className="highnote-with-sidebar"
          data-side={side}
          data-resizable={resizable ? "true" : "false"}
          data-type={type}
          data-theme={sidebarConfig?.theme}
        >
          <div className="inner">
            <div
              className="highnote-sidebar-overlay"
              data-is-open={isSidebarOpen}
              onClick={() => {
                closeSidebar();
              }}
            />

            <ErrorBoundary name="Sidebar">
              <section
                className="highnote-sidebar"
                style={{
                  ...(resizable &&
                    isSidebarOpen && {
                      width: sidebarWidth,
                      ...(shouldSidebarBeSetFixed && {
                        position: "fixed",
                      }),
                    }),
                }}
                ref={sidebarRef}
                data-id={sidebarConfig?.id}
                data-is-open={isSidebarOpen}
              >
                {!forceClose && showToggleTab && <ToggleGlobalSidebar />}

                <div className="inner">
                  <div className="scrollable">{sidebarConfig?.sidebar}</div>
                </div>
                {isSidebarOpen && resizable && (
                  <div
                    className="highnote-sidebar-resizer"
                    onMouseDown={startResizing}
                  />
                )}
              </section>
            </ErrorBoundary>

            <ThemeProvider theme={THEME.DARK}>
              <main
                style={{
                  ...(shouldSidebarBeSetFixed && {
                    marginLeft: "auto",
                    marginRight: 0,
                    minWidth: window.innerWidth - SidebarMinWidth,
                    maxWidth: window.innerWidth - SidebarMinWidth,
                  }),
                  ...(side === SIDEBAR_SIDE.LEFT && {
                    // this is to ensure that the main content is not hidden behind the sidebar,
                    // which may happen if smart banner is rendered.
                    height: "100dvh",
                  }),
                }}
              >
                {children}
              </main>
            </ThemeProvider>
          </div>
        </div>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export const SidebarHeader = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}) => {
  return (
    <Navigation className="highnote-sidebar-header">
      <h2>{title}</h2>

      {subtitle && (
        <span className="subtitle">
          <PreviewText>{subtitle}</PreviewText>
        </span>
      )}

      <Link
        className="back-link close"
        data-cypress-id="close-sidebar"
        to={location.pathname}
      >
        <Button theme={BUTTON_THEME.ICON}>
          <CaretRightSVG />
        </Button>
      </Link>
    </Navigation>
  );
};
