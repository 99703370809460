import { useMemo } from "react";
import { routePaths } from "App/modules/urls";
import { matchPath, useLocation } from "react-router";

export const useUrlContext = () => {
  useLocation();

  const matchSpacePage = matchPath(location.pathname, {
    path: routePaths.space,
    exact: true,
    strict: false,
  });

  const matchSettingsPage = matchPath(location.pathname, {
    path: routePaths.settings,
    exact: true,
    strict: false,
  });

  const params: {
    spaceId?: string;
    trackId?: string;
    settingsTabId?: string;
  } = {
    ...(matchSpacePage?.params || {}),
    ...(matchSettingsPage?.params || {}),
  };

  const query = new URLSearchParams(location.search);
  const commentId = query.get("commentId") || undefined;
  const versionId = query.get("versionId") || undefined;
  const shareKey = query.get("shareKey") || undefined;

  const { spaceId, trackId, settingsTabId } = params;

  const value = useMemo(
    () => ({
      spaceId,
      trackId,
      versionId,
      commentId,
      settingsTabId,
      shareKey,
    }),
    [spaceId, trackId, versionId, commentId, settingsTabId, shareKey],
  );

  return value;
};
