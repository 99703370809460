import "./NewCommentForm.scss";
import { v4 as uuidv4 } from "uuid";
import React, { useState } from "react";
import { useCommentContext } from "../useComment";
import { TextInput } from "../TextInput";
import { Button, BUTTON_THEME, BUTTON_SIZE } from "../../core/Button";
import {
  COMMENT_BLOCK_TYPE,
  Comment,
} from "@highnote/server/src/core/entities";
import { ErrorBoundary } from "../ErrorBoundary";
import constants from "App/modules/consts";
import { useSpaceCommentsContext } from "../useSpaceComments";
import { useTrack } from "App/components/useEntities/useTrack";
import { getAuthId, useAuth } from "App/components/Auth";
import { useSpaceContext } from "../useSpace";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";

export const NewCommentForm = ({
  onClose,
  trackId,
  spaceId,
  saveButtonChildren,
  isDisabled,
}: {
  trackId?: Id;
  spaceId: Id;
  onClose?: () => void;
  saveButtonChildren?: React.ReactNode;
  isDisabled?: boolean;
}) => {
  const { space } = useSpaceContext();
  const { comment } = useCommentContext();
  const { addSpaceComment } = useSpaceCommentsContext();
  const { currentTrackVersion } = useTrack();
  const { joinEntity } = useAuth();
  const [text, setText] = useState<string>("");

  const onSave = async () => {
    if (!text) return;

    setText("");

    const commentId = uuidv4();
    const commentData: Comment = {
      id: commentId,
      createdBy: getAuthId(),
      createdAt: Date.now(),
      spaceId,
      trackId: trackId || null,
      trackVersionIds: currentTrackVersion ? [currentTrackVersion.id] : [],
      blocks: [
        {
          type: COMMENT_BLOCK_TYPE.TEXT,
          text: text.trim(),
        },
      ],
    };

    if (comment) {
      commentData.timestamp = comment.timestamp;
      commentData.parentComment = comment.id;
    }

    await addSpaceComment({
      id: commentId,
      data: commentData,
    });

    joinEntity({
      entity: space,
      entityType: "Space",
      trigger: JOIN_ENTITY_TRIGGER.COMMENT,
    });
  };

  return (
    <ErrorBoundary name="NewCommentForm">
      <div className="highnote-new-comment-form">
        <TextInput
          value={text}
          onChange={(str) => setText(str)}
          placeholder={"Type here..."}
          onSubmit={onSave}
          isReadOnly={isDisabled}
          maxLength={constants.longMaxLength}
        />

        {onClose && (
          <Button
            className="close"
            size={BUTTON_SIZE.XSMALL}
            theme={BUTTON_THEME.TEXT}
            onClick={onClose}
            disabled={isDisabled}
          >
            Close
          </Button>
        )}
        <Button
          className="save"
          size={BUTTON_SIZE.XSMALL}
          theme={BUTTON_THEME.PRIMARY}
          onClick={onSave}
          disabled={isDisabled || !text?.trim()}
        >
          {saveButtonChildren || "Send"}
        </Button>
      </div>
    </ErrorBoundary>
  );
};
