import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";
import { useAuth } from "App/components/Auth";
import { highnote } from "@highnote/server/src/sdk";
import { OAUTH_PROVIDERS } from "@highnote/server/src/core/entities";
import { routePaths } from "App/modules/urls";
import { useToast } from "App/common/useToast";
import { EntityNotFound } from "../Main/EntityNotFound";

export const DropboxCallback = () => {
  const history = useHistory();
  const { authLoading, user } = useAuth();
  const { addErrorMessage, addSuccessMessage } = useToast();

  const [isProcessing, setIsProcessing] = useState(true);

  const params = new URLSearchParams(location.search);
  const code = params.get("code");
  const state = params.get("state");

  useEffect(() => {
    if (!code || !state) {
      addErrorMessage("Failed to link Dropbox account: Invalid code or state");
      setIsProcessing(false);
    }
  }, []);

  useEffect(() => {
    if (!authLoading && user && code && state) {
      history.replace(routePaths.dropboxCallback);
      if (state !== user.id) {
        addErrorMessage("Failed to link Dropbox account: Invalid state");
        setIsProcessing(false);
        return;
      }
      highnote
        .associateOAuthAccount({
          code,
          provider: OAUTH_PROVIDERS.DROPBOX,
          redirectUri: `${window.location.origin}${routePaths.dropboxCallback}`,
        })
        .then(() => {
          addSuccessMessage("Dropbox account linked successfully");
          history.replace(routePaths.library);
        })
        .catch((error) => {
          addErrorMessage(
            `Could not link your Dropbox account: ${error.message}`,
          );
          setIsProcessing(false);
        });
    }
  }, [authLoading, user]);

  // we should show 404 like page if a user visits this page without a code
  if (!code && !isProcessing) {
    return <EntityNotFound entityType="page" />;
  }

  return <LoadingScreen />;
};
