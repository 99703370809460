import "./StorageUsageEditor.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as DeleteSVG } from "App/common/icons/trash.svg";
import { DEFAULT_COLUMNS, EntityTable } from "App/components/EntityTable";
import { ENTITY_TYPE } from "App/components/EntityTable/config";
import { highnote } from "@highnote/server/src/sdk";
import {
  COLLECTION_ID,
  Comment,
  FileBlockV2,
  FileEntity,
  FileReferences,
  PollBlockV2,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import { useConfirmation } from "App/common/useConfirmation";
import { useToast } from "App/common/useToast";
import { Link } from "react-router-dom";

const defaultColumns = [
  DEFAULT_COLUMNS.PREVIEW,
  DEFAULT_COLUMNS.CREATED_AT,
  DEFAULT_COLUMNS.ACTIONS,
];

const TrackFileReference = ({
  file,
  track,
}: {
  file: FileEntity;
  track: Track;
}) => {
  let role: string;
  if (file.id === track.artworkFile) {
    role = "artwork";
  }

  if ((track.files || []).includes(file.id)) {
    role = "an attachment";
  }

  if ((track.versionFilesV2 || []).includes(file.id)) {
    role = "a version";
  }

  const url = track.spaceId
    ? `/space/${track.spaceId}/${track.id}`
    : "/library";

  return (
    <span>
      {role ? `as ${role} ` : ""}in the Track{" "}
      <Link style={{ textDecoration: "underline" }} to={url}>
        {track.title}.
      </Link>
    </span>
  );
};

const SpaceFileReference = ({
  file,
  space,
}: {
  file: FileEntity;
  space: Space;
}) => {
  let role: string;
  if (file.id === space.artworkFile) {
    role = "artwork";
  }

  if ((space.files || []).includes(file.id)) {
    role = "an attachment";
  }

  return (
    <span>
      {role ? `as ${role} ` : ""}in the Space{" "}
      <Link style={{ textDecoration: "underline" }} to={`/space/${space.id}`}>
        {space.name}.
      </Link>
    </span>
  );
};

const CommentFileReference = ({
  file,
  comment,
}: {
  file: FileEntity;
  comment: Comment;
}) => {
  let role: string;

  if (comment.blocks) {
    const fileBlock = comment.blocks[0] as FileBlockV2;
    if (file.id === fileBlock?.file) {
      role = "a file reference";
    }

    const pollBlock = comment.blocks[0] as PollBlockV2;
    const pollOptions = pollBlock?.options || [];
    const pollFiles = pollOptions.map((o) => (o as FileBlockV2)?.file);
    if (pollFiles.includes(file.id)) {
      role = "a poll option";
    }
  }

  let url: string;

  if (comment.spaceId && comment.trackId) {
    url = `/space/${comment.spaceId}/${comment.trackId}?commentId=${comment.id}`;
  } else if (comment.spaceId) {
    url = `/space/${comment.spaceId}/?space-chat&commentId=${comment.id}`;
  }

  return (
    <span>
      {role ? `as ${role} ` : ""}in a{" "}
      {url ? (
        <Link style={{ textDecoration: "underline" }} to={url}>
          Space Comment.
        </Link>
      ) : (
        "Comment."
      )}
    </span>
  );
};

const FileReferences = ({
  file,
  references,
}: {
  file: FileEntity;
  references: FileReferences;
}) => {
  const total =
    references[COLLECTION_ID.TRACK].length +
    references[COLLECTION_ID.SPACE].length +
    references[COLLECTION_ID.COMMENT].length;

  if (total < 1) {
    return (
      <div>This File is not currently being used in any Highnote entities.</div>
    );
  }

  return (
    <div>
      This file is currently being used{" "}
      {references[COLLECTION_ID.TRACK].map((track) => (
        <TrackFileReference file={file} key={track.id} track={track} />
      ))}
      {references[COLLECTION_ID.SPACE].map((space) => (
        <SpaceFileReference file={file} key={space.id} space={space} />
      ))}
      {references[COLLECTION_ID.COMMENT].map((comment) => (
        <CommentFileReference file={file} key={comment.id} comment={comment} />
      ))}
    </div>
  );
};

export const ManageStorage = () => {
  const { toasted } = useToast();
  const [loading, setLoading] = useState<boolean>(true);
  const [renderTrigger, setRenderTrigger] = useState<string>();
  const [files, setFiles] = useState<FileEntity[]>([]);
  const { confirm, renderConfirmation } = useConfirmation();

  useEffect(() => {
    let unmounted: boolean;
    highnote.getStorageFiles().then((files) => {
      if (unmounted) return;
      setFiles(files);
      setLoading(false);
    });

    return () => {
      unmounted = true;
    };
  }, [renderTrigger]);

  const deleteFile = useCallback(async (file: FileEntity) => {
    const references = await highnote.getFileReferences({
      id: file.id,
    });

    await confirm({
      title: `Delete ${file.fileName}?`,
      body: (
        <p>
          <FileReferences file={file} references={references} />
          <br />
          <br />
          <strong>
            Are you sure you want to permanently delete this file?
          </strong>{" "}
          Deleting this file may cause related items to break.
        </p>
      ),
    });

    toasted({
      promise: highnote
        .deleteStorageFile({
          storagePath: file.storagePath,
        })
        .then(() => {
          setRenderTrigger(uuidv4());
        }),
      createMessage: `Deleting ${file.fileName}...`,
      successMessage: `${file.fileName} permanently deleted.`,
      errorMessage: `Could not delete ${file.fileName}. Please try again.`,
    });
  }, []);

  const rows = useMemo(() => {
    return files
      .sort((a, b) => b.size - a.size)
      .map((entity) => {
        const fileName = entity.fileName || "Untitled";
        const cleanEntity = { ...entity, fileName };
        return {
          id: entity.id,
          entity: cleanEntity,
          type: ENTITY_TYPE.FILE,
          actions: [
            {
              name: "Delete File",
              icon: <DeleteSVG />,
              warn: true,
              onClick: async () => {
                deleteFile(cleanEntity);
              },
            },
          ],
        };
      });
  }, [files, deleteFile]);

  if (loading) {
    return (
      <>
        {Array(5)
          .fill(null)
          .map((_, i) => {
            return (
              <div key={i} className="skeleton">
                <div className="skeleton-box" />
                <ul className="skeleon-list-wrapper">
                  <div className="skeleton-box" />
                  <div className="skeleton-box" />
                </ul>
                <div className="skeleton-box skeleton-timestamp" />
              </div>
            );
          })}
      </>
    );
  }

  return (
    <>
      {renderConfirmation}
      {rows.length > 0 ? (
        <EntityTable rows={rows} columns={defaultColumns} />
      ) : (
        <p>No files found.</p>
      )}
    </>
  );
};
