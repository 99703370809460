import "./PlanLimits.scss";
import React, { createContext, useContext, useMemo, useState } from "react";
import { Button, BUTTON_THEME } from "App/core/Button";
import { DialogButtons, Dialog, DialogSection } from "../Dialog";
import { useAuth } from "App/components/Auth";
import {
  USAGE_EDITOR_TAB,
  USAGE_EDITOR_TAB_KEY,
} from "App/components/Usages/UsageEditor";
import { Link } from "react-router-dom";
import { parseSubscription } from "@highnote/server/src/core/shared-util";
import { PLANS } from "App/components/Plans/config";
import { PlanPicker } from "App/components/Plans/Plans";

export enum LIMIT_TYPE {
  STORAGE = "storage",
  SPACES = "spaces",
}

const limitTypeVerbMap = {
  [LIMIT_TYPE.STORAGE]: "Store",
  [LIMIT_TYPE.SPACES]: "Share",
};

type PlanLimitsContextValue = {
  title: string;
  subtitle: string;
  hasReachedStorageLimit: boolean;
  hasReachedSpaceLimit: boolean;
  showPlanLimitsDialog: (type?: LIMIT_TYPE) => void;
  showPlanPickerDialog: () => void;
};

const PlanLimitsContext = createContext<PlanLimitsContextValue>({
  title: "",
  subtitle: "",
  hasReachedStorageLimit: false,
  hasReachedSpaceLimit: false,
  showPlanLimitsDialog: () => {},
  showPlanPickerDialog: () => {},
});

export const PlanLimitsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [limitTypeToShow, setLimitTypeToShow] = useState<LIMIT_TYPE>();
  const [isPlanPickerOpen, setPlanPickerOpen] = useState<boolean>(false);
  const { storageLimit, activeSpacesLimit, user } = useAuth();
  const { tier } = parseSubscription(user?.subscriptions?.active);
  const plan = PLANS.find((p) => p.tier === tier);

  const value = useMemo(() => {
    const { storageUsed, activeSpacesUsed } = user || {
      storageUsed: 0,
      spacesUsed: 0,
    };

    const hasReachedStorageLimit = storageUsed >= storageLimit;
    const hasReachedSpaceLimit = activeSpacesUsed >= activeSpacesLimit;

    return {
      title: "",
      subtitle: "",
      hasReachedStorageLimit,
      hasReachedSpaceLimit,
      showPlanLimitsDialog: (typeToShow: LIMIT_TYPE) => {
        setLimitTypeToShow(typeToShow || undefined);
        setDialogOpen(true);
      },
      showPlanPickerDialog: () => setPlanPickerOpen(true),
    };
  }, [user, storageLimit, activeSpacesLimit, plan?.name]);

  let limitType = value.hasReachedSpaceLimit
    ? LIMIT_TYPE.SPACES
    : LIMIT_TYPE.STORAGE;

  if (limitTypeToShow) limitType = limitTypeToShow;

  value.title = `${limitTypeVerbMap[limitType]} even more ${
    limitType[0]
  }${limitType.slice(1)}`;
  value.subtitle = `Upgrade your ${plan.name} plan to ${
    limitType === LIMIT_TYPE.SPACES
      ? "enable more shared Spaces"
      : "unlock more storage"
  }.`;

  return (
    <PlanLimitsContext.Provider value={value}>
      {children}

      <Dialog
        className="PlanPicker-dialog"
        title={"Upgrade Now"}
        open={isPlanPickerOpen}
        onClose={() => setPlanPickerOpen(false)}
      >
        <PlanPicker />
      </Dialog>

      <Dialog
        className="PlanLimits-dialog"
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <div className="PlanLimits-dialog-hero" />
        <DialogSection>
          <p className="heading">
            <strong>{value.title}</strong>
          </p>
          <p>{value.subtitle}</p>
        </DialogSection>
        <DialogButtons>
          <Button
            theme={BUTTON_THEME.CTA}
            onClick={() => {
              setPlanPickerOpen(true);
              setDialogOpen(false);
            }}
          >
            Upgrade Now
          </Button>
          {limitType === LIMIT_TYPE.STORAGE && (
            <Link
              to={`${location.pathname}?${USAGE_EDITOR_TAB_KEY}=${USAGE_EDITOR_TAB.STORAGE}`}
            >
              <Button
                theme={BUTTON_THEME.SECONDARY}
                onClick={() => {
                  setDialogOpen(false);
                }}
              >
                Manage Storage
              </Button>
            </Link>
          )}
          {limitType === LIMIT_TYPE.SPACES && (
            <Link
              to={`${location.pathname}?${USAGE_EDITOR_TAB_KEY}=${USAGE_EDITOR_TAB.SPACES}`}
            >
              <Button
                theme={BUTTON_THEME.SECONDARY}
                onClick={() => {
                  setDialogOpen(false);
                }}
              >
                Manage Spaces
              </Button>
            </Link>
          )}
        </DialogButtons>
      </Dialog>
    </PlanLimitsContext.Provider>
  );
};

export const usePlanLimitsContext = () => useContext(PlanLimitsContext);
