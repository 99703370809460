import "./CommentMeta.scss";
import React, { useMemo } from "react";
import { Button, BUTTON_THEME } from "../../core/Button";
import { ReactComponent as CheckSVG } from "App/common/icons/check.svg";
import {
  formatDuration,
  PERMISSION,
} from "@highnote/server/src/core/shared-util";
import {
  ConnectedUserAvatar,
  ConnectedUserName,
  USER_AVATAR_SIZE,
} from "../UserAvatar/UserAvatar";
import moment from "moment";
import { CommentActions, DEFAULT_COMMENT_ACTION } from "./CommentActions";
import { COMMENT_BLOCKS } from "./blocks";
import { getValidCommentBlock, useCommentContext } from "../useComment";
import { StatusIndicator } from "../StatusIndicator";
import { useCarouselSync } from "App/routes/Main/Space/Carousel/useCarouselSync";
import { useAuth } from "App/components/Auth";
import { useSpaceContext } from "../useSpace";

export enum COMMENT_META_VARIANT {
  DEFAULT = "default",
  INLINE = "inline",
}

export const CommentMeta = ({
  variant,
}: {
  variant?: COMMENT_META_VARIANT;
}) => {
  const { isAllowed } = useAuth();
  const { space } = useSpaceContext();
  const { isPublished, comment, saveComment } = useCommentContext();
  const sync = useCarouselSync();
  const blockData = getValidCommentBlock(comment);

  const createdAtStr = moment(comment?.createdAt).fromNow();
  const canResolve = isAllowed(PERMISSION.TO_RESOLVE_COMMENT_IN_SPACE, {
    space,
  });
  const isResolvable =
    !comment?.parentComment && !!comment?.trackId && canResolve;

  const allActions = useMemo(
    () => [
      ...(isResolvable ? [DEFAULT_COMMENT_ACTION.TOGGLE_RESOLVE] : []),
      DEFAULT_COMMENT_ACTION.EDIT,
      DEFAULT_COMMENT_ACTION.COPY,
      DEFAULT_COMMENT_ACTION.DELETE,
    ],
    [isResolvable],
  );

  if (!blockData) return null;

  const blockType = blockData.type as keyof typeof COMMENT_BLOCKS;
  const blockConfig = COMMENT_BLOCKS[blockType];

  if (!blockConfig) {
    console.log(
      "[CommentMeta] Could not find matching block config:",
      blockType,
    );
    return null;
  }

  if (!blockConfig.Component || !blockConfig.Icon) {
    return null;
  }

  return (
    <div className="highnote-comment-meta" data-variant={variant}>
      {variant !== COMMENT_META_VARIANT.INLINE && (
        <>
          <ConnectedUserAvatar
            userId={comment.createdBy}
            size={USER_AVATAR_SIZE.MEDIUM}
          />
          <div className="user-name">
            <ConnectedUserName userId={comment.createdBy} />
            <div className="created-at">{createdAtStr}</div>
          </div>
        </>
      )}

      <div className="tools">
        {comment.timestamp !== undefined && (
          <>
            {comment.isResolved ? <CheckSVG /> : <blockConfig.Icon />}
            <Button
              theme={BUTTON_THEME.PLAIN}
              className="timestamp"
              onClick={() => {
                sync.onCardIdClick(comment.id);
              }}
            >
              {formatDuration(comment.timestamp)}
            </Button>
          </>
        )}

        <StatusIndicator
          isLoading={comment.isSaving}
          errorMessage={comment.updateError}
          retryCallback={!isPublished ? saveComment : undefined}
        />

        {isPublished && !comment.isSaving && (
          <CommentActions actions={allActions} />
        )}
      </div>
    </div>
  );
};
