import React from "react";
import { Dialog, DialogButtons, DialogSection } from "App/common/Dialog";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import "./PrivateInboxIntroDialog.scss";
import classNames from "classnames";
import {
  ConnectedUserAvatar,
  ConnectedUserName,
  USER_AVATAR_SIZE,
} from "App/common/UserAvatar/UserAvatar";
import { Badge } from "App/common/Badge/Badge";
import { FileSelect } from "App/common/FileSelect";

interface PrivateInboxVisitDialogProps {
  onClose?: () => void;
  isOpen: boolean;
  spaceOwner: string;
  onUploadFiles: (files: File[]) => void;
}

export const PrivateInboxVisitDialog = ({
  isOpen,
  onClose,
  spaceOwner,
  onUploadFiles,
}: PrivateInboxVisitDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      className={classNames("PrivateInboxIntro-dialog")}
      onClose={onClose}
    >
      <DialogSection className={classNames("PrivateInboxIntro-dialog-content")}>
        <ConnectedUserAvatar
          userId={spaceOwner}
          size={USER_AVATAR_SIZE.XXXLARGE}
        />
        <Badge>Private Inbox</Badge>
        <div
          className={classNames("PrivateInboxIntro-dialog-content-description")}
        >
          <h3>
            Send files to <ConnectedUserName userId={spaceOwner} />
          </h3>
          <p>
            <ConnectedUserName userId={spaceOwner} /> is using Highnote to
            accept files securely.
          </p>
        </div>
      </DialogSection>
      <DialogButtons>
        <FileSelect fullWidth allowMultiple onSelect={onUploadFiles}>
          <Button size={BUTTON_SIZE.MEDIUM} theme={BUTTON_THEME.CTA}>
            Upload Files
          </Button>
        </FileSelect>
      </DialogButtons>
    </Dialog>
  );
};
