export const FUNCTION_ID = {
  REFRESH_DROPBOX_TOKEN: "refreshDropboxToken",
  CLONE_DROPBOX_ENTITITES: "cloneDropboxEntities",
  HANDLE_DROPBOX_JOB: "handleDropboxJob",

  ASSOCIATE_OAUTH_ACCOUNT: "associateOAuthAccount",

  CHECK_PROTECTED_ENTITY: "checkProtectedEntity",
  JOIN_PROTECTED_ENTITY: "joinProtectedEntity",

  CREATE_SPACE: "createSpace",
  UPDATE_SPACE: "updateSpace",
  DELETE_SPACE: "deleteSpace",
  CLONE_SPACE: "cloneSpace",

  CREATE_TRACK: "createTrack",
  UPDATE_TRACK: "updateTrack",
  DELETE_TRACK: "deleteTrack",
  GET_TRACK: "getTrack",

  CREATE_OR_UPDATE_FILE: "createOrUpdateFile",
  PROCESS_AUDIO_FILE: "processAudioFile",
  GENERATE_DOWNLOAD_LINK: "generateDownloadLink",
  ACKNOWLEDGE_DOWNLOAD_REQUEST: "acknowledgeDownloadRequest",
  GET_FILES: "getFiles",
  GET_STORAGE_FILES: "getStorageFiles",
  DELETE_STORAGE_FILE: "deleteStorageFile",
  GET_FILE_REFERENCES: "getFileReferences",

  GET_COMMENT: "getComment",
  CREATE_COMMENT: "createComment",
  UPDATE_COMMENT: "updateComment",
  DELETE_COMMENT: "deleteComment",

  GET_KNOCK_AUTH_TOKEN: "getKnockAuthToken",

  GET_FILE_URL: "getFileUrl",
  ON_ENTITY_WRITE: "onEntityWrite",
  ON_FILE_UPLOAD: "onFileUpload",
  NOTIFY: "notify",

  UPDATE_USER: "updateUser",
  UPDATE_AUTH0_USER: "updateAuth0User",
  VERIFY_AUTH0_EMAIL: "verifyAuth0Email",
  RESET_AUTH0_PASSWORD: "resetAuth0Password",

  UPDATE_USER_SUBSCRIPTION: "payments_updateUserSubscription",
  GET_PRICE: "payments_getPrice",
  GET_PAYMENT_PORTAL_URL: "payments_getCustomerPortalUrl",
  GET_CHECKOUT_URL: "payments_getCheckoutUrl",

  AUTHENTICATE_USER: "authenticateUser",
  SEND_VERIFICATION_CODE: "sendVerificationCode",
  GET_USER_PUBLIC: "getUserPublic",
  GET_USERS_PUBLIC: "getUsersPublic",
  SEARCH_USERS: "searchUsers",
  UNLINK_ACCOUNT: "unlinkAccount",

  API_GET_SPACE: "api_getSpace",
  API_GET_TRACK: "api_getTrack",
  API_RESET_TEST_DATA: "api_resetTestData",

  WEBHOOK_WELCOME_SURVEY: "webhook_welcomeSurvey",
  WEBHOOK_STRIPE: "webhook_stripe",
  WEBHOOK_KNOCK: "webhook_knock",
};

export const MIN_FIREBASE_INSTANCES =
  process.env.DEPLOY_ENV === "production" ? 2 : 0;
