import { withMobileDeeplinkRoot } from "App/modules/urls";
import { useUrlContext } from "./useUrlContext";
import { useCallback } from "react";
import { TAB_ID } from "../Settings";

export enum ValidMobileWebAppComponents {
  ShareDialogMembers = "shareDialogMembers",
  ShareDialogLink = "shareDialogLink",
  MoveToSpaceDialog = "moveToSpaceDialog",
  AddToSpaceDialog = "addToSpaceDialog",
}

export const getValidMobileWebAppComponent = (
  value: string | null,
  isFromMobileApp: boolean,
) => {
  if (
    isFromMobileApp &&
    Object.values(ValidMobileWebAppComponents).includes(
      value as ValidMobileWebAppComponents,
    )
  ) {
    return value as ValidMobileWebAppComponents;
  }

  return undefined;
};

const getValidAccountSettingsTab = (value: string | null) => {
  if (Object.values(TAB_ID).includes(value as TAB_ID)) {
    return value as TAB_ID;
  }

  return undefined;
};

export const useMobileAppParams = () => {
  /**
   * Query params that will trigger the authentication flow:
   * - `mobileToken`
   * - `fromMobileApp`: via `FROM_MOBILE_APP_FLAG`
   * - `webAppComponent: ActiveMobileWebAppComponents` => ***IF THIS PARAM IS NOT PASSED, APP SHOULD REDIRECT TO MOBILE ONCE AUTHENTICATED, if fromMobileApp flag is true***
   * - `returnTo`: Mobile app will control where to redirect to
   * - `returnToOnError`: Mobile app will control where to redirect to on error
   * ie. `/root/<space>/<trackId>?shareKey=<...>&fromMobileApp=true&mobileToken=<...>&webAppComponent=shareDialogMembers&returnTo=<...>&returnToOnError=<...>`
   */
  const {
    mobileToken,
    fromMobileApp,
    returnTo,
    returnToOnError,
    webAppComponent,
    settingsTabId,
  } = useUrlContext();

  const validMobileWebAppComponent = getValidMobileWebAppComponent(
    webAppComponent,
    // We should only consider the webAppComponent if we are coming from the mobile app
    Boolean(mobileToken && fromMobileApp),
  );

  const showShareDialog =
    validMobileWebAppComponent ===
      ValidMobileWebAppComponents.ShareDialogLink ||
    validMobileWebAppComponent ===
      ValidMobileWebAppComponents.ShareDialogMembers;

  const isAuthorizedMobileComponent =
    Boolean(
      getValidAccountSettingsTab(settingsTabId) || validMobileWebAppComponent,
    ) && Boolean(fromMobileApp && mobileToken);

  const redirectToMobileApp = useCallback(
    (path?: string) => {
      if (fromMobileApp) {
        window.location.href = withMobileDeeplinkRoot(
          path || returnTo || returnToOnError,
        );
      }
    },
    [fromMobileApp, returnTo],
  );

  return {
    validMobileWebAppComponent,
    showShareDialog,
    showAddToSpaceDialog:
      validMobileWebAppComponent ===
      ValidMobileWebAppComponents.AddToSpaceDialog,
    showMoveToSpaceDialog:
      validMobileWebAppComponent ===
      ValidMobileWebAppComponents.MoveToSpaceDialog,
    mobileToken: fromMobileApp ? mobileToken : undefined,
    isAuthorizedMobileComponent,
    redirectToMobileApp,
    returnToOnError,
  };
};
