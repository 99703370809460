import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { ImageWithFallback } from "App/common/ImageWithFallback";
import { TextInput } from "App/common/TextInput";
import { ReactComponent as IconDefaultTrack } from "App/common/icons/default-track.svg";
import { ReactComponent as LogoFullSVG } from "App/common/icons/logo-full.svg";
import { getAuthUrl, useAuth } from "App/components/Auth";
import { BUTTON_THEME, Button } from "App/core/Button";

import styles from "./RequestSpaceAccess.module.scss";
import classNames from "classnames";
import { highnote } from "@highnote/server/src/sdk";
import { KNOCK_WORKFLOW } from "@highnote/server/src/core/entities";
import { useUrlContext } from "../useUrlContext";

export const RequestSpaceAccess = () => {
  const { spaceId: spaceIdViaUrl } = useUrlContext();
  const { user } = useAuth();
  const [requestSent, setRequestSent] = useState(false);
  const [displayName, setDisplayName] = useState<string>("");
  const [submittingRequest, setSubmittingRequest] = useState(false);
  const displayNameInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (displayNameInputRef.current) {
      displayNameInputRef.current.focus();
    }
  }, []);

  const handleSubmit = async () => {
    setSubmittingRequest(true);
    try {
      // If user exists, then we want to ensure identifier is set to either phone or email
      // User dictates notification flow for anon vs logged in users.
      await highnote.notify({
        workflowId: KNOCK_WORKFLOW.SPACE_MEMBER_REQUESTED,
        data: {
          displayName: displayName || user.name || "Someone",
          identifier:
            user?.connection === "sms" ? user.phone : user?.email || undefined,
          spaceId: spaceIdViaUrl,
        },
      });

      setRequestSent(true);
    } catch (error) {
      console.error("Failed to send request", error);
    } finally {
      setSubmittingRequest(false);
    }
  };

  return (
    <div className={styles.container} data-cypress-id="access-request-form">
      <div className={styles["request-space-content"]}>
        <a href="https://highnote.fm" className={styles["logo-container"]}>
          <LogoFullSVG />
        </a>

        <div className={styles["request-space-content-artwork"]}>
          <ImageWithFallback
            src="/public/default-track-artwork.png"
            fallback={<IconDefaultTrack />}
          />
        </div>
        {requestSent ? (
          <>
            <div className={styles["request-space-content-section"]}>
              <h2 className={styles["request-space-content-section-header"]}>
                Request Sent
              </h2>
              <p
                className={styles["request-space-content-section-description"]}
              >
                We sent your request to the space owner.
                <br />
                Reach out to them for further details.
              </p>
            </div>
          </>
        ) : (
          <>
            <div className={styles["request-space-content-section"]}>
              <p
                className={styles["request-space-content-section-description"]}
              >
                {user
                  ? `You aren’t a member of this space. Confirm your account details
                to request access.`
                  : `You aren’t a member of this space. Enter your name to request a
                link. No account required.`}
              </p>
            </div>
            <div
              className={classNames(
                styles["request-space-content-section"],
                styles["no-margin"],
              )}
            >
              <form>
                {user ? (
                  <>
                    <TextInput
                      placeholder="Display Name"
                      type="text"
                      value={user.name || "Phone User"}
                      isSingleLine
                      isReadOnly
                    />
                    <div className={styles["request-flow-contact-info"]}>
                      <TextInput
                        placeholder="Contact Info"
                        type={user.connection === "sms" ? "tel" : "email"}
                        value={
                          user.connection === "sms" ? user.phone : user.email
                        }
                        isSingleLine
                        isReadOnly
                      />
                    </div>
                  </>
                ) : (
                  <TextInput
                    type="text"
                    placeholder="Display Name"
                    value={displayName}
                    onChange={setDisplayName}
                    onSubmit={handleSubmit}
                    inputRef={displayNameInputRef}
                    isSingleLine
                    autoFocus
                    required
                  />
                )}
                <Button
                  loading={submittingRequest}
                  type="button"
                  className={styles["request-space-content-section-submit"]}
                  theme={BUTTON_THEME.CTA}
                  disabled={user ? false : !displayName}
                  onClick={handleSubmit}
                >
                  Request Link
                </Button>
              </form>
            </div>
            <div
              className={classNames(
                styles["request-space-content-section"],
                styles["request-space-content-section-footnote"],
              )}
            >
              {user ? (
                <>
                  Not you? <Link to={getAuthUrl()}>Switch accounts</Link>
                </>
              ) : (
                <>
                  or, <Link to={getAuthUrl()}>login to Highnote</Link>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
