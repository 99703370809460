import "./SpaceSettings.scss";
import React, { useEffect, useState } from "react";
import { useAuth } from "../Auth";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { DialogSection } from "App/common/Dialog";
import { useTheme } from "App/common/ThemeProvider";
import { Switch } from "App/core/Switch";
import { PermissionTooltip } from "App/components/PermissionTooltip";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import {
  CONTROLLED_SHARE_FEATURES,
  ControlledFeatureCallout,
  ControlledFeatureTooltipLabel,
} from "App/core/ControlledFeatureCallout";
import { List, ListItemButton } from "App/common/List";
import { highnote } from "@highnote/server/src/sdk";
import { useToast } from "App/common/useToast";
import { usePlanLimitsContext } from "App/common/PlanLimits/usePlanLimits";
import { useSpaceRaw } from "../useEntities";
import { SUBSCRIPTION_TIER_FORMATTED } from "@highnote/server/src/core/entities";

export const SpaceSettings = () => {
  const { showPlanPickerDialog } = usePlanLimitsContext();
  const { addErrorMessage } = useToast();
  const { user, isAllowed } = useAuth();
  const { entity: space, loading: isSpaceLoading } = useSpaceRaw();
  const { theme } = useTheme();
  const [isLockedOptimistic, setIsLockedOptimistic] = useState(
    Boolean(space?.audioQualityLocked),
  );

  useEffect(() => {
    if (!isSpaceLoading && space) {
      setIsLockedOptimistic(space.audioQualityLocked);
    }
  }, [space, isSpaceLoading]);

  const handleToggle = async (nowLocked: boolean) => {
    try {
      setIsLockedOptimistic(nowLocked);
      await highnote.updateSpace({
        id: space.id,
        data: {
          audioQualityLocked: nowLocked,
        },
      });
    } catch (err) {
      setIsLockedOptimistic(!nowLocked);
      addErrorMessage(
        "Could not lock audio quality for the space. Please try again.",
      );
    }
  };

  const isSpaceOwner = user && space && user.id === space.createdBy;
  const canManageSpace =
    Boolean(space) && isAllowed(PERMISSION.TO_MANAGE_SPACE, { space });
  const canToggleAudioQualityLock =
    Boolean(space) && isAllowed(PERMISSION.TO_LOCK_AUDIO_QUALITY, { space });
  const isDisabled = !canToggleAudioQualityLock || isSpaceLoading;

  return (
    <ErrorBoundary name="SpaceSettings">
      {isSpaceOwner && (
        <ControlledFeatureCallout
          className="highnote-space-editor-callout"
          featuresStatus={{
            [CONTROLLED_SHARE_FEATURES.AUDIO_QUALITY_LOCK]: {
              enabled: canToggleAudioQualityLock,
            },
          }}
          upgradeSuffix={`to Highnote ${SUBSCRIPTION_TIER_FORMATTED.STUDIO} for`}
          onUpgrade={showPlanPickerDialog}
        />
      )}
      <DialogSection
        className="highnote-space-editor-settings"
        data-theme={theme}
      >
        <List>
          <ListItemButton
            className="highnote-space-editor-listbutton"
            disabled={isDisabled}
            onClick={() => {
              handleToggle(!isLockedOptimistic);
            }}
          >
            <div
              data-disabled={`${!canToggleAudioQualityLock ? "true" : "false"}`}
              className="highnote-space-editor-settings-inner"
            >
              <h3>Audio Quality Lock</h3>
              <p>
                Lock audio at Original Quality in this space, regardless of
                listeners&apos; connection speed
              </p>
            </div>
            <PermissionTooltip
              title={
                isSpaceOwner
                  ? ControlledFeatureTooltipLabel[
                      CONTROLLED_SHARE_FEATURES.AUDIO_QUALITY_LOCK
                    ]
                  : canManageSpace
                    ? "Contact the space owner."
                    : undefined
              }
              hasPermission={canToggleAudioQualityLock}
            >
              <div>
                <Switch disabled={isDisabled} checked={isLockedOptimistic} />
              </div>
            </PermissionTooltip>
          </ListItemButton>
        </List>
      </DialogSection>
    </ErrorBoundary>
  );
};
