import React, { createContext, useContext, useEffect, useState } from "react";

const ViewportContext = createContext({
  vw: 0,
  vh: 0,
});

export const ViewportContextProvider = ({ children }) => {
  const [vh, setVh] = useState();
  const [vw, setVw] = useState();

  useEffect(() => {
    let unmounted;

    const onResize = () => {
      if (unmounted) return;
      const newVh = window.innerHeight;
      const newVw = window.innerWidth;

      if (newVh !== vh) setVh(window.innerHeight);
      if (newVw !== vw) setVw(window.innerWidth);
    };

    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      unmounted = true;
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <ViewportContext.Provider
      value={{
        vw,
        vh,
      }}
    >
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewport = () => useContext(ViewportContext);
