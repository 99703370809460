import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { routePaths } from "App/modules/urls";
import { Library } from "./Library";
import Space from "./Space";
import { SpaceContextProvider, useSpaceContext } from "App/common/useSpace";
import { Layout } from "App/components/Layout";
import { Settings } from "../Settings";
import { EntityNotFound } from "./EntityNotFound";
import { getAuthUrl, useAuth } from "App/components/Auth";

import { NoSpaces } from "./Space/NoSpaces";
import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";
import { FileUploadsContextProvider } from "App/components/useFiles";
import { useHighnote } from "App/common/useHighnote";
import { useUrlContext } from "App/routes/Main/useUrlContext";
import { useViewport } from "App/common/useViewport";
import { AuthRedirectRequired } from "App/modules/Auth/AuthRedirectRequired";
import { GlobalSidebar } from "App/components/Sidebar/GlobalSidebar";
import { NotificationsProvider } from "App/common/useNotifications";
import { SpaceEditorProvider } from "App/components/useSpaceEditor";
import { UsageEditorProvider } from "App/components/Usages/UsageEditor";
import { TopLevelSpacesContextProvider } from "App/components/useEntities/useTopLevelSpaces";
import { GlobalSpacesContextProvider } from "App/store/spaces/useGlobalSpaces";
import { GlobalTracksContextProvider } from "App/store/tracks/useGlobalTracks";
import { MoveToSpaceProvider } from "App/components/useEntities/useLibraryTracks/useMoveToSpace";
import { AddToSpaceProvider } from "App/components/useEntities/useLibraryTracks/useAddToSpace";
import { DownloadRequestPage } from "./DownloadRequest";
import {
  APP_FEATURES,
  AppFeaturesStatus,
} from "@highnote/server/src/core/features";
import { DropboxClone } from "../Dropbox/Clone";
import { DropboxLogin } from "../Dropbox/Login";
import { DropboxCallback } from "../Dropbox/Callback";
import { GlobalAudioPlayerContextProvider } from "App/components/GlobalAudioPlayer";

const SpaceRedirect = () => {
  const { spaceId, trackId } = useUrlContext();
  const history = useHistory();
  const { space } = useSpaceContext();
  const { user: currentUser } = useAuth();

  useEffect(() => {
    if (!space || space?.id !== spaceId) return;
    if (trackId) return;
    history.push(`/space/${spaceId}${location.search}`);
  }, [trackId, space]);

  const render = useMemo(() => {
    if (spaceId) {
      return <Space />;
    }

    if (!currentUser) {
      return <Redirect to={getAuthUrl()} />;
    }

    return <NoSpaces />;
  }, [spaceId, currentUser]);

  return render;
};

// Only allow one automatically created space per session.
let autocreatedSpaceId: string;

const CreateSpace = () => {
  const { createSpace } = useHighnote();
  const history = useHistory();
  const { user } = useAuth();
  const isLoggedIn = !!user;

  useEffect(() => {
    if (!user) return;
    if (autocreatedSpaceId) return;

    createSpace().then((space) => {
      autocreatedSpaceId = space.id;
      history.push(`/space/${space.id}`);
    });
  }, [user]);

  if (!isLoggedIn || autocreatedSpaceId) {
    return <Redirect to={getAuthUrl()} />;
  }

  return <LoadingScreen />;
};

const HighnoteCore = () => {
  return (
    <Switch>
      <Route path={routePaths.space}>
        <SpaceRedirect />
      </Route>
      <Redirect
        exact
        path={routePaths.collectionRoot}
        to={{
          pathname: routePaths.spaceRoot,
          search: window.location.search,
        }}
      />
      <Redirect
        path={routePaths.collectionHome}
        to={{
          pathname: `${routePaths.spaceRoot}/:collectionId`,
          search: window.location.search,
        }}
      />
      <Route path={routePaths.library}>
        <Library />
      </Route>
      <Route path={routePaths.download}>
        <DownloadRequestPage />
      </Route>
      {AppFeaturesStatus[APP_FEATURES.DROPBOX_EXTENSION] && (
        <>
          <Route path={routePaths.dropboxLogin}>
            <AuthRedirectRequired>
              <DropboxLogin />
            </AuthRedirectRequired>
          </Route>
          <Route path={routePaths.dropboxCallback}>
            <AuthRedirectRequired>
              <DropboxCallback />
            </AuthRedirectRequired>
          </Route>
          <Route path={routePaths.dropboxClone}>
            <AuthRedirectRequired>
              <DropboxClone />
            </AuthRedirectRequired>
          </Route>
        </>
      )}
      <Route render={() => <EntityNotFound entityType="page" />} />
    </Switch>
  );
};

const HighnoteRoot = () => {
  const { vw } = useViewport();
  const isMobile = vw < 450;
  if (isMobile) {
    return (
      <AuthRedirectRequired>
        <GlobalSidebar />
      </AuthRedirectRequired>
    );
  } else {
    return <Redirect to={`/library`} />;
  }
};

const Main = () => {
  return (
    <>
      <Helmet>
        <title>Highnote | Gather feedback on your tracks</title>
      </Helmet>
      <FileUploadsContextProvider>
        <TopLevelSpacesContextProvider>
          <GlobalSpacesContextProvider>
            <GlobalTracksContextProvider>
              <MoveToSpaceProvider>
                <AddToSpaceProvider>
                  <SpaceContextProvider>
                    <SpaceEditorProvider>
                      <NotificationsProvider>
                        <UsageEditorProvider>
                          <GlobalAudioPlayerContextProvider>
                            <Layout>
                              <Switch>
                                <Route
                                  path={routePaths.createSpace}
                                  component={CreateSpace}
                                />
                                <Route
                                  path={routePaths.settings}
                                  component={Settings}
                                />
                                <Route path={routePaths.root} exact>
                                  <HighnoteRoot />
                                </Route>
                                <Route path={routePaths.root}>
                                  <HighnoteCore />
                                </Route>
                              </Switch>
                            </Layout>
                          </GlobalAudioPlayerContextProvider>
                        </UsageEditorProvider>
                      </NotificationsProvider>
                    </SpaceEditorProvider>
                  </SpaceContextProvider>
                </AddToSpaceProvider>
              </MoveToSpaceProvider>
            </GlobalTracksContextProvider>
          </GlobalSpacesContextProvider>
        </TopLevelSpacesContextProvider>
      </FileUploadsContextProvider>
    </>
  );
};

export default Main;
