import "./TrackVersionTabs.scss";
import _ from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { useTrack } from "App/components/useEntities/useTrack";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { ReactComponent as CloseSVG } from "App/common/icons/close.svg";
import { PREVIEW_ICON_SIZE, PreviewIcon } from "App/common/PreviewIcon";
import { PreviewText } from "@highnote/preview-text/src";
import {
  MAX_PINNED_VERSIONS,
  PERMISSION,
} from "@highnote/server/src/core/shared-util";
import { FileEntity } from "@highnote/server/src/core/entities";
import { AddVersionButton } from "App/components/TrackEditor/TrackVersions/AddVersionButton";
import { FileUpload, UPLOAD_GROUP, useFiles } from "App/components/useFiles";
import { ReactComponent as HorizontalEllipsisSVG } from "App/common/icons/ellipsis-horizontal.svg";
import { ReactComponent as PinSVG } from "App/common/icons/pin.svg";
import { ReactComponent as PlusSVG } from "App/common/icons/plus.svg";
import { useTrackEditor } from "App/components/useTrackEditor";
import { TRACK_EDITOR_TAB } from "App/components/TrackEditor";
import { useAuth } from "App/components/Auth";
import { useSpaceContext } from "App/common/useSpace";
import {
  DEFAULT_TRACK_VERSION_ACTIONS,
  TRACK_VERSION_ACTIONS,
  TrackVersionActions,
} from "App/components/TrackEditor/TrackVersions/TrackVersionActions";
import { TrackVersionIcon } from "App/components/TrackEditor/TrackVersions/TrackVersionIcon";
import { DEFAULT_VERSION_COLOR_ID } from "App/components/TrackEditor/TrackVersions";
import { MenuItem } from "App/common/Menu";
import { ProgressBar } from "App/common/ProgressBar";
import { TrackVersionStar } from "App/components/TrackEditor/TrackVersions/TrackVersionStar";

enum TAB_TYPE {
  VERSION_TAB = "VERSION",
  UPLOAD_TAB = "UPLOAD",
}

const UploadProgressLabel = ({ upload }: { upload: FileUpload }) => {
  return (
    <div className="UploadProgressLabel">
      <div className="info">
        <PreviewText>{upload.file.fileName}</PreviewText>
        <ProgressBar progress={upload.progress} />
      </div>
      <Button
        size={BUTTON_SIZE.XSMALL}
        theme={BUTTON_THEME.ICON}
        className="cancel"
        type="button"
        onClick={upload.cancelUpload}
      >
        <CloseSVG />
      </Button>
    </div>
  );
};

const VersionLabel = ({ version }: { version: FileEntity }) => {
  const tabRef = useRef<HTMLDivElement>();
  const { currentTrackVersion } = useTrack();

  if (!version) {
    return <>Version not found.</>;
  }

  const isActive = currentTrackVersion?.id === version?.id;

  return (
    <div
      className="VersionLabel"
      ref={tabRef}
      data-version-color-id={DEFAULT_VERSION_COLOR_ID}
    >
      <PreviewIcon
        size={PREVIEW_ICON_SIZE.MEDIUM}
        fallback={<TrackVersionIcon version={version} />}
      />
      <div className="info">
        <PreviewText>{version.name}</PreviewText>
      </div>
      <TrackVersionStar version={version} />
      <TrackVersionActions
        actions={_.without(
          DEFAULT_TRACK_VERSION_ACTIONS,
          TRACK_VERSION_ACTIONS.REMOVE,
        )}
        version={version}
        menuProps={{
          className: `highnote-track-version-tab-menu ${
            isActive ? "active" : ""
          }`,
          anchorEl: tabRef.current,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }}
      />
    </div>
  );
};

export const TrackVersionTabs = () => {
  const {
    track,
    currentTrackVersion,
    setCurrentTrackVersion,
    trackVersions,
    pinnedTrackVersions,
    trackFilesLoading,
  } = useTrack();
  const { getUploadCache, getUploads } = useFiles();
  const uploadCache = getUploadCache(
    UPLOAD_GROUP.TRACK_VERSIONS_PINNED,
    track?.id,
  );
  const uploads = getUploads({ cache: uploadCache });
  // Leaving this so it's easy uncommenting for local testing
  // const uploads: FileUpload[] = [
  //   {
  //     file: {
  //       id: "123124",
  //       fileName: "adfadadfadfadfadfadfadsfadfaadflongadfadfadsff.awdf",
  //     },
  //     progress: 0.5,
  //     cancelUpload: () => {},
  //   },
  // ];
  const lastUpload = uploads[uploads.length - 1];
  const { openTrackEditor } = useTrackEditor();
  const { isAllowed } = useAuth();
  const { space } = useSpaceContext();
  const canAddToTrack = isAllowed(PERMISSION.TO_ADD_TO_TRACK_IN_SPACE, {
    space,
    track,
  });

  useEffect(() => {
    if (lastUpload?.file?.id) {
      setCurrentTrackVersion(lastUpload.file as FileEntity);
    }
  }, [lastUpload?.file?.id]);

  const pinnedVersionTabs = useMemo(() => {
    return pinnedTrackVersions.map((version) => {
      return {
        type: TAB_TYPE.VERSION_TAB,
        data: trackVersions.find((v) => v.id === version),
      };
    });
  }, [pinnedTrackVersions, trackVersions]);

  const uploadTabs = useMemo(() => {
    return uploads.map((upload) => {
      return {
        type: TAB_TYPE.UPLOAD_TAB,
        data: upload,
      };
    });
  }, [uploads]);

  const allTabs = useMemo(
    () => [...pinnedVersionTabs, ...uploadTabs],
    [uploadTabs, pinnedVersionTabs],
  );

  const excessTabs = trackVersions.length - pinnedTrackVersions.length;
  const showTabs = canAddToTrack || pinnedTrackVersions.length > 1;

  if (!showTabs) return null;
  if (trackFilesLoading) return null;

  return (
    <ErrorBoundary name="TrackVersionTabs">
      <div className="highnote-track-version-tabs">
        <div className="tabs">
          {new Array(MAX_PINNED_VERSIONS).fill("").map((_, i) => {
            const tab = allTabs[i];

            let tabId: string;
            if (tab?.type == TAB_TYPE.VERSION_TAB) {
              tabId = (tab.data as FileEntity)?.id;
            } else if (tab?.type == TAB_TYPE.UPLOAD_TAB) {
              tabId = (tab.data as FileUpload)?.file?.id;
            }

            const isActive = currentTrackVersion?.id === tabId;

            return (
              <div
                key={tabId || i}
                data-type={tab?.type || "ADD"}
                data-is-active={isActive}
                className="highnote-track-version-tab"
                role={tab ? "button" : ""}
                onClick={() => {
                  if (!tab) return;

                  if (tab.type == TAB_TYPE.VERSION_TAB) {
                    setCurrentTrackVersion(tab.data as FileEntity);
                  }

                  if (tab.type == TAB_TYPE.UPLOAD_TAB) {
                    setCurrentTrackVersion(
                      (tab.data as FileUpload).file as FileEntity,
                    );
                  }
                }}
              >
                {!tab && (
                  <>
                    <AddVersionButton
                      pinOnSave
                      className="add-version"
                      theme={BUTTON_THEME.SECONDARY}
                      size={BUTTON_SIZE.XSMALL}
                      additionalMenuOptions={
                        trackVersions.length > pinnedTrackVersions.length
                          ? [
                              // eslint-disable-next-line react/jsx-key
                              <MenuItem
                                onClick={() => {
                                  openTrackEditor({
                                    track,
                                    initialTab: TRACK_EDITOR_TAB.VERSIONS,
                                  });
                                }}
                              >
                                <PinSVG />
                                Pin existing version
                              </MenuItem>,
                            ]
                          : []
                      }
                      menuProps={{
                        className: "highnote-track-version-dropdown-menu",
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                      }}
                    >
                      <PlusSVG /> <span>Version</span>
                    </AddVersionButton>
                  </>
                )}

                {tab?.type == TAB_TYPE.VERSION_TAB && (
                  <VersionLabel key={i} version={tab.data as FileEntity} />
                )}

                {tab?.type == TAB_TYPE.UPLOAD_TAB && (
                  <UploadProgressLabel
                    key={i}
                    upload={tab.data as FileUpload}
                  />
                )}
              </div>
            );
          })}
        </div>

        {canAddToTrack && (
          <Button
            theme={BUTTON_THEME.PLAIN}
            onClick={() =>
              openTrackEditor({ track, initialTab: TRACK_EDITOR_TAB.VERSIONS })
            }
            className="highnote-track-version-tab manage-versions-tab"
          >
            <div className="inner">
              <HorizontalEllipsisSVG />
              {excessTabs > 0 && ` +${excessTabs}`}
            </div>
          </Button>
        )}
      </div>
    </ErrorBoundary>
  );
};
