import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { UploadTaskSnapshot } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { highnote } from "@highnote/server/src/sdk";
import {
  AUDIO_ACCEPT_TYPES_STRING,
  FileEntity,
} from "@highnote/server/src/core/entities";
import {
  AUDIO_UPLOAD_LIMIT_ERR_MESSAGE,
  AUDIO_UPLOAD_SIZE_LIMIT_IN_BYTES,
  getFileStoragePath,
} from "@highnote/server/src/core/shared-util";
import { getAuthId, useAuth } from "App/components/Auth";

import {
  LIMIT_TYPE,
  usePlanLimitsContext,
} from "App/common/PlanLimits/usePlanLimits";
import { useToast } from "App/common/useToast";

export type FileUpload = {
  file: Partial<FileEntity>;
  progress: number;
  error?: string;
  cancelUpload: () => void;
};

export enum UPLOAD_GROUP {
  TRACKS_BY_SPACE = "tracks-by-space",
  TRACK_VERSIONS_PINNED = "track-versions-pinned",
  TRACK_VERSIONS_UNPINNED = "track-versions-unpinned",
  ATTACHMENTS_BY_SPACE = "attachments-by-space",
}

export type UploadCache = Record<Id, boolean>;
type UploadGroup = Record<Id, UploadCache>;

const uploadCache: Record<UPLOAD_GROUP, UploadGroup> = {
  [UPLOAD_GROUP.TRACKS_BY_SPACE]: {},
  [UPLOAD_GROUP.TRACK_VERSIONS_PINNED]: {},
  [UPLOAD_GROUP.TRACK_VERSIONS_UNPINNED]: {},
  [UPLOAD_GROUP.ATTACHMENTS_BY_SPACE]: {},
};

type FileUploadsContext = {
  addUploadJob: (task: () => Promise<void>) => void;
  updateUpload: (id: Id, data: Partial<FileUpload>) => void;
  uploadsById: Record<Id, FileUpload>;
};

export const FileUploadsContext = createContext<FileUploadsContext>({
  addUploadJob: () => {},
  updateUpload: () => {},
  uploadsById: {},
});

export const FileUploadsContextProvider = ({
  children,
  concurrencySize = 5,
}: {
  children: React.ReactNode;
  concurrencySize?: number;
}) => {
  const [renderTrigger, setRenderTrigger] = useState<string>();
  const uploadsById = useRef<Record<Id, FileUpload>>({}).current;
  const uploadQueue = useRef<Array<() => Promise<void>>>([]);
  const jobCount = useRef(0);

  const promptUserBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const execute = async (task: () => Promise<void>) => {
    try {
      jobCount.current++;
      await task();
    } finally {
      jobCount.current = Math.max(0, jobCount.current - 1);
      await executeNext();
    }
  };

  const executeNext = async () => {
    const nextJob = uploadQueue.current.shift();
    if (nextJob) {
      await execute(nextJob);
      return;
    }
    if (jobCount.current === 0) {
      // upload has finished, so detach event listener for beforeunload
      window.removeEventListener("beforeunload", promptUserBeforeUnload);
    }
  };

  const addUploadJob = async (task: () => Promise<void>) => {
    if (uploadQueue.current.length === 0) {
      // upload has started, so prompt user before leaving the page
      window.addEventListener("beforeunload", promptUserBeforeUnload);
    }
    if (jobCount.current >= concurrencySize) {
      uploadQueue.current.push(task);
      return;
    }
    await execute(task);
  };

  const updateUpload = useCallback((id, data) => {
    const existing = uploadsById[id];

    if (existing && !data) {
      delete uploadsById[id];
      setRenderTrigger(uuidv4());
      return;
    }

    // We need a file.
    if (!existing?.file && !data?.file) return;

    uploadsById[id] = {
      ...(uploadsById[id] || {}),
      ...data,
    };
    setRenderTrigger(uuidv4());
  }, []);

  const value = useMemo(
    () => ({
      addUploadJob,
      updateUpload,
      uploadsById,
    }),
    [addUploadJob, updateUpload, uploadsById, renderTrigger],
  );

  return (
    <FileUploadsContext.Provider value={value}>
      {children}
    </FileUploadsContext.Provider>
  );
};

const useFileUploads = () => useContext(FileUploadsContext);

interface FileUploadHandlerPayload {
  cache?: UploadCache;
  file: File;
  props?: Partial<FileEntity>;
  onStateChange?: (snapshot: UploadTaskSnapshot) => void;
}

export interface FileUploadPayload {
  fileId: string;
  file: File;
  fileData: Partial<FileEntity>;
  storagePath: string;
  onStateChange?: (snapshot: UploadTaskSnapshot) => void;
}

const fileToUploadPayload = (
  filePayload: FileUploadHandlerPayload,
  userId: string,
): FileUploadPayload => {
  const { file, props = {}, onStateChange } = filePayload;
  const fileId = props.id || uuidv4();
  const fileName = props.fileName || file.name;
  const fileType = props.fileType || file.type;

  const storagePath = getFileStoragePath({
    userId,
    fileId,
    fileName,
  });

  const fileData: Partial<FileEntity> = {
    id: fileId,
    name: props.name || fileName,
    url: props.url,
    fileName,
    fileType,
    storagePath,
    ...(props.metadata ? { metadata: props.metadata } : {}),
  };

  return {
    fileId,
    file,
    fileData,
    storagePath,
    onStateChange,
  };
};

export class AudioUploadFileSizeErr extends Error {
  title = "Audio upload limit exceeded";
  constructor() {
    super(AUDIO_UPLOAD_LIMIT_ERR_MESSAGE);

    // `Error` class needs a proper prototype chain setup to work correctly with `instanceof`.
    Object.setPrototypeOf(this, AudioUploadFileSizeErr.prototype);
  }
}

class UploadCancelErr extends Error {
  constructor() {
    super("upload cancelled");
  }
}

export const useFiles = () => {
  const { addErrorMessage } = useToast();
  const { addUploadJob, updateUpload, uploadsById } = useFileUploads();
  const { user, storageLimit } = useAuth();
  const { storageUsed } = user || {
    storageUsed: 0,
    spacesUsed: 0,
  };
  const { showPlanLimitsDialog } = usePlanLimitsContext();

  const getDownloadUrl = useCallback(async (file: FileEntity) => {
    if (!file || !file.storagePath) {
      return;
    }

    // eslint-disable-next-line no-useless-catch
    try {
      const url = await highnote.getFileUrl({ id: file.id });
      return url;
    } catch (e) {
      throw e;
    }
  }, []);

  const handleFileUpload = useCallback(
    async ({
      fileId,
      file,
      fileData,
      storagePath,
      onStateChange,
    }: FileUploadPayload) => {
      let fileSize: number;
      try {
        await highnote.uploadFile({
          file,
          storagePath,
          onStateChange: (snapshot: UploadTaskSnapshot) => {
            fileSize = snapshot.totalBytes;
            updateUpload(fileId, {
              file: fileData,
              progress: snapshot.bytesTransferred / snapshot.totalBytes,
              cancelUpload: () => {
                snapshot.task.cancel();
                updateUpload(fileId, null);
                throw new UploadCancelErr();
              },
            });
            onStateChange && onStateChange(snapshot);
          },
        });

        const fileEntity = await highnote.createOrUpdateFile({
          id: fileId,
          data: fileData,
        });
        return fileEntity;
      } catch (e) {
        const wouldReachStorageLimit = storageUsed + fileSize >= storageLimit;

        if (!!user && wouldReachStorageLimit) {
          console.log("[DEBUG] Over storage limit");
          showPlanLimitsDialog(LIMIT_TYPE.STORAGE);
        }

        updateUpload(fileId, { error: e.message });
        throw e;
      }
    },
    [user, storageLimit, storageUsed],
  );

  const uploadFiles = useCallback(
    async ({
      payloads,
      onSuccess,
      onError,
    }: {
      payloads: FileUploadHandlerPayload[];
      onSuccess: (file: File, uploadedFile: FileEntity) => Promise<void>;
      onError?: (e: Error) => void;
      concurrencySize?: number;
    }) => {
      const payloadGroups: FileUploadPayload[] = [];
      payloads.forEach((payload) => {
        const uploadPayload = fileToUploadPayload(
          payload,
          user?.id || getAuthId(),
        );
        if (payload.cache) {
          payload.cache[uploadPayload.fileId] = true;
        }
        const exceedsUploadLimit = checkIfExceedsUploadLimit(payload.file);
        updateUpload(uploadPayload.fileId, {
          file: uploadPayload.fileData,
          progress: 0,
          cancelUpload: () => {
            updateUpload(uploadPayload.fileId, null);
            throw new UploadCancelErr();
          },
          ...(exceedsUploadLimit && { error: AUDIO_UPLOAD_LIMIT_ERR_MESSAGE }),
        });
        if (!exceedsUploadLimit) {
          payloadGroups.push(uploadPayload);
        } else {
          addErrorMessage(AUDIO_UPLOAD_LIMIT_ERR_MESSAGE, {
            title: "Audio upload limit exceeded",
          });
        }
      });

      payloadGroups.forEach((uploadPayload) => {
        addUploadJob(async () => {
          try {
            const uploadedFile = await handleFileUpload(uploadPayload);
            await onSuccess(uploadPayload.file, uploadedFile);
          } catch (e) {
            onError?.(e);
          }
        });
      });
    },
    [user, handleFileUpload],
  );

  const checkIfExceedsUploadLimit = (file: File) => {
    if (!file.size) {
      throw new Error("File size is not available");
    }
    return (
      AUDIO_ACCEPT_TYPES_STRING.includes(file.type) &&
      file.size > AUDIO_UPLOAD_SIZE_LIMIT_IN_BYTES
    );
  };

  const uploadFile = useCallback(
    async ({
      file,
      cache,
      props = {},
      isTrackUpload,
      onStateChange,
    }: {
      file: File;
      cache?: UploadCache;
      props?: Partial<FileEntity>;
      isTrackUpload?: boolean;
      onStateChange?: (snapshot: UploadTaskSnapshot) => void;
    }): Promise<FileEntity | undefined> => {
      const payload = fileToUploadPayload(
        {
          file,
          props,
          onStateChange,
        },
        user?.id || getAuthId(),
      );
      if (cache) {
        cache[payload.fileId] = true;
      }

      try {
        if (isTrackUpload && checkIfExceedsUploadLimit(file)) {
          throw new AudioUploadFileSizeErr();
        }
        return handleFileUpload(payload);
      } catch (e) {
        if (!(e instanceof UploadCancelErr)) {
          throw e;
        }
      }
    },
    [user, handleFileUpload],
  );

  const getUploadCache = (group: UPLOAD_GROUP, id?: Id) => {
    uploadCache[group] = uploadCache[group] || {};
    uploadCache[group][id] = uploadCache[group][id] || {};
    return uploadCache[group][id];
  };

  const getUploads = ({
    cache,
    cacheGroup,
  }: {
    cache?: UploadCache;
    cacheGroup?: UPLOAD_GROUP;
  }) => {
    const ids: Record<Id, boolean> = {};
    if (cache) {
      Object.keys(cache).forEach((id) => (ids[id] = true));
    }
    if (cacheGroup) {
      Object.values(uploadCache[cacheGroup]).forEach((cache) => {
        Object.keys(cache).forEach((id) => (ids[id] = true));
      });
    }

    return Object.keys(ids)
      .map((id) => uploadsById[id])
      .filter((u) => !!u);
  };

  const removeUpload = (id: Id) => {
    updateUpload(id, null);
  };

  return {
    getDownloadUrl,
    uploadFile,
    uploadFiles,
    uploadsById,
    getUploads,
    getUploadCache,
    removeUpload,
  };
};
