import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { highnote } from "@highnote/server/src/sdk";
import {
  ARCHIVABLE_ENTITY_TYPES,
  COLLECTION_ID,
  PUBLIC_ID,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import { useHistory } from "react-router";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { Action, Actions } from "App/common/Actions";
import { useConfirmation } from "App/common/useConfirmation";
import { ReactComponent as CopySVG } from "App/common/icons/copy-hollow.svg";
import { ReactComponent as MoveSVG } from "App/common/icons/collection-move.svg";
import { ReactComponent as InfoSVG } from "App/common/icons/track-list.svg";
import { ReactComponent as RemoveSVG } from "App/common/icons/remove.svg";
import { ReactComponent as DeleteSVG } from "App/common/icons/trash.svg";
import { ReactComponent as DownloadSVG } from "App/common/icons/download.svg";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { formatCount } from "App/modules/utils";
import { getAuthId, useAuth } from "App/components/Auth";

import { useToast } from "App/common/useToast";
import { useHighnote } from "App/common/useHighnote";
import { getMoveToSpaceWarning } from "App/components/util";
import { useUrlContext } from "App/routes/Main/useUrlContext";
import { useTrackEditor } from "App/components/useTrackEditor";
import { useMoveToSpace } from "./useMoveToSpace";
import { useTrack } from "../useTrack";
import {
  TrackFileDownloadType,
  useEntityDownload,
} from "App/common/useEntityDownload";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { useUserDownloadRequests } from "../useDownloadRequests";

enum DEFAULT_TRACK_ACTION {
  // Only show if you are a Collaborator+
  DUPLICATE = "duplicate",
  // Always show
  DETAILS = "details",
  // Only show if you are a Collaborator+
  MOVE_TO_SPACE = "move-to-space",
  // Only show if you are the Owner
  DELETE = "delete",
  // Only show if you are Collaborator+ of the Space OR Collaborator+ of the Track
  REMOVE = "remove-from-space",
  // Not an action, but a way to include a separator in the actions menus
  SEPARATOR = "separator",
  // Only show if you are Collaborator+ of the Space OR Collaborator+ of the Track
  DOWNLOAD_DEFAULT_VERSION = "download-default-version",
  DOWNLOAD_ALL_VERSIONS = "download-all-versions",
}

const TrackActions = ({
  track,
  actions,
  parentSpace,
}: {
  track: Track;
  actions: (DEFAULT_TRACK_ACTION | Action)[];
  parentSpace?: Space;
}) => {
  const history = useHistory();
  const { confirm, renderConfirmation } = useConfirmation();
  const { toasted } = useToast();
  const { moveToSpace } = useHighnote();
  const url = useUrlContext();
  const { openMoveToSpace } = useMoveToSpace();
  const { openTrackEditor } = useTrackEditor();
  const { trackArtworkUrl } = useTrack();
  const { downloadTrackFiles } = useEntityDownload(parentSpace);
  const { getDownloadRequest } = useUserDownloadRequests();
  const trackDownloadRequest = getDownloadRequest(
    track.id,
    ARCHIVABLE_ENTITY_TYPES.TRACK,
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadTrackFiles = async (type: TrackFileDownloadType) => {
    setIsLoading(true);
    await downloadTrackFiles(type, track, trackArtworkUrl);
    setIsLoading(false);
  };

  const deleteTrack = async () => {
    let messageContent: React.ReactNode =
      "This Track is not yet in any Spaces.";

    if (track.spaceId) {
      let space: Space;
      try {
        space = await highnote.getSpace({
          id: track.spaceId,
        });
      } catch (e) {
        // empty
      }

      if (space) {
        const numPeople = Object.keys(space?.rolesV2 || {}).filter(
          (u) => u !== PUBLIC_ID && u !== getAuthId(),
        ).length;

        const commentCount = await highnote.getCommentsCount({
          spaceId: space.id,
          trackId: track.id,
        });

        const commentsAffected = commentCount > 0;
        messageContent = (
          <>
            {commentsAffected && (
              <>
                This Track has {formatCount(commentCount, "comment")} in
                {space.name} which will no longer be visible.{" "}
              </>
            )}
            {commentsAffected ? "It will also" : "This track will"} be removed
            from {space.name}, which is currently shared with{" "}
            {formatCount(numPeople, "other person", "other people")}.
          </>
        );
      }
    }

    try {
      await confirm({
        title: `Delete ${track.title}?`,
        body: (
          <p>
            {messageContent}
            <br />
            <strong>Are you sure you want to delete {track.title}?</strong> This
            cannot be undone.
          </p>
        ),
      });

      // Delete the collection
      await toasted({
        promise: highnote.deleteTrack({ id: track.id }),
        createMessage: `Deleting Track...`,
        successMessage: `Track deleted.`,
        errorMessage: `Could not delete Track. Please try again.`,
      });

      if (url.spaceId === track.spaceId && url.trackId === track.id) {
        history.push(`/space/${track.spaceId}`);
      }
    } catch (e) {
      // empty
    }
  };

  const removeFromSpaces = async () => {
    const warning = await getMoveToSpaceWarning({
      entity: track,
      entityType: COLLECTION_ID.TRACK,
      userId: getAuthId(),
    });

    if (warning) {
      await confirm({
        title: "Remove from Space",
        body: (
          <>
            <p>
              <strong>{warning.title}</strong>
            </p>
            {warning.description}
          </>
        ),
      });
    }

    moveToSpace({
      entityId: track.id,
      entityType: COLLECTION_ID.TRACK,
      newSpaceId: undefined,
    });
  };

  const openDetails = () => {
    openTrackEditor({ track });
  };

  const duplicateTrack = async () => {
    const newTrackId = uuidv4();
    const promise = highnote.createTrack({
      id: newTrackId,
      data: {
        title: `${track.title} - Copy`,
        artistName: track.artistName || "",
        versionFilesV2: track.versionFilesV2 || [],
        defaultVersionId: track.defaultVersionId || null,
        files: track.files || [],
        artworkFile: track.artworkFile || null,
        spaceId: url.spaceId,
      },
    });

    await toasted({
      promise,
      ErrorContent: ({ error }: { error: Error }) => (
        <>Could not duplicate Track. {error.message}</>
      ),
    });

    const newTrack = await promise;
    openTrackEditor({ track: newTrack });
  };

  if (!track) return null;

  return (
    <ErrorBoundary name="CollectionActions">
      <Actions
        actions={actions.map((action) => {
          if (action === DEFAULT_TRACK_ACTION.SEPARATOR) {
            return { name: "SEPARATOR" };
          }

          if (action === DEFAULT_TRACK_ACTION.DETAILS) {
            return {
              name: "Details",
              icon: <InfoSVG />,
              onClick: openDetails,
            };
          }

          if (action === DEFAULT_TRACK_ACTION.DUPLICATE) {
            return {
              name: "Duplicate",
              icon: <CopySVG />,
              onClick: duplicateTrack,
            };
          }

          if (action === DEFAULT_TRACK_ACTION.MOVE_TO_SPACE) {
            return {
              name: "Move to...",
              icon: <MoveSVG />,
              onClick: () => {
                openMoveToSpace({
                  entity: track,
                  entityType: COLLECTION_ID.TRACK,
                });
              },
            };
          }

          if (action === DEFAULT_TRACK_ACTION.REMOVE) {
            if (!track.spaceId) return null;
            return {
              name: "Remove from Space",
              icon: <RemoveSVG />,
              onClick: removeFromSpaces,
            };
          }

          if (action === DEFAULT_TRACK_ACTION.DELETE) {
            return {
              name: "Delete Track",
              icon: <DeleteSVG />,
              onClick: deleteTrack,
              warn: true,
            };
          }

          if (action === DEFAULT_TRACK_ACTION.DOWNLOAD_DEFAULT_VERSION) {
            return {
              name: "Download Default Version",
              icon: <DownloadSVG />,
              onClick: () => handleDownloadTrackFiles("default"),
            };
          }

          if (action === DEFAULT_TRACK_ACTION.DOWNLOAD_ALL_VERSIONS) {
            if (track.versionFilesV2.length > 1) {
              return {
                disabled: Boolean(trackDownloadRequest) || isLoading,
                disabledReason: "We're processing this download.",
                name: "Download All Versions",
                icon: <DownloadSVG />,
                onClick: () => handleDownloadTrackFiles("all"),
              };
            }

            return null;
          }

          return action;
        })}
      />
      {renderConfirmation}
    </ErrorBoundary>
  );
};

export const DefaultTrackActions = ({ track }: { track: Track }) => {
  const { isAllowed } = useAuth();
  const { getGlobalSpace } = useGlobalSpaces();
  const [parentSpace, setParentSpace] = useState<Space | undefined>();

  useEffect(() => {
    let isMounted = true;

    if (isMounted && track.spaceId) {
      const globalSpace = getGlobalSpace(track.spaceId);
      if (globalSpace) {
        setParentSpace(globalSpace);
      } else {
        // TODO(globalState): This will rarely be used, when we implement the
        // new global space state in SpaceHome. Can keep as a fallback.
        highnote
          .getSpace({ id: track.spaceId, fromCache: true })
          .then((space) => {
            setParentSpace(space);
          })
          .catch((error) => {
            // Track you uploaded can belong to a space you don't have access to.
            // ie. User 1 has a space, and makes User 2 admin. User 2 creates their own space
            // that User 1 is not apart of yet, and moves a track/entity from User 1 Space -> User 2 Space.
            // User 1 will get an error for fetching space data of that new track's location.
            console.error("Error getting parent space", error);
          });
      }
    }

    return () => {
      isMounted = false;
    };
  }, [track.spaceId]);

  const authId = getAuthId();
  const actions = useMemo(() => {
    const canManageSpace = isAllowed(PERMISSION.TO_MANAGE_SPACE, {
      space: parentSpace,
    });
    const isTrackOwner = track.createdBy === authId;

    return [
      DEFAULT_TRACK_ACTION.DETAILS,

      ...(canManageSpace || isTrackOwner
        ? [
            DEFAULT_TRACK_ACTION.SEPARATOR,
            DEFAULT_TRACK_ACTION.DOWNLOAD_DEFAULT_VERSION,
            DEFAULT_TRACK_ACTION.DOWNLOAD_ALL_VERSIONS,
            DEFAULT_TRACK_ACTION.SEPARATOR,
          ]
        : []),

      ...(canManageSpace ? [DEFAULT_TRACK_ACTION.MOVE_TO_SPACE] : []),

      ...(canManageSpace || isTrackOwner
        ? [DEFAULT_TRACK_ACTION.DUPLICATE, DEFAULT_TRACK_ACTION.REMOVE]
        : []),

      ...(isTrackOwner
        ? [DEFAULT_TRACK_ACTION.SEPARATOR, DEFAULT_TRACK_ACTION.DELETE]
        : []),
    ];
  }, [isAllowed, track, authId, parentSpace]);

  return (
    <TrackActions track={track} actions={actions} parentSpace={parentSpace} />
  );
};
