import { History } from "history";
import { SIDEBAR_ID, SIDEBAR_IDS } from "./types";

export const isSidebarUrl: (id?: SIDEBAR_ID) => boolean = (id) => {
  if (!id) {
    return SIDEBAR_IDS.some((sidebarId) => isSidebarUrl(sidebarId));
  }

  const query = new URLSearchParams(location.search);
  return query.has(id);
};

export const setSidebarUrl = (sidebarId: SIDEBAR_ID, history: History) => {
  history.push(`${location.pathname}?${sidebarId}`);
};

export const unsetSidebarUrl = (history: History) => {
  history.push(location.pathname);
};
