import React from "react";
import { Track } from "@highnote/server/src/core/entities";
import {
  formatDuration,
  isFilePlayable,
} from "@highnote/server/src/core/shared-util";
import { EntityRowConfig, ENTITY_TYPE } from "./config";
import { useGlobalAudioPlayer } from "../GlobalAudioPlayer";

const TrackMetadata = ({ track }: { track: Track }) => {
  const { getQueueItem, currentQueueOrder } = useGlobalAudioPlayer();
  const queueItem = getQueueItem(track.id);
  const isLoading = !isFilePlayable(queueItem?.file);

  if (!queueItem?.file && currentQueueOrder.queue.length === 0) {
    return null;
  }

  return (
    <div className="highnote-entity-metadata" data-is-loading={isLoading}>
      {isLoading
        ? "--:--"
        : formatDuration(queueItem.file.metadata.duration, true)}
    </div>
  );
};

export const EntityMetadata = ({ row }: { row: EntityRowConfig }) => {
  if (row.type === ENTITY_TYPE.TRACK) {
    const track = row.entity as Track;
    return <TrackMetadata track={track} />;
  }

  return null;
};
