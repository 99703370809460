import "./SpaceChatSidebar.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Comment, KNOCK_WORKFLOW } from "@highnote/server/src/core/entities";
import animateScrollTo from "animated-scroll-to";
import { v4 as uuidv4 } from "uuid";
import { useSpaceCommentsContext } from "App/common/useSpaceComments";
import { useSidebarContext } from "../Sidebar";
import { useNotificationsContext } from "App/common/useNotifications";
import { useSpaceContext } from "App/common/useSpace";

const within = (a: number, b: number) => Math.abs(a - b) <= 3;

export const useScrollingComments = ({
  scrollableRef,
  highlightedCommentRef,
}: {
  scrollableRef: React.MutableRefObject<HTMLDivElement>;
  highlightedCommentRef: React.MutableRefObject<HTMLDivElement>;
}) => {
  const { isSidebarOpen } = useSidebarContext();
  const { space } = useSpaceContext();
  const { notifications, notificationsLoading, markAsRead } =
    useNotificationsContext();
  const { comments, commentsLoading } = useSpaceCommentsContext();
  const [firstOpenRender, setFirstOpenRender] = useState<boolean>(true);
  const [oldestUnread, setOldestUnread] = useState<Comment>();
  const [autoScrollTrigger, setAutoScrollTrigger] = useState<string>();
  const isScrollingRef = useRef<boolean>(false);
  const scrollReadyRef = useRef<boolean>(false);
  const [showNewerCommentsPrompt, setShowNewerCommentsPrompt] = useState(false);
  const [showOlderCommentsPrompt, setShowOlderCommentsPrompt] = useState(false);

  const unreadChatNotifications = useMemo(() => {
    return notifications.filter(
      (n) =>
        n.source.key === KNOCK_WORKFLOW.CHAT_COMMENT_ADDED &&
        space?.id === n.data.spaceId &&
        !n.read_at,
    );
  }, [notifications, space?.id]);

  useEffect(() => {
    if (!scrollableRef.current || !isSidebarOpen) return;

    const handleScroll = () => {
      const scrollEl = scrollableRef.current;
      const elHeight = scrollEl.clientHeight;
      const maxScroll = scrollEl.scrollHeight - elHeight;
      const isScrolledToBottom = within(scrollEl.scrollTop, maxScroll);
      const isScrolledToTop =
        scrollEl.scrollTop === 0 && elHeight < scrollEl.scrollHeight;
      isScrollingRef.current = !isScrolledToBottom;
      setShowOlderCommentsPrompt(scrollReadyRef.current && isScrolledToTop);
      setShowNewerCommentsPrompt(scrollReadyRef.current && !isScrolledToBottom);

      if (isScrolledToBottom) {
        markAsRead(unreadChatNotifications);
      }
    };

    scrollableRef.current.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      scrollableRef.current &&
        scrollableRef.current.removeEventListener("scroll", handleScroll);
    };
  }, [scrollableRef.current, isSidebarOpen, unreadChatNotifications]);

  useEffect(() => {
    const triggerAnimation = () => {
      scrollReadyRef.current = false;
      isScrollingRef.current = false;
      setAutoScrollTrigger(uuidv4());
    };
    window.addEventListener("resize", triggerAnimation);

    return () => {
      window.removeEventListener("resize", triggerAnimation);
    };
  }, []);

  useEffect(() => {
    if (firstOpenRender) return;
    const shouldAutoScroll =
      !!highlightedCommentRef.current ||
      (isSidebarOpen && !isScrollingRef.current);
    if (!shouldAutoScroll) return;

    const offset = scrollableRef.current.clientHeight;
    const destination = highlightedCommentRef.current
      ? highlightedCommentRef.current.offsetTop + offset - 5
      : scrollableRef.current.scrollHeight;

    scrollReadyRef.current = false;
    animateScrollTo([null, destination - offset], {
      cancelOnUserAction: true,
      elementToScroll: scrollableRef.current,
      maxDuration: 300,
    }).then(() => {
      scrollReadyRef.current = true;
    });
  }, [
    oldestUnread,
    firstOpenRender,
    comments,
    isSidebarOpen,
    autoScrollTrigger,
  ]);

  useEffect(() => {
    if (!isSidebarOpen) {
      scrollReadyRef.current = false;
      isScrollingRef.current = false;
      return;
    }

    setFirstOpenRender(true);
    setOldestUnread(undefined);
  }, [isSidebarOpen]);

  useEffect(() => {
    if (!firstOpenRender) return;
    if (commentsLoading || notificationsLoading) return;

    const unreadComments = comments.filter((c) =>
      unreadChatNotifications.some((n) => n.data.commentId === c.id),
    );
    const _oldestUnread = unreadComments.sort(
      (a, b) => a.createdAt - b.createdAt,
    )[0];

    setOldestUnread(_oldestUnread);
    setFirstOpenRender(false);
  }, [
    firstOpenRender,
    commentsLoading,
    notificationsLoading,
    unreadChatNotifications,
    comments,
  ]);

  const scrollToNewest = () => {
    if (!scrollableRef.current) return;
    animateScrollTo([null, scrollableRef.current.scrollHeight], {
      cancelOnUserAction: true,
      elementToScroll: scrollableRef.current,
      maxDuration: 300,
    });
  };

  return {
    showNewerCommentsPrompt,
    showOlderCommentsPrompt,
    oldestUnread,
    scrollToNewest,
  };
};
