import {
  ArrayRemove,
  FileEntity,
  KNOCK_WORKFLOW,
} from "@highnote/server/src/core/entities";
import {
  getDefaultVersionId,
  PERMISSION,
} from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import { MenuProps } from "@mui/material";
import {
  ActionDivider,
  ActionInfo,
  ActionItem,
  Actions,
} from "App/common/Actions";
import { ReactComponent as DownloadSVG } from "App/common/icons/download.svg";
import { ReactComponent as EditSVG } from "App/common/icons/edit.svg";
import { ReactComponent as InfoSVG } from "App/common/icons/info.svg";
import { ReactComponent as PinSVG } from "App/common/icons/pin.svg";
import { ReactComponent as RemoveSVG } from "App/common/icons/remove.svg";
import { ReactComponent as StarFilledSVG } from "App/common/icons/star-filled.svg";
import { ReactComponent as UnpinSVG } from "App/common/icons/unpin.svg";
import { useConfirmation } from "App/common/useConfirmation";
import { useFileDownload } from "App/common/useFileDownload";
import { useHighnote } from "App/common/useHighnote";
import { ConnectedUserName } from "App/common/UserAvatar/UserAvatar";
import { useSpaceContext } from "App/common/useSpace";
import { useToast } from "App/common/useToast";
import { useAuth } from "App/components/Auth";
import { FILE_EDITOR_FIELD, FileEditor } from "App/components/FileEditor";
import { useTrack } from "App/components/useEntities/useTrack";
import React, { useState } from "react";

export enum TRACK_VERSION_ACTIONS {
  DOWNLOAD = "DOWNLOAD",
  RENAME = "RENAME",
  SET_AS_DEFAULT = "SET_AS_DEFAULT",
  REMOVE = "REMOVE",
  PIN = "PIN",
}

export const DEFAULT_TRACK_VERSION_ACTIONS = [
  TRACK_VERSION_ACTIONS.DOWNLOAD,
  TRACK_VERSION_ACTIONS.RENAME,
  TRACK_VERSION_ACTIONS.SET_AS_DEFAULT,
  TRACK_VERSION_ACTIONS.REMOVE,
  TRACK_VERSION_ACTIONS.PIN,
];

const SetAsDefaultVersion = ({ version }: { version: FileEntity }) => {
  const { toasted } = useToast();
  const {
    track,
    setCurrentTrackVersion,
    pinnedTrackVersions,
    setPinnedTrackVersions,
  } = useTrack();
  const { pinTrackVersion } = useHighnote();
  const isCurrentlyPinned = pinnedTrackVersions.includes(version.id);

  return (
    <ActionItem
      action={{
        icon: <StarFilledSVG />,
        name: `Set as Default Version`,
        onClick: () => {
          if (isCurrentlyPinned) {
            const pinnedVersionFiles = [...pinnedTrackVersions];
            const index = pinnedVersionFiles.indexOf(version.id);
            pinnedVersionFiles.splice(index, 1);
            pinnedVersionFiles.push(version.id);
            setPinnedTrackVersions(pinnedVersionFiles);
            toasted({
              promise: highnote.updateTrack({
                id: track.id,
                data: {
                  pinnedVersionFiles,
                },
              }),
              ErrorContent: ({ error }: { error: Error }) => (
                <>Could not set default version. {error.message}</>
              ),
            });
          } else {
            pinTrackVersion({ track, versionId: version.id });
          }

          setCurrentTrackVersion(version);
        },
      }}
    />
  );
};

export const TrackVersionActions = ({
  actions = [...DEFAULT_TRACK_VERSION_ACTIONS],
  version,
  menuProps,
}: {
  actions?: TRACK_VERSION_ACTIONS[];
  version: FileEntity;
  menuProps?: Partial<MenuProps>;
}) => {
  const { toasted } = useToast();
  const { confirm, renderConfirmation } = useConfirmation();
  const [fileEditorOpen, setFileEditorOpen] = useState(false);
  const { isAllowed } = useAuth();
  const { space, spaceArtworkUrl } = useSpaceContext();
  const {
    track,
    trackArtworkUrl,
    refetchTrackFiles,
    pinnedTrackVersions,
    trackVersions,
  } = useTrack();
  const { downloadFile, isDownloading } = useFileDownload();
  const { pinTrackVersion, unpinTrackVersion } = useHighnote();
  const canEditFile = isAllowed(PERMISSION.TO_EDIT_FILE, { file: version });
  const canAddToTrack = isAllowed(PERMISSION.TO_ADD_TO_TRACK_IN_SPACE, {
    space,
    track,
  });
  const canEditTrack = isAllowed(PERMISSION.TO_EDIT_TRACK_IN_SPACE, {
    space,
    track,
  });
  const canDownload = isAllowed(PERMISSION.TO_DOWNLOAD_TRACK_IN_SPACE, {
    track,
    space,
  });

  const isCurrentlyPinned = pinnedTrackVersions.includes(version.id);
  const isOnlyPin = isCurrentlyPinned && pinnedTrackVersions.length === 1;
  const isOnlyVersion = trackVersions.length === 1;
  const isDefaultVersion = version.id === getDefaultVersionId(track);

  const menuActions = [];

  if (canDownload) {
    actions.includes(TRACK_VERSION_ACTIONS.DOWNLOAD) &&
      menuActions.push({
        icon: <DownloadSVG />,
        name: "Download",
        disabled: isDownloading,
        disabledReason: "We're processing this download.",
        onClick: async () => {
          await downloadFile(version.id);
          highnote.notify({
            workflowId: KNOCK_WORKFLOW.TRACK_VERSION_DOWNLOADED,
            data: {
              files: [
                {
                  fileId: version.id,
                  fileName: version.name,
                  versionNumber: track.versionFilesV2.indexOf(version.id) + 1,
                },
              ],
              artworkUrl: spaceArtworkUrl,
              spaceId: space.id,
              spaceName: space.name,
              trackId: track.id,
              trackName: track.title,
              trackArtworkUrl: trackArtworkUrl,
            },
          });
        },
      });
  }

  if (canAddToTrack) {
    actions.includes(TRACK_VERSION_ACTIONS.SET_AS_DEFAULT) &&
      !isDefaultVersion &&
      menuActions.push({
        Component: () => <SetAsDefaultVersion version={version} />,
      });

    actions.includes(TRACK_VERSION_ACTIONS.PIN) &&
      !isOnlyPin &&
      menuActions.push({
        Component: () => (
          <ActionItem
            action={{
              icon: isCurrentlyPinned ? <UnpinSVG /> : <PinSVG />,
              name: `${isCurrentlyPinned ? "Unpin" : "Pin"} Version`,
              onClick: () => {
                if (isCurrentlyPinned) {
                  unpinTrackVersion({ track, versionId: version.id });
                  return;
                }
                pinTrackVersion({ track, versionId: version.id });
              },
            }}
          />
        ),
      });
  }

  if (canEditFile) {
    actions.includes(TRACK_VERSION_ACTIONS.RENAME) &&
      menuActions.push({
        icon: <EditSVG />,
        name: "Rename",
        onClick: () => {
          setFileEditorOpen(true);
        },
      });
  }

  if (canEditTrack) {
    actions.includes(TRACK_VERSION_ACTIONS.REMOVE) &&
      !isOnlyVersion &&
      menuActions.push({
        icon: <RemoveSVG />,
        name: "Remove from Track",
        onClick: async () => {
          await confirm({
            title: "Remove from Track",
            body: (
              <p>
                <strong>
                  Are you sure you want to remove {version.name} from this
                  track?
                </strong>
                <br />
                <br />
                All comments assigned to this version will be lost. This action
                cannot be undone.
              </p>
            ),
          });

          toasted({
            promise: highnote.updateTrack({
              id: track.id,
              data: {
                versionFilesV2: new ArrayRemove([
                  version.id,
                ]) as unknown as Id[],
                pinnedVersionFiles: new ArrayRemove([
                  version.id,
                ]) as unknown as Id[],
              },
            }),
            ErrorContent: ({ error }: { error: Error }) => (
              <>Could not remove version from track. {error.message}</>
            ),
          });
        },
      });
  }

  if (!canEditFile && menuActions.length > 0) {
    menuActions.push(ActionDivider, {
      Component: () => (
        <ActionInfo>
          <InfoSVG /> Added by&nbsp;
          <ConnectedUserName userId={version.createdBy} />
        </ActionInfo>
      ),
      disabled: true,
    });
  }

  if (!menuActions.length) {
    return null;
  }

  return (
    <>
      {renderConfirmation}
      {fileEditorOpen && (
        <FileEditor
          file={version}
          title="Rename Version"
          fields={[FILE_EDITOR_FIELD.NAME]}
          onSave={refetchTrackFiles}
          onClose={() => setFileEditorOpen(false)}
        />
      )}
      <Actions actions={menuActions} menuProps={menuProps} />
    </>
  );
};
