import React from "react";
import { ConnectedUserAvatar, USER_AVATAR_SIZE } from "./UserAvatar";
import "./UserAvatarGroup.scss";

export const UserAvatarGroup = ({
  users,
  size,
}: {
  users: { id: Id; alias?: string }[];
  size?: USER_AVATAR_SIZE;
}) => {
  const uniqueIds: Record<string, boolean> = {};
  const dedupedUsers: typeof users = [];
  users.forEach((u) => {
    if (uniqueIds[u.id]) return;
    dedupedUsers.push(u);
    uniqueIds[u.id] = true;
  });
  const lastThreeUsers = dedupedUsers.slice(0, 3);
  const overflow = users.length - 3;

  return (
    <div className="user-avatar-group">
      <div className="avatars">
        {lastThreeUsers.map(({ id, alias }) => (
          <ConnectedUserAvatar
            key={id}
            userId={id}
            alias={alias}
            size={size || USER_AVATAR_SIZE.MEDIUM}
          />
        ))}
      </div>
      {overflow > 0 && <span className="overflow">+{overflow}</span>}
    </div>
  );
};
