import React, { useEffect } from "react";
import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";
import { useAuth } from "App/components/Auth";

import { useHistory } from "react-router";

export const AuthRedirectRequired = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const history = useHistory();
  const { user, authLoading } = useAuth();

  useEffect(() => {
    if (!!user || authLoading) return;
    history.push(`/login?returnTo=${location.pathname}`);
  }, [user, authLoading]);

  if (user) {
    return <>{children || null}</>;
  }

  return <LoadingScreen />;
};
