import "./CommentContent.scss";
import React, { useCallback, useEffect, useState } from "react";
import { CommentBlock } from "@highnote/server/src/core/entities";
import { COMMENT_BLOCKS } from "./blocks";
import { ExpandablePreview } from "App/common/ExpandablePreview";
import { useSpaceContext } from "App/common/useSpace";
import { getValidCommentBlock, useCommentContext } from "App/common/useComment";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { Tooltip } from "App/core/Tooltip";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { useCarouselSync } from "App/routes/Main/Space/Carousel/useCarouselSync";
import { useFocusOnCard } from "../useFocusedCard";
import { useSystemEditing } from "../useSystemEditing";
import { useAuth } from "App/components/Auth";

export const CommentContent = ({
  onCancel,
  isCollapsible,
}: {
  onCancel?: () => void;
  isCollapsible?: boolean;
}) => {
  const {
    comment,
    replies,
    isEditing,
    setEditing,
    updateComment,
    saveComment,
    resetComment,
  } = useCommentContext();
  const { setSystemEditing } = useSystemEditing();
  const { focusOnCard } = useFocusOnCard();
  const sync = useCarouselSync();
  const { space, spaceId } = useSpaceContext();
  const { isAllowed } = useAuth();
  const [isExpanded, setExpanded] = useState(isEditing);
  const canCommentInSpace = isAllowed(PERMISSION.TO_COMMENT_IN_SPACE, {
    space,
  });

  useEffect(() => {
    if (!isEditing) return;
    setExpanded(true);
    // Focus the text area immediately
    const commentElements = document
      .querySelector(`[data-id="${comment.id}"]`)
      ?.getElementsByTagName("textarea");
    if (commentElements?.length) commentElements[0].focus();
  }, [isEditing]);

  const handleCancel = () => {
    setSystemEditing(`comment-${comment.id}`, false);
    onCancel && onCancel();
    resetComment();
    setEditing(false);
    setExpanded(false);
  };

  const handleExpand = useCallback((expanded: boolean) => {
    setExpanded(expanded);
  }, []);

  const onBlockChange = async (block: CommentBlock) => {
    updateComment({ blocks: [block] });
  };

  const onBlockSave = async (block: CommentBlock) => {
    await saveComment({ blocks: [block] });
    focusOnCard().then(() => {
      sync.onCardIdClick(comment.id);
    });
    setSystemEditing(`comment-${comment.id}`, false);
  };

  const blockData = getValidCommentBlock(comment);
  const blockType = blockData?.type as keyof typeof COMMENT_BLOCKS;
  const blockConfig = COMMENT_BLOCKS[blockType];

  if (!blockConfig) {
    console.log(
      "[CommentContent] Could not find matching block config:",
      blockType,
    );
    return null;
  }

  if (!blockConfig.Component || !blockConfig.Icon) {
    return null;
  }

  const showEditButtons = isEditing && blockConfig.isEditable;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validationError = blockConfig.validate(blockData as unknown as any);

  return (
    <div
      className="highnote-comment-content"
      data-id={comment.id}
      data-is-expanded={isExpanded}
    >
      <div className="comment-data">
        {isCollapsible ? (
          <ExpandablePreview
            disabled={isEditing}
            isExpanded={isExpanded}
            setExpanded={handleExpand}
          >
            <blockConfig.Component
              id={comment.id}
              createdBy={comment.createdBy}
              replies={replies}
              isEditing={isEditing}
              onChange={onBlockChange}
              onSave={onBlockSave}
              timestamp={comment.timestamp}
              spaceId={spaceId}
              data={blockData}
            />
          </ExpandablePreview>
        ) : (
          <blockConfig.Component
            id={comment.id}
            createdBy={comment.createdBy}
            replies={replies}
            isEditing={isEditing}
            onChange={onBlockChange}
            onSave={onBlockSave}
            timestamp={comment.timestamp}
            spaceId={spaceId}
            data={blockData}
          />
        )}
      </div>

      {showEditButtons && canCommentInSpace && (
        <div className="editor-buttons">
          <Button
            size={BUTTON_SIZE.XSMALL}
            theme={BUTTON_THEME.TEXT}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Tooltip title={validationError} arrow isDisabled={!validationError}>
            <Button
              size={BUTTON_SIZE.XSMALL}
              theme={BUTTON_THEME.PRIMARY}
              onClick={async () => {
                await saveComment();
                focusOnCard();
              }}
              disabled={!!validationError}
            >
              Save
            </Button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
