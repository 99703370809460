import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { RecordingPlayer } from "App/common/AudioPlayer/RecordingPlayer";
import { FileBlockV2, FileEntity } from "@highnote/server/src/core/entities";
import { useFiles } from "App/components/useFiles";
import { watchAudioFiles } from "App/components/watchAudioFiles";

export const FileBlock = (props: {
  spaceId: Id;
  timestamp: number;
  isEditing: boolean;
  data: FileBlockV2;
  onChange: (block: FileBlockV2) => void;
  onSave: (block: FileBlockV2) => void;
}) => {
  const { uploadFile } = useFiles();
  const { isEditing, onChange, data } = props;
  const [isLoading, setLoading] = useState(false);
  const [file, setFile] = useState<FileEntity>();
  const unmountedRef = useRef<boolean>(false);

  useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    [],
  );

  useEffect(() => {
    if (!data.file) return;

    const unwatch = watchAudioFiles([data.file], (files) => {
      if (unmountedRef.current) return;
      const matchingFile = files.find((f) => f.id === data.file);
      setFile(matchingFile);
    });

    return () => {
      unwatch();
    };
  }, [data.file]);

  const onRecordingChange = async (file: File) => {
    if (!file) {
      onChange({ ...data, file: null });
      return;
    }

    const fileId = uuidv4();
    setLoading(true);

    await uploadFile({
      file,
      props: {
        id: fileId,
      },
    });

    if (unmountedRef.current) return;
    onChange({ ...data, file: fileId });
    setLoading(false);
  };

  return (
    <div>
      <RecordingPlayer
        isEditing={isEditing}
        isLoading={isLoading}
        onChange={onRecordingChange}
        file={file}
      />
    </div>
  );
};
