import "./JoinSpaceConfirmation.scss";
import React, { useState } from "react";
import { ImageWithFallback } from "App/common/ImageWithFallback";
import { BUTTON_THEME, Button } from "App/core/Button";
import { useToast } from "App/common/useToast";
import { useAuth } from "App/components/Auth";
import { AUTH_TYPE, AuthForm } from "App/components/Auth/AuthForm";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";

export const JoinSpaceConfirmation = ({
  spaceName,
  spaceArtworkUrl,
  referrerId,
  onSubmit,
}: {
  spaceName: string;
  spaceArtworkUrl?: string;
  referrerId?: string;
  onSubmit: () => Promise<void>;
}) => {
  const { user } = useAuth();
  const { addErrorMessage } = useToast();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleJoinSpace = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit();
    } catch (err) {
      addErrorMessage(err.message);
      setIsSubmitting(false);
    }
  };

  return (
    <div className="join-space-confirmation">
      <div className="join-confirmation-content">
        <div className="join-confirmation-content-artwork">
          <ImageWithFallback
            src={spaceArtworkUrl}
            fallback={"/public/default-space-artwork.png"}
          />
        </div>
        {user ? (
          <div className="join-confirmation-content-section">
            <p className="join-confirmation-content-section-header">
              Join {spaceName}
            </p>
            <p className="join-confirmation-content-section-description">
              Joining this Space shares your username and activity with other
              members.
            </p>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              theme={BUTTON_THEME.CTA}
              onClick={handleJoinSpace}
            >
              Join Space
            </Button>
          </div>
        ) : (
          <div className="join-confirmation-content-section">
            <p className="join-confirmation-content-section-header">
              Log in or create a free account to join {spaceName}
            </p>
            <AuthForm
              type={AUTH_TYPE.JOIN}
              onAuth={close}
              returnTo={`${location.pathname}${location.search}`}
              trigger={JOIN_ENTITY_TRIGGER.BUTTON}
              hideWelcomeSurvey
              signUpMetadata={{
                referrer_type: "invite_link_join_button",
                referrer_id: referrerId,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
