import { Breadcrumbs } from "App/common/Breadcrumbs";
import { useSpaceContext } from "App/common/useSpace";
import { Navigation, NavigationToolbar } from "App/components/Navigation";
import { SpaceMemberPreview } from "App/components/SpaceTools";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import React, { useMemo } from "react";
import { useHistory } from "react-router";
import { ReactComponent as ChatSVG } from "App/common/icons/chat.svg";
import { ReactComponent as GearSVG } from "App/common/icons/gear.svg";
import { setSidebarUrl, SIDEBAR_ID } from "App/components/Sidebar";
import { useTrack } from "App/components/useEntities/useTrack";
import { useAuth } from "App/components/Auth";
import { KNOCK_WORKFLOW } from "@highnote/server/src/core/entities";
import { useJoinEntityButton } from "App/common/AuthButtons";
import { useShareDialog } from "App/components/ShareDialog";
import {
  getEntitySubscribers,
  PERMISSION,
} from "@highnote/server/src/core/shared-util";
import { useNotificationsContext } from "App/common/useNotifications";
import { UnreadDot } from "App/common/UnreadDot";
import { TrackDownloadButton } from "App/components/EntityTable/EntityShortcut";
import "./SpaceNavigation.scss";
import { useSpaceEditor } from "App/components/useSpaceEditor";
import { SPACE_EDITOR_TAB } from "App/components/SpaceEditor";
import { HelperDialog } from "App/components/HelperDialog/HelperDialog";

const SpaceDetailsButton = () => {
  const { space } = useSpaceContext();
  const { openSpaceEditor } = useSpaceEditor();
  return (
    <Button
      className="settings-button"
      data-cypress-id="settings-button"
      size={BUTTON_SIZE.SMALL}
      theme={BUTTON_THEME.SECONDARY}
      onClick={() => {
        openSpaceEditor(space, SPACE_EDITOR_TAB.SETTINGS);
      }}
    >
      <GearSVG />
    </Button>
  );
};

const SpaceChatToolbarButton = () => {
  const history = useHistory();
  const { space } = useSpaceContext();
  const { notifications } = useNotificationsContext();

  const unreads = useMemo(
    () =>
      notifications.filter(
        (n) =>
          n.source.key === KNOCK_WORKFLOW.CHAT_COMMENT_ADDED &&
          n.data.spaceId === space?.id &&
          !n.read_at,
      ),
    [space?.id, notifications],
  );

  return (
    <span>
      {unreads.length > 0 && <UnreadDot>&nbsp;</UnreadDot>}
      <Button
        className="chat-button"
        size={BUTTON_SIZE.SMALL}
        theme={BUTTON_THEME.SECONDARY}
        onClick={() => setSidebarUrl(SIDEBAR_ID.SPACE_CHAT, history)}
      >
        <ChatSVG />
        <span className="label">Chat</span>
      </Button>
    </span>
  );
};

type Props = {
  // Only pass showShareSpaceOnboarding prop to SpaceHomeNavigation as it is only effected
  // when a user uploads a new track, which is from the SpaceHome page, not the SpaceTrack page.
  showShareSpaceOnboarding?: boolean;
  closeShareSpaceOnboarding?: () => void;
};

const SpaceNavigationToolbar = ({
  showShareSpaceOnboarding,
  closeShareSpaceOnboarding,
}: Props) => {
  const { space } = useSpaceContext();
  const { track, trackArtworkUrl } = useTrack();
  const { openShareDialog, renderShareDialog } = useShareDialog();
  const { user, isAllowed, isPublicView } = useAuth();
  const isLoggedIn = !!user;

  const subscribers = getEntitySubscribers(space);
  const isMember = subscribers.includes(user?.id);
  const joinSpaceButton = useJoinEntityButton(space, "Space");

  const canDownload = isAllowed(PERMISSION.TO_DOWNLOAD_TRACK_IN_SPACE, {
    track,
    space,
  });

  const handleOpenShareDialog = () => openShareDialog(space);

  const toolbarItems = useMemo(() => {
    const items: React.ReactElement[] = [];
    if (isMember) {
      items.push(<SpaceMemberPreview onClick={handleOpenShareDialog} />);
    }

    // Download button should only show on Track pages
    if (canDownload && !!track) {
      items.push(
        <TrackDownloadButton
          className="track-navigation-download-button"
          track={track}
          trackArtworkUrl={trackArtworkUrl}
          showDownloadSpace
        />,
      );
    }

    if (isMember) {
      items.push(<SpaceDetailsButton />);
    }

    if (
      isAllowed(PERMISSION.TO_COMMENT_IN_SPACE, { space }) ||
      !isPublicView(space)
    ) {
      items.push(<SpaceChatToolbarButton />);
    }

    if (isMember) {
      items.push(
        <>
          <Button
            className="share"
            size={BUTTON_SIZE.SMALL}
            theme={BUTTON_THEME.CTA}
            onClick={handleOpenShareDialog}
          >
            Share
          </Button>

          {showShareSpaceOnboarding && (
            <HelperDialog
              confirmCta={{
                label: "Okay",
                onConfirm: closeShareSpaceOnboarding,
              }}
              heading="Allow Access"
              description={
                <p>
                  Spaces are private by default. Enable your Link to allow
                  others to access your space{" "}
                  <strong>without an account</strong>.
                </p>
              }
            />
          )}
        </>,
      );
    }

    if (!isMember) {
      items.push(joinSpaceButton);
    }
    return items;
  }, [isLoggedIn, space, joinSpaceButton]);

  return (
    <>
      <NavigationToolbar items={toolbarItems} />
      {renderShareDialog}
    </>
  );
};

export const SpaceHomeNavigation = ({
  showShareSpaceOnboarding,
  closeShareSpaceOnboarding,
}: Props) => {
  const { space } = useSpaceContext();
  const { user } = useAuth();
  const isLoggedIn = !!user;
  const subscribers = getEntitySubscribers(space);
  const isMember = subscribers.includes(user?.id);

  return (
    <Navigation
      className="space-navigation space-home-navigation"
      showIndent
      showLogo={!isLoggedIn}
    >
      <Breadcrumbs activeSpace={space} hideLibrary={!isMember} />
      <SpaceNavigationToolbar
        showShareSpaceOnboarding={showShareSpaceOnboarding}
        closeShareSpaceOnboarding={closeShareSpaceOnboarding}
      />
    </Navigation>
  );
};

export const SpaceTrackNavigation = () => {
  const { space } = useSpaceContext();
  const { track } = useTrack();
  const { user } = useAuth();
  const isLoggedIn = !!user;
  const subscribers = getEntitySubscribers(space);
  const isMember = subscribers.includes(user?.id);

  return (
    <Navigation
      className="space-navigation space-track-navigation"
      showLogo={!isLoggedIn}
      showIndent
    >
      <Breadcrumbs
        activeSpace={space}
        activeTrack={track}
        hideLibrary={!isMember}
      />
      <SpaceNavigationToolbar />
    </Navigation>
  );
};
