import React, { useCallback, useEffect, useMemo } from "react";
import { ENTITY_TYPE, EntityRowConfig } from "../config";
import {
  BaseRow,
  BaseTableRowProps,
  TableRowVariant,
} from "App/common/BaseTable";
import { FileEntity, Space, Track } from "@highnote/server/src/core/entities";
import {
  AttachmentsContextProvider,
  useAttachmentsContext,
} from "App/common/useAttachments";
import { useHistory } from "react-router";
import {
  sortRowByAlphabetical,
  sortRowByCreatedAt,
  sortRowByCreatedAtAscending,
} from "App/components/util";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { useGlobalTracks } from "App/store/tracks/useGlobalTracks";
import { useFileDownload } from "App/common/useFileDownload";
import { useChildEntityWatchers } from "App/store/helpers/useChildEntityWatchers";
import { DefaultTrackActions } from "App/components/useEntities/useLibraryTracks/TrackActions";
import { DefaultSpaceActions } from "App/common/SpaceActions";
import { DefaultAttachmentActions } from "App/common/AttachmentActions";
import { SORT_TYPE } from "App/routes/Main/Library/config";
import { sortItemsByItemsOrder } from "@highnote/server/src/core/shared-util";
import { useGlobalAudioPlayer } from "App/components/GlobalAudioPlayer";
import { Confirmator } from "App/common/useConfirmation";
import { BUTTON_THEME } from "App/core/Button";
import { useUser } from "App/components/useEntities/useUser";
import { withMobileDeeplinkRoot } from "App/modules/urls";
import { useViewport } from "App/common/useViewport";
import { isTouchDevice } from "App/modules/utils";

/**
 * 'useFormatSpaceToBaseEntityRow', 'useFormatTrackToBaseEntityRow', and 'useFormatFileToBaseEntityRow' create
 * a row configuration object for entities (Space, Track, File) in the EntityTable component.
 * These functions ensures consistency and ease of maintenance across different components.
 * Used via useSpaceChildItemRows in: ExpandableSpaceRow.tsx, EntityLibrary.tsx, SpaceItems.tsx.
 *
 * Table Rows:
 * - Track Row (Flat, expandable with versions)
 * - Attachment/File Row (Flat)
 * - Space Row (Expanded, Click-Thru, Flat)
 */

type EntityTableRowOptions = {
  hasPlayableTracks?: boolean;
  tableRowVariant?: TableRowVariant;
};

export const useFormatSpaceToBaseEntityRow = () => {
  const history = useHistory();

  const formatSpaceToBaseEntityRow = ({
    space,
    options,
  }: {
    space: Space;
    options?: EntityTableRowOptions;
  }): EntityRowConfig => {
    const tableRowVariant = options?.tableRowVariant;

    return {
      key: space.id,
      id: space.id,
      entity: space,
      type: ENTITY_TYPE.SPACE,
      createdAt: space.createdAt,
      name: space.name,
      actions: <DefaultSpaceActions space={space} />,
      onClick: () => {
        history.push(`/space/${space.id}`);
      },
      attributes:
        tableRowVariant === "nested-click-thru"
          ? { "data-is-nested": "true" }
          : undefined,
      NestedTable:
        tableRowVariant === "expanded-row" ||
        tableRowVariant === "draggable-expanded-row"
          ? ExpandableParentSpaceRow
          : undefined,
    };
  };

  return { formatSpaceToBaseEntityRow };
};

export const trackDeeplinkConfirmPayload = {
  wrapperClassName: "track-open-in-mobile-confirm-dialog",
  body: "Open Track",
  continueCTAConfig: {
    label: "Open in App",
    theme: BUTTON_THEME.CTA,
  },
  cancelCTAConfig: {
    label: "Continue in Browser",
    theme: BUTTON_THEME.SECONDARY,
  },
};

export const useFormatTrackToBaseEntityRow = () => {
  const { user } = useUser();
  const history = useHistory();
  const { vw } = useViewport();
  const isMobile = vw <= 768 || isTouchDevice();

  const formatTrackToBaseEntityRow = ({
    track,
    options,
  }: {
    track: Track;
    options?: EntityTableRowOptions;
  }): EntityRowConfig => {
    return {
      key: track.id,
      id: track.id,
      entity: track,
      type: ENTITY_TYPE.TRACK,
      actions: <DefaultTrackActions track={track} />,
      createdAt: track.createdAt,
      name: track.title,
      onClick: () => {
        history.push(`/space/${track.spaceId}/${track.id}`);
      },
      isPlayable: options?.hasPlayableTracks,
      ...(user?.isMobileUser &&
        isMobile && {
          confirmOnClick: (confirm: Confirmator) =>
            confirm(trackDeeplinkConfirmPayload),
          onConfirmCancel: () => {
            window.location.href = `${withMobileDeeplinkRoot(`root/space/${track.spaceId}/${track.id}`)}`;
          },
        }),
    };
  };

  const formatTracksListToBaseEntityRows = ({
    tracks,
    options,
  }: {
    tracks: Track[];
    options?: EntityTableRowOptions;
  }) => {
    return tracks.map((track) => {
      return formatTrackToBaseEntityRow({ track, options });
    });
  };

  return { formatTrackToBaseEntityRow, formatTracksListToBaseEntityRows };
};

const useFormatFileToBaseEntityRow = () => {
  // Assumes all components using this function are wrapped with the AttachmentsContextProvider
  const { canEdit } = useAttachmentsContext();
  const { downloadFile } = useFileDownload();

  const formatFileToBaseEntityRow = (file: FileEntity): EntityRowConfig => {
    return {
      key: file.id,
      id: file.id,
      entity: file,
      type: ENTITY_TYPE.FILE,
      actions: <DefaultAttachmentActions attachment={file} />,
      createdAt: file.createdAt,
      name: file.name,
      onClick: file.url
        ? () => window.open(file.url, "_blank")
        : file.storagePath && canEdit
          ? () => downloadFile(file.id)
          : undefined,
    };
  };

  return { formatFileToBaseEntityRow };
};

export const getSortedChildRows = ({
  childItemRows,
  sortType,
  itemsOrder,
}: {
  childItemRows: EntityRowConfig[];
  sortType: SORT_TYPE;
  // Used to track order changes via drag and drop
  // TODO: Optimistic update benefit, but is this needed, or can we rely on backend to update the order via space.itemsOrder?
  itemsOrder?: string[];
}): EntityRowConfig[] => {
  if (sortType !== SORT_TYPE.CUSTOM) {
    return childItemRows.slice().sort((aRow, bRow) => {
      if (sortType === SORT_TYPE.ALPHABETICAL) {
        return sortRowByAlphabetical(aRow, bRow);
      }
      return sortRowByCreatedAt(aRow, bRow);
    });
  }

  const { orderedItems, unOrderedItems } = sortItemsByItemsOrder(
    itemsOrder || [],
    childItemRows as { id: Id; entity: Entity }[],
  );

  unOrderedItems.sort((aRow, bRow) => {
    return sortRowByCreatedAtAscending(
      aRow as EntityRowConfig,
      bRow as EntityRowConfig,
    );
  });

  return [...orderedItems, ...unOrderedItems];
};

export const useSpaceChildItemRows = ({
  space,
  options,
}: {
  space: Space;
  options?: EntityTableRowOptions;
}) => {
  const { globalSpaces, getGlobalSpace } = useGlobalSpaces();
  const { globalTracks } = useGlobalTracks();
  const { attachments } = useAttachmentsContext();
  const { formatSpaceToBaseEntityRow } = useFormatSpaceToBaseEntityRow();
  const { formatTrackToBaseEntityRow } = useFormatTrackToBaseEntityRow();
  const { formatFileToBaseEntityRow } = useFormatFileToBaseEntityRow();
  const spaceId = space.id;

  const childItemRows = useMemo(() => {
    const childTracks =
      globalTracks.childTracks.get(spaceId)?.map((trackId) => {
        return formatTrackToBaseEntityRow({
          track: globalTracks.tracks.get(trackId),
          options: {
            hasPlayableTracks: options?.hasPlayableTracks,
            tableRowVariant: options.tableRowVariant,
          },
        });
      }) || [];

    const childSpaces =
      globalSpaces.childSpaces
        .get(spaceId)
        ?.reduce<EntityRowConfig[]>((acc, childSpaceId) => {
          const childSpace = getGlobalSpace(childSpaceId, true);

          if (childSpace && !childSpace.isArchived) {
            acc.push(
              formatSpaceToBaseEntityRow({
                space: childSpace,
                options: { tableRowVariant: options?.tableRowVariant },
              }),
            );
          }
          return acc;
        }, []) || [];

    const childAttachments =
      attachments?.map((attachment) => {
        return formatFileToBaseEntityRow(attachment);
      }) || [];

    return [...childTracks, ...childSpaces, ...childAttachments];
  }, [
    globalSpaces.childSpaces.get(spaceId),
    globalTracks.childTracks.get(spaceId),
    attachments,
  ]);

  // Fetches all child spaces and tracks of the space
  const { manageGlobalChildEntityWatchers } = useChildEntityWatchers();

  useEffect(() => {
    const watcher = manageGlobalChildEntityWatchers({
      spaceId,
    });

    // Used to fetch child entities and set realtime watchers/listeners
    // for the current parent spaceId.
    watcher.attach();

    return () => {
      // Used to unsubscribe from realtime watchers/listeners
      // for the current parent spaceId.
      watcher.detach();
    };
  }, []);

  const _getSortedChildRows = useCallback(
    ({
      sortType = SORT_TYPE.CUSTOM,
      itemsOrder,
    }: {
      sortType: SORT_TYPE;
      // Used to track order changes via drag and drop
      // TODO: Optimistic update benefit, but is this needed, or can we rely on backend to update the order via space.itemsOrder?
      itemsOrder?: string[];
    }): EntityRowConfig[] => {
      return getSortedChildRows({
        sortType,
        childItemRows,
        itemsOrder: itemsOrder || space.itemsOrder,
      });
    },
    [childItemRows],
  );

  return { getSortedSpaceChildItemRows: _getSortedChildRows };
};

const _ExpandableParentSpaceRow = ({
  space,
  tableRowProps,
  sortType = SORT_TYPE.CUSTOM,
}: {
  space: Space;
  tableRowProps: Omit<BaseTableRowProps, "row"> & {
    hasPlayableTracks?: boolean;
  };
  sortType?: SORT_TYPE;
}) => {
  const { fetchAudioFiles } = useGlobalAudioPlayer();
  const { getSortedSpaceChildItemRows } = useSpaceChildItemRows({
    space,
    options: {
      tableRowVariant: tableRowProps.tableRowVariant,
      hasPlayableTracks: tableRowProps.hasPlayableTracks ?? true,
    },
  });

  // TODO: `itemsOrder` will need to be passed here as well for optimistically ordering nested rows via the client (client side sorting).
  // Currently, it defaults to the space.itemsOrder (from the server) if not passed in.
  const spaceChildItemRows = getSortedSpaceChildItemRows({
    sortType,
  });

  useEffect(() => {
    spaceChildItemRows.forEach((itemRow) => {
      if (itemRow.type === ENTITY_TYPE.TRACK) {
        fetchAudioFiles(itemRow.entity as Track);
      }
    });
  }, [spaceChildItemRows]);

  return (
    <>
      {spaceChildItemRows.map((childRow, i) => {
        const baseProps = {
          ...tableRowProps,
          indentation: tableRowProps.indentation + 1,
        };

        return (
          <BaseRow
            key={`expandable-space-row-${childRow.key}-${i}`}
            {...baseProps}
            row={{
              ...childRow,
              ...(childRow.type === ENTITY_TYPE.SPACE && {
                NestedTable: ExpandableParentSpaceRow,
              }),
            }}
          />
        );
      })}
    </>
  );
};

const ExpandableParentSpaceRow = ({
  parentRow,
  tableRowProps,
  sortType,
}: {
  parentRow: EntityRowConfig;
  tableRowProps: Omit<BaseTableRowProps, "row">;
  sortType?: SORT_TYPE;
}) => {
  const parentSpace = parentRow.entity as Space;

  return (
    <AttachmentsContextProvider
      space={parentSpace}
      attachmentIds={parentSpace.files || []}
    >
      <_ExpandableParentSpaceRow
        space={parentSpace}
        tableRowProps={tableRowProps}
        sortType={sortType}
      />
    </AttachmentsContextProvider>
  );
};
