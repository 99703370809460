import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { AUDIO_ACCEPT_TYPES_LIST } from "@highnote/server/src/core/entities";
import { Button, BUTTON_THEME } from "App/core/Button";
import { Dialog, DialogButtons, DialogSection } from "App/common/Dialog";
import styles from "./chooser.module.scss";
import { useAuth } from "App/components/Auth";
import {
  AUDIO_UPLOAD_SIZE_LIMIT_IN_BYTES,
  DropboxManagerBase,
  getStorageLimitByTier,
} from "@highnote/server/src/core/shared-util";
import { getUserDropboxJobsByFileIds } from "App/services/firebase/entities/helpers";

const chooserScriptId = "dropboxjs";

type DropboxFilesFromChooser = Array<{
  id: string;
  bytes: number;
  name: string;
}>;

// https://www.dropbox.com/developers/chooser
const baseChooserOptions = {
  // called when a user selects an item in the Chooser.
  success: () => {},
  // called when the Chooser dialog is closed
  cancel: () => {},
  multiselect: true,
  extensions: [...AUDIO_ACCEPT_TYPES_LIST],
  folderselect: false,
  sizeLimit: AUDIO_UPLOAD_SIZE_LIMIT_IN_BYTES,
};

enum DropboxChooserOption {
  SINGLE = "single",
  PLAYLIST = "playlist",
}

const getDropbox = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).Dropbox;
};

export const DropboxChooserDialog = ({
  fileId,
  fileName,
  folderName,
  onSubmit,
  onError,
}: {
  fileId: string;
  fileName: string;
  folderName?: string;
  onSubmit: (ids: string[]) => Promise<void>;
  onError?: (message: React.ReactNode | string) => void;
}) => {
  const { user } = useAuth();
  const [selectedOption, setSelectedOption] = useState<DropboxChooserOption>(
    DropboxChooserOption.SINGLE,
  );

  const [dropboxReady, setDropboxReady] = useState(Boolean(getDropbox()));
  const [isProcessing, setIsProceessing] = useState(false);

  useEffect(() => {
    if (document.getElementById(chooserScriptId)) {
      return;
    }
    const script = document.createElement("script");
    script.setAttribute("id", chooserScriptId);
    script.setAttribute(
      "data-app-key",
      process.env.REACT_APP_DROPBOX_CLIENT_ID,
    );
    script.src = "https://www.dropbox.com/static/api/2/dropins.js";
    script.async = true;
    script.addEventListener("load", () => {
      setDropboxReady(getDropbox());
    });
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // there is no need to check that each file is less than 2GB as the Dropbox Chooser
  // will not allow the user to select files that are too large (via sizeLimit option).
  const onFilesSelect = async (files: DropboxFilesFromChooser) => {
    try {
      setIsProceessing(true);
      // make sure that the user has not selected more than 10 files
      if (files.length > 10) {
        onError(DropboxManagerBase.tooManyFilesErrorMsg);
        return;
      }
      // make sure that the user has enough storage left to clone the files
      const totalSize = files.reduce((acc, file) => acc + file.bytes, 0);
      const storageUsed = user?.storageUsed || 0;
      const storageLimit = getStorageLimitByTier(user?.subscriptionTier);
      if (storageUsed + totalSize > storageLimit) {
        onError(DropboxManagerBase.storageLimitExceedsErrorMsg);
        return;
      }
      // make sure that the files have not been cloned before
      const existingJobs = await getUserDropboxJobsByFileIds({
        fileIds: files.map((file) => file.id),
        userId: user.id,
      });
      const dupeFiles = existingJobs
        .map((job) => files.find((file) => job.fileId === file.id))
        .filter(Boolean)
        .map((file) => file.name);
      if (existingJobs.length > 0) {
        onError(
          <div>
            <p>The following files have already been cloned:</p>
            <ul>
              {dupeFiles.map((dupeFile, i) => (
                <li key={`${dupeFile}-${i}`}>{dupeFile}</li>
              ))}
            </ul>
          </div>,
        );
        return;
      }
      await onSubmit(files.map((file) => file.id));
    } catch (e) {
      onError("Something went wrong");
      console.error(
        "Something went wrong while checking the Dropbox files: ",
        e,
      );
    } finally {
      setIsProceessing(false);
    }
  };

  const onContinue = () => {
    setIsProceessing(true);
    if (selectedOption === DropboxChooserOption.PLAYLIST) {
      getDropbox().choose({
        ...baseChooserOptions,
        success: onFilesSelect,
        cancel: () => {
          setIsProceessing(false);
        },
      });
      return;
    }
    onSubmit([fileId]).finally(() => {
      setIsProceessing(false);
    });
  };

  return (
    <Dialog open={dropboxReady} className="highnote-dropbox-chooser">
      <DialogSection className={styles.DropboxChooserDialog__header}>
        <img src="/public/highnote-dropbox.png" />
        <h1>Importing from Dropbox</h1>
        <h2>{fileName}</h2>
      </DialogSection>
      <DialogButtons className={styles.DropboxChooserDialog__btn__groups}>
        <Button
          className={classNames(styles.DropboxChooserDialog__btn, {
            [styles.DropboxChooserDialog__btn__selected]:
              selectedOption === DropboxChooserOption.SINGLE,
          })}
          disabled={isProcessing}
          theme={BUTTON_THEME.SECONDARY}
          onClick={() => setSelectedOption(DropboxChooserOption.SINGLE)}
        >
          <section className={styles.DropboxChooserDialog__option}>
            <span className={styles.DropboxChooserDialog__option__text}>
              Single Track
            </span>
            <span>{fileName}</span>
          </section>
          <section>
            <span className={styles.DropboxChooserDialog__option__free}>
              Free
            </span>
          </section>
        </Button>
        <Button
          className={classNames(styles.DropboxChooserDialog__btn, {
            [styles.DropboxChooserDialog__btn__selected]:
              selectedOption === DropboxChooserOption.PLAYLIST,
          })}
          disabled={isProcessing}
          theme={BUTTON_THEME.SECONDARY}
          onClick={() => setSelectedOption(DropboxChooserOption.PLAYLIST)}
        >
          <section className={styles.DropboxChooserDialog__option}>
            <span className={styles.DropboxChooserDialog__option__text}>
              Full Playlist
            </span>
            <span>
              Select more content {folderName ? `from ${folderName}` : ""}
            </span>
          </section>
          <section>
            <span className={styles.DropboxChooserDialog__option__free}>
              Free
            </span>
            <span>10 track limit</span>
          </section>
        </Button>
      </DialogButtons>
      <DialogSection>
        <Button
          className={styles.DropboxChooserDialog__submit}
          theme={BUTTON_THEME.CTA}
          onClick={onContinue}
          loading={isProcessing}
        >
          Continue
        </Button>
      </DialogSection>
    </Dialog>
  );
};
