import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import Knock, {
  PreferenceSet,
  Feed,
  FeedItem,
  FeedClientOptions,
  WorkflowPreferenceSetting,
} from "@knocklabs/client";
import { useAuth } from "App/components/Auth";
import { highnote } from "@highnote/server/src/sdk";

const knockClient = new Knock(process.env.REACT_APP_KNOCK_PUBLIC_KEY);
const FEED_ID = "f952827e-a510-40fc-a812-134791165ebe";

export const NOTIFICATION_FILTER: Record<string, NotificationFilter> = {
  ALL: {
    name: "All",
    status: "all",
  },
  UNREAD: {
    name: "Unread",
    status: "unread",
  },
};

type NotificationFilter = {
  name: string;
  status: FeedClientOptions["status"];
};

type NotificationsContextProps = {
  preferences?: PreferenceSet;
  notifications: FeedItem[];
  notificationsLoading: boolean;
  filter: NotificationFilter;
  setFilter: (filter: NotificationFilter) => void;
  setPreference: (workflowId: string, value: WorkflowPreferenceSetting) => void;
  markAsRead: (items: FeedItem[]) => void;
  markAsSeen: (items: FeedItem[]) => void;
};

const NotificationsContext = React.createContext<NotificationsContextProps>({
  filter: NOTIFICATION_FILTER.UNREAD,
  notifications: [],
  notificationsLoading: true,
  setFilter: () => {},
  setPreference: () => {},
  markAsRead: () => {},
  markAsSeen: () => {},
});

export const NotificationsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user: currentUser } = useAuth();
  const [notificationsLoading, setNotificationsLoading] =
    useState<boolean>(true);
  const [notifications, _setNotifications] = useState<FeedItem[]>([]);
  const [preferences, setPreferences] = useState<PreferenceSet>();
  const [filter, setFilter] = useState<NotificationFilter>(
    NOTIFICATION_FILTER.UNREAD,
  );
  const [renderTrigger, setRenderTrigger] = useState<string>(uuidv4());
  const [authenticatedKnock, setAuthenticatedKnock] = useState<Knock>();
  const unreadFeedRef = useRef<Feed>();
  const readFeedRef = useRef<Feed>();

  const knockUserId = currentUser?.id;

  useEffect(() => {
    let unmounted: boolean;

    if (!knockUserId || !knockClient) {
      return;
    }

    highnote.getKnockAuthToken().then((token: string) => {
      if (unmounted) return;
      knockClient.authenticate(knockUserId, token);
      unreadFeedRef.current = knockClient.feeds.initialize(FEED_ID);
      readFeedRef.current = knockClient.feeds.initialize(FEED_ID);
      setAuthenticatedKnock(knockClient);
    });

    return () => {
      unmounted = true;
      setAuthenticatedKnock(undefined);
      knockClient.teardown();
      setPreferences(undefined);
    };
  }, [knockUserId]);

  const setNotifications = useCallback(() => {
    const unreadFeed = unreadFeedRef.current;
    const readFeed = readFeedRef.current;

    const unreadState = unreadFeed
      ? unreadFeed.getState()
      : { items: [] as FeedItem[] };
    const readState = unreadFeed
      ? readFeed.getState()
      : { items: [] as FeedItem[] };

    const ids: string[] = [];
    const deduped: FeedItem[] = [];
    [...readState.items, ...unreadState.items].forEach((item) => {
      if (ids.includes(item.id)) return;
      deduped.push(item);
      ids.push(item.id);
    });

    _setNotifications(
      deduped.sort((a, b) => (a.inserted_at > b.inserted_at ? -1 : 1)),
    );
    setNotificationsLoading(false);
  }, []);

  useEffect(() => {
    let unmounted: boolean;
    if (!authenticatedKnock) {
      setNotificationsLoading(false);
      return;
    }

    const FEEDS = [
      {
        status: "unread",
        page_size: 80,
        autoRefetch: true,
        teardown: () => {},
        feedRef: unreadFeedRef,
      },
      {
        status: "read",
        page_size: 80,
        autoRefetch: false,
        teardown: () => {},
        feedRef: readFeedRef,
      },
    ];

    setNotificationsLoading(true);

    FEEDS.forEach((f) => {
      const feed = f.feedRef.current;
      f.teardown = feed.listenForUpdates();

      feed.on("items.received.*", () => {
        if (unmounted) return;
        if (f.autoRefetch) feed.fetchNextPage.call(feed);
        setNotifications();
      });

      feed.fetch({
        status: f.status as FeedClientOptions["status"],
        page_size: f.page_size,
      });
    });

    return () => {
      unmounted = true;
      FEEDS.forEach((f) => {
        f.teardown && f.teardown();
      });
    };
  }, [authenticatedKnock]);

  useEffect(() => {
    let unmounted: boolean;
    if (!authenticatedKnock) return;

    authenticatedKnock.preferences.get().then((_preferences) => {
      if (unmounted) return;
      setPreferences(_preferences);
    });

    return () => {
      unmounted = true;
    };
  }, [authenticatedKnock, renderTrigger]);

  const setPreference = async (
    workflowId: string,
    value: WorkflowPreferenceSetting,
  ) => {
    if (!authenticatedKnock) {
      console.log("Knock client not initialized properly.");
      return;
    }

    await authenticatedKnock.preferences.setWorkflow(workflowId, value);
    setRenderTrigger(uuidv4());
  };

  const markAs = useCallback(
    async (items: FeedItem[], status: "read" | "seen") => {
      try {
        if (items.length < 1) return;

        const batches = [];
        const numBatches = Math.ceil(items.length / 100);

        for (let i = 0; i < numBatches; i++) {
          batches.push(items.slice(i * 100, (i + 1) * 100));
        }

        await Promise.all(
          batches.map(async (batch) => {
            const unreadFeed = unreadFeedRef.current;
            const readFeed = readFeedRef.current;

            if (status === "read") {
              await unreadFeed?.markAsRead(batch);
              await readFeed?.markAsRead(batch);
            }

            await unreadFeed?.markAsSeen(batch);
            await readFeed?.markAsSeen(batch);
          }),
        );

        setNotifications();
      } catch (e) {
        console.log(`Could not mark as ${status}: ${items}`);
      }
    },
    [],
  );

  const currentDomain = location.origin;
  const value = useMemo(
    () => ({
      filter,
      preferences,
      // Only show notifications from this domain
      notifications: notifications.filter(
        (n) => currentDomain === n.data.origin,
      ),
      notificationsLoading,
      setPreference,
      setFilter,
      markAsRead: (items: FeedItem[]) => markAs(items, "read"),
      markAsSeen: (items: FeedItem[]) => markAs(items, "seen"),
    }),
    [
      filter,
      preferences,
      notifications,
      notificationsLoading,
      setPreference,
      setFilter,
    ],
  );

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsContext = () =>
  React.useContext(NotificationsContext);
