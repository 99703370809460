import React, { ReactNode, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import {
  ARCHIVABLE_ENTITY_TYPES,
  FileEntity,
  KNOCK_WORKFLOW,
  Space,
  Track,
} from "@highnote/server/src/core/entities";
import {
  PERMISSION,
  getDefaultVersionId,
} from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import { Menu, MenuItem } from "App/common/Menu";
import { ReactComponent as DownloadSVG } from "App/common/icons/download.svg";
import { ReactComponent as ExternalLinkSVG } from "App/common/icons/external-link.svg";
import { ReactComponent as FolderHollowSVG } from "App/common/icons/folder-hollow-zip.svg";
import { ReactComponent as MusicNoteSVG } from "App/common/icons/music-note.svg";
import { ReactComponent as PinSVG } from "App/common/icons/pin.svg";
import { ReactComponent as UnpinSVG } from "App/common/icons/unpin.svg";
import { useFileDownload } from "App/common/useFileDownload";
import { useHighnote } from "App/common/useHighnote";
import { useSpaceContext } from "App/common/useSpace";
import { useAuth } from "App/components/Auth";
import { BUTTON_SIZE, BUTTON_THEME, Button } from "App/core/Button";
import { PermissionTooltip } from "../PermissionTooltip";
import { useTrack } from "../useEntities/useTrack";
import { ENTITY_TYPE, EntityRowConfig, Version } from "./config";
import { DownloadSpaceMenu } from "App/routes/Main/Space/DownloadSpace";
import "./EntityShortcut.scss";
import { useUserDownloadRequests } from "../useEntities/useDownloadRequests";
import {
  TrackFileDownloadType,
  useEntityDownload,
} from "App/common/useEntityDownload";

export const TrackDownloadButton = ({
  track,
  trackArtworkUrl,
  className,
  toggleVisibility,
  showDownloadSpace = false,
}: {
  track: Track;
  trackArtworkUrl: string;
  className?: string;
  toggleVisibility?: (isVisible: boolean) => void;
  showDownloadSpace?: boolean;
}) => {
  const { isAllowed, isPublicView } = useAuth();
  const { downloadSpace } = useFileDownload();
  const { getDownloadRequest } = useUserDownloadRequests();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDownloadingDefault, setIsDownloadingDefault] = useState(false);
  const [isDownloadingAllVersions, setIsDownloadingAllVersions] =
    useState(false);
  const [isDownloadingTrackSpace, setIsDownloadingTrackSpace] = useState(false);

  const triggerRef = useRef();
  const [trackSpace, setTrackSpace] = useState<Space | undefined>();

  const { downloadTrackFiles } = useEntityDownload(trackSpace);

  const hasMultipleVersions = track.versionFilesV2.length > 1;
  const hasAssets = track.artworkFile || (track.files || []).length > 0;
  const hasOptions = hasAssets || hasMultipleVersions;
  const isVisible = isMenuOpen;

  const trackDownloadRequest = hasOptions
    ? getDownloadRequest(track.id, ARCHIVABLE_ENTITY_TYPES.TRACK)
    : undefined;
  const trackSpaceDownloadRequest = showDownloadSpace
    ? getDownloadRequest(track.spaceId, ARCHIVABLE_ENTITY_TYPES.SPACE)
    : undefined;
  const isDownloadingTrack =
    Boolean(trackDownloadRequest) || isDownloadingAllVersions;
  const isDownloadingSpace =
    Boolean(trackSpaceDownloadRequest) || isDownloadingTrackSpace;

  useEffect(() => {
    highnote
      .getSpace({ id: track.spaceId, fromCache: true })
      .then((space) => {
        setTrackSpace(space);
      })
      .catch(() => {});
  }, [track.spaceId]);

  if (!track.versionFilesV2 || track.versionFilesV2.length < 1) return null;

  const canDownload =
    trackSpace &&
    isAllowed(PERMISSION.TO_DOWNLOAD_TRACK_IN_SPACE, {
      track,
      space: trackSpace,
    });

  const defaultVersionId = getDefaultVersionId(track);

  if (!canDownload && isPublicView(trackSpace)) return null;

  const handleDownloadTrackFiles = async (type: TrackFileDownloadType) => {
    const statusSetter =
      type === "default"
        ? setIsDownloadingDefault
        : setIsDownloadingAllVersions;
    statusSetter(true);
    await downloadTrackFiles(type, track, trackArtworkUrl);
    statusSetter(false);
  };

  const handleDownloadSpace = async () => {
    setIsDownloadingTrackSpace(true);
    await downloadSpace(trackSpace.id);
    setIsDownloadingTrackSpace(false);
  };

  // The button row should be hidden via the parent for one of the following
  // scenarios, in the TableRow.
  // 1. On hover of row, button visible
  // 2. on click of button, button visible
  // 3. on click of download option, button visible
  // 4. on click outside of options, hide button

  useEffect(() => {
    if (toggleVisibility) {
      toggleVisibility(isVisible);
    }
  }, [isMenuOpen, toggleVisibility]);

  const disabled = !canDownload || (!hasOptions && isDownloadingDefault);
  return (
    <PermissionTooltip
      hasPermission={!disabled}
      title={
        !hasOptions && isDownloadingDefault
          ? "We're processing this download."
          : ""
      }
    >
      <div>
        <Button
          size={BUTTON_SIZE.LARGE}
          theme={BUTTON_THEME.ICON}
          disabled={disabled}
          className={classNames(
            "highnote-actions entity-shortcut-track-download-button-default",
            className,
          )}
          data-is-active={isMenuOpen}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (hasOptions) {
              setIsMenuOpen(true);
            } else {
              handleDownloadTrackFiles("default");
            }
          }}
          ref={triggerRef}
        >
          <DownloadSVG />
        </Button>
        <Menu
          className="highnote-actions-menu"
          data-cypress-id="highnote-actions-menu"
          anchorEl={triggerRef.current}
          onClick={(e) => {
            e.stopPropagation();
            setIsMenuOpen(false);
          }}
          onClose={() => {
            setIsMenuOpen(false);
          }}
          open={isMenuOpen}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <PermissionTooltip
            title="We're processing this download."
            hasPermission={!isDownloadingDefault}
          >
            <div>
              <MenuItem
                className="highnote-action-item"
                onClick={() => {
                  handleDownloadTrackFiles("default");
                }}
                disabled={!canDownload || isDownloadingDefault}
              >
                <MusicNoteSVG />
                <span>
                  Default Version{" "}
                  {`(V${track.versionFilesV2.indexOf(defaultVersionId) + 1})`}
                </span>
              </MenuItem>
            </div>
          </PermissionTooltip>
          {hasOptions && (
            <PermissionTooltip
              title="We're processing this download."
              hasPermission={!isDownloadingTrack}
            >
              <div>
                <MenuItem
                  className="highnote-action-item"
                  onClick={() => {
                    handleDownloadTrackFiles("all");
                  }}
                  disabled={!canDownload || isDownloadingTrack}
                >
                  <FolderHollowSVG />
                  <span>All Track Files</span>
                </MenuItem>
              </div>
            </PermissionTooltip>
          )}
          {showDownloadSpace && canDownload && (
            <PermissionTooltip
              title="We're processing this download."
              hasPermission={!isDownloadingSpace}
            >
              <div>
                <MenuItem
                  className="highnote-action-item"
                  onClick={handleDownloadSpace}
                  disabled={isDownloadingSpace}
                >
                  <FolderHollowSVG />
                  <span>All Space Files</span>
                </MenuItem>
              </div>
            </PermissionTooltip>
          )}
        </Menu>
      </div>
    </PermissionTooltip>
  );
};

export const EntityShortcut = ({ row }: { row: EntityRowConfig }) => {
  const { isAllowed, isPublicView } = useAuth();
  const { downloadFile } = useFileDownload();
  const [isDownloading, setIsDownloading] = useState(false);

  const { space, spaceArtworkUrl } = useSpaceContext();

  const { pinTrackVersion, unpinTrackVersion } = useHighnote();
  const { track, trackArtworkUrl, pinnedTrackVersions } = useTrack();
  let children: ReactNode;
  const entityShortcutRef = useRef<HTMLDivElement>();

  const toggleEntityShortcutVisibility = (isVisible: boolean) => {
    if (entityShortcutRef.current) {
      if (isVisible) {
        entityShortcutRef.current.classList.add("active-download");
      } else {
        entityShortcutRef.current.classList.remove("active-download");
      }
    }
  };

  if (row.type === ENTITY_TYPE.SPACE) {
    // TODO: Add this back when favoriting is implemented
    // children = <FavoriteButton entityId={row.id} />;
  }

  if (row.type === ENTITY_TYPE.SPACE && Boolean(space)) {
    const space = row.entity as Space;
    children = (
      <DownloadSpaceMenu
        className="entity-shortcut-space-download-button"
        space={space}
      />
    );
  }

  if (row.type === ENTITY_TYPE.TRACK) {
    const track = row.entity as Track;
    children = (
      <TrackDownloadButton
        className="entity-shortcut-track-download-button"
        track={track}
        trackArtworkUrl={trackArtworkUrl}
        toggleVisibility={(isVisible: boolean) =>
          toggleEntityShortcutVisibility(isVisible)
        }
      />
    );
  }

  if (row.type === ENTITY_TYPE.FILE) {
    const file = row.entity as FileEntity;
    const version = (file as Version).isVersion && (file as Version);

    const canAddToTrack = isAllowed(PERMISSION.TO_ADD_TO_TRACK_IN_SPACE, {
      track,
      space,
    });
    const canDownload = isAllowed(PERMISSION.TO_DOWNLOAD_FILE_IN_SPACE, {
      file,
      space,
    });

    const handleFileDownload = async (fileToDownload: FileEntity) => {
      setIsDownloading(true);
      await downloadFile(fileToDownload.id);
      const isTrack = Boolean(track);
      const basePayload = {
        artworkUrl: spaceArtworkUrl,
        spaceId: space.id,
        spaceName: space.name,
      };
      highnote.notify({
        workflowId: isTrack
          ? KNOCK_WORKFLOW.TRACK_ATTACHMENT_DOWNLOADED
          : KNOCK_WORKFLOW.SPACE_ATTACHMENT_DOWNLOADED,
        data: isTrack
          ? {
              ...basePayload,
              files: [
                {
                  fileId: fileToDownload.id,
                  fileName: fileToDownload.name,
                },
              ],
              trackArtworkUrl: trackArtworkUrl,
              trackId: track.id,
              trackName: track.title,
            }
          : {
              ...basePayload,
              fileId: fileToDownload.id,
              fileName: fileToDownload.name,
            },
      });
      setIsDownloading(false);
    };

    if (version) {
      const isCurrentlyPinned = pinnedTrackVersions.includes(version.id);
      const isOnlyPin = isCurrentlyPinned && pinnedTrackVersions.length === 1;

      if (!isOnlyPin && canAddToTrack) {
        children = (
          <Button
            className="pin-version-toggle-icon-button"
            size={BUTTON_SIZE.MEDIUM}
            theme={BUTTON_THEME.ICON}
            onClick={() => {
              if (isCurrentlyPinned) {
                unpinTrackVersion({ track, versionId: version.id });
                return;
              }
              pinTrackVersion({ track, versionId: version.id });
            }}
          >
            {isCurrentlyPinned ? <UnpinSVG /> : <PinSVG />}
          </Button>
        );
      }
    } else {
      if (file.storagePath && (canDownload || !isPublicView(space))) {
        children = (
          <PermissionTooltip
            hasPermission={!isDownloading && canDownload}
            title={isDownloading ? "We're processing this download." : ""}
          >
            <div>
              <Button
                size={BUTTON_SIZE.LARGE}
                theme={BUTTON_THEME.ICON}
                disabled={!canDownload || isDownloading}
                onClick={() => {
                  handleFileDownload(file);
                }}
                className="entity-shortcut-track-download-button-default"
              >
                <DownloadSVG />
              </Button>
            </div>
          </PermissionTooltip>
        );
      }

      if (file.url) {
        children = (
          <Button
            size={BUTTON_SIZE.LARGE}
            theme={BUTTON_THEME.ICON}
            onClick={() => {
              window.open(file.url, "_blank");
            }}
          >
            <ExternalLinkSVG />
          </Button>
        );
      }
    }
  }

  if (!children) return null;
  return (
    <div className="highnote-entity-shortcut" ref={entityShortcutRef}>
      {children}
    </div>
  );
};
