import {
  SUBSCRIPTION_PRICING,
  SUBSCRIPTION_TIER,
} from "@highnote/server/src/core/entities";

export type PlanConfig = {
  name: string;
  tier: SUBSCRIPTION_TIER;
  prices: string[];
  features: string[];
};

export const PLANS: PlanConfig[] = [
  {
    name: "Free",
    tier: SUBSCRIPTION_TIER.FREE,
    prices: [],
    features: ["50GB", "1 Shared Space", "Unlimited Private Spaces"],
  },
  {
    name: "Indie",
    tier: SUBSCRIPTION_TIER.INDIE,
    prices: Object.values(SUBSCRIPTION_PRICING[SUBSCRIPTION_TIER.INDIE]),
    features: ["200GB", "3 Shared Spaces", "Download Control"],
  },
  {
    name: "Pro",
    tier: SUBSCRIPTION_TIER.PRO,
    prices: Object.values(SUBSCRIPTION_PRICING[SUBSCRIPTION_TIER.PRO]),
    features: [
      "1TB",
      "Unlimited Shared Spaces",
      "Download Control",
      "Password Protected Links",
    ],
  },
  {
    name: "Studio",
    tier: SUBSCRIPTION_TIER.STUDIO,
    prices: Object.values(SUBSCRIPTION_PRICING[SUBSCRIPTION_TIER.STUDIO]),
    features: [
      "5TB",
      "Unlimited Shared Spaces",
      "Download Control",
      "Password Protected Links",
      "Audio Quality Lock",
    ],
  },
];
