export enum APP_FEATURES {
  DROPBOX_EXTENSION = "dropboxExtension",
  PRIVATE_INBOX = "privateInbox",
}

export const AppFeaturesStatus: Record<APP_FEATURES, boolean> = {
  [APP_FEATURES.DROPBOX_EXTENSION]: false,
  [APP_FEATURES.PRIVATE_INBOX]: false,
};

export const checkFeaturePermissions = (allowedChecks: {
  [key in APP_FEATURES]?: boolean;
}): boolean => {
  return Object.keys(allowedChecks).every((key) => {
    const featureKey = key as unknown as APP_FEATURES;
    if (AppFeaturesStatus[featureKey]) {
      return allowedChecks[featureKey];
    }
    return true;
  });
};
