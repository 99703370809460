import "./SpaceHomeV2.scss";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { THEME, ThemeProvider } from "App/common/ThemeProvider";
import { useSpaceContext } from "App/common/useSpace";
import { ReactComponent as LibrarySVG } from "App/common/icons/library.svg";
import { ReactComponent as UploadSVG } from "App/common/icons/upload.svg";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { useAuth } from "App/components/Auth";
import {
  getEntitySubscribers,
  isSpacePrivateInboxEnabled,
  MAX_DESCRIPTION_LENGTH,
  MAX_TITLE_LENGTH,
  PERMISSION,
  stripExtension,
} from "@highnote/server/src/core/shared-util";
import { useHistory } from "react-router";
import { ImageWithFallback } from "App/common/ImageWithFallback";
import {
  useGlobalAudioPlayer,
  useHTMLAudioPlayer,
} from "App/components/GlobalAudioPlayer";
import {
  GlobalAudioIcon,
  GlobalAudioPlayer,
  GlobalAudioTrackPreview,
} from "App/components/GlobalAudioPlayer/GlobalAudioPlayer";
import { AddToSpaceMenu } from "./AddToSpace";
import {
  AttachmentsContextProvider,
  useAttachmentsContext,
} from "App/common/useAttachments";
import { DownloadSpaceMenu } from "./DownloadSpace";
import { EntitySortMenu } from "App/components/EntityLibrary/EntitySortMenu";
import { FileDrop } from "App/common/FileDrop";
import { AudioFileSelect, FileSelect } from "App/common/FileSelect";
import { UPLOAD_GROUP, useFiles } from "App/components/useFiles";
import { highnote } from "@highnote/server/src/sdk";
import { useAddToSpace } from "App/components/useEntities/useLibraryTracks/useAddToSpace";
import { SpaceHomeNavigation } from "./SpaceNavigation";
import { EditableArtwork } from "App/components/EditableArtwork";
import { useToast } from "App/common/useToast";
import { EditableText } from "App/components/EditableText";
import { useNotificationsContext } from "App/common/useNotifications";
import {
  AUDIO_ACCEPT_TYPES,
  JOB_STATUS,
  KNOCK_WORKFLOW,
} from "@highnote/server/src/core/entities";
import { useEntityShareKeyInUrl } from "App/components/ShareDialog/Members/InviteByLink";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";
import { SpaceItems } from "./SpaceItems";
import { useChildEntityWatchers } from "App/store/helpers/useChildEntityWatchers";
import { DownloadRequestsContextProvider } from "App/components/useEntities/useDownloadRequests";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { useGlobalTracks } from "App/store/tracks/useGlobalTracks";
import { DropboxJobsContextProvider } from "App/components/useEntities/useDropboxDownloads";
import { PrivateInboxVisitDialog } from "./PrivateInboxIntroDialog";
import {
  APP_FEATURES,
  AppFeaturesStatus,
} from "@highnote/server/src/core/features";
import { useCreatedTracks } from "App/components/useEntities/useCreatedTracks";

const ONBOARDING_SHARE_SPACE_LOCALSTORAGE_KEY =
  "highnote-share-screen-onboarded";

const SpaceHomeUI = () => {
  const { user, joinEntity } = useAuth();
  const { toasted } = useToast();
  const {
    space,
    spaceArtworkUrl,
    setSpaceArtworkUrl,
    itemSortType,
    setItemSortType,
  } = useSpaceContext();
  const { globalSpaces } = useGlobalSpaces();
  const { globalTracks } = useGlobalTracks();
  const { createdTracks } = useCreatedTracks();
  const { getChildSpaces, getChildTracks } = useChildEntityWatchers();

  const subscribers = getEntitySubscribers(space);
  const isMember = subscribers.includes(user?.id);
  const isInboxGuest = isSpacePrivateInboxEnabled(space) && !isMember;

  const childSpaces = useMemo(
    () => getChildSpaces(space?.id),
    [globalSpaces.childSpaces.get(space.id)],
  );

  const spaceTracks = useMemo(() => {
    return isInboxGuest ? createdTracks : getChildTracks(space?.id);
  }, [isInboxGuest, globalTracks.childTracks.get(space.id), createdTracks]);

  const spaceAttachments = useMemo(() => {
    return isInboxGuest ? [] : space?.files || [];
  }, [isInboxGuest, space?.files]);

  const { isAllowed } = useAuth();
  const { markAsRead, notifications } = useNotificationsContext();
  const { uploadFiles, getUploadCache, removeUpload } = useFiles();
  const { attachFileToContext } = useAttachmentsContext();
  const { openAddToSpace } = useAddToSpace();
  useEntityShareKeyInUrl(space);
  const [showShareSpaceOnboarding, setShowShareSpaceOnboarding] =
    useState(false);

  const [isPrivateInboxModalOpen, setIsPrivateInboxModalOpen] = useState(false);

  const fileDragRef = useRef<HTMLDivElement>();
  const unmountedRef = useRef<boolean>(false);

  const canManage = isAllowed(PERMISSION.TO_MANAGE_SPACE, { space });
  const canAdd = isAllowed(PERMISSION.TO_ADD_TO_SPACE, { space });
  const canDownload = isAllowed(PERMISSION.TO_DOWNLOAD_SPACE, {
    space,
  });

  useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    [],
  );

  useEffect(() => {
    if (!space) return;
    const unreads = notifications.filter(
      (n) =>
        n.source.key === KNOCK_WORKFLOW.SPACE_MEMBER_ADDED &&
        n.data.spaceId === space?.id &&
        !n.read_at,
    );
    markAsRead(unreads);
  }, [space, notifications]);

  const scrollToTop = () => {
    if (document) {
      // scroll to the top of the page before showing the dialog
      // as the button being indicated is showing at the top
      document.querySelector(".highnote-space-home .scrollable").scrollTo(0, 0);
    }
  };

  const onUploadFiles = useCallback(
    async (files: File[]) => {
      // if the user has already been introduced to the Private Inbox,
      if (isPrivateInboxModalOpen) {
        setIsPrivateInboxModalOpen(false);
      }

      if (files.length < 1) return;
      const uploadPayloads = files.map((file) => {
        const asTrack = AUDIO_ACCEPT_TYPES.includes(file.type);
        const cache = asTrack
          ? getUploadCache(UPLOAD_GROUP.TRACKS_BY_SPACE, space?.id)
          : getUploadCache(UPLOAD_GROUP.ATTACHMENTS_BY_SPACE, space?.id);
        return {
          file,
          cache,
        };
      });

      await uploadFiles({
        payloads: uploadPayloads,
        onSuccess: async (file, fileEntity) => {
          const asTrack = AUDIO_ACCEPT_TYPES.includes(file.type);
          if (asTrack) {
            await highnote.createTrack({
              id: uuidv4(),
              data: {
                title: stripExtension(fileEntity.fileName),
                versionFilesV2: [fileEntity.id],
                spaceId: space?.id,
              },
            });

            if (user) {
              if (!user.shareSpaceOnboarded) {
                // scroll to top first, to ensure dialog indicates to the top button
                scrollToTop();

                // if first track ever uploaded to a space, show helper dialog prompt
                // if user has never been onboarded to share space, show helper dialog prompt
                setShowShareSpaceOnboarding(true);
              }
            } else {
              const anonOnboardedShareSpace = localStorage.getItem(
                ONBOARDING_SHARE_SPACE_LOCALSTORAGE_KEY,
              );

              if (!anonOnboardedShareSpace) {
                // scroll to top first, to ensure dialog indicates to the top button
                scrollToTop();
                // if first track ever uploaded to a space, for anon users, show helper dialog prompt
                setShowShareSpaceOnboarding(true);
              }
            }
          } else {
            await attachFileToContext(fileEntity.id);
          }

          joinEntity({
            entity: space,
            entityType: "Space",
            trigger: JOIN_ENTITY_TRIGGER.UPLOAD,
          });

          removeUpload(fileEntity.id);
        },
      });
    },
    [space?.id, user],
  );

  const spaceItems = [...spaceTracks, ...childSpaces, ...spaceAttachments];

  useEffect(() => {
    if (!user || !space) return;

    const hasIntroducedToPrivateInbox = Boolean(
      user?.hasIntroducedToPrivateInbox,
    );

    const isPrivateInboxEnabled = isSpacePrivateInboxEnabled(space);

    if (!hasIntroducedToPrivateInbox && isPrivateInboxEnabled) {
      setTimeout(() => {
        setIsPrivateInboxModalOpen(true);

        /**
         * Prevents the user from seeing the Private Inbox dialog again
         * until they have been introduced to it.
         */
        highnote.updateUser({
          id: user.id,
          data: {
            hasIntroducedToPrivateInbox: true,
          },
        });
      }, 1000);
    }
  }, [user, space]);

  return (
    <ThemeProvider theme={THEME.DARK}>
      <div
        className="highnote-space-home"
        data-cypress-id="highnote-space-home"
      >
        {canAdd && (
          <FileDrop
            dragRef={fileDragRef}
            onDrop={onUploadFiles}
            instruction={"Add files to this Space by dropping them here"}
          />
        )}

        {AppFeaturesStatus[APP_FEATURES.PRIVATE_INBOX] && (
          <PrivateInboxVisitDialog
            spaceOwner={space.createdBy}
            isOpen={isPrivateInboxModalOpen}
            onUploadFiles={onUploadFiles}
            onClose={() => setIsPrivateInboxModalOpen(false)}
          />
        )}

        <div className="scrollable">
          <div className="hero" data-cypress-id="highnote-space-home-hero">
            <SpaceHomeNavigation
              showShareSpaceOnboarding={showShareSpaceOnboarding}
              closeShareSpaceOnboarding={() => {
                setShowShareSpaceOnboarding(false);

                if (user) {
                  highnote.updateUser({
                    id: user.id,
                    data: { shareSpaceOnboarded: true },
                  });
                } else {
                  localStorage.setItem(
                    ONBOARDING_SHARE_SPACE_LOCALSTORAGE_KEY,
                    "true",
                  );
                }
              }}
            />

            <div className="space-preview">
              <span className={"space-art-wrapper"}>
                <EditableArtwork
                  disabled={!canManage}
                  artworkUrl={spaceArtworkUrl}
                  fallbackArtworkUrl={"/public/default-space-artwork.png"}
                  onChange={async (file, tempUrl) => {
                    const cachedArtworkUrl = spaceArtworkUrl;
                    setSpaceArtworkUrl(tempUrl);

                    try {
                      await toasted({
                        promise: highnote.updateSpace({
                          id: space?.id,
                          data: {
                            artworkFile: file?.id || null,
                          },
                        }),
                        successMessage: `Space artwork ${
                          file ? "saved" : "removed"
                        }.`,
                        errorMessage: `Could not ${
                          file ? "update" : "remove"
                        } Space artwork. Please try again.`,
                      });
                    } catch (e) {
                      if (unmountedRef.current) return;
                      setSpaceArtworkUrl(cachedArtworkUrl);
                    }
                  }}
                />
              </span>

              <div className="space-info">
                <EditableText
                  data-cypress-id="space-name"
                  className="space-name"
                  disabled={!canManage}
                  value={space?.name}
                  maxLength={MAX_TITLE_LENGTH}
                  required
                  onChange={async (name) => {
                    if (name === space?.name) return;

                    if (space?.id) {
                      await toasted({
                        promise: highnote.updateSpace({
                          id: space.id,
                          data: { name },
                        }),
                        successMessage: `Space name saved!`,
                        errorMessage: `Could not save Space name. Please try again.`,
                      });
                    }
                  }}
                />

                <div className="spacer"></div>

                <EditableText
                  data-cypress-id="space-description"
                  className="space-description"
                  disabled={!canManage}
                  value={space?.description}
                  maxLength={MAX_DESCRIPTION_LENGTH}
                  placeholder={
                    canManage ? "Add a topic or description for this Space" : ""
                  }
                  onChange={async (description) => {
                    if (description === space?.description) return;
                    if (space?.id) {
                      await toasted({
                        promise: highnote.updateSpace({
                          id: space.id,
                          data: { description },
                        }),
                        successMessage: `Space description saved!`,
                        errorMessage: `Could not save Space description. Please try again.`,
                      });
                    }
                  }}
                />

                <div className="space-header-action-buttons">
                  <GlobalAudioIcon canUpdateQueueOnPlay />
                  {canDownload && <DownloadSpaceMenu space={space} />}
                </div>
              </div>
            </div>
            <div className="background">
              <ImageWithFallback
                backgroundStyle="linear-gradient(0deg, #121216, transparent, transparent)"
                src={spaceArtworkUrl || "/public/default-space-artwork.png"}
                fallback={null}
              />
            </div>
          </div>

          {canAdd && (
            <div className="space-action-buttons">
              <div className="inner">
                {isInboxGuest ? (
                  <AudioFileSelect allowMultiple onSelect={onUploadFiles}>
                    <Button size={BUTTON_SIZE.SMALL} theme={BUTTON_THEME.CTA}>
                      <UploadSVG /> Upload
                    </Button>
                  </AudioFileSelect>
                ) : (
                  <FileSelect allowMultiple onSelect={onUploadFiles}>
                    <Button size={BUTTON_SIZE.SMALL} theme={BUTTON_THEME.CTA}>
                      <UploadSVG />
                      Upload
                    </Button>
                  </FileSelect>
                )}

                <AddToSpaceMenu />

                {!!user && (
                  <Button
                    size={BUTTON_SIZE.SMALL}
                    theme={BUTTON_THEME.SECONDARY}
                    onClick={() => openAddToSpace(space)}
                  >
                    <LibrarySVG />
                    Add from Library
                  </Button>
                )}
              </div>
            </div>
          )}

          <div className="outer-library-container" ref={fileDragRef}>
            <div
              className="entity-library-container"
              data-cypress-id="library-container"
            >
              {spaceItems.length > 0 && (
                <div className="count-sort-container">
                  <div className="entity-count">{spaceItems.length} Items</div>
                  {isMember && (
                    <EntitySortMenu
                      sortType={itemSortType}
                      onChange={setItemSortType}
                    />
                  )}
                </div>
              )}
              <DropboxJobsContextProvider
                spaceId={space?.id}
                statuses={[
                  JOB_STATUS.PENDING,
                  JOB_STATUS.IN_PROGRESS,
                  JOB_STATUS.FAILED,
                ]}
              >
                <SpaceItems sortType={itemSortType} />
              </DropboxJobsContextProvider>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

const SpaceHomePlayer = () => {
  const history = useHistory();
  const { spaceId } = useSpaceContext();
  const { goToTrack, nowPlaying, prevTrackId } = useGlobalAudioPlayer();
  const { useHTMLPlayer, currentTime, onSeek } = useHTMLAudioPlayer();

  const playbackProps = {
    ...(useHTMLPlayer && {
      onPrev: () => {
        if (currentTime > 1) {
          onSeek(0);
          return;
        }
        goToTrack(prevTrackId);
      },
    }),
  };

  const preview = useMemo(() => {
    const track = nowPlaying.track;

    return (
      <GlobalAudioTrackPreview
        track={track}
        onClick={() => {
          history.push(`/space/${spaceId}/${track.id}`);
        }}
      />
    );
  }, [nowPlaying.track, spaceId]);

  return <GlobalAudioPlayer preview={preview} {...playbackProps} />;
};

export const SpaceHome = () => {
  const { space } = useSpaceContext();
  return (
    <AttachmentsContextProvider space={space} attachmentIds={space?.files}>
      <DownloadRequestsContextProvider isProcessing>
        <SpaceHomeUI />
      </DownloadRequestsContextProvider>
      <SpaceHomePlayer />
    </AttachmentsContextProvider>
  );
};
