import React from "react";
import { ROLE } from "@highnote/server/src/core/entities";
import { getEntityShareKeys } from "@highnote/server/src/core/shared-util";
import { DialogSection } from "App/common/Dialog";
import { usePlanLimitsContext } from "App/common/PlanLimits/usePlanLimits";
import {
  CONTROLLED_SHARE_FEATURES,
  ControlledFeatureCallout,
} from "App/core/ControlledFeatureCallout";
import { InviteByLink } from "../Members/InviteByLink";
import { useShareDialogContext } from "../ShareDialogContext";

export const ShareDialogLink = () => {
  const { isEntityOwner, entity, canManageDownloadControl, canManagePassword } =
    useShareDialogContext();
  const { showPlanPickerDialog } = usePlanLimitsContext();

  const [shareKey] = getEntityShareKeys(entity);
  const shareRoles = (entity?.rolesV2 || {})[shareKey] || [];

  const isShared = shareRoles.includes(ROLE.VIEW);

  const downloadToggleable =
    isShared && shareRoles.includes(ROLE.DOWNLOAD)
      ? canManageDownloadControl
      : true;

  const passwordToggleable = canManagePassword;

  return (
    <div className="ShareDialogMembers">
      <DialogSection>
        <InviteByLink />
      </DialogSection>

      {isEntityOwner && (
        <ControlledFeatureCallout
          featuresStatus={{
            [CONTROLLED_SHARE_FEATURES.DOWNLOAD]: {
              enabled: downloadToggleable,
            },
            [CONTROLLED_SHARE_FEATURES.PASSWORD]: {
              enabled: passwordToggleable,
              ...(!downloadToggleable && {
                label: "passwords",
              }),
            },
          }}
          upgradeSuffix={
            !downloadToggleable && !passwordToggleable
              ? "for advanced sharing options inc."
              : "to enable"
          }
          onUpgrade={showPlanPickerDialog}
        />
      )}
    </div>
  );
};
