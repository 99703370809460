import "./EditableArtwork.scss";
import React, { useEffect, useRef, useState } from "react";
import { ImageWithFallback } from "App/common/ImageWithFallback";
import { ReactComponent as EditSVG } from "App/common/icons/edit.svg";
import { ReactComponent as TrashSVG } from "App/common/icons/trash.svg";
import { ImageFileSelect } from "App/common/FileSelect";
import { useFiles } from "App/components/useFiles";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { FileEntity } from "@highnote/server/src/core/entities";
import { ProgressBar } from "App/common/ProgressBar";

export const EditableArtwork = ({
  disabled,
  artworkUrl,
  fallbackArtworkUrl,
  onChange,
  onFileSelected,
}: {
  disabled?: boolean;
  artworkUrl: string;
  fallbackArtworkUrl: string;
  onChange?: (file: FileEntity, temporaryUrl?: string) => void;
  onFileSelected?: (file: File) => void;
}) => {
  const { uploadFile } = useFiles();
  const unmountedRef = useRef<boolean>(false);
  const [progress, setProgress] = useState<number>();

  useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    [],
  );

  const onFileSelect = async (files: File[]) => {
    if (!files || !files[0]) {
      return;
    }

    onFileSelected?.(files[0]);
    const uploadStart = Date.now();
    const fileEntity = await uploadFile({
      file: files[0],
      onStateChange: (snapshot) => {
        const now = Date.now();
        // Only show upload progress if it's taking more than 2 seconds
        if (now - uploadStart > 2000) {
          const progress = snapshot.bytesTransferred / snapshot.totalBytes;
          setProgress(progress || 0.0000001);
        }
      },
    });

    if (unmountedRef.current) return;
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        if (unmountedRef.current) return;
        const tempUrl = reader.result as string;
        onChange && onChange(fileEntity, tempUrl);
      },
      false,
    );
    reader.readAsDataURL(files[0]);
  };

  const removeArtwork = () => {
    onChange && onChange(null);
  };

  return (
    <div className="EditableArtwork" data-disabled={!!disabled}>
      <div className="EditableArtwork-image">
        <ImageWithFallback src={artworkUrl} fallback={fallbackArtworkUrl} />
      </div>

      {progress > 0 && progress < 1 && (
        <div className="EditableArtwork-uploading">
          <ProgressBar progress={progress} />
        </div>
      )}

      {!disabled && (
        <div className="EditableArtwork-actions">
          {!!artworkUrl && (
            <Button
              theme={BUTTON_THEME.ICON}
              size={BUTTON_SIZE.SMALL}
              onClick={removeArtwork}
            >
              <TrashSVG />
            </Button>
          )}

          <ImageFileSelect onSelect={onFileSelect}>
            <Button theme={BUTTON_THEME.ICON} size={BUTTON_SIZE.SMALL}>
              <EditSVG />
            </Button>
          </ImageFileSelect>
        </div>
      )}
    </div>
  );
};
