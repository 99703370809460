import "./StatusIndicator.scss";
import React from "react";
import { Tooltip } from "App/core/Tooltip";
import { ReactComponent as ExclamationSVG } from "App/common/icons/exclamation.svg";
import { ReactComponent as RetrySVG } from "App/common/icons/retry.svg";
import { LoadingSpinner } from "App/common/icons/LoadingSpinner";

export const StatusIndicator = ({
  isLoading,
  errorMessage,
  retryCallback,
}: {
  isLoading?: boolean;
  errorMessage?: string;
  retryCallback?: () => void;
}) => {
  if (errorMessage) {
    return (
      <Tooltip title={errorMessage}>
        <div className="highnote-status-indicator" onClick={retryCallback}>
          {retryCallback ? (
            <RetrySVG className="retry" />
          ) : (
            <ExclamationSVG className="error" />
          )}
        </div>
      </Tooltip>
    );
  }

  if (isLoading) {
    return (
      <div className="highnote-status-indicator">
        <LoadingSpinner />
      </div>
    );
  }

  return null;
};
