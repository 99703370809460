import "./SpaceSettings.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "../Auth";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { DialogSection } from "App/common/Dialog";
import { useTheme } from "App/common/ThemeProvider";
import { Switch } from "App/core/Switch";
import { PermissionTooltip } from "App/components/PermissionTooltip";
import {
  isSpacePrivateInboxEnabled,
  isValidUrl,
  PERMISSION,
  isSpacePrivateInboxToggleable,
} from "@highnote/server/src/core/shared-util";
import {
  CONTROLLED_SHARE_FEATURES,
  ControlledFeatureCallout,
  ControlledFeatureTooltipLabel,
} from "App/core/ControlledFeatureCallout";
import { List, ListItemButton } from "App/common/List";
import { highnote } from "@highnote/server/src/sdk";
import { useToast } from "App/common/useToast";
import { usePlanLimitsContext } from "App/common/PlanLimits/usePlanLimits";
import { useSpaceRaw } from "../useEntities";
import { SUBSCRIPTION_TIER_FORMATTED } from "@highnote/server/src/core/entities";
import { TextInput } from "App/common/TextInput";
import { debounce } from "lodash";

const FEATURES = [
  "privateInbox",
  "audioQuality",
  "privateInboxRedirectUrl",
] as const;

type FeatureKey = (typeof FEATURES)[number];

export const SpaceSettings = () => {
  const { showPlanPickerDialog } = usePlanLimitsContext();
  const { addErrorMessage } = useToast();
  const { user, isAllowed } = useAuth();
  const { entity: space, loading: isSpaceLoading } = useSpaceRaw();
  const { theme } = useTheme();

  const redirectInputRef = useRef<HTMLInputElement>(null);
  const [redirectInputValue, setRedirectInputValue] = useState<string>("");
  const [redirectInputInvalid, setRedirectInputInvalid] =
    useState<boolean>(false);

  const [isLockedOptimistic, setIsLockedOptimistic] = useState<
    Record<FeatureKey, boolean>
  >({
    privateInbox: Boolean(space?.privateInboxEnabled),
    audioQuality: Boolean(space?.audioQualityLocked),
    privateInboxRedirectUrl: Boolean(space?.privateInboxRedirectUrl),
  });

  useEffect(() => {
    if (!isSpaceLoading && space) {
      setIsLockedOptimistic({
        privateInbox: space.privateInboxEnabled,
        audioQuality: space.audioQualityLocked,
        privateInboxRedirectUrl: Boolean(space.privateInboxRedirectUrl),
      });

      setRedirectInputValue(space.privateInboxRedirectUrl || "");
    }
  }, [space, isSpaceLoading]);

  const updateRedirectUrl = useCallback(
    debounce(async (value: string) => {
      if (!isLockedOptimistic.privateInboxRedirectUrl) return;

      try {
        if (!isValidUrl(value)) {
          throw new Error("Invalid URL");
        }

        await highnote.updateSpace({
          id: space.id,
          data: {
            privateInboxRedirectUrl: value,
          },
        });
      } catch (err) {
        setRedirectInputInvalid(true);
        console.error("Error updating redirect url: ", err);
        addErrorMessage(`Could not update redirect URL: ${err.message}`);
      }
    }, 2000),
    [isLockedOptimistic.privateInboxRedirectUrl],
  );

  useEffect(() => {
    return () => updateRedirectUrl.cancel();
  }, [updateRedirectUrl]);

  const handleToggle = async (feature: FeatureKey, nowLocked: boolean) => {
    try {
      setIsLockedOptimistic({
        ...isLockedOptimistic,
        [feature]: nowLocked,
      });

      if (feature === "privateInbox") {
        await highnote.updateSpace({
          id: space.id,
          data: {
            privateInboxEnabled: nowLocked,
          },
        });
      }

      if (feature === "audioQuality") {
        await highnote.updateSpace({
          id: space.id,
          data: {
            audioQualityLocked: nowLocked,
          },
        });
      }

      if (feature === "privateInboxRedirectUrl" && !nowLocked) {
        await highnote.updateSpace({
          id: space.id,
          data: {
            privateInboxRedirectUrl: null,
          },
        });
      }
    } catch (err) {
      console.error("Error updating feature lock: ", err);
      setIsLockedOptimistic({
        ...isLockedOptimistic,
        [feature]: !nowLocked,
      });
      addErrorMessage(
        `Could not ${
          nowLocked ? "unlock" : "lock"
        } ${feature}. Please try again.`,
      );
    }
  };

  const isSpaceOwner = user && space && user.id === space.createdBy;
  const canManageSpace =
    Boolean(space) && isAllowed(PERMISSION.TO_MANAGE_SPACE, { space });

  const canToggleAudioQualityLock =
    Boolean(space) && isAllowed(PERMISSION.TO_LOCK_AUDIO_QUALITY, { space });

  const isDisabled = {
    privateInbox: isSpaceLoading,
    privateInboxRedirectUrl: !canManageSpace || isSpaceLoading,
    audioQuality: !canToggleAudioQualityLock || isSpaceLoading,
  };

  const isPrivateInboxEnabled = isSpacePrivateInboxEnabled(space);

  return (
    <ErrorBoundary name="SpaceSettings">
      {isSpaceOwner && (
        <ControlledFeatureCallout
          className="highnote-space-editor-callout"
          featuresStatus={{
            [CONTROLLED_SHARE_FEATURES.AUDIO_QUALITY_LOCK]: {
              enabled: canToggleAudioQualityLock,
            },
          }}
          upgradeSuffix={`to Highnote ${SUBSCRIPTION_TIER_FORMATTED.STUDIO} for`}
          onUpgrade={showPlanPickerDialog}
        />
      )}
      <DialogSection
        className="highnote-space-editor-settings"
        data-theme={theme}
      >
        <List>
          {isSpacePrivateInboxToggleable(space) && (
            <ListItemButton
              className="highnote-space-editor-listbutton"
              disabled={isDisabled.privateInbox}
              onClick={() =>
                handleToggle("privateInbox", !isLockedOptimistic.privateInbox)
              }
            >
              <div
                data-disabled={"false"}
                className="highnote-space-editor-settings-inner"
              >
                <h3>Private Inbox</h3>
                <p>Accept files and collaborate with visitors privately</p>
              </div>

              <div>
                <Switch
                  disabled={isDisabled.privateInbox}
                  checked={isLockedOptimistic.privateInbox}
                />
              </div>
            </ListItemButton>
          )}
          {isPrivateInboxEnabled && isLockedOptimistic.privateInbox && (
            <ListItemButton
              className="highnote-space-editor-listbutton"
              disabled={isDisabled.privateInboxRedirectUrl}
              onClick={() =>
                handleToggle(
                  "privateInboxRedirectUrl",
                  !isLockedOptimistic.privateInboxRedirectUrl,
                )
              }
            >
              <section>
                <div
                  data-disabled={"false"}
                  className="highnote-space-editor-settings-inner"
                >
                  <h3>Redirect</h3>
                  <p>Redirect visitors to another page after they submit</p>
                </div>

                <PermissionTooltip
                  title={
                    canManageSpace ? "Contact the space owner." : undefined
                  }
                  hasPermission={canManageSpace}
                >
                  <div>
                    <Switch
                      disabled={isDisabled.privateInboxRedirectUrl}
                      checked={isLockedOptimistic.privateInboxRedirectUrl}
                    />
                  </div>
                </PermissionTooltip>
              </section>

              <TextInput
                placeholder="https://..."
                type="text"
                isSingleLine
                isDisabled={!isLockedOptimistic.privateInboxRedirectUrl}
                inputRef={redirectInputRef}
                value={redirectInputValue}
                onClick={(event) => event.stopPropagation()} // prevents clicking on the input and toogle redirectUrl switch
                onChange={(value) => {
                  setRedirectInputInvalid(false);
                  setRedirectInputValue(value);
                  updateRedirectUrl(value);
                }}
              />
              {isLockedOptimistic.privateInboxRedirectUrl &&
                redirectInputInvalid && (
                  <span className="input-error">Invalid URL</span>
                )}
            </ListItemButton>
          )}
          <ListItemButton
            className="highnote-space-editor-listbutton"
            disabled={isDisabled.audioQuality}
            onClick={() => {
              handleToggle("audioQuality", !isLockedOptimistic.audioQuality);
            }}
          >
            <div
              data-disabled={`${!canToggleAudioQualityLock ? "true" : "false"}`}
              className="highnote-space-editor-settings-inner"
            >
              <h3>Audio Quality Lock</h3>
              <p>
                Lock audio at Original Quality in this space, regardless of
                listeners&apos; connection speed
              </p>
            </div>
            <PermissionTooltip
              title={
                isSpaceOwner
                  ? ControlledFeatureTooltipLabel[
                      CONTROLLED_SHARE_FEATURES.AUDIO_QUALITY_LOCK
                    ]
                  : canManageSpace
                    ? "Contact the space owner."
                    : undefined
              }
              hasPermission={canToggleAudioQualityLock}
            >
              <div>
                <Switch
                  disabled={isDisabled.audioQuality}
                  checked={isLockedOptimistic.audioQuality}
                />
              </div>
            </PermissionTooltip>
          </ListItemButton>
        </List>
      </DialogSection>
    </ErrorBoundary>
  );
};
