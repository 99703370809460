import "./GlobalSidebar.scss";
import React, { useEffect, useRef, useState } from "react";
import { Menu, MenuItem } from "App/common/Menu";
import { SpacePicker } from "./SpacePicker";
import { routePaths } from "App/modules/urls";
import { ReactComponent as EllipsisVerticalSVG } from "App/common/icons/ellipsis-vertical.svg";
import { ReactComponent as LogoFullSVG } from "App/common/icons/logo-full.svg";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { List, ListItemButton } from "App/common/List";
import { useSidebarContext } from "../Sidebar";
import { useAuth } from "App/components/Auth";

import {
  ConnectedUserAvatar,
  USER_AVATAR_SIZE,
} from "App/common/UserAvatar/UserAvatar";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { PlanLimitCallout } from "App/components/Plans/PlanLimitCallout";
import { ReactComponent as LibrarySVG } from "App/common/icons/library.svg";
import { matchAppPath } from "App/modules/utils";
import { useViewport } from "App/common/useViewport";
import { GlobalHelpMenu } from "App/components/GlobalHelp";
import { Notifications } from "App/components/Layout/Notifications";
import { Navigation } from "App/components/Navigation";

let firstLoad = true;

export const GlobalSidebar = () => {
  const history = useHistory();
  const { closeSidebar, openSidebar } = useSidebarContext();
  const { user: currentUser } = useAuth();
  const location = useLocation();
  const userMenuRef = useRef<HTMLButtonElement>();
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const { vw } = useViewport();
  const [isViewingLibrary, setIsViewingLibrary] = useState(
    location.pathname === routePaths.library,
  );
  const isMobile = vw < 450;

  const isLoggedIn = !!currentUser;

  useEffect(() => {
    if (!firstLoad) return;
    if (isLoggedIn) {
      if (isMobile && !matchAppPath(routePaths.root)) return;
      openSidebar(true);
      firstLoad = false;
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setIsViewingLibrary(location.pathname === routePaths.library);
  }, [location.pathname]);

  if (!isLoggedIn) return null;

  return (
    <div
      className="highnote-global-side-nav"
      data-cypress-id="highnote-global-side-nav"
    >
      <Navigation className="highnote-sidebar-header" hideBackButton>
        <Link to="/" data-cypress-id="highnote-logo" className="logo">
          <LogoFullSVG />
        </Link>

        <Notifications />

        <button
          ref={userMenuRef}
          className="open-user-menu"
          onClick={() => setUserMenuOpen(true)}
        >
          <ConnectedUserAvatar
            userId={currentUser.id}
            // alias={currentUser.isAnonymous && currentUser.name}
            size={USER_AVATAR_SIZE.LARGE}
          />
          <EllipsisVerticalSVG />
        </button>
        <Menu
          className="primary-navigation-user-menu"
          anchorEl={userMenuRef.current}
          onClose={() => {
            setUserMenuOpen(false);
          }}
          open={isUserMenuOpen}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem disabled>{currentUser.name}</MenuItem>
          <MenuItem
            className="bottom-spacer"
            onClick={() => {
              setUserMenuOpen(false);
              closeSidebar();
            }}
          >
            <Link to={"/settings/profile"}>Account</Link>
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push("/logout");
            }}
          >
            Log out
          </MenuItem>
        </Menu>
      </Navigation>

      <ErrorBoundary name="GlobalSidebar">
        <div className="highnote-global-side-nav-content">
          <section className="library-link">
            <List>
              <ListItemButton
                selected={!isMobile && isViewingLibrary}
                onClick={() => {
                  closeSidebar();
                }}
              >
                <Link to={"/library"}>
                  <LibrarySVG className="library-svg" />
                  <span className="library-text">Library</span>
                </Link>
              </ListItemButton>
            </List>
          </section>

          <section className="plan-limit-callout">
            <PlanLimitCallout />
          </section>

          <section>
            <SpacePicker />
          </section>
        </div>
      </ErrorBoundary>

      <GlobalHelpMenu />
    </div>
  );
};
