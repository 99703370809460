import "./GlobalAudioPlayer.scss";
import { daw, useDAW, useDAWTrack } from "@highnote/daw/src";
import React, { useEffect, useMemo } from "react";
import { SeekableLineform } from "App/common/AudioPlayer";
import { useTheme } from "App/common/ThemeProvider";
import { AudioPlayToggle } from "App/common/AudioPlayer/AudioPlayToggle";
import { ReactComponent as PlayCircleHollowSVG } from "App/common/icons/play-circle-hollow.svg";
import { ReactComponent as PauseCircleHollowSVG } from "App/common/icons/pause-circle-hollow.svg";
import { getDAWTrackId, useGlobalAudioPlayer, useHTMLAudioPlayer } from ".";
import { VolumeControl } from "../VolumeControl";
import {
  formatDuration,
  isFilePlayable,
} from "@highnote/server/src/core/shared-util";
import {
  ThumbnailPreview,
  useTrackArtworkUrl,
} from "App/common/ThumbnailPreview";
import {
  AUDIO_QUALITY,
  SUBSCRIPTION_TIER,
  Track,
} from "@highnote/server/src/core/entities";
import { TrackDescription, TrackTitle } from "../EntityTable/EntityPreview";
import { AudioProcessingIcon } from "../AudioProcessingIcon";
import { ToggleAudioQuality } from "./ToggleAudioQuality";
import { TRACK_EDITOR_TAB } from "App/components/TrackEditor";
import {
  TrackEditorProvider,
  useTrackEditor,
} from "App/components/useTrackEditor";
import { getAuthId, useAuth } from "../Auth";
import { highnote } from "@highnote/server/src/sdk";
import { BaseMediaControls } from "./BaseMediaControls";
import { useSpaceRaw } from "../useEntities";
import { StatusIndicator } from "App/common/StatusIndicator";
import { useLocation } from "react-router";

const GlobalAudioTrackPreviewUI = ({
  track,
  onClick,
  children,
}: {
  track: Track;
  onClick: () => void;
  children?: React.ReactNode;
}) => {
  const { source, fallback } = useTrackArtworkUrl({ track });
  const { openTrackEditor } = useTrackEditor();
  if (!track) return null;

  return (
    <ThumbnailPreview
      className="global-audio-player-track-preview"
      title={<TrackTitle onClick={onClick} track={track} />}
      description={
        children || (
          <TrackDescription
            onClick={() =>
              openTrackEditor({ track, initialTab: TRACK_EDITOR_TAB.VERSIONS })
            }
            track={track}
            showTrackTime
          />
        )
      }
      iconFallback={fallback}
      iconSource={source}
    />
  );
};

export const GlobalAudioTrackPreview = ({
  track,
  onClick,
  children,
}: {
  track: Track;
  onClick: () => void;
  children?: React.ReactNode;
}) => {
  return (
    <TrackEditorProvider>
      <GlobalAudioTrackPreviewUI track={track} onClick={onClick}>
        {children}
      </GlobalAudioTrackPreviewUI>
    </TrackEditorProvider>
  );
};

const GlobalAudioSeeker = () => {
  const { nowPlaying, seek } = useGlobalAudioPlayer();
  const { isActive, currentDuration, currentTime, onSeek, useHTMLPlayer } =
    useHTMLAudioPlayer();
  const dawTrack = useDAWTrack(nowPlaying.id);

  const handleSeek = (ts: number) => {
    isActive ? onSeek(ts) : seek(ts + (dawTrack?.startTime || 0));
  };

  return (
    <SeekableLineform
      currentTime={
        useHTMLPlayer ? currentTime : daw.getTrackTime(nowPlaying.id)
      }
      duration={
        (useHTMLPlayer
          ? currentDuration
          : nowPlaying?.file?.metadata?.duration || dawTrack?.duration) || 0
      }
      onSeek={handleSeek}
    />
  );
};

const GlobalAudioCurrentTime = () => {
  useDAW(["currentTime"]);
  const { nowPlaying } = useGlobalAudioPlayer();
  const { useHTMLPlayer, currentTime: HTMLPlayerCurrentTime } =
    useHTMLAudioPlayer();
  const dawTrackId = getDAWTrackId(nowPlaying?.file?.id, nowPlaying?.track);
  const currentTime = useHTMLPlayer
    ? HTMLPlayerCurrentTime
    : daw.getTrackTime(dawTrackId);
  return <>{formatDuration(currentTime)}</>;
};

export const GlobalAudioCurrentFormattedTime = () => {
  const { nowPlaying } = useGlobalAudioPlayer();
  const { useHTMLPlayer, currentDuration } = useHTMLAudioPlayer();
  const dawTrackId = getDAWTrackId(nowPlaying?.file?.id, nowPlaying?.track);
  const dawTrack = daw.getTrack(dawTrackId);
  const duration = useHTMLPlayer ? currentDuration : dawTrack?.duration || 0;

  return (
    <>
      <GlobalAudioCurrentTime />
      <span> / {formatDuration(duration)}</span>
    </>
  );
};

const localTrackPlayCache: Record<string, boolean> = {};

export const GlobalAudioPlayer = ({
  preview,
  onNext,
  onPrev,
  seekable = true,
}: {
  preview: React.ReactNode;
  onNext?: () => void;
  onPrev?: () => void;
  seekable?: boolean;
}) => {
  useDAW(["isPlaying", "tracks", "volume", "isLoading"]);
  const { user } = useAuth();
  const { theme } = useTheme();
  const {
    nowPlaying,
    prevTrackId,
    nextTrackId,
    isActive,
    play,
    pause,
    goToTrack,
    quality,
    fetchAudioFiles,
    setAudioQuality,
    seekTrack,
    skipAudioMode,
  } = useGlobalAudioPlayer();
  const {
    useHTMLPlayer,
    currentDuration,
    getTrack,
    isActive: isHTMLPlayerActive,
    isLoading,
    isPlaying,
    onTogglePlay,
    seekTrack: HTMLseekTrack,
  } = useHTMLAudioPlayer();
  const { entity: currentSpace, loading: currentSpaceLoading } = useSpaceRaw();

  const dawTrackId = getDAWTrackId(nowPlaying?.file?.id, nowPlaying?.track);
  const dawTrack = daw.getTrack(dawTrackId);
  const HTMLTrack = getTrack(nowPlaying?.id);

  const isAudioQualityLocked = useMemo(() => {
    if (!currentSpaceLoading && currentSpace) {
      return (
        currentSpace.subscriptionTier === SUBSCRIPTION_TIER.PRO &&
        currentSpace.audioQualityLocked
      );
    }
    return false;
  }, [currentSpace, currentSpaceLoading]);

  useEffect(() => {
    if (isAudioQualityLocked) {
      setAudioQuality(AUDIO_QUALITY.ORIGINAL, true);
    }
  }, [currentSpace, quality, setAudioQuality]);

  useEffect(() => {
    const trackId = nowPlaying?.track?.id;
    if (!trackId || !daw.state.isPlaying) return;
    const trackPlayId = `${trackId}:${getAuthId()}`;

    // Only trigger track plays the first time in a browser session
    if (!localTrackPlayCache[trackPlayId]) {
      highnote.addTrackPlay({ trackId, userId: getAuthId() });
    }

    localTrackPlayCache[trackPlayId] = true;
  }, [daw.state.isPlaying, nowPlaying?.track?.id, user?.id]);

  useEffect(() => {
    const onKeyup = (e: KeyboardEvent) => {
      if (e.code !== "Space") return;
      if (["TEXTAREA", "INPUT"].includes((e.target as HTMLElement)?.tagName))
        return;
      e.preventDefault();
      if (useHTMLPlayer) {
        onTogglePlay();
        return;
      }
      if (daw.state.isPlaying) pause();
      else play();
    };
    // Focusing the play button must occur on keydown or another button may be focused briefly
    // between keydown and keyup
    const onKeydown = (e: KeyboardEvent) => {
      if (e.code !== "Space") return;
      if (["TEXTAREA", "INPUT"].includes((e.target as HTMLElement)?.tagName))
        return;
      e.preventDefault();
      // Focus the play button
      const playButton = document.getElementsByClassName(
        "highnote-audio-play-toggle play-pause",
      );
      if (playButton.length) {
        (playButton[0] as HTMLElement).focus();
      }
    };
    window.addEventListener("keyup", onKeyup);
    window.addEventListener("keydown", onKeydown);

    return () => {
      window.removeEventListener("keyup", onKeyup);
      window.removeEventListener("keydown", onKeydown);
    };
  }, [nowPlaying]);

  const prev = () => {
    if (skipAudioMode !== "full-skip") {
      useHTMLPlayer ? HTMLseekTrack("back") : seekTrack("back");
    } else {
      if (
        (!prevTrackId && !onPrev) ||
        daw.state.currentTime > dawTrack?.startTime + 1
      ) {
        goToTrack(nowPlaying.id, 0);
        return;
      }
      if (onPrev) {
        onPrev();
        return;
      }

      goToTrack(prevTrackId);
    }
  };

  const next = () => {
    if (skipAudioMode !== "full-skip") {
      useHTMLPlayer ? HTMLseekTrack("forward") : seekTrack("forward");
    } else {
      if (onNext) {
        onNext();
        return;
      }

      goToTrack(nextTrackId);
    }
  };

  const duration = useHTMLPlayer ? currentDuration : dawTrack?.duration || 0;
  const error =
    nowPlaying?.file?.processingErrorV3 ||
    (useHTMLPlayer ? HTMLTrack?.error : dawTrack?.error);
  const isPlayable = isFilePlayable(nowPlaying?.file);

  return (
    <div
      className="highnote-global-audio-player"
      data-cypress-id="global-audio-player"
      data-is-active={useHTMLPlayer ? isHTMLPlayerActive : isActive}
      data-is-playable={
        useHTMLPlayer ? isLoading : dawTrack && !daw.state.isLoading
      }
      data-has-error={!!error}
      data-is-playing={useHTMLPlayer ? isPlaying : daw.state.isPlaying}
      data-theme={theme}
      data-is-empty={!nowPlaying}
    >
      <div className="content-top">{seekable && <GlobalAudioSeeker />}</div>
      <div className="content-bottom">
        <section className="left">
          {preview}
          {nowPlaying?.track && !isPlayable && (
            <AudioProcessingIcon
              file={nowPlaying?.file}
              onRetry={() => fetchAudioFiles(nowPlaying.track)}
            />
          )}
        </section>
        <section className="center">
          <div className="time current">
            {isPlayable && (
              <>
                <GlobalAudioCurrentTime />
                <span className="mobile-only">
                  {" "}
                  / {formatDuration(duration)}
                </span>
              </>
            )}
          </div>
          <BaseMediaControls
            isNextDisabled={!nextTrackId && !onNext}
            onPrevClick={prev}
            onNextClick={next}
          >
            <div className="toggle-play">
              <GlobalAudioIcon disabled={!isPlayable} useLoadProgress />
            </div>
          </BaseMediaControls>
          <div className="time duration">
            {isPlayable && formatDuration(duration)}
          </div>
        </section>
        <section className="right">
          <ToggleAudioQuality />
          <VolumeControl />
        </section>
      </div>
    </div>
  );
};

const LoadProgress = () => {
  const { nowPlaying } = useGlobalAudioPlayer();
  const dawTrack = daw.getTrack(nowPlaying.id);
  if (!dawTrack) return null;

  const loadProgress = dawTrack.loadProgress || 0;
  const loadRadius = 22;
  const loadCircumference = Math.PI * 2 * loadRadius;
  const offset = (1 - loadProgress) * loadCircumference;

  return (
    <div className="load-progress">
      <label>{Math.floor(loadProgress * 100)}%</label>
      <svg>
        <circle
          cx="50%"
          cy="50%"
          r={loadRadius}
          strokeDashoffset={offset}
          strokeDasharray={loadCircumference}
        />
      </svg>
    </div>
  );
};

export const GlobalAudioIcon = ({
  disabled,
  useLoadProgress,
  canUpdateQueueOnPlay,
}: {
  disabled?: boolean;
  useLoadProgress?: boolean;
  canUpdateQueueOnPlay?: boolean;
}) => {
  useDAW(["isPlaying", "isLoading", "tracks"]);
  const { pathname } = useLocation();
  const {
    nowPlaying,
    play,
    pause,
    setQueue,
    currentQueueOrder,
    nextQueueOrder,
  } = useGlobalAudioPlayer();
  const {
    useHTMLPlayer,
    isLoading: isHTMLPlayerLoading,
    isPlaying: isHTMLPlayerPlaying,
    onTogglePlay: HTMLPlayerTogglePlay,
  } = useHTMLAudioPlayer();
  // Specific logic used for the Space header, to update the queue to the current page
  // when the play button is clicked.
  const isNowPlayingPreviousSpace = canUpdateQueueOnPlay
    ? pathname !== currentQueueOrder.pageId
    : false;
  const isLoading = useHTMLPlayer ? isHTMLPlayerLoading : daw.state.isLoading;

  // If the current page is not the same as the current queue order page, we want to
  // ensure that the button does not indicate that it is playing, as the current queue for the page.
  const isPlaying = isNowPlayingPreviousSpace
    ? false
    : useHTMLPlayer
      ? isHTMLPlayerPlaying
      : daw.state.isPlaying;
  const isPlayerReady = useHTMLPlayer
    ? !isHTMLPlayerLoading
    : !daw.state.isLoading && daw.state.tracks.length > 0;

  // If the current page is not the same as the current queue order page,
  // check if there are tracks in the next queue order to determine if the
  // play button should be enabled.
  const isPlayable = isNowPlayingPreviousSpace
    ? nextQueueOrder.queue.length > 0
    : !!nowPlaying.id && !disabled && isPlayerReady;

  const onTogglePlay = () => {
    if (!isPlayable) return;
    if (canUpdateQueueOnPlay) {
      // We setQueue to update the current queue order to the current page
      // GlobalAudioIcon is used in the header, and GlobalAudioPlayer, so
      // we want to differentiate between the two. GlobalAudioPlayer should
      // not update the queue, as it is already set, where as the Space header
      // should update the queue to the current page.
      const nextTrackToPlayInQueue = nextQueueOrder.queue[0];
      setQueue({
        type: "UPDATE_CURRENT_FROM_NEXT",
        _tracks: [],
        currentPageId: pathname,
      });
      if (nextTrackToPlayInQueue) {
        play(nextTrackToPlayInQueue);
        return;
      }
    }
    if (useHTMLPlayer) {
      HTMLPlayerTogglePlay();
      return;
    }
    if (daw.state.isPlaying) {
      pause();
      return;
    }
    play();
  };

  return (
    <AudioPlayToggle
      wrapperClassName="highnote-global-audio-icon"
      cypressId="global-audio-icon"
      isPlayable={isPlayable}
      isPlaying={isPlaying}
      disabled={!isPlayable}
      isLoading={daw.state.isLoading}
      renderToggleButton={!useLoadProgress || !daw.state.isLoading}
      onTogglePlay={onTogglePlay}
    >
      {useLoadProgress && isLoading && <LoadProgress />}
    </AudioPlayToggle>
  );
};

export const TrackAudioIcon = ({
  dawTrackId,
  fallback,
  trackVersionId,
}: {
  dawTrackId?: string;
  fallback?: React.ReactNode;
  trackVersionId?: string;
}) => {
  useDAW(["isPlaying", "isLoading"]);
  const { pathname } = useLocation();
  const {
    nowPlaying,
    getQueueItem,
    play,
    pause,
    fetchAudioFiles,
    setQueue,
    currentQueueOrder,
    queueTrackVersion,
  } = useGlobalAudioPlayer();
  const {
    useHTMLPlayer,
    isLoading,
    isPlaying: isHTMLPlayerPlaying,
    playTrack,
    onTogglePlay,
  } = useHTMLAudioPlayer();
  const queueItem = getQueueItem(dawTrackId, trackVersionId);

  const isQueueable = queueItem && queueItem.file;
  const isNowPlaying = isQueueable && nowPlaying?.id === dawTrackId;
  const isPlaying =
    queueItem.file?.id === nowPlaying.file?.id &&
    isNowPlaying &&
    (useHTMLPlayer ? isHTMLPlayerPlaying : daw.state.isPlaying);
  const isPlayable = isQueueable && isFilePlayable(queueItem.file);
  const isNowLoading =
    (useHTMLPlayer ? isLoading : daw.state.isLoading) && isNowPlaying;

  if (!isQueueable || !isFilePlayable(queueItem.file)) {
    if (isQueueable) {
      return (
        <AudioProcessingIcon
          file={queueItem.file}
          dawTrackId={dawTrackId}
          onRetry={() => fetchAudioFiles(queueItem.track)}
        />
      );
    }
    return (
      <StatusIndicator
        isLoading
        errorMessage={queueItem?.file?.processingErrorV3}
      />
    );
  }

  const handleOnTogglePlay = () => {
    if (trackVersionId) {
      // Set queue to only the track, to differentiate from the main
      // top level queue of the Space page. Playing a track version
      // should only be a temporary queue, and only play that track version once.
      setQueue({
        type: "SET_VERSION",
        _tracks: [queueItem.track],
        currentPageId: pathname,
      });
      queueTrackVersion(dawTrackId, trackVersionId);
      // TODO: daw.__minDuration is not working as expected
      daw.__minDuration = queueItem.file.metadata.duration;
      play(dawTrackId);
      return;
    }
    if (pathname !== currentQueueOrder.pageId) {
      setQueue({
        type: "UPDATE_CURRENT_FROM_NEXT",
        _tracks: [],
        currentPageId: pathname,
      });
    }
    if (useHTMLPlayer) {
      if (nowPlaying?.id === dawTrackId) {
        onTogglePlay();
        return;
      }
      playTrack(dawTrackId);
      return;
    }
    if (!isPlayable || isNowLoading) return;

    if (isNowPlaying) {
      if (daw.state.isPlaying) pause();
      else play();
      return;
    }

    play(dawTrackId);
  };

  return (
    <AudioPlayToggle
      wrapperClassName="highnote-track-audio-icon"
      isNowPlaying={Boolean(isNowPlaying)}
      isPlayable={isPlayable}
      isPlaying={isPlaying}
      renderToggleButton={isQueueable && isFilePlayable(queueItem.file)}
      isLoading={isNowLoading}
      pauseIcon={<PauseCircleHollowSVG />}
      playIcon={<PlayCircleHollowSVG />}
      onTogglePlay={handleOnTogglePlay}
    >
      {isQueueable && !isFilePlayable(queueItem.file) && (
        <AudioProcessingIcon
          file={queueItem.file}
          dawTrackId={dawTrackId}
          onRetry={() => fetchAudioFiles(queueItem.track)}
        />
      )}
      <span className="fallback">{fallback}</span>
    </AudioPlayToggle>
  );
};
