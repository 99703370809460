import React from "react";
import { ReactComponent as CaretDownSVG } from "App/common/icons/toggle-filled-down.svg";
import { ReactComponent as CaretRightSVG } from "App/common/icons/toggle-hollow-right.svg";
import { BUTTON_THEME, Button } from "App/core/Button";
import { EntityRowConfig } from "./config";

export const EntityIconExpand = ({
  isExpanded,
  toggleExpanded,
}: {
  row: EntityRowConfig;
  isExpanded: boolean;
  toggleExpanded: () => void;
}) => {
  return (
    <div className="highnote-entity-icon entity-icon-expand">
      <Button
        theme={BUTTON_THEME.ICON}
        type="button"
        className="expander"
        onClick={toggleExpanded}
      >
        {isExpanded ? <CaretDownSVG /> : <CaretRightSVG />}
      </Button>
    </div>
  );
};
