import { Comment } from "@highnote/server/src/core/entities";
import { useEffect, useMemo, useRef, useState } from "react";
import { StatefulComment, useSpaceCommentsContext } from "./useSpaceComments";
import { useTrack } from "App/components/useEntities/useTrack";

export type Card = {
  id: Id;
  createdAt: number;
  timestamp: number;
  comment?: Comment;
};

const getCardsFromComments = (comments: StatefulComment[] = []) => {
  const rootComments = comments.filter(
    (c) => !c.parentComment && typeof c.timestamp === "number",
  );

  return rootComments.map((comment) => ({
    id: comment.id,
    hidden: false,
    draft: false,
    createdAt: comment.createdAt,
    timestamp: comment.timestamp,
    comment,
  }));
};

const sortCards = (card1: Card, card2: Card) => {
  if (card1.timestamp < card2.timestamp) return -1;
  if (card1.timestamp > card2.timestamp) return 1;
  if (card1.timestamp === card2.timestamp) {
    if (card1.createdAt < card2.createdAt) return -1;
    if (card1.createdAt >= card2.createdAt) return 1;
  }
};

export const useCommentCards = () => {
  const { filteredComments } = useSpaceCommentsContext();
  const { currentTrackVersion } = useTrack();
  const cardsRef = useRef<Card[]>(getCardsFromComments(filteredComments));
  const getOrderedCards = () =>
    cardsRef.current.length > 0 ? cardsRef.current.sort(sortCards) : [];
  const orderedCardsRef = useRef(getOrderedCards());
  const [orderedCards, _setOrderedCards] = useState<Card[]>(
    orderedCardsRef.current,
  );

  const setOrderedCards = () => {
    orderedCardsRef.current = [...getOrderedCards()];
    _setOrderedCards(orderedCardsRef.current);
  };

  useEffect(() => {
    const allCards = getCardsFromComments(filteredComments);
    cardsRef.current = allCards.filter((c) =>
      c.comment.trackVersionIds?.includes(currentTrackVersion?.id),
    );
    setOrderedCards();
  }, [filteredComments, currentTrackVersion?.id]);

  return useMemo(() => {
    return {
      orderedCards: orderedCardsRef.current,
    };
  }, [orderedCards]);
};
