import "./Navigation.scss";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import React, { ReactElement } from "react";
import { Button, BUTTON_THEME } from "App/core/Button";
import { ReactComponent as CaretLeftSVG } from "App/common/icons/caret-left.svg";
import { useHistory } from "react-router-dom";
import { useAuth } from "App/components/Auth";
import { ReactComponent as HighnoteLogoSVG } from "App/common/icons/hn-mark.svg";

export const NavigationToolbar = ({ items }: { items: ReactElement[] }) => {
  const wrappedItems = items.map((item: ReactElement, index: number) => (
    <div key={index} className="navigation-toolbar-item">
      {item}
    </div>
  ));
  return <div className="highnote-navigation-toolbar">{wrappedItems}</div>;
};

export const Navigation = ({
  children,
  className,
  showIndent,
  showLogo,
  hideBackButton,
}: {
  children: React.ReactNode;
  className?: string;
  showIndent?: boolean;
  showLogo?: boolean;
  hideBackButton?: boolean;
}) => {
  const { user } = useAuth();
  const { goBack } = useHistory();
  const isLoggedIn = !!user;

  return (
    <ErrorBoundary name="Navigation">
      <div
        className={`highnote-navigation ${className || ""} ${
          showLogo ? "showLogo" : ""
        }`}
        data-show-indent={isLoggedIn && !!showIndent}
      >
        {!hideBackButton && (
          <div className="back-link">
            <Button
              className="back-link-button"
              onClick={goBack}
              theme={BUTTON_THEME.ICON}
              aria-label="Go back"
            >
              <CaretLeftSVG />{" "}
            </Button>
          </div>
        )}

        {showLogo && (
          <a href="https://highnote.fm" className="highnote-logo-link">
            <HighnoteLogoSVG className="highnote-logo" />
          </a>
        )}
        {children}
      </div>
    </ErrorBoundary>
  );
};
