import * as Sentry from "@sentry/browser";
import { EVENT_ID, GROUP_BY_EVENT } from "@highnote/server/src/core/entities";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { v4 as uuidv4 } from "uuid";
import { getAuthId, useAuth } from "App/components/Auth";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  ADMIN_UID,
  checkIsUserAnonymous,
} from "@highnote/server/src/core/shared-util";
import { useLocation } from "react-router";

type SegmentContextProps = {
  trackEvent: (eventId: string, properties?: object, uid?: string) => void;
};

const SegmentContext = createContext<SegmentContextProps>({
  trackEvent: () => {},
});

export const SegmentContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useAuth();
  const userId = user?.id || getAuthId() || uuidv4();
  const routerLocation = useLocation();
  const lastPath = useRef<string>();

  // Call Segment's page() method every time the URL changes.
  // This is vital to AppCues working correctly.
  useEffect(() => {
    if (lastPath.current === routerLocation.pathname) return;
    window?.analytics?.page();
    lastPath.current = routerLocation.pathname;
  }, [routerLocation]);

  useEffect(() => {
    const traits = user || { isAnonymous: true };

    const userData = {
      ...traits,
    };

    if (localStorage.getItem("SHOW_SEGMENT_LOGS")) {
      console.log("[SEGMENT IDENTIFY]", userId);
      // Add an extra row to the console table because the
      // last one gets hidden by scrollbars.
      console.table({ ...userData, "[blank]": "[blank]" });
    }

    try {
      Sentry.setUser({ id: userId });

      if (!shouldFilterOutEvent(userId)) {
        // Identify user in Segment
        if (!checkIsUserAnonymous(userId)) {
          window.analytics.identify(userId, userData);
        }

        // Identify user in FullStory
        /* eslint-disable @typescript-eslint/no-explicit-any */
        (window as any).FS.identify(userId);
        (window as any).FS.setUserVars({
          /* eslint-enable @typescript-eslint/no-explicit-any */
          displayName: user?.name || "Anonymous", // Unique property to FullStory
          ...userData,
        });

        if (user?.email) {
          // Identify user in Customer.IO
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any)._cio.identify({
            // IMPORTANT: We use `email` as `id` here because when customers arrive from the marketing
            // site, they don't necessarily have a user id. So we ALWAYS match via email instead.
            id: user.email,
          });
        }
      }
    } catch (e) {
      // Don't worry if these don't fire - they're not critical to app functionality.
      console.log("Could not fire analytics calls:", e);
    }
  }, [user]);

  const trackEvent = useCallback(
    (eventId: EVENT_ID, properties?: object, uid?: string) => {
      console.log(eventId, uid);
      // cheap identify for outliers like log in / sign up events
      if (uid) {
        window.analytics?.identify(uid);
      }

      let fullStorySessionURL;
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fullStorySessionURL = (window as any).FS?.getCurrentSessionURL();
      } catch (e) {
        console.error(e);
      }

      // The data we want to see show up in Segment.
      const allProperties = {
        full_story_session_url: fullStorySessionURL,
        origin: location.origin,
        url: location.href,
        group_id: GROUP_BY_EVENT[eventId],
        ...(properties || {}),
      };

      if (localStorage.getItem("SHOW_SEGMENT_LOGS")) {
        console.log("[SEGMENT TRACK]", eventId);
        // Add an extra row to the console table because the
        // last one gets hidden by scrollbars.
        console.table({ ...allProperties, "[blank]": "[blank]" });
      }

      if (!shouldFilterOutEvent(userId)) {
        window.analytics.track(eventId, allProperties);
      }
    },
    [user],
  );

  const segmentContextValue = useMemo(() => ({ trackEvent }), [trackEvent]);

  return (
    <ErrorBoundary name="SegmentContextProvider">
      <SegmentContext.Provider value={segmentContextValue}>
        {children}
      </SegmentContext.Provider>
    </ErrorBoundary>
  );
};

export const useSegmentContext = () => useContext(SegmentContext);

/**
 * To save on costs, we don't want to track admin actions or actions performed on localhost. This
 * function identifies if either case is true on the client.
 */
const shouldFilterOutEvent = (userId: string): boolean => {
  // window.analytics is the variable Segment uses for its tracking API. We conditionally do not
  // load the Segment script when in development mode (see the handlebars html file): hence the
  // absence of the variable indicates we shouldn't be tracking.
  return !window.analytics || ADMIN_UID === userId;
};
