import "./SpaceChatSidebar.scss";
import React, { useMemo, useRef } from "react";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { SidebarHeader } from "../Sidebar";
import { useSpaceContext } from "App/common/useSpace";
import { ReactComponent as ArrowDownSVG } from "App/common/icons/arrow-down.svg";
import { ReactComponent as ArrowUpSVG } from "App/common/icons/arrow-up.svg";
import {
  SpaceCommentsContextProvider,
  useSpaceCommentsContext,
} from "App/common/useSpaceComments";
import { CommentContent } from "App/common/Comment/CommentContent";
import { CommentContextProvider } from "App/common/useComment";
import { NewCommentForm } from "App/common/Comment/NewCommentForm";
import {
  CommentMeta,
  COMMENT_META_VARIANT,
} from "App/common/Comment/CommentMeta";
import { UserAvatarGroup } from "App/common/UserAvatar/UserAvatarGroup";
import { ReactComponent as SendSVG } from "App/common/icons/send.svg";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "../../../core/Button";
import { useScrollingComments } from "./useScrollingComments";
import { useAuth } from "App/components/Auth";

import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { PermissionTooltip } from "App/components/PermissionTooltip";
import { usePinnedCommentId } from "App/components/usePinnedCommentId";

const FIVE_MIN = 1000 * 60 * 5;

const ChatComments = () => {
  const { isAllowed, isPublicView } = useAuth();
  const { space, spaceId } = useSpaceContext();
  const {
    comments,
    fetchOlderComments,
    olderCommentsLoading,
    allCommentsFetched,
  } = useSpaceCommentsContext();
  const scrollableRef = useRef<HTMLDivElement>();
  const highlightedCommentRef = useRef<HTMLDivElement>();
  const {
    showNewerCommentsPrompt,
    showOlderCommentsPrompt,
    oldestUnread,
    scrollToNewest,
  } = useScrollingComments({ scrollableRef, highlightedCommentRef });
  const pinnedCommentId = usePinnedCommentId();
  const canCommentInSpace = isAllowed(PERMISSION.TO_COMMENT_IN_SPACE, {
    space,
  });

  const commentElements = useMemo(() => {
    let lastAuthorId: Id;
    let lastCreatedAt: number;

    return comments
      .sort((a, b) => {
        return a.createdAt - b.createdAt;
      })
      .map((comment) => {
        const sameAuthorAsPrevious = comment.createdBy === lastAuthorId;
        const timeDiff = comment.createdAt - lastCreatedAt;
        const sameTimeAsPrevious = lastCreatedAt && timeDiff < FIVE_MIN;
        const isOldestUnread = oldestUnread && comment.id === oldestUnread.id;
        const isHighlighted = pinnedCommentId === comment.id;
        const ref = isHighlighted ? highlightedCommentRef : undefined;
        lastAuthorId = comment.createdBy;
        lastCreatedAt = comment.createdAt;

        const groupWithPrevious = sameAuthorAsPrevious && sameTimeAsPrevious;

        return (
          <CommentContextProvider key={comment.id} id={comment.id}>
            <div
              className="chat-comment"
              data-is-highlighted={isHighlighted}
              data-is-deleted={comment.isDeleted}
              data-has-meta={!sameAuthorAsPrevious}
              ref={ref}
            >
              {isOldestUnread && (
                <div className="unread-marker">
                  <ArrowDownSVG />
                  New Messages
                </div>
              )}
              {!groupWithPrevious && <CommentMeta />}
              <div className="comment-content">
                <CommentContent />
                {groupWithPrevious && (
                  <CommentMeta variant={COMMENT_META_VARIANT.INLINE} />
                )}
              </div>
            </div>
          </CommentContextProvider>
        );
      });
  }, [comments, oldestUnread, pinnedCommentId]);

  const commentAuthors = Object.keys(space.rolesV2 || {}).map((id) => ({
    id,
  }));

  const showNewCommentForm =
    !isPublicView(space) ||
    isAllowed(PERMISSION.TO_COMMENT_IN_SPACE, { space });

  return (
    <>
      <section className="context-info">
        <p>You are chatting with everyone in {space.name}</p>
        <UserAvatarGroup users={commentAuthors} />
      </section>
      <section className="highnote-chat-comments">
        <div className="older-comments-overlay">
          {showOlderCommentsPrompt &&
            !olderCommentsLoading &&
            !allCommentsFetched && (
              <Button
                size={BUTTON_SIZE.XSMALL}
                theme={BUTTON_THEME.SECONDARY}
                onClick={fetchOlderComments}
              >
                See older messages <ArrowUpSVG />
              </Button>
            )}

          {olderCommentsLoading && (
            <Button
              size={BUTTON_SIZE.XSMALL}
              theme={BUTTON_THEME.SECONDARY}
              disabled
            >
              Loading...
            </Button>
          )}
        </div>

        <div className="scrollable-comments-wrapper">
          <div className="scrollable-comments" ref={scrollableRef}>
            {allCommentsFetched && (
              <p className="no-older-comments">No older comments found.</p>
            )}
            {commentElements}
          </div>
          <div className="newer-comments-overlay">
            {showNewerCommentsPrompt && !showOlderCommentsPrompt && (
              <Button
                size={BUTTON_SIZE.XSMALL}
                theme={BUTTON_THEME.SECONDARY}
                onClick={scrollToNewest}
              >
                See newer messages <ArrowDownSVG />
              </Button>
            )}
          </div>
        </div>

        {showNewCommentForm && (
          <PermissionTooltip
            permission={PERMISSION.TO_COMMENT_IN_SPACE}
            space={space}
          >
            <div>
              <NewCommentForm
                spaceId={spaceId}
                saveButtonChildren={<SendSVG />}
                isDisabled={!canCommentInSpace}
              />
            </div>
          </PermissionTooltip>
        )}
      </section>
    </>
  );
};

export const SpaceChatSidebar = () => {
  const { space } = useSpaceContext();

  if (!space) return null;

  return (
    <ErrorBoundary name="SpaceChatSidebar">
      <div className="highnote-space-chat">
        <SidebarHeader title={"Chat"} subtitle={space.name} />

        <SpaceCommentsContextProvider spaceId={space.id} trackId={null}>
          <ChatComments />
        </SpaceCommentsContextProvider>
      </div>
    </ErrorBoundary>
  );
};
